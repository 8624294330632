import { all, fork } from 'redux-saga/effects';

import getInfo from './getInfo';
import sendLink from './sendLink';
import getSinglePayments from './getSinglePayments';
import getClients from './getClients';
import getSubscription from './getSubscription';
import refetchSubscription from './refetchSubscription';
import refetchSinglePayments from './refetchSinglePayments';
import refetchClients from './refetchClients';

export default all([
  fork(getInfo),
  fork(sendLink),
  fork(getSinglePayments),
  fork(getSubscription),
  fork(refetchSubscription),
  fork(refetchSinglePayments),
  fork(getClients),
  fork(refetchClients),
]);
