import { createMuiTheme } from '@material-ui/core/styles';

const palette = {
  text: {
    primary: '#011638',
    secondary: '#BCBEC0'
  },
  divider: '#F1F2F2',
  primary: {
    main: '#3772FF',
    light: '#BED0FC',
    dark: '#011638',
  },
  info: {
    main: '#1C5FFE',
    dark: '#0044E8'
  },
  success: {
    main: '#4BB462'
  },
  secondary: {
    main: '#EDAB1E',
  },
  error: {
    main: '#E20338'
  },
  warning: {
    light: '#F5FAFE',
    main: '#F2F8FD',
    contrastText: '#E7EDFD',
    dark: '#6D6E71'
  },
  background: {
    default: '#F2F8FD',
    paper: '#F5FAFE'
  }
};

const theme = createMuiTheme({
  palette,
  breakpoints: {
    values: {
      xs: 0,
      sm: 601,
      md: 1024,
      lg: 1281,
      xl: 1367,
    },
  },
  typography: {
    fontFamily: 'Montserrat'
  },
});

export default theme;
