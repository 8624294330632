import { defineMessages } from 'react-intl';

export default defineMessages({
  onBoardingTitle: {
    id: 'onBoarding.mainTitle',
    defaultMessage: 'ChalicePay',
    description: 'Onboarding title'
  },
  onBoardingDescription: {
    id: 'onBoarding.secondTitle',
    defaultMessage: 'ChalicePay is the billing system for professional financial advisors which is based on "based on the fee-for-service model',
    description: 'Onboarding description'
  }
});
