import { defineMessages } from 'react-intl';

export default defineMessages({
  employerINField: {
    id: 'fields.employerIN',
    defaultMessage: 'Employer identification number',
    description: 'Field label'
  },
  companyStreetAddressField: {
    id: 'fields.companyStreetAddress',
    defaultMessage: 'Company street address',
    description: 'Field label'
  },
  cityField: {
    id: 'fields.city',
    defaultMessage: 'City',
    description: 'Field label'
  },
  stateField: {
    id: 'fields.state',
    defaultMessage: 'State',
    description: 'Field label'
  },
  zipCodeField: {
    id: 'fields.zipCode',
    defaultMessage: 'Zip Code',
    description: 'Field label'
  },
  companyNameError: {
    id: 'fields.companyName.error',
    defaultMessage: 'Company name is too long.',
    description: 'Field error label'
  },
  companyStreetAddressMaxError: {
    id: 'fields.companyStreetAddress.maxError',
    defaultMessage: 'Street address is too long.',
    description: 'Field label'
  },
  zipCodeErrorField: {
    id: 'fields.zipCode.error',
    defaultMessage: 'Zip code should contain from 5 up to 9 digits.',
    description: 'Field label'
  },
  companySiteError: {
    id: 'fields.companySite.error',
    defaultMessage: 'Please enter a valid company website.',
    description: 'Field label'
  },
  phoneNumberError: {
    id: 'fields.phoneNumber.error',
    defaultMessage: 'Phone number should contain only digits.',
    description: 'Field label'
  },
  employerINErrorField: {
    id: 'fields.employerIN.error',
    defaultMessage: 'Employer identification number should contain nine digits.',
    description: 'Field label'
  },
  companyStreetAddressMinError: {
    id: 'fields.companyStreetAddress.minError',
    defaultMessage: 'Street address should be at least 3 letters long.',
    description: 'Field label'
  },
  companySiteField: {
    id: 'fields.companySite',
    defaultMessage: 'Company website',
    description: 'Field label'
  },
  phoneNumberField: {
    id: 'fields.phoneNumber',
    defaultMessage: 'Phone number',
    description: 'Field label'
  },
  requiredMassage: {
    id: 'onBoarding.requiredFieldMassage',
    defaultMessage: 'Please fill in all required fields.',
    description: 'Message'
  },
  firstNameError: {
    id: 'fields.firstName.error',
    defaultMessage: 'First name is too long.',
    description: 'Field label'
  },
  firstNameInvalidError: {
    id: 'fields.firstName.invalidError',
    defaultMessage: 'First name contains invalid symbols.',
    description: 'Field label'
  },
  lastNameField: {
    id: 'fields.lastName',
    defaultMessage: 'Last name',
    description: 'Field label'
  },
  lastNameError: {
    id: 'fields.lastName.error',
    defaultMessage: 'Last name is too long.',
    description: 'Field label'
  },
  lastNameInvalidError: {
    id: 'fields.lastName.invalidError',
    defaultMessage: 'Last name contains invalid symbols.',
    description: 'Field label'
  },
  dateOfBirthField: {
    id: 'fields.dateOfBirth',
    defaultMessage: 'Date of birth',
    description: 'Field label'
  },
  secNumberField: {
    id: 'fields.secNumber',
    defaultMessage: 'Social security number',
    description: 'Field label'
  },
  secNumberError: {
    id: 'fields.secNumber.Error',
    defaultMessage: 'Social security number should contain nine digits.',
    description: 'Field label'
  },
  firstNameField: {
    id: 'fields.firstName',
    defaultMessage: 'First name',
    description: 'Field label'
  },
  addressStreetField: {
    id: 'fields.streetAddress',
    defaultMessage: 'Street address',
    description: 'Field label'
  },
  optionText: {
    id: 'onBoarding.accountInfoStep.option',
    defaultMessage: 'Please enter one of the two:',
    description: 'Navigation'
  },
  businessWebsiteField: {
    id: 'fields.businessWebsite',
    defaultMessage: 'Your business website',
    description: 'Button label'
  },
  professionalOccupationField: {
    id: 'fields.professionalOccupation',
    defaultMessage: 'Your professional occupation',
    description: 'Button label'
  },
  cancelButton: {
    id: 'buttons.cancel',
    defaultMessage: 'Cancel',
    description: 'Button label'
  },
  saveButton: {
    id: 'buttons.save',
    defaultMessage: 'Save',
    description: 'Button label'
  },
});
