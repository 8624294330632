import {
  put, takeLatest, call, select
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { toastr } from 'react-redux-toastr';

import { onBoardingError, setStep, submitCompanyStepSuccess } from '../../actions/onBoaring';
import { SubmitCompanyStepAction } from '../../actions/onBoaring/types';
import { ONBOARDING_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker({ payload }: SubmitCompanyStepAction): SagaIterator<void> {
  try {
    const store = yield select();
    const { data } = yield call(Api.userProfiles.updateAccountInfo, { values: payload, id: store.auth.userData.id });
    yield put(submitCompanyStepSuccess(data));

    yield put(setStep(2));
  } catch (e) {
    yield put(onBoardingError(e.message));
    toastr.error('Please check the entered data', e.response.data.detail.slice(28));
  }
}

export default function* watcher() {
  yield takeLatest(ONBOARDING_TYPES.SUBMIT_ACCOUNT_INFO_STEP, worker);
}
