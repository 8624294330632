import {
  put, takeLatest, call, select
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { advisorProfileError, refetchClientsSuccess } from '../../actions/AdvisorProfile';
import { ADVISOR_PROFILE_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker({ payload }: any): SagaIterator<void> {
  try {
    const { router } = yield select();
    const { data } = yield call(Api.clients.refetchClients,
      {
        advisor: router.location.pathname.slice(16),
        ...payload
      });
    yield put(refetchClientsSuccess({ ...payload, data }));
  } catch (e) {
    yield put(advisorProfileError(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(ADVISOR_PROFILE_TYPES.REFETCH_CLIENTS, worker);
}
