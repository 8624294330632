/* eslint-disable @typescript-eslint/quotes  */
import { defineMessages } from 'react-intl';

export default defineMessages({
  addCreditCardButton: {
    id: 'buttons.addCreditCard',
    defaultMessage: 'add credit card',
    description: 'Button label'
  },
  singlePayments: {
    id: 'singlePayments',
    defaultMessage: 'One Time payments',
    description: 'Label'
  },
  noCards: {
    id: 'wallet.noCards',
    defaultMessage: 'You have not added any credit cards',
    description: 'Label'
  },
  pageTitle: {
    id: 'wallet.title',
    defaultMessage: 'Your Wallet',
    description: 'Label'
  },
  creditCards: {
    id: 'wallet.creditCards',
    defaultMessage: 'Credit cards',
    description: 'Label'
  },
  creditCardAdded: {
    id: 'wallet.creditCardAdded',
    defaultMessage: 'Credit Card Added',
    description: 'Label'
  },
  billingAddress: {
    id: 'wallet.billingAddress',
    defaultMessage: 'Billing Address',
    description: 'Label'
  },
  addCreditCard: {
    id: 'wallet.addCreditCard',
    defaultMessage: 'Add Credit card',
    description: 'Label'
  },
});
