import { connect } from 'react-redux';

import Container from './Container';
import { getClients, createSinglePayment } from '../../redux/actions/PaymentModal';
import { createSubscription } from '../../redux/actions/Subscriptions';

const mapStateToProps = (state: any) => ({
  clients: state.paymentModal.clientList,
  userId: state.auth.userData.id,
});

const mapDispatchToProps = {
  getClients,
  createSinglePayment,
  createSubscription,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
