import { connect } from 'react-redux';

import Container from './Container';
import { getInvoice } from '../../redux/actions/Invoice';

const mapStateToProps = (state: any) => ({
  invoiceInfo: state.invoice.invoiceInfo,
  loading: state.invoice.loading,
  userRole: state.auth.userRole,
});

const mapDispatchToProps = {
  getInvoice,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
