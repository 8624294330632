import { defineMessages } from 'react-intl';

export default defineMessages({
  setPasswordTitle: {
    id: 'onBoarding.setPasswordTitle',
    defaultMessage: 'Set Password',
    description: 'Section title'
  },
  terms: {
    id: 'onBoarding.terms',
    defaultMessage: 'Terms & Conditions',
    description: 'Section title'
  },
  closeButton: {
    id: 'buttons.close',
    defaultMessage: 'Close',
    description: 'Button label'
  },
  agreeButton: {
    id: 'buttons.agree',
    defaultMessage: 'Agree',
    description: 'Button label'
  },
  registerButton: {
    id: 'buttons.register',
    defaultMessage: 'Register',
    description: 'Button label'
  },
  password: {
    id: 'password',
    defaultMessage: 'Password',
    description: 'Password label'
  },
  confirmPassword: {
    id: 'confirmPassword',
    defaultMessage: 'Confirm Password',
    description: 'Confirm Password label'
  },
  agreeStatement: {
    id: 'onBoarding.agreeStatement',
    defaultMessage: 'I agree with ',
    description: 'Agree Statement'
  },
  termsAndConditions: {
    id: 'onBoarding.termsAndConditions',
    defaultMessage: 'terms & conditions',
    description: 'Terms and conditions statement'
  },
  passwordHelperText: {
    id: 'onBoarding.helperPasswordText',
    defaultMessage: 'Your password needs to be at least 8 characters long and contain one uppercase letter, one symbol, and a number. No spaces are accepted.',
    description: 'Helper text'
  },
  passwordRequired: {
    id: 'fields.password.error',
    defaultMessage: 'Please provide a password.',
    description: 'Validation error message',
  },
  confirmPasswordError: {
    id: 'fields.confirmPassword.error',
    defaultMessage: 'Passwords do not match.',
    description: 'Validation error message',
  },
  expiredLinkMessage: {
    id: 'onBoarding.expiredLinkMessage',
    defaultMessage: 'The verification link has expired. Please re-start the registration.',
    description: 'Message',
  }
});
