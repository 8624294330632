import React from 'react';
import Box from '@material-ui/core/Box';
import DoneIcon from '@material-ui/icons/Done';
import { FormattedMessage } from 'react-intl';

import H1 from '../../../H1';
import H3 from '../../../H3';
import Button from '../../../Button';
import messages from '../../messages';

interface Props {
  onSuccess: () => void,
  isAppSubscription: boolean,
}

const SuccessScreen: React.FC<Props> = ({ onSuccess, isAppSubscription }) => (
  <Box display="flex" alignItems="center" flexDirection="column">
    <DoneIcon style={{ color: '#3772FF', fontSize: '50px' }} />
    <H1 mt={8}><FormattedMessage {...messages.successTitle} /></H1>
    <H3 maxWidth={450} mt={3} mb="40px">
      {isAppSubscription
        ? 'Your ChalicePay Subscription is activated.'
        : <FormattedMessage {...messages.successMessage} />}
    </H3>
    <Box>
      <Button onClick={onSuccess} variant="contained">
        <FormattedMessage {...messages.okButton} />
      </Button>
    </Box>
  </Box>
);

export default SuccessScreen;
