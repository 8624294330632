import React, { Component } from 'react';

import Content from './Content';
import Api from '../../api';
import { DeleteCreditCardModal } from '../../components';

interface Props {
  createCard: (values: {}, callback: (state: boolean) => void) => void,
  userId: string,
  getCards: () => void,
  cards: {}[],
}

interface State {
  modalOpen: boolean,
  deleteModalOpen: boolean,
  deleteId: string,
  clientAdded: boolean,
}

class Container extends Component<Props, State> {
  state = {
    modalOpen: false,
    clientAdded: false,
    deleteModalOpen: false,
    deleteId: '',
  };

  componentDidMount() {
    const { getCards } = this.props;

    getCards();
  }

  get actions() {
    const { getCards } = this.props;
    return [
      {
        label: 'Delete',
        onClick: (row: any) => this.setState({ deleteId: row.id, deleteModalOpen: true }),
        getIsHidden: (row: any) => row.is_default,
      },
      {
        label: 'Make Default',
        onClick: (row: any) => Api.userProfiles.makeCreditCardDefault(row.id).then(getCards),
        getIsHidden: (row: any) => row.is_default || row.card.status === 'expired',
      },
    ];
  }

  toggleModal = () => this.setState((prevState) => ({ modalOpen: !prevState.modalOpen }));

  handleAddCard = (values: any | null) => {
    const { createCard, userId } = this.props;
    if (values) {
      createCard({
        content_type_id: userId,
        content_type: 'userprofile',
        address: {
          street: values.streetAddress,
          city: values.city,
          zip_code: `${values.zipCode}`,
          state: values.state,
        },
        source: values.source,
        cardholder_name: values.cardHolderName,
      }, (state: boolean) => {
        if (state) {
          return this.setState({ clientAdded: true }, () => {
            setTimeout(() => this.setState({ modalOpen: false, clientAdded: false }), 1000);
          });
        }
        return this.setState({ modalOpen: false });
      });
    }
  };

  render() {
    const {
      modalOpen,
      clientAdded,
      deleteId,
      deleteModalOpen,
    } = this.state;
    const { cards, getCards } = this.props;

    return (
      <>
        <Content
          clientAdded={clientAdded}
          onAddCard={this.handleAddCard}
          modalOpen={modalOpen}
          toggleModal={this.toggleModal}
          cards={cards}
          actions={this.actions}
        />
        <DeleteCreditCardModal
          open={deleteModalOpen}
          id={deleteId}
          callback={getCards}
          close={() => this.setState({ deleteModalOpen: false, deleteId: '' })}
        />
      </>
    );
  }
}

export default Container;
