import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { FormattedMessage, useIntl } from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import { loadStripe } from '@stripe/stripe-js';
import { RouteComponentProps, withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import { MoonLoader } from 'react-spinners';

import { stripePushKey } from '../../constants';
import Api from '../../api';
import H1 from '../H1';
import messages from './messages';
import CloseIcon from '../../metadata/icons/CloseIcon';
import Navigation from '../PaymentNavigation';
import Modal from '../Modal';
import { refetchPayments } from '../../redux/actions/SinglePayments';
import { refetchSubscriptions } from '../../redux/actions/Subscriptions';
import {
  SuccessScreen,
  NoWallet,
  InvoiceDetails,
  Form,
} from './components';
import { H3Dark } from '../index';

interface Props extends RouteComponentProps<{ id: string }> {
  paymentId: string,
  open: boolean,
  close: () => void,
  isSubscription?: boolean,
  isAppSubscription?: boolean,
  callback?: () => void,
}

export interface Card {
  stripe_id: string,
  id: string,
  is_default: boolean,
  billing_details: {
    name: string,
    address: {
      city: string,
      line1: string,
      state: string,
      postal_code: string,
    }
  },
  card: {
    last4: string,
    status: string,
    exp_year: number,
    exp_month: number,
  }
}

interface Invoice {
  number: string,
  client_secret: string,
  amount: number,
}

const Container: React.FC<Props> = ({
  paymentId,
  close,
  open,
  match,
  isSubscription,
  callback,
  isAppSubscription,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState(false);
  const [paymentUsed, setPaymentUsed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [cards, setCards] = useState<Card[]>([]);
  const [selectedCard, setSelectedCard] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  // @ts-ignore
  const [invoiceInfo, setPaymentInfo] = useState<Invoice>({});
  const intl = useIntl();
  const stripe = useStripe();
  const options = cards.filter(item => item.card.status !== 'expired').map((item) => ({
    label: `${item.billing_details.name} ---- ---- ----${item.card.last4} ${item.card.exp_month}/${item.card.exp_year}`,
    value: item.stripe_id,
  }));

  useEffect(() => {
    setActiveStep(0);
  }, [open]);

  useEffect(() => {
    Api.userProfiles.getBilling().then(({ data }) => {
      setCards(data);
      if (match.params.id && !match.path.includes('subscription-details') && !match.path.includes('settings')) {
        (isSubscription
          ? Api.subscriptions.getSubscriptionById(match.params.id)
          : Api.payments.getPaymentById(match.params.id)
        )
          .then(({ data: paymentData }) => {
            if (paymentData.status === 'inactive' || paymentData.status === 'unpaid' || paymentData.status === 'failed') {
              setPaymentInfo(paymentData);
            } else {
              setPaymentUsed(true);
            }
          })
          .catch(() => setError(true))
          .finally(() => setLoading(false));
      } else {
        setLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    if (paymentId) {
      (!isSubscription ? Api.payments.getPaymentById(paymentId) : Api.subscriptions.getSubscriptionById(paymentId))
        .then(({ data }) => setPaymentInfo(data));
    }
  }, [paymentId]);

  const selectedCardObject = cards.find(item => item.stripe_id === selectedCard);

  const submitPayment = () => {
    setPaymentLoading(true);
    if (isSubscription) {
      Api.subscriptions.activateSubscription(invoiceInfo.id, {
        ...invoiceInfo,
        billing_detail: selectedCardObject?.id
      }).then(() => {
        setActiveStep(2);
      });
    } else {
      stripe?.confirmCardPayment(invoiceInfo.client_secret, {
        payment_method: selectedCardObject?.stripe_id
      }).then((data) => {
        if (data.paymentIntent) {
          return Api.payments.createOneTimePayments({
            payment_type: 'credit_card',
            status: data.paymentIntent?.status,
            amount: data.paymentIntent?.amount,
            due_date: invoiceInfo.due_date,
            date_created: invoiceInfo.date_created,
            description: invoiceInfo.description,
            invoice: invoiceInfo.id,
            stripe_id: data.paymentIntent?.id,
          });
        }
        return null;
      }).then(() => {
        setActiveStep(2);
      });
    }
  };

  const handleSuccess = () => {
    if (isSubscription) {
      dispatch(refetchSubscriptions(undefined));
    } else {
      dispatch(refetchPayments(undefined));
    }
    close();
    setPaymentLoading(false);
    if (callback) {
      callback();
    }
  };

  return (
    <Modal open={open} handleClose={close}>
      {error || loading || paymentUsed ? (
        <Box
          height="100vh"
          width="100vw"
          display="flex"
          justifyContent="center"
          flexDirection="column"
        >
          {loading && (
            <Box justifyContent="center" display="flex">
              <MoonLoader size={100} color="#011638" />
            </Box>
          )}
          {paymentUsed && (
            <Box>
              <IconButton
                onClick={() => history.push(isSubscription ? '/transactions/subscription' : '/transactions/single-payments')}
                style={{
                  position: 'absolute',
                  right: '100px',
                  top: '100px'
                }}
              >
                <CloseIcon />
              </IconButton>
              <H3Dark textAlign="center">Link is expired</H3Dark>
            </Box>
          )}
          {error && (
            <Box>
              <IconButton
                onClick={() => history.push('/transactions/single-payments')}
                style={{
                  position: 'absolute',
                  right: '100px',
                  top: '100px'
                }}
              >
                <CloseIcon />
              </IconButton>
              <H3Dark textAlign="center">{'Sorry, You don\'t have permission to access this page.'}</H3Dark>
            </Box>
          )}
        </Box>
      ) : (
        <Box
          height="100vh"
          width="100vw"
          display="flex"
          justifyContent="center"
        >
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            pt={10}
            maxWidth="1240px"
            width="inherit"
            marginX={3}
            style={{ overflowY: 'auto' }}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
              <H1><FormattedMessage {...messages.title} /></H1>
              <IconButton onClick={close}>
                <CloseIcon />
              </IconButton>
            </Box>
            {cards.length === 0 && (
              <Box display="flex" flex="1 1 auto" alignItems="center">
                <NoWallet />
              </Box>
            )}
            {cards.length === 1 && cards[0].card.status === 'expired' && (
              <Box display="flex" flex="1 1 auto" alignItems="center">
                <NoWallet />
              </Box>
            )}
            {cards.length > 0 && cards[0].card.status !== 'expired' && (
              <Box maxWidth={600} width="100%" mb={3}>
                {activeStep !== 2 && (
                  <Box mt={12}>
                    <Navigation
                      steps={['Select Payment Method', 'Confirm Payment']}
                      activeStep={activeStep}
                    />
                  </Box>
                )}
                {activeStep === 0 && (
                  <Form
                    selectedCard={selectedCard}
                    options={options}
                    intl={intl}
                    cards={cards}
                    onSubmit={(values: { card: string }) => {
                      setActiveStep(1);
                      setSelectedCard(values.card);
                    }}
                  />
                )}
                {activeStep === 1 && (
                  <InvoiceDetails
                    isAppSubscription={Boolean(isAppSubscription)}
                    isSubscription={Boolean(isSubscription)}
                    invoiceInfo={invoiceInfo}
                    selectedCardObject={selectedCardObject}
                    submit={submitPayment}
                    goBack={() => setActiveStep(0)}
                    loading={paymentLoading}
                  />
                )}
              </Box>
            )}
            {activeStep === 2 && (
              <Box display="flex" flex="1 1 auto" alignItems="center">
                <SuccessScreen onSuccess={handleSuccess} isAppSubscription={Boolean(isAppSubscription)} />
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Modal>
  );
};

const stripePromise = loadStripe(stripePushKey() || '');

const StripeWrapper: React.FC<Props> = (props) => (
  <Elements stripe={stripePromise}>
    <Container {...props} />
  </Elements>
);

export default withRouter(StripeWrapper);
