import users from './users';
import auth from './auth';
import userProfiles from './userProfiles';
import clients from './clients';
import settings from './settings';
import payments from './payments';
import subscriptions from './subscriptions';
import advisors from './advisors';

export default {
  advisors,
  users,
  userProfiles,
  auth,
  clients,
  settings,
  payments,
  subscriptions,
};
