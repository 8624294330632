/* eslint-disable @typescript-eslint/quotes  */
import { defineMessages } from 'react-intl';

export default defineMessages({
  saveChangesButton: {
    id: 'buttons.saveChanges',
    defaultMessage: 'save changes',
    description: 'Button label'
  },
  changesSaved: {
    id: 'buttons.changesSaved',
    defaultMessage: 'Changes saved',
    description: 'Button label'
  },
  pageTitle: {
    id: 'profile.title',
    defaultMessage: 'Your profile',
    description: 'Button label'
  },
  changePassword: {
    id: 'profile.changePassword',
    defaultMessage: 'Change password',
    description: 'Button label'
  },
  firstName: {
    id: 'firstName',
    defaultMessage: 'First name',
    description: 'Button label'
  },
  email: {
    id: 'email',
    defaultMessage: 'Email',
    description: 'Button label'
  },
  lastName: {
    id: 'lastName',
    defaultMessage: 'Last name',
    description: 'Button label'
  },
});
