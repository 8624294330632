import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  H1,
  BackButton,
  Table,
  Link,
  CurrencyProvider
} from '../../components';
import {
  fetchInitData as fetchInitDataAction,
  refetchData as refetchDataAction
} from '../../redux/actions/TransferDetails';

const tableData = [
  {
    Header: 'Client',
    accessor: 'client_name',
  },
  {
    Header: 'Advisor',
    accessor: 'advisor_name',
  },
  {
    Header: 'Gross',
    accessor: (row: any) => <CurrencyProvider value={row.amount / 100} />,
    id: 'amount',
  },
  {
    Header: 'Fee',
    accessor: (row: any) => <CurrencyProvider value={row.fee / 100} />,
    id: 'fee',
  },
  {
    Header: 'Total',
    accessor: (row: any) => <CurrencyProvider value={(row.amount - row.fee) / 100} />,
    id: 'total',
  },
  {
    Header: 'Paid date',
    accessor: (row: any) => moment(row.date_paid).format('MM/DD/YYYY'),
    id: 'date_paid',
  },
  {
    Header: 'Invoice',
    accessor: (row: any) => (
      <Link href={`/billing/single-payments/invoice/${row.id}`}>
        {`#${row.number}`}
      </Link>
    ),
    id: 'number'
  },
];

interface Props extends RouteComponentProps<{ id: string }> {
  fetchInitData: (id: string) => void,
  refetchData: (data: any) => void,
  data: {}[],
  total: number,
  pagination: any,
  sort: any,
}

const TransferDetails: React.FC<Props> = ({
  match,
  fetchInitData,
  refetchData,
  data,
  total,
  pagination,
  sort,
}) => {
  useEffect(() => {
    fetchInitData(match.params.id);
  }, []);

  return (
    <Box>
      <H1 mb={3}>Transfer Details</H1>
      <BackButton />
      <Box mt={3}>
        <Table
          refetchData={refetchData}
          columns={tableData}
          data={data}
          sort={sort}
          countTotal={total}
          withPagination
          pagination={pagination}
        />
      </Box>
    </Box>
  );
};

const mapStateToProps = ({ transferDetails }: any) => ({
  data: transferDetails.data,
  total: transferDetails.total,
  pagination: transferDetails.pagination,
  sort: transferDetails.sort,
});

const mapDispatchToProps = {
  fetchInitData: fetchInitDataAction,
  refetchData: refetchDataAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransferDetails);
