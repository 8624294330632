import { connect } from 'react-redux';

import { getSubscriptions, refetchSubscriptions } from '../../redux/actions/Subscriptions';
import { getCompanyDetails } from '../../redux/actions/Settings';
import Container from './Container';

const mapStateToProps = (state: any) => ({
  userRole: state.auth.userRole,
  step: state.onBoarding.step,
  subscriptions: state.subscriptions.subscriptions,
  total: state.subscriptions.total,
  pagination: state.subscriptions.pagination,
  sort: state.subscriptions.sort,
  accountVerified: state.settings.companyInfo ? state.settings.companyInfo.status === 'verified' : false,
  accountSubscribed: Boolean(state.settings.companyInfo ? state.settings.companyInfo.subscribed : false),
});

const mapDispatchToProps = {
  getSubscriptions,
  refetchSubscriptions,
  getCompanyDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
