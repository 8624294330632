import React, { useRef, useEffect, useState } from 'react';
import MiuButton from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import { PulseLoader } from 'react-spinners';

import Modal from '../Modal';

const StyledButton = withStyles((theme) => ({
  root: {
    height: 36,
    boxSizing: 'border-box',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 500,
    padding: '0 16px',
    fontSize: 14,
    minWidth: 70,
    '& .css-0': {
      display: 'flex',
    },
  },
  disabled: {
    background: 'white',
    color: theme.palette.text.secondary,
    borderColor: theme.palette.text.secondary,
  },
  text: {
    color: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.warning.contrastText,
    },
    '&:focus': {
      background: theme.palette.primary.light,
    },
    '&.Mui-disabled': {
      color: theme.palette.text.secondary,
      background: 'none',
    },
  },
  textSecondary: {
    color: '#E20338',
    '&:hover': {
      background: '#E20338',
      color: 'white'
    },
    '&:focus': {
      background: '#E20338',
      color: 'white'
    },
  },
  contained: {
    background: theme.palette.primary.main,
    color: 'white',
    boxShadow: '0px 4px 4px rgba(55, 114, 255, 0.3)',
    '&:hover': {
      background: theme.palette.info.main,
      boxShadow: '0px 8px 8px rgba(55, 114, 255, 0.3)'
    },
    '&:focus': {
      background: theme.palette.info.dark,
      boxShadow: '0px 4px 4px rgba(55, 114, 255, 0.3)',
    },
    '&.Mui-disabled': {
      background: theme.palette.text.secondary,
      color: 'white'
    },
  },
  containedSecondary: {
    background: '#E20338',
    '&:hover': {
      boxShadow: '0px 4px 4px #E3ABB8',
      background: '#E20338',
      color: 'white'
    },
    '&:focus': {
      boxShadow: '0px 4px 4px #E3ABB8',
      background: '#E20338',
      color: 'white'
    },
  },
  outlined: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.warning.contrastText,
    },
    '&:focus': {
      background: theme.palette.primary.light,
    },
  }
}))(MiuButton);

interface Props {
  variant?: 'outlined' | 'contained' | 'text'
  disabled?: boolean,
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  fullWidth?: boolean
  startIcon?: React.ComponentType,
  type?: 'submit' | 'button' | 'reset',
  loading?: boolean,
  shouldValidateAccount?: boolean,
  accountVerified?: boolean,
  modalContent?: any,
  color?: 'secondary'
  children: any,
}

const Button: React.FC<Props> = ({
  children,
  variant = 'outlined',
  startIcon: StartIcon,
  disabled,
  loading = false,
  shouldValidateAccount,
  modalContent,
  accountVerified,
  onClick,
  ...rest
}) => {
  const ref = useRef<HTMLButtonElement>(null);
  const [iconColor, setIconColor] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [width, setWidth] = useState('initial');

  useEffect(() => {
    if (StartIcon && ref && ref.current) {
      setIconColor(window.getComputedStyle(ref.current, null).color || '');
    }
  }, [ref, StartIcon]);

  useEffect(() => {
    if (ref && ref.current) {
      setWidth(`${ref.current.offsetWidth + 10}px`);
    }
  }, []);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (shouldValidateAccount && onClick) {
      if (accountVerified) {
        return onClick(e);
      }
      toggleModal();
    } else if (onClick) {
      onClick(e);
    }
    return null;
  };

  // @ts-ignore
  const icon = StartIcon ? <StartIcon color={iconColor} /> : null;
  return (
    <StyledButton
      onClick={handleClick}
      ref={ref}
      variant={variant}
      startIcon={icon}
      style={{ width }}
      disabled={disabled || loading}
      {...rest}
    >
      <PulseLoader
        size={10}
        color={variant === 'text' ? '#011638' : '#fff'}
        loading={loading}
      />
      {shouldValidateAccount && (
        <Modal
          open={modalOpen}
          handleClose={toggleModal}
        >
          {modalContent}
        </Modal>
      )}
      {!loading && children}
    </StyledButton>
  );
};

export default Button;
