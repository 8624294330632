import React from 'react';
import Box from '@material-ui/core/Box';
import { FormattedMessage } from 'react-intl';

import { withParentData } from '../../Context';
import messages from './messages';
import { DoneIcon } from '../../../../metadata/icons';

interface Props {
  activeStep: 0
}

const Navigation: React.FC<Props> = ({
  activeStep
}) => (
  <Box justifyContent="center" display="flex" alignItems="center" height="100%" p={4}>
    <Box>
      {Object.keys(messages).map((item, index) => (
        <Box
          color="white"
          display="flex"
          key={item}
          alignItems="center"
          mb={3}
        >
          <Box
            width={50}
            bgcolor={activeStep >= index ? '#3772FF' : 'white'}
            color={activeStep < index ? '#6D6E71' : 'white'}
            height={50}
            borderRadius="50%"
            justifyContent="center"
            display="flex"
            alignItems="center"
            mr={3}
          >
            {activeStep > index && <DoneIcon color="white" />}
            {activeStep <= index && index + 1}
          </Box>
          <FormattedMessage
            // @ts-ignore
            {...messages[item]}
          />
        </Box>
      ))}
    </Box>
  </Box>
);

export default withParentData(Navigation);
