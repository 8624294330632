import React from 'react';

import { GetStartedOnBoardingContainer } from '../../components';
import { VerifiedMessage, Form } from './components';

interface Props {
  emailSend: boolean,
}

const Content: React.FC<Props> = ({
  emailSend
}) => (
  <GetStartedOnBoardingContainer>
    {emailSend ? <VerifiedMessage /> : <Form />}
  </GetStartedOnBoardingContainer>
);

export default Content;
