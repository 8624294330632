import { defineMessages } from 'react-intl';

export default defineMessages({
  reviewStep: {
    id: 'onBoarding.reviewStep',
    defaultMessage: 'Review',
    description: 'Navigation'
  },
  setUpAccountStep: {
    id: 'onBoarding.setUpAccountStep',
    defaultMessage: 'Set Up your Account',
    description: 'Navigation'
  },
  accountInfoStep: {
    id: 'onBoarding.accountInfoStep',
    defaultMessage: 'Account information',
    description: 'Navigation'
  },
  bankingDetailsStep: {
    id: 'onBoarding.bankingDetailsStepTitle',
    defaultMessage: 'Your banking details',
    description: 'Text'
  },
  billingDetails: {
    id: 'onBoarding.billingDetails',
    defaultMessage: 'Billing Details',
    description: 'Text'
  },
  typeOfAccount: {
    id: 'onBoarding.review.typeOfAccount',
    defaultMessage: 'Type of account',
    description: 'Text'
  },
  businessWebsite: {
    id: 'onBoarding.review.businessWebsite',
    defaultMessage: 'Business website',
    description: 'Text'
  },
  address: {
    id: 'onBoarding.review.address',
    defaultMessage: 'Address',
    description: 'Text'
  },
  companyNameField: {
    id: 'fields.companyName',
    defaultMessage: 'Company name',
    description: 'Field label'
  },
  employerINField: {
    id: 'fields.employerIN',
    defaultMessage: 'Employer identification number',
    description: 'Field label'
  },
  streetAddressField: {
    id: 'fields.streetAddress',
    defaultMessage: 'Company street address',
    description: 'Field label'
  },
  cityField: {
    id: 'fields.city',
    defaultMessage: 'City',
    description: 'Field label'
  },
  stateField: {
    id: 'fields.state',
    defaultMessage: 'State',
    description: 'Field label'
  },
  zipCodeField: {
    id: 'fields.zipCode',
    defaultMessage: 'Zip Code',
    description: 'Field label'
  },
  companySiteField: {
    id: 'fields.companySite',
    defaultMessage: 'Company website',
    description: 'Field label'
  },
  phoneNumberField: {
    id: 'fields.phoneNumber',
    defaultMessage: 'Phone number',
    description: 'Field label'
  },
  firstNameField: {
    id: 'fields.firstName',
    defaultMessage: 'First name',
    description: 'Field label'
  },
  lastNameField: {
    id: 'fields.lastName',
    defaultMessage: 'Last name',
    description: 'Field label'
  },
  dateOfBirthField: {
    id: 'fields.dateOfBirth',
    defaultMessage: 'Date of birth',
    description: 'Field label'
  },
  secNumberField: {
    id: 'fields.secNumber',
    defaultMessage: 'Social security number',
    description: 'Field label'
  },
  professionalOccupationField: {
    id: 'fields.professionalOccupation',
    defaultMessage: 'Your professional occupation',
    description: 'Button label'
  },
  accountHolderField: {
    id: 'fields.accountHolder',
    defaultMessage: 'Account holder',
    description: 'Field label'
  },
  typeOfBusinessField: {
    id: 'fields.typeOfBusiness',
    defaultMessage: 'Type of business',
    description: 'Field label'
  },
  routingNumberField: {
    id: 'fields.routingNumber',
    defaultMessage: 'Routing number',
    description: 'Field label'
  },
  accountNumberField: {
    id: 'fields.accountNumber',
    defaultMessage: 'Account number',
    description: 'Field label'
  },
  addressStreetField: {
    id: 'fields.streetAddress',
    defaultMessage: 'Street address',
    description: 'Field label'
  },
  cardHolderNameField: {
    id: 'fields.cardHolderName',
    defaultMessage: 'Card holder name',
    description: 'Field label'
  },
  cardNumberField: {
    id: 'fields.cardNumber',
    defaultMessage: 'Card number',
    description: 'Field label'
  },
  expiryDateField: {
    id: 'fields.expiryDate',
    defaultMessage: 'Expiry date',
    description: 'Field label'
  },
  backButton: {
    id: 'buttons.back',
    defaultMessage: 'Back',
    description: 'Button label'
  },
  submitButton: {
    id: 'buttons.submit',
    defaultMessage: 'Submit',
    description: 'Button label'
  },
});
