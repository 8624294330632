import { AUTH_TYPES, ONBOARDING_TYPES } from '../constants';

import { getCookie } from '../../helpers';

const initState = {
  isAuthorized: Boolean(getCookie('csrftoken')),
  userData: {},
  userRole: '',
  error: {},
};

export default (state = initState, { type, payload }: any) => {
  switch (type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        isAuthorized: Boolean(getCookie('csrftoken'))
      };

    case AUTH_TYPES.GET_USER_SUCCESS:
      return {
        ...state,
        userData: payload,
        userRole: payload.groups[0].name
      };

    case ONBOARDING_TYPES.SET_STEP:
      return {
        ...state,
        userData: {
          ...state.userData,
          onboarding_status: payload,
        }
      };

    case AUTH_TYPES.ERROR:
      return {
        ...state,
        error: payload,
      };

    default: {
      return state;
    }
  }
};
