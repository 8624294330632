import Box from '@material-ui/core/Box';
import withStyles from '@material-ui/core/styles/withStyles';

export default withStyles({
  root: {
    background: '#FFFFFF',
    boxShadow: '4px 10px 20px rgba(55, 114, 255, 0.1)',
    borderRadius: '4px',
  }
})(Box);
