import { SUBSCRIPTION_DETAILS_TYPES } from '../../constants';

export const getInfo = (payload: string) => ({
  type: SUBSCRIPTION_DETAILS_TYPES.GET_INFO,
  payload,
});

export const getInfoSuccess = (payload: any) => ({
  type: SUBSCRIPTION_DETAILS_TYPES.GET_INFO_SUCCESS,
  payload,
});

export const getPayments = (payload: string) => ({
  type: SUBSCRIPTION_DETAILS_TYPES.GET_PAYMENTS,
  payload,
});

export const getPaymentsSuccess = (payload: any) => ({
  type: SUBSCRIPTION_DETAILS_TYPES.GET_PAYMENTS_SUCCESS,
  payload,
});

export const refetchPayments = (payload: any) => ({
  type: SUBSCRIPTION_DETAILS_TYPES.REFETCH_PAYMENTS,
  payload
});

export const refetchPaymentsSuccess = (payload: any) => ({
  type: SUBSCRIPTION_DETAILS_TYPES.REFETCH_PAYMENTS_SUCCESS,
  payload
});

export const subscriptionDetailsError = (payload: any) => ({
  type: SUBSCRIPTION_DETAILS_TYPES.ERROR,
  payload,
});
