import React from 'react';
import moment from 'moment';
import Box from '@material-ui/core/Box';

import {
  Link,
  Table,
  EmptyTableMessageContainer,
  MakePaymentModal,
  CurrencyProvider,
} from '../../../../components';
import { withParentData } from '../../Context';

interface Props {
  payments: {}[],
  pagination: { page: number },
  sort: any,
  total: number,
  refetchData: (data: {}) => void,
  actions: any,
  makePaymentModalOpen: boolean,
  toggleMakePaymentModal: () => void,
  makePaymentCallback: () => void,
  paymentId: string,
}

const columns = [
  {
    Header: 'Status',
    accessor: (row: any) => <span style={{ textTransform: 'uppercase' }}>{row.status.replace(/_/g, ' ')}</span>,
    id: 'status',
  },
  {
    Header: 'Invoice ID',
    accessor: (row: any) => (
      <Link href={`/transactions/single-payments/invoice/${row.id}`}>
        {row.number ? `#${row.number}` : 'N/A'}
      </Link>
    ),
    id: 'number'
  },
  {
    Header: 'Amount',
    accessor: (row: any) => <CurrencyProvider value={row.amount / 100} />,
    id: 'amount',
  },
  {
    Header: 'Due date',
    accessor: (row: any) => moment(row.due_date).format('MM/DD/YYYY'),
    id: 'due_date',
  },
];

const MyComponent: React.FC<Props> = ({
  pagination,
  payments,
  total,
  sort,
  refetchData,
  actions,
  makePaymentModalOpen,
  toggleMakePaymentModal,
  paymentId,
  makePaymentCallback,
}) => (
  <Box>
    <Table
      actions={actions}
      columns={columns}
      data={payments}
      withPagination
      pagination={pagination}
      sort={sort}
      countTotal={total}
      refetchData={refetchData}
      getRowError={({ original }: any) => original.status === 'failed'}
      noItemsMessage={(
        <EmptyTableMessageContainer>
          No invoice for this subscription, so far. Please try to activate it.
        </EmptyTableMessageContainer>
      )}
    />
    <MakePaymentModal
      open={makePaymentModalOpen}
      close={toggleMakePaymentModal}
      paymentId={paymentId}
      callback={makePaymentCallback}
    />
  </Box>
);

export default withParentData(MyComponent);
