import { WALLET_TYPES } from '../constants';

const initState = {
  loading: false,
  error: {},
  cardList: [],
};

export default (state = initState, { type, payload }: any) => {
  switch (type) {
    case WALLET_TYPES.GET_CARDS_SUCCESS:
      return {
        ...state,
        loading: false,
        cardList: payload,
      };
    case WALLET_TYPES.ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default: {
      return state;
    }
  }
};
