import Box from '@material-ui/core/Box';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import {
  H1
} from '../../components';
import {
  Password,
  SectionContainer as Container,
  UserInfo,
} from './components';

const Content: React.FC<{}> = () => (
  <Box mt={2}>
    <H1><FormattedMessage {...messages.pageTitle} /></H1>
    <Container>
      <UserInfo />
    </Container>
    <Container
      p={3}
      mt={3}
      boxShadow="4px 10px 20px rgba(55, 114, 255, 0.1)"
      borderRadius={4}
      maxWidth={650}
      bgcolor="white"
    >
      <Password />
    </Container>
  </Box>
);

export default Content;
