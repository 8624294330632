import React, { Component } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import Box from '@material-ui/core/Box';
import { MoonLoader } from 'react-spinners';

import Content from './Content';
import { Provider } from './Context';

interface Props {
  intl: IntlShape,
  error: null | {},
  loading: boolean,
  singUpWithEmail: (email: string) => void,
  login: () => void,
  emailSend: boolean,
  isAuthorized: boolean,
}

interface State {
  email: string
}

class Container extends Component<Props, State> {
  state = {
    email: ''
  };

  handleResendLink = () => {
    const { singUpWithEmail } = this.props;
    const { email } = this.state;
    this.setState({ email });
    singUpWithEmail(email);
  };

  handleSubmit = ({ email }: { email: string }) => {
    const { singUpWithEmail } = this.props;
    this.setState({ email });
    singUpWithEmail(email);
  };

  login = () => {
    const { login } = this.props;
    login();
  };

  render() {
    const { email } = this.state;
    const {
      intl,
      error,
      loading,
      emailSend,
      isAuthorized,
    } = this.props;

    if (isAuthorized) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh" width="100%">
          <MoonLoader
            size={100}
            color="#011638"
          />
        </Box>
      );
    }

    return (
      <Provider
        value={{
          onResendLink: this.handleResendLink,
          onSubmit: this.handleSubmit,
          login: this.login,
          email,
          intl,
          loading,
          emailError: Boolean(error)
        }}
      >
        <Content emailSend={emailSend} />
      </Provider>
    );
  }
}

export default injectIntl(Container);
