import { AUTH_TYPES } from '../../constants';
import { Error, UserData } from './types';

export const getUser = () => ({
  type: AUTH_TYPES.GET_USER,
});

export const getUserSuccess = (payload: UserData) => ({
  type: AUTH_TYPES.GET_USER_SUCCESS,
  payload,
});

export const authError = (error: Error) => ({
  type: AUTH_TYPES.ERROR,
  payload: error
});
