import React from 'react';

interface Props {
  color?: string
}

const Icon: React.FC<Props> = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill={color || '#000'}
      d="M14.71 6.71c.39.39.39 1.02 0 1.41L10.83 12l3.88 3.88a.996.996 0 11-1.41 1.41L8.71 12.7a.996.996 0 010-1.41L13.3 6.7c.38-.38 1.02-.38 1.41.01z"
    />
  </svg>
);

export default Icon;
