import React, { Component } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { RouteComponentProps } from 'react-router';

import { Provider } from './Context';
import Content from './Content';
import { ROLES } from '../../constants';
import { EditAdvisorModal, DeleteAdvisorModal } from '../../components';

interface Props extends RouteComponentProps {
  intl: IntlShape,
  getCompanyDetails: () => void,
  refetchAdvisors: (data: any) => void,
  step: number,
  total: number,
  createAdvisor: (payload: {}, callback: (error: boolean) => void)
  => void,
  getAdvisors: () => void,
  advisorsList: { id: string }[],
  error: {},
  pagination: { page: number },
  sort: { id: string, firstDirection: boolean },
  userRole: string,
  userId: string,
  accountSubscribed: boolean,
  accountVerified: boolean,
}

interface State {
  modalOpen: boolean,
  advisorAdded: boolean,
  editAdvisorId: string,
  editAdvisorModalOpen: boolean,
  deleteAdvisorId: string,
  deleteAdvisorModalOpen: boolean,
  deletedAdvisorClients: number,
}

class Container extends Component<Props, State> {
  state = {
    modalOpen: false,
    advisorAdded: false,
    editAdvisorModalOpen: false,
    editAdvisorId: '',
    deleteAdvisorId: '',
    deleteAdvisorModalOpen: false,
    deletedAdvisorClients: 0,
  };

  componentDidMount() {
    const {
      getCompanyDetails,
      getAdvisors,
      userRole,
      step,
      history
    } = this.props;

    if (userRole === ROLES.CLIENT) {
      history.push('/transactions/subscriptions');
    }
    if (step < 5 && userRole === ROLES.ACCOUNT_OWNER) {
      history.push('/onBoarding');
    }

    getCompanyDetails();
    getAdvisors();
  }

  get actions() {
    const { history } = this.props;
    return [
      {
        label: 'View Profile',
        onClick: (row: { id: string }) => history.push(`/users/advisors/${row.id}`),
      },
      {
        label: 'Edit Profile',
        onClick: (row: any) => this.setState((prevState) => ({
          editAdvisorId: row.id,
          editAdvisorModalOpen: !prevState.editAdvisorModalOpen,
        })),
      },
      {
        label: 'Delete advisor',
        onClick: (row: any) => this.setState((prevState) => ({
          deleteAdvisorId: row.id,
          deleteAdvisorModalOpen: !prevState.deleteAdvisorModalOpen,
          deletedAdvisorClients: row.registered_clients_count + row.unregistered_clients_count
        })),
      }
    ];
  }

  toggleAdvisorModal = () => this.setState((prevState) => ({ modalOpen: !prevState.modalOpen }));

  handleAdvisorCreate = (values: any) => {
    const { createAdvisor, userId } = this.props;
    createAdvisor({
      email: values.email,
      first_name: values.firstName,
      last_name: values.lastName,
      role: values.permission,
      advisor: userId,
    }, (isError) => {
      this.setState({ advisorAdded: true, modalOpen: false }, () => {
        setTimeout(() => this.setState({ advisorAdded: false }), isError ? 3000 : 1000);
      });
    });
  };

  handleRefetch = (data: any) => {
    const { refetchAdvisors } = this.props;
    refetchAdvisors(data);
  };

  render() {
    const {
      props: {
        intl,
        advisorsList,
        error,
        sort,
        pagination,
        total,
        accountSubscribed,
        accountVerified,
        getAdvisors,
      },
      state: {
        modalOpen,
        advisorAdded,
        editAdvisorModalOpen,
        editAdvisorId,
        deleteAdvisorId,
        deleteAdvisorModalOpen,
        deletedAdvisorClients
      }
    } = this;

    return (
      <Provider
        value={{
          intl,
          onCloseModal: this.toggleAdvisorModal,
          onAddAdvisor: this.handleAdvisorCreate,
          accountSubscribed,
        }}
      >
        <Content
          accountSubscribed={accountSubscribed}
          accountVerified={accountVerified}
          refetchData={this.handleRefetch}
          total={total}
          sort={sort}
          pagination={pagination}
          actions={this.actions}
          error={error}
          list={advisorsList}
          addAdvisor={this.toggleAdvisorModal}
          advisorAdded={advisorAdded}
          modalOpen={modalOpen}
        />
        <EditAdvisorModal
          id={editAdvisorId}
          open={editAdvisorModalOpen}
          callback={getAdvisors}
          close={() => this.setState({ editAdvisorModalOpen: false })}
          advisorInfo={advisorsList.find(item => item.id === editAdvisorId) || {}}
        />
        <DeleteAdvisorModal
          advisorHasClients={Boolean(deletedAdvisorClients)}
          open={deleteAdvisorModalOpen}
          close={() => {
            this.setState({ deleteAdvisorModalOpen: false });
            getAdvisors();
          }}
          id={deleteAdvisorId}
          callback={() => {
            getAdvisors();
            this.setState({
              modalOpen: false,
              advisorAdded: false,
              editAdvisorModalOpen: false,
              editAdvisorId: '',
              deleteAdvisorId: '',
              deleteAdvisorModalOpen: false,
              deletedAdvisorClients: 0,
            });
          }}
        />
      </Provider>
    );
  }
}

export default injectIntl(Container);
