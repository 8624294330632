import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage, IntlShape } from 'react-intl';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';

import messages from './messages';

import {
  Button,
  FormikInput,
  H2,
  FormikSelect,
} from '../../../../components';
import { withParentData } from '../../Context';
import { REGEXP } from '../../../../constants/validation';
import { ROLES } from '../../../../constants';

const validationSchema = (intl: IntlShape) => Yup.object().shape({
  firstName: Yup.string()
    .max(35, intl.formatMessage(messages.firstNameError))
    .matches(/^[a-zA-Z- ]{1,1000}$/, intl.formatMessage(messages.firstNameInvalidError))
    .required(intl.formatMessage(messages.requiredMassage)),
  lastName: Yup.string()
    .max(35, intl.formatMessage(messages.lastNameError))
    .matches(/^[a-zA-Z- ]{1,1000}$/, intl.formatMessage(messages.lastNameInvalidError))
    .required(intl.formatMessage(messages.requiredMassage)),
  email: Yup.string()
    .matches(REGEXP.EMAIL, intl.formatMessage(messages.invalidEmailMessage))
    .required(intl.formatMessage(messages.requiredMassage)),
  advisor: Yup.string()
    .required('Please select the advisor')
});

interface Props {
  intl: IntlShape,
  onAddClient: (values: {}) => void,
  onCloseModal: () => void,
  advisorsList: {}[],
  userRole: string,
  userData: {
    last_name: string,
    first_name: string,
  }
}

const EnterEmailForm: React.FC<Props> = ({
  intl,
  onAddClient: submit,
  onCloseModal,
  userData,
  advisorsList,
  userRole,
}) => (
  <Box p={3} bgcolor="white" width={650}>
    <H2 mb={2}><FormattedMessage {...messages.addClientTitle} /></H2>
    <Formik
      enableReinitialize
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        advisor: userRole === ROLES.ADVISOR ? `${userData.first_name} ${userData.last_name}` : '',
      }}
      onSubmit={submit}
      validationSchema={validationSchema(intl)}
    >
      {({ isValid, dirty }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Field
                component={FormikInput}
                name="firstName"
                label={intl.formatMessage(messages.firstNameField)}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={FormikInput}
                name="lastName"
                label={intl.formatMessage(messages.lastNameField)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={FormikInput}
                name="email"
                label={intl.formatMessage(messages.emailLabel)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              {userRole === ROLES.ADVISOR
                ? (
                  <Field
                    component={FormikInput}
                    name="advisor"
                    label={intl.formatMessage(messages.advisorField)}
                    disabled
                    fullWidth
                  />
                )
                : (
                  <Field
                    component={FormikSelect}
                    name="advisor"
                    label={intl.formatMessage(messages.advisorField)}
                    options={advisorsList
                      .filter((item: any) => item.is_active)
                      .map((item: any) => ({ value: item.id, label: item.name }))}
                  />
                )}
            </Grid>
          </Grid>
          <Box mt={3} display="flex" justifyContent="flex-end">
            <Box mr={2}>
              <Button variant="text" onClick={onCloseModal}>
                <FormattedMessage {...messages.cancelButton} />
              </Button>
            </Box>
            <Button variant="text" type="submit" disabled={!dirty || !isValid}>
              <FormattedMessage {...messages.addButton} />
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  </Box>
);

export default withParentData(EnterEmailForm);
