import { all, fork } from 'redux-saga/effects';

import sendVereficationEmail from './sendVereficationEmail';
import setPassword from './setPassword';
import verifyEmail from './verifyEmail';
import goToLogin from './goToLogin';
import logout from './logout';
import submitCompanyStep from './submitCompanyStep';
import submitAccountInfoStep from './submitAccountInfoStep';
import getCompany from './getCompany';
import getAccountInfo from './getAccountInfo';
import submitBankingStep from './submitBankingStep';
import submitBillingStep from './submitBillingStep';
import getBankingAccount from './getBankingAccount';
import finishOnboarding from './finishOnboarding';
import getBillingInfo from './getBillingInfo';

export default all([
  fork(sendVereficationEmail),
  fork(getBankingAccount),
  fork(setPassword),
  fork(goToLogin),
  fork(verifyEmail),
  fork(logout),
  fork(submitCompanyStep),
  fork(getCompany),
  fork(getAccountInfo),
  fork(submitAccountInfoStep),
  fork(submitBankingStep),
  fork(submitBillingStep),
  fork(finishOnboarding),
  fork(getBillingInfo),
]);
