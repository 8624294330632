import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import { MoonLoader } from 'react-spinners';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import moment from 'moment';

import { getInvoice as getInvoiceAction } from '../../redux/actions/Invoice';
import {
  BackButton,
  H1,
  H3,
  H3Dark,
  Link,
  Button,
  CurrencyProvider,
} from '../../components';
import messages from './messages';
import { ROLES } from '../../constants';
import { ReturnIcon } from '../../metadata/icons';
import RefundForm from './RefundForm';
import Api from '../../api';

interface Props extends RouteComponentProps {
  getInvoice: (id: string) => void,
  invoiceInfo: {
    amount: number,
    advisor: string,
    refund_amount: number,
    description: string,
    due_date: string,
    date_paid: string,
    advisor_name: string,
    date_created: string,
    date_canceled: string,
    client_name: string,
    client: string,
    invoiceInfo: string,
    refund_description: string,
    date_refunded: string,
    status: string,
    id: string,
    number: string | null,
  },
  loading: boolean,
  refundStatuses: string[],
  accessDenied: boolean,
  userRole: string,
}

const Container: React.FC<Props> = ({
  invoiceInfo,
  loading,
  userRole,
  getInvoice,
  match,
  accessDenied,
  refundStatuses,
  history,
}) => {
  const [refundModalOpen, setRefundModalOpen] = useState(false);

  const toggleRefundModal = () => setRefundModalOpen((prev) => !prev);

  const handleRefundPayment = (values: any) => Api.payments.refundInvoice({
    id: invoiceInfo.id,
    data: {
      advisor: invoiceInfo.advisor,
      client: invoiceInfo.client,
      amount: Number(values.amount.toFixed(2)) * 100,
      description: values.description || null,
    }
  });

  useEffect(() => {
    // @ts-ignore
    getInvoice(match.params.id);
  }, [getInvoice, match]);

  return (
    <Box mt={2} flex={1} display="flex" flexDirection="column">
      {!accessDenied && <BackButton />}
      <Box
        display={loading || accessDenied ? 'flex' : 'none'}
        flex={1}
        justifyContent="center"
        alignItems="center"
      >
        <MoonLoader
          size={100}
          color="#011638"
          loading={loading}
        />
        {accessDenied && <H3Dark>{'Sorry, You don\'t have permission to access this page.'}</H3Dark>}
      </Box>
      {!loading && !accessDenied && (
        <Box mt={3}>
          <H1>Invoice Details</H1>
          {invoiceInfo.status === 'failed' && (
            <H3 mt={2} mb={2}>
              <span style={{ color: '#E20338' }}>Last Action: </span>
              {userRole !== ROLES.CLIENT
                ? 'Payment failed due to the credit card error. Your client is notified by email.'
                : 'Payment failed. Please check your credit card and retry making payment.'}

            </H3>
          )}
          {invoiceInfo.status === 'failed' && userRole === ROLES.CLIENT && (
            <Button onClick={() => history.push('/wallet')} variant="contained">Go to Wallet</Button>
          )}
          {refundStatuses.includes('failed') && userRole !== ROLES.CLIENT && (
            <H3 mt={2} mb={2}>
              <span style={{ color: '#E20338' }}>Last Action: </span>
              <span>Refund failed due the card problem. Your client is notified by email.</span>
            </H3>
          )}
          {refundStatuses.includes('failed') && userRole === ROLES.CLIENT && (
            <Box mt={2} mb={2}>
              <H3 mb={2}>
                <span style={{ color: '#E20338' }}>Last Action: </span>
                <span>
                  Refund failed due the card problem. Please check the credit card that was used for making payment.
                </span>
              </H3>
              <Button onClick={() => history.push('/wallet')} variant="contained">Go to Wallet</Button>
            </Box>
          )}
          {refundStatuses.includes('pending') && userRole !== ROLES.CLIENT && (
            <H3 mt={2} mb={2}>
              <span style={{ color: '#E20338' }}>Last Action: </span>
              <span>Refund is pending due to insufficient funds on your transfer bank account.</span>
            </H3>
          )}
          {refundStatuses.includes('pending') && userRole === ROLES.CLIENT && (
            <H3 mt={2} mb={2}>
              <span style={{ color: '#E20338' }}>Last Action: </span>
              <span>
                Refund failed due the card problem. Please check the credit card that was used for making payment.
              </span>
            </H3>
          )}
          <Box
            mt={3}
            bgcolor="white"
            p={3}
            maxWidth={550}
          >
            <Box display="flex">
              <Box mr={2} display="flex" flexDirection="column">
                <H3Dark mt={2}><FormattedMessage {...messages.amount} /></H3Dark>
                <H3Dark mt={2}><FormattedMessage {...messages.status} /></H3Dark>
                {userRole !== ROLES.CLIENT && <H3Dark mt={2}><FormattedMessage {...messages.client} /></H3Dark>}
                <H3Dark mt={2}><FormattedMessage {...messages.advisor} /></H3Dark>
                <H3Dark mt={2}><FormattedMessage {...messages.due} /></H3Dark>
                <H3Dark mt={2}><FormattedMessage {...messages.billed} /></H3Dark>
                {invoiceInfo.date_paid && <H3Dark mt={2}><FormattedMessage {...messages.paid} /></H3Dark>}
                {invoiceInfo.date_canceled && <H3Dark mt={2}><FormattedMessage {...messages.canceled} /></H3Dark>}
                {invoiceInfo.date_refunded && <H3Dark mt={2}>Refund issued</H3Dark>}
                {invoiceInfo.refund_amount !== 0 && <H3Dark mt={2}>Refund amount</H3Dark>}
                <H3Dark mt={2}><FormattedMessage {...messages.invoiceId} /></H3Dark>
                <H3Dark mt={2}><FormattedMessage {...messages.description} /></H3Dark>
                {invoiceInfo.refund_description && <H3Dark minWidth={155} mt="auto">Refund Comments</H3Dark>}
              </Box>
              <Box>
                <H3 mt={2}><CurrencyProvider value={invoiceInfo.amount / 100} /></H3>
                <H3 mt={2}>
                  <span style={{ textTransform: 'uppercase' }}>{invoiceInfo.status?.replace(/_/g, ' ')}</span>
                </H3>
                {userRole !== ROLES.CLIENT && (
                  <Box mt={2}><Link href={`/users/clients/${invoiceInfo.client}`}>{invoiceInfo.client_name}</Link></Box>
                )}
                {userRole === ROLES.ADVISOR || userRole === ROLES.CLIENT ? (
                  <H3 mt={2}>{invoiceInfo.advisor_name}</H3>
                ) : (
                  <Box mt={2}>
                    <Link href={`/users/advisors/${invoiceInfo.advisor}`}>{invoiceInfo.advisor_name}</Link>
                  </Box>
                )}
                <H3 mt={2}>{moment(invoiceInfo.due_date).format('MM/DD/YYYY')}</H3>
                <H3 mt={2}>{moment(invoiceInfo.date_created).format('MM/DD/YYYY')}</H3>
                {invoiceInfo.date_paid && <H3 mt={2}>{moment(invoiceInfo.date_paid).format('MM/DD/YYYY')}</H3>}
                {invoiceInfo.date_canceled && <H3 mt={2}>{moment(invoiceInfo.date_canceled).format('MM/DD/YYYY')}</H3>}
                {invoiceInfo.date_refunded && <H3 mt={2}>{moment(invoiceInfo.date_refunded).format('MM/DD/YYYY')}</H3>}
                {invoiceInfo.refund_amount !== 0 && (
                  <H3 mt={2}>
                    <span>$</span>
                    {invoiceInfo.refund_amount / 100}
                  </H3>
                )}
                <H3 mt={2}>
                  <Link href={userRole === ROLES.CLIENT
                    ? `/transactions/single-payments/invoice/${invoiceInfo.id}`
                    : `/billing/single-payments/invoice/${invoiceInfo.id}`}
                  >
                    <span>#</span>
                    {invoiceInfo.number}
                  </Link>
                </H3>
                <H3 mt={2}>{invoiceInfo.description}</H3>
                {invoiceInfo.refund_description && <H3 mt={2}>{invoiceInfo.refund_description}</H3>}
              </Box>
            </Box>
            {userRole !== ROLES.CLIENT
            && invoiceInfo.status === 'paid'
            && (invoiceInfo.client !== invoiceInfo.advisor)
            && (
              <Box mt={5}>
                <Button
                  variant="text"
                  onClick={toggleRefundModal}
                  disabled={refundStatuses.includes('pending') || refundStatuses.includes('failed')}
                >
                  <Box mr={1}><ReturnIcon color="#3772FF" /></Box>
                  <FormattedMessage {...messages.refund} />
                </Button>
                <RefundForm
                  amount={invoiceInfo.amount}
                  open={refundModalOpen}
                  close={toggleRefundModal}
                  refundPayment={handleRefundPayment}
                />
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};


const mapStateToProps = (state: any) => ({
  invoiceInfo: state.invoice.invoiceInfo,
  loading: state.invoice.loading,
  accessDenied: state.invoice.error === 'Request failed with status code 404',
  userRole: state.auth.userRole,
  refundStatuses: state.invoice.refunds.map((item: any) => item.status),
});

const mapDispatchToProps = {
  getInvoice: getInvoiceAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
