import { TRANSFER_DETAILS_TYPES } from '../../constants';

export const fetchInitData = (payload: string) => ({
  type: TRANSFER_DETAILS_TYPES.FETCH_INIT_DATA,
  payload,
});

export const fetchInitDataSuccess = (payload: any) => ({
  type: TRANSFER_DETAILS_TYPES.FETCH_INIT_DATA_SUCCESS,
  payload
});

export const refetchData = (payload: any) => ({
  type: TRANSFER_DETAILS_TYPES.REFETCH_DATA,
  payload,
});

export const refetchDataSuccess = (payload: any) => ({
  type: TRANSFER_DETAILS_TYPES.REFETCH_DATA_SUCCESS,
  payload
});

export const transfersError = (error: any) => ({
  type: TRANSFER_DETAILS_TYPES.ERROR,
  payload: error,
});
