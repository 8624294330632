import React from 'react';
import Box from '@material-ui/core/Box';
import moment from 'moment';

import {
  BoxContainer,
  CurrencyProvider,
  H3Dark,
  Link
} from '../../../../components';
import { ROLES } from '../../../../constants';
import { withParentData } from '../../Context';

interface Props {
  userRole: string,
  subscriptionInfo: any,
}

const Info: React.FC<Props> = ({
  userRole,
  subscriptionInfo,
}) => (
  <BoxContainer maxWidth={550} p={3} display="flex">
    <Box mr={3}>
      <H3Dark mb={2}>Amount Owed</H3Dark>
      <H3Dark mb={2}>Status</H3Dark>
      <H3Dark mb={2}>Frequency</H3Dark>
      {userRole !== ROLES.CLIENT && <H3Dark mb={2}>Client</H3Dark>}
      <H3Dark mb={2}>Advisor</H3Dark>
      <H3Dark mb={2}>Advisor ID</H3Dark>
      {
        subscriptionInfo.next_bill_date
        && subscriptionInfo.status !== 'canceled'
        && subscriptionInfo.status !== 'completed'
        && <H3Dark mb={2}>Next Billing Date</H3Dark>
      }
      {subscriptionInfo.status !== 'inactive' && <H3Dark mb={2}>Final Billing Date</H3Dark>}
      <H3Dark mb={2}>Created on</H3Dark>
      {subscriptionInfo.date_canceled && subscriptionInfo.status !== 'completed' && <H3Dark mb={2}>Canceled on</H3Dark>}
      <H3Dark>Description</H3Dark>
    </Box>
    <Box>
      <H3Dark mb={2}><CurrencyProvider value={subscriptionInfo.amount / 100} /></H3Dark>
      <H3Dark mb={2}>
        <span style={{ textTransform: 'uppercase' }}>{subscriptionInfo.status?.replace(/_/g, ' ')}</span>
      </H3Dark>
      <H3Dark mb={2}>{subscriptionInfo.frequency}</H3Dark>
      {userRole !== ROLES.CLIENT && (
        <Box mb={2}><Link href={`/users/clients/${subscriptionInfo.client}`}>{subscriptionInfo.client_name}</Link></Box>
      )}
      {userRole === ROLES.ADVISOR || userRole === ROLES.CLIENT ? (
        <H3Dark mb={2}>{subscriptionInfo.advisor_name}</H3Dark>
      ) : (
        <Box mb={2}>
          <Link href={`/users/advisors/${subscriptionInfo.advisor}`}>{subscriptionInfo.advisor_name}</Link>
        </Box>
      )}
      {
        subscriptionInfo.next_bill_date
        && subscriptionInfo.status !== 'canceled'
        && subscriptionInfo.status !== 'completed'
        && <H3Dark mb={2}>{moment(subscriptionInfo.next_bill_date).format('MM/DD/YYYY')}</H3Dark>
      }
      {subscriptionInfo.status !== 'inactive' && (
        <H3Dark mb={2}>
          {subscriptionInfo.date_end ? moment(subscriptionInfo.date_end).format('MM/DD/YYYY') : '-'}
        </H3Dark>
      )}
      <H3Dark mb={2}>{moment(subscriptionInfo.date_created).format('MM/DD/YYYY')}</H3Dark>
      {subscriptionInfo.date_canceled && subscriptionInfo.status !== 'completed' && (
        <H3Dark mb={2}>{moment(subscriptionInfo.date_canceled).format('MM/DD/YYYY')}</H3Dark>
      )}
      <H3Dark>{subscriptionInfo.description}</H3Dark>
    </Box>
  </BoxContainer>
);

export default withParentData(Info);
