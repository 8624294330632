import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from '../../messages';
import { Button, EmptyTableMessageContainer, H2 } from '../../../../components';
import Link from '../../../../components/Link';
import H3Dark from '../../../../components/H3Dark';

interface Props {
  addAdvisor: () => void,
  accountSubscribed: boolean,
}

const Message: React.FC<Props> = ({ addAdvisor, accountSubscribed }) => (
  <EmptyTableMessageContainer>
    <H2 mb={3}>No Advisors, Yet</H2>
    <Button
      onClick={addAdvisor}
      variant="text"
      shouldValidateAccount
      accountVerified={accountSubscribed}
      modalContent={(
        <H3Dark p={3} maxWidth={600}>
          <span>
            Sorry but you are unable to make action until your ChalicePay subscription is unpaid. Please check your
          </span>
          {' '}
          <Link href="/settings">Bank Details</Link>
        </H3Dark>
      )}
    >
      add advisor
    </Button>
  </EmptyTableMessageContainer>
);

export default Message;
