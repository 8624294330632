import {
  put,
  takeLatest,
  call,
  select
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { settingsError, getBillingHistorySuccess } from '../../actions/Settings';
import { SETTINGS_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker(): SagaIterator<void> {
  try {
    const { auth } = yield select();
    const { data } = yield call(Api.payments.fetchBillingHistory, auth.userData.id);
    yield put(getBillingHistorySuccess(data));
  } catch (e) {
    yield put(settingsError(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(SETTINGS_TYPES.GET_BILLING_HISTORY, worker);
}
