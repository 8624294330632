/* eslint no-nested-ternary: 0 */
import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { Formik, Form, Field } from 'formik';
import { FormattedMessage, IntlShape } from 'react-intl';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe } from '@stripe/react-stripe-js';

import { withParentData } from '../../Context';
import {
  Modal,
  H2,
  H3Dark,
  H3,
  InputMask,
  FormikInput,
  FormikSelect,
  DropZoneInput,
  FormikDatePicker,
  Button,
} from '../../../../components';
import { CloseIcon } from '../../../../metadata/icons';
import messages from './messages';
import { STATES, occupationOptions, stripePushKey } from '../../../../constants';
import validationSchema from './validationSchema';

interface Props {
  editModalOpen: boolean,
  editLoading: boolean,
  intl: IntlShape,
  openEditForm: () => void,
  userData: {
    last_name: string,
    first_name: string,
    social_security_number: string,
    date_of_birth: string,
    occupation: string,
    email: string,
    address: {
      street: string,
      city: string,
      state: string,
      zip_code: string,
    },
    contacts: {
      web_site: string,
      phone_number: string,
    },
  },
  companyInfo: {
    employee_id: string,
    address: {
      street: string,
      city: string,
      state: string,
      zip_code: string,
    },
    contacts: {
      web_site: string,
      phone_number: string,
    },
    errors: {
      code: string,
      field: string,
    }[],
  },
  onSubmitEditForm: (data: {}) => void,
}

const EditForm: React.FC<Props> = ({
  editModalOpen,
  openEditForm,
  intl,
  companyInfo,
  userData,
  onSubmitEditForm,
  editLoading,
}) => {
  const errors = companyInfo.errors.map((item) => item.field);
  const stripe = useStripe();

  return (
    <Modal open={editModalOpen} handleClose={openEditForm}>
      <Box
        p={3}
        borderRadius={4}
        maxWidth={1300}
        maxHeight="100vh"
        overflow="auto"
      >
        <H2 mb={3}>Edit Account details</H2>
        <Formik
          initialValues={{
            employee_id: companyInfo.employee_id || '',
            street: companyInfo.address ? companyInfo.address.street || '' : '',
            city: errors.includes('city')
              ? ''
              : companyInfo.address ? companyInfo!.address!.city || '' : '',
            state: errors.includes('state')
              ? ''
              : companyInfo.address ? companyInfo!.address!.state || '' : '',
            zip_code: errors.includes('zip_code')
              ? ''
              : companyInfo.address ? companyInfo!.address!.zip_code || '' : '',
            web_site: companyInfo.contacts ? companyInfo!.contacts.web_site || '' : '',
            phone_number: companyInfo.contacts ? companyInfo!.contacts.phone_number || '' : '',
            first_name: userData.first_name,
            last_name: userData.last_name,
            social_security_number: '*********',
            date_of_birth: userData.date_of_birth,
            user_street: userData.address !== null ? userData.address.street : '',
            user_state: userData.address !== null ? userData.address.state : '',
            user_city: userData.address !== null ? userData.address.city : '',
            user_zip_code: userData.address !== null ? userData.address.zip_code : '',
            user_phone_number: userData.contacts !== null ? userData.contacts.phone_number : '',
            businessWebsite: userData.contacts !== null ? userData.contacts.web_site : '',
            occupation: userData.occupation,
            docs: [],
          }}
          initialErrors={{
            ...(errors.includes('city') && { city: 'City is not verified. Please edit.' }),
            ...(errors.includes('zip_code') && { zip_code: 'Zip code is not verified. Please edit.' }),
            ...(errors.includes('state') && { state: 'State code is not verified. Please edit.' }),
          }}
          initialTouched={{
            ...(errors.includes('city') && { city: true }),
            ...(errors.includes('zip_code') && { zip_code: true }),
            ...(errors.includes('state') && { state: true }),
          }}
          enableReinitialize
          validateOnMount={false}
          validationSchema={validationSchema(intl, errors)}
          onSubmit={async (values) => {
            if (stripe) {
              const { token } = await stripe.createToken('person', {
                first_name: values.first_name,
                last_name: values.last_name,
                ssn_last_4: values.social_security_number.slice(-4),
                phone: values.user_phone_number,
                email: userData.email,
                address: {
                  city: values.user_city,
                  state: values.user_state,
                  postal_code: `${values.user_zip_code}`,
                  country: 'US',
                  line1: values.user_street,
                },
                dob: {
                  day: moment(values.date_of_birth).date(),
                  month: moment(values.date_of_birth).month(),
                  year: moment(values.date_of_birth).year(),
                },
                relationship: {
                  owner: true,
                  representative: true,
                  title: 'Advisor',
                },
              });
              onSubmitEditForm({ ...values, source: token?.id });
            }
          }}
        >
          {({
            values,
            isValid,
            setFieldValue,
            setFieldTouched,
          }) => {
            const handleFieldChange = () => {
              if (values.social_security_number.includes('*')) {
                setFieldTouched('social_security_number', true);
                setFieldValue('social_security_number', '');
              }
            };

            return (
              <Form>
                <Box display={{ xs: 'block', md: 'flex' }} mb={3}>
                  <Box mr={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} style={{ padding: '0 12px' }}>
                        <H3Dark fontWeight={500}>Company Details</H3Dark>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={InputMask}
                          mask="99-9999999"
                          label={intl.formatMessage(messages.employerINField)}
                          name="employee_id"
                          onChange={handleFieldChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          onChange={handleFieldChange}
                          component={FormikInput}
                          label={intl.formatMessage(messages.companyStreetAddressField)}
                          name="street"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={FormikInput}
                          onChange={handleFieldChange}
                          label={intl.formatMessage(messages.cityField)}
                          name="city"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          name="state"
                          component={FormikSelect}
                          onChange={handleFieldChange}
                          label={intl.formatMessage(messages.stateField)}
                          options={STATES}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={FormikInput}
                          onChange={handleFieldChange}
                          label={intl.formatMessage(messages.zipCodeField)}
                          name="zip_code"
                          fullWidth
                          type="number"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={FormikInput}
                          onChange={handleFieldChange}
                          label={intl.formatMessage(messages.companySiteField)}
                          name="web_site"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={InputMask}
                          mask="(999)999-9999"
                          onChange={handleFieldChange}
                          label={intl.formatMessage(messages.phoneNumberField)}
                          name="phone_number"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {errors.includes('verification_document') && (
                          <Box>
                            <H3Dark mb="11px">ID card/Driver Licence</H3Dark>
                            <H3 mb="18px">Please upload a picture of your ID card or driver’s license.</H3>
                            <DropZoneInput
                              accept="*i"
                              getInitFiles={(data) => {
                                if (values.social_security_number.includes('*')) {
                                  setFieldTouched('social_security_number', true);
                                  setFieldValue('social_security_number', '');
                                }
                                setFieldValue('docs', [
                                  ...values.docs,
                                  ...data
                                    .filter(({ type }) => type.split('/')[1] === 'png' || type.split('/')[1] === 'jpeg')
                                    .map((item) => ({ item, id: uuidv4() }))
                                ]);
                              }}
                            />
                            <H3 mt={3}>The images should be 10 MB or less. JPG or PNG supported.</H3>
                          </Box>
                        )}
                      </Grid>
                      {values.docs.map(({ item, id }: any) => (
                        <Grid item xs={12} key={id}>
                          <Box display="flex" justifyContent="space-between">
                            <Box
                              height={56}
                              px={3}
                              border="1px solid #3772FF"
                              borderRadius={4}
                              color="#3772FF"
                              fontSize={24}
                              fontWeight={500}
                              display="flex"
                              alignItems="center"
                            >
                              {item.type.split('/')[1]}
                            </Box>
                            <Box>
                              <H3Dark>{item.name}</H3Dark>
                              <H3 fontSize={12}>
                                <span>{(item.size / 1000000).toFixed(1)}</span>
                                <span> MB</span>
                              </H3>
                            </Box>
                            <Box>
                              <IconButton
                                onClick={() => {
                                  setFieldValue(
                                    'docs',
                                    values.docs.filter((doc: any) => doc.id !== id)
                                  );
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                  <Box>
                    <Grid container spacing={3}>
                      <Grid item xs={12} style={{ padding: '0 12px' }}>
                        <H3Dark fontWeight={500}>Account Information</H3Dark>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={FormikInput}
                          label={intl.formatMessage(messages.firstNameField)}
                          name="first_name"
                          onChange={handleFieldChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={FormikInput}
                          label={intl.formatMessage(messages.lastNameField)}
                          name="last_name"
                          onChange={handleFieldChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={FormikDatePicker}
                          label={intl.formatMessage(messages.dateOfBirthField)}
                          name="date_of_birth"
                          onChange={handleFieldChange}
                          maxDate={moment().set({ year: moment().year() - 18 })}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={FormikInput}
                          type="password"
                          onChange={handleFieldChange}
                          label={intl.formatMessage(messages.secNumberField)}
                          name="social_security_number"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={FormikInput}
                          onChange={handleFieldChange}
                          label={intl.formatMessage(messages.addressStreetField)}
                          name="user_street"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={FormikInput}
                          onChange={handleFieldChange}
                          label={intl.formatMessage(messages.cityField)}
                          name="user_city"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          name="user_state"
                          component={FormikSelect}
                          onChange={handleFieldChange}
                          label={intl.formatMessage(messages.stateField)}
                          options={STATES}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={FormikInput}
                          onChange={handleFieldChange}
                          label={intl.formatMessage(messages.zipCodeField)}
                          name="user_zip_code"
                          type="number"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          component={InputMask}
                          onChange={handleFieldChange}
                          mask="(999)999-9999"
                          label={intl.formatMessage(messages.phoneNumberField)}
                          name="user_phone_number"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}><H3><FormattedMessage {...messages.optionText} /></H3></Grid>
                      <Grid item xs={12}>
                        <Field
                          component={FormikInput}
                          onChange={handleFieldChange}
                          label={intl.formatMessage(messages.businessWebsiteField)}
                          name="businessWebsite"
                          fullWidth
                          disabled={Boolean(values.occupation && values.occupation !== '0')}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          options={occupationOptions}
                          label={intl.formatMessage(messages.professionalOccupationField)}
                          name="occupation"
                          onChange={handleFieldChange}
                          component={FormikSelect}
                          disabled={values.businessWebsite}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="flex-end">
                  <Button variant="text" onClick={openEditForm}>
                    <FormattedMessage {...messages.cancelButton} />
                  </Button>
                  <Box ml={3}>
                    <Button
                      type="submit"
                      variant="text"
                      disabled={!isValid}
                      loading={editLoading}
                    >
                      <FormattedMessage {...messages.saveButton} />
                    </Button>
                  </Box>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Modal>
  );
};
const stripePromise = loadStripe(stripePushKey() || '');

const StripeWrapper: React.FC<Props> = (props) => (
  <Elements stripe={stripePromise}>
    <EditForm {...props} />
  </Elements>
);


export default withParentData(StripeWrapper);
