import { CLIENT_PROFILE_TYPES, PAYMENT_MODAL_TYPES } from '../constants';

const initState = {
  clientInfo: {},
  loading: false,
  error: {},
  singlePayments: [],
  singlePaymentsTotal: 0,
  singlePaymentsPagination: {
    page: 1
  },
  singlePaymentsSort: {
    id: null,
    firstDirection: false,
  },
  subscriptions: [],
  subscriptionsTotal: 0,
  subscriptionsPagination: {
    page: 1
  },
  subscriptionsSort: {
    id: null,
    firstDirection: false,
  },
};

export default (state = initState, { type, payload }: any) => {
  switch (type) {
    case CLIENT_PROFILE_TYPES.FETCH_INIT_DATA:
      return {
        ...state,
        loading: true
      };
    case CLIENT_PROFILE_TYPES.FETCH_INIT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CLIENT_PROFILE_TYPES.GET_CLIENT_INFO:
      return {
        ...state,
      };
    case CLIENT_PROFILE_TYPES.GET_SINGLE_PAYMENTS_SUCCESS:
      return {
        ...state,
        singlePayments: payload.results,
        singlePaymentsTotal: payload.count,
      };
    case CLIENT_PROFILE_TYPES.GET_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptions: payload.results,
        subscriptionsTotal: payload.count,
      };
    case PAYMENT_MODAL_TYPES.CREATE_SINGLE_PAYMENT_SUCCESS:
      return {
        ...state,
        singlePayments: [...state.singlePayments, payload],
      };
    case CLIENT_PROFILE_TYPES.REFETCH_SINGLE_PAYMENTS_SUCCESS:
      return {
        ...state,
        singlePaymentsSort: payload.sort,
        singlePaymentsPagination: payload.pagination,
        singlePayments: payload.data.results,
        singlePaymentsTotal: payload.data.count,
      };
    case CLIENT_PROFILE_TYPES.REFETCH_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptionsSort: payload.sort,
        subscriptionsPagination: payload.pagination,
        subscriptions: payload.data.results,
        subscriptionsTotal: payload.data.count,
      };
    case CLIENT_PROFILE_TYPES.GET_CLIENT_INFO_SUCCESS:
      return {
        ...state,
        clientInfo: payload,
      };
    case CLIENT_PROFILE_TYPES.ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default: {
      return state;
    }
  }
};
