import React from 'react';
import Box from '@material-ui/core/Box';
import DoneIcon from '@material-ui/icons/Done';
import { FormattedMessage } from 'react-intl';
import Link from '@material-ui/core/Link';

import messages from './messages';
import { withParentData } from '../../Context';
import H1 from '../../../../components/H1';

interface Props {
  onResendLink: () => void,
  email: string
}

const VefiredMessage = ({ onResendLink, email }: Props) => (
  <Box height="100%" display="flex" justifyContent="center" alignItems="center">
    <Box width={{
      xs: 530,
      md: 400,
      lg: 600,
    }}
    >
      <Box color="#3772FF" fontSize={64}><DoneIcon fontSize="inherit" /></Box>
      <H1 mb={3} mt={5}><FormattedMessage {...messages.checkEmailTitle} /></H1>
      <Box color="#6D6E71" fontSize={16}>
        <FormattedMessage {...messages.checkEmailDescription} values={{ email }} />
      </Box>
      <Box mt="50px" fontWeight={500}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link onClick={onResendLink}>
          <FormattedMessage {...messages.resendLink} />
        </Link>
      </Box>
    </Box>
  </Box>
);

export default withParentData(VefiredMessage);
