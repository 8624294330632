import React, { useState } from 'react';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  AuBankAccountElement,
} from '@stripe/react-stripe-js';

import './StripeElements.scss';

interface Props {
  type: 'card' | 'cvc' | 'exp' | 'bank',
  error: string,
  onChange: (name: string, completed: false) => void,
  onTouched: (name: string) => void,
}

const getComponent = (type: 'card' | 'cvc' | 'exp' | 'bank') => {
  switch (type) {
    case 'card':
      return CardNumberElement;
    case 'cvc':
      return CardCvcElement;
    case 'exp':
      return CardExpiryElement;
    case 'bank':
      return AuBankAccountElement;
    default:
      return CardNumberElement;
  }
};

const StripeElement: React.FC<Props> = ({
  type,
  error,
  onChange,
  onTouched,
}) => {
  const [focused, setFocused] = useState(false);
  const [touched, setTouched] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [hasValue, setHasValue] = useState(false);
  const [isError, setError] = useState(false);

  const options = {
    style: {
      base: {
        fontSize: '16px',
        color: '#6D6E71',
        letterSpacing: '0.025em',
        fontWeight: '200',
        '::placeholder': {
          color: isError ? '#E20338' : '#6D6E71',
        },
      },
    }
  };
  const Element = getComponent(type);

  const handleChange = (e: any) => {
    setEmpty(e.empty);
    setError(Boolean(e.error));
    onChange(type, e.complete);
    setHasValue(true);
  };

  const handleFocus = () => {
    setFocused(true);
    setTouched(true);
    onTouched(type);
  };
  const hasError = ((touched && !hasValue) || empty) || isError;

  return (
    <Box>
      <Box className={clsx('container', focused && 'focused', hasError && 'error')}>
        <Element
          onFocus={handleFocus}
          onBlur={() => setFocused(false)}
          onChange={handleChange}
          options={options}
        />
      </Box>
      {hasError && (
        <Box
          color="#E20338"
          marginX="12px"
          fontSize={12}
        >
          {error}
        </Box>
      )}
    </Box>
  );
};

export default StripeElement;
