import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { Form, Formik, Field } from 'formik';

import Modal from '../../Modal';
import H2 from '../../H2';
import Button from '../../Button';
import FormikSelect from '../../Controls/FormikSelect';
import Api from '../../../api';

interface Props {
  open: boolean,
  close: () => void,
  callback: () => void,
  id?: string,
  clientId: string,
}

const ChangeAdvisorModal: React.FC<Props> = ({
  open,
  close,
  id,
  clientId,
  callback
}) => {
  const [advisors, setAdvisors] = useState([]);
  const [done, setDone] = useState(false);

  const handleSubmit = ({ advisor }: any) => {
    Api.advisors.reassignAdvisors({
      old_advisor: id,
      new_advisor: advisor,
      client: clientId,
    }).then(() => {
      setDone(true);
      callback();
      setTimeout(() => {
        setDone(false);
        close();
      }, 1000);
    });
  };

  useEffect(() => {
    Api.advisors.getAllAdvisors().then(({ data }) => setAdvisors(data));
  }, []);

  return (
    <Modal
      open={open}
      handleClose={close}
    >
      {done ? (
        <Box
          p={3}
          width={500}
          justifyContent="center"
          alignItems="center"
          display="flex"
          height={200}
        >
          <H2>Advisor Changed!</H2>
        </Box>
      ) : (
        <Box p={3} width={500}>
          <H2 mb={3}>Change Advisor</H2>
          <Formik
            onSubmit={handleSubmit}
            initialValues={{
              advisor: id,
            }}
          >
            {({ dirty, isValid }) => (
              <Form>
                <Field
                  component={FormikSelect}
                  name="advisor"
                  label="Select Advisor"
                  options={advisors.map((item: any) => ({ value: item.id, label: item.name }))}
                />
                <Box mt={3} display="flex" justifyContent="space-between">
                  <Button onClick={close}>Cancel</Button>
                  <Button type="submit" disabled={!dirty || !isValid} variant="contained">Change Advisor</Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      )}
    </Modal>
  );
};

export default ChangeAdvisorModal;
