import { put, takeLatest, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { onBoardingError, getOrganizationSuccess } from '../../actions/onBoaring';
import { ONBOARDING_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker(): SagaIterator<void> {
  try {
    const { data } = yield call(Api.userProfiles.getOrganizations);
    yield put(getOrganizationSuccess(data.results.length === 0 ? {} : data.results[0]));
  } catch (e) {
    yield put(onBoardingError(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(ONBOARDING_TYPES.GET_COMPANY, worker);
}
