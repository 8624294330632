import React from 'react';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    background: 'white',
    boxShadow: '4px 10px 20px rgba(55, 114, 255, 0.1)',
    borderRadius: 4,
    padding: '8px',
    maxWidth: 500,
    fontSize: 14,
    color: '#011638'
  },
}))(Tooltip);

const Help: React.FC<{ title: any }> = ({ title }) => (
  <HtmlTooltip title={title}>
    <IconButton>
      <HelpIcon />
    </IconButton>
  </HtmlTooltip>
);

export default Help;
