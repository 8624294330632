import React from 'react';
import { connect } from 'react-redux';
import { Box } from '@material-ui/core';

import { logout } from '../../redux/actions/onBoaring';

import { Button, H2 } from '../../components';

const AccountDeletedScreen: React.FC<{ logoutAction: () => void }> = ({ logoutAction }) => (
  <Box display="flex" flexDirection="column" height="100vh" p={5}>
    <Box display="flex" justifyContent="flex-end">
      <Button variant="text" onClick={logoutAction}>Logout</Button>
    </Box>
    <Box
      flex={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <H2>Your ChalicePay account deleted</H2>
    </Box>
  </Box>
);


export default connect(null, { logoutAction: logout })(AccountDeletedScreen);
