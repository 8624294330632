import {
  put,
  takeLatest,
  call
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { settingsError, getAppSubscriptionSuccess } from '../../actions/Settings';
import { SETTINGS_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker(): SagaIterator<void> {
  try {
    const { data } = yield call(Api.settings.getAppSubscription);
    yield put(getAppSubscriptionSuccess(data));
  } catch (e) {
    yield put(settingsError(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(SETTINGS_TYPES.GET_APP_SUBSCRIPTION, worker);
}
