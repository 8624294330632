import React, { Component } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { RouteComponentProps } from 'react-router';

import Content from './Content';
import { Provider } from './Context';
import { VerifiedEmailData } from '../../redux/actions/onBoaring/types';

interface MatchParams {
  id: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  intl: IntlShape,
  setUpPassword: (password: string, id: string) => void,
  verifyEmail: (hash: string) => void,
  verifiedEmailData: {} | VerifiedEmailData,
  loading: boolean,
  linkExpired: boolean,
}

interface State {
  termsOpen: boolean,
  checked: boolean,
  passwordTouched: boolean,
  confirmPasswordTouched: boolean,
  isFormValid: boolean,
  confirmPassword: string,
  password: string,
  editPassword: string,
  editConfirmPassword: string,
}

class Container extends Component<Props, State> {
  state = {
    termsOpen: false,
    confirmPassword: '',
    editPassword: '',
    editConfirmPassword: '',
    password: '',
    checked: false,
    passwordTouched: false,
    confirmPasswordTouched: false,
    isFormValid: false,
  };

  componentDidMount() {
    const { verifyEmail, match } = this.props;

    verifyEmail(match.params.id);
  }

  handleTermsAccept = () => {
    const { isFormValid } = this.state;
    if (isFormValid) {
      this.agreeHandler();
    }
    this.setState({ termsOpen: false, checked: true });
  };

  handleTermsClose = () => {
    this.setState({ termsOpen: false });
  };

  handleOpenTerms = (isFormValid: boolean) => {
    const { editConfirmPassword, editPassword } = this.state;
    this.setState({
      termsOpen: true,
      password: editPassword,
      confirmPassword: editConfirmPassword,
      isFormValid
    });
  };

  handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'password') {
      this.setState({ editPassword: event.target.value });
    }
    if (event.target.name === 'confirmPassword') {
      this.setState({ editConfirmPassword: event.target.value });
    }
  };

  handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.name === 'password') {
      this.setState({ passwordTouched: true });
    }
    if (event.target.name === 'confirmPassword') {
      this.setState({ confirmPasswordTouched: true });
    }
  };

  agreeHandler = () => {
    const { password } = this.state;
    const { setUpPassword, verifiedEmailData } = this.props;
    if ('email' in verifiedEmailData) {
      setUpPassword(password, verifiedEmailData.email);
    }
  };

  handleFromSubmit = ({ password }: { password: string }) => {
    const { setUpPassword, verifiedEmailData } = this.props;
    if ('email' in verifiedEmailData) {
      setUpPassword(password, verifiedEmailData.email);
    }
  };

  handelCheckboxChange = () => this.setState((prev) => ({ checked: !prev.checked }));

  render() {
    const {
      termsOpen,
      confirmPassword,
      password,
      checked,
      passwordTouched,
      confirmPasswordTouched,
    } = this.state;
    const {
      intl,
      verifiedEmailData,
      loading,
      linkExpired,
    } = this.props;
    const pageLoading = verifiedEmailData && Object.keys(verifiedEmailData).length === 0;
    return (
      <Provider
        value={{
          onClose: this.handleTermsClose,
          onAgree: this.handleTermsAccept,
          openTerms: this.handleOpenTerms,
          onFieldChange: this.handleFieldChange,
          onCheckboxChange: this.handelCheckboxChange,
          onFieldFocus: this.handleFocus,
          onSubmit: this.handleFromSubmit,
          intl,
          confirmPassword,
          password,
          passwordTouched,
          confirmPasswordTouched,
          checked,
          loading,
        }}
      >
        <Content linkExpired={linkExpired} termsOpen={termsOpen} loading={pageLoading} />
      </Provider>
    );
  }
}

export default injectIntl(Container);
