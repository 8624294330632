import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import clsx from 'clsx';

import './styels.scss';

interface Props {
  value: string | number,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  name: string,
  helperText?: string,
  label?: string,
  error?: boolean,
}

const MyComponent: React.FC<Props> = ({
  label,
  error,
  value,
  helperText,
  ...rest
}) => {
  const [focused, setFocused] = useState(false);

  return (
    <div className={clsx('number-input-container', error && 'error')}>
      <div
        className="label"
        style={{
          ...(focused && { color: '#3772FF' }),
          ...(error && { color: '#E20338' }),
          ...(value && { color: '#011638' }),
          top: focused || value ? '8px' : '18px',
          fontSize: value || focused ? '12px' : '16px',
        }}
      >
        {label}
      </div>
      <NumberFormat
        style={{ paddingTop: label ? '16px' : 0 }}
        onFocus={() => setFocused(true)}
        onBlurCapture={() => setFocused(false)}
        className="currency-input"
        thousandSeparator
        prefix="$"
        inputmode="numeric"
        value={value}
        {...rest}
      />
      {helperText && (
        <div className={clsx('helper-text', error && 'error')}>
          {helperText}
        </div>
      )}
    </div>
  );
};

export default MyComponent;
