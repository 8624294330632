/* eslint-disable @typescript-eslint/quotes  */
import { defineMessages } from 'react-intl';

export default defineMessages({
  requestButton: {
    id: 'buttons.requestButton',
    defaultMessage: 'Request payment',
    description: 'Button label'
  },
  subscription: {
    id: 'subscription',
    defaultMessage: 'Subscriptions',
    description: 'Label'
  },
  noSubscription: {
    id: 'subscription.noPayments',
    defaultMessage: 'No Subscriptions, Yet',
    description: 'Label'
  },
});
