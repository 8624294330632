import { SINGLE_PAYMENTS_TYPES } from '../../constants';

export const getSinglePayments = () => ({
  type: SINGLE_PAYMENTS_TYPES.GET_PAYMENTS,
});

export const getSinglePaymentsSuccess = (payload: any) => ({
  type: SINGLE_PAYMENTS_TYPES.GET_PAYMENTS_SUCCESS,
  payload,
});

export const refetchPayments = (payload: any) => ({
  type: SINGLE_PAYMENTS_TYPES.REFETCH_PAYMENTS,
  payload
});

export const refetchSingleSuccess = (payload: any) => ({
  type: SINGLE_PAYMENTS_TYPES.REFETCH_PAYMENTS_SUCCESS,
  payload
});

export const singlePaymentsError = (error: any) => ({
  type: SINGLE_PAYMENTS_TYPES.ERROR,
  payload: error
});
