import React from 'react';
import Box from '@material-ui/core/Box';

const InfoBox = ({ label, value }: { label: string, value: string }) => (
  <Box
    height={56}
    bgcolor="#F2F8FD"
    pl={2}
    borderRadius="4px 4px 0px 0px"
  >
    <Box fontSize={12} color="#011638">{label}</Box>
    <Box fontSize={16} color="#6D6E71">{value}</Box>
  </Box>
);

export default InfoBox;
