import React from 'react';
import Box from '@material-ui/core/Box';

import { MoonLoader } from 'react-spinners';
import { FormattedMessage } from 'react-intl';
import { Button, BackButton } from '../../components';
import { PrintIcon } from '../../metadata/icons';
import messages from './messages';
import Invoice from './Invoice';

interface Props {
  onPrint: () => void,
  loading: boolean,
  userRole: string,
  invoiceInfo: {
    amount: number,
    refund_amount: number,
    description: string,
    refund_description: string,
    organisation: string,
    due_date: string,
    date_paid: string,
    date_canceled: string,
    date_refunded: string,
    advisor_name: string,
    date_created: string,
    client_name: string,
    invoiceInfo: string,
    status: string,
    advisor_email: string,
    number: string | null,
  },
}

const MyComponent: React.FC<Props> = ({
  invoiceInfo,
  loading,
  onPrint,
  userRole,
}) => (
  <Box mt={2} flex={1} display="flex" flexDirection="column">
    <BackButton />
    <Box display="flex" flex={1} justifyContent="center" alignItems="center">
      <MoonLoader
        size={100}
        color="#011638"
        loading={loading}
      />
    </Box>
    {!loading && (
    <Box mt={3} maxWidth={700}>
      <Box display="flex" justifyContent="flex-end">
        <Button
          startIcon={PrintIcon}
          variant="text"
          onClick={onPrint}
        >
          <FormattedMessage {...messages.printButton} />
        </Button>
      </Box>
      <Box m={3} mt={2}><Invoice invoiceInfo={invoiceInfo} userRole={userRole} /></Box>
    </Box>
    )}
  </Box>
);

export default MyComponent;
