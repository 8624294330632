import {
  put, takeLatest, call, select
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { clientsError, refetchClients } from '../../actions/Clients';
import { CLIENT_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker({ payload, callback }: any): SagaIterator<void> {
  try {
    yield call(Api.clients.createClient, payload);
    const store = yield select();
    yield put(refetchClients({
      sort: store.clients.sort,
      pagination: store.clients.pagination,
    }));
    yield callback(false);
  } catch (e) {
    yield put(clientsError(e));
    yield callback(true);
  }
}

export default function* watcher() {
  yield takeLatest(CLIENT_TYPES.CREATE_CLIENT, worker);
}
