import {
  put,
  takeLatest,
  call,
  select
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { subscriptionDetailsError, refetchPaymentsSuccess } from '../../actions/SubscriptionDetails';
import { SUBSCRIPTION_DETAILS_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker({ payload }: any): SagaIterator<void> {
  try {
    const { subscriptionDetails } = yield select();
    const { data } = yield call(
      Api.subscriptions.refetchSubscriptionPayments,
      subscriptionDetails.subscriptionInfo.id, payload,
    );
    yield put(refetchPaymentsSuccess({ ...payload, data }));
  } catch (e) {
    yield put(subscriptionDetailsError(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(SUBSCRIPTION_DETAILS_TYPES.REFETCH_PAYMENTS, worker);
}
