import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { FormattedMessage, useIntl, IntlShape } from 'react-intl';
import { Formik, Form, Field } from 'formik';
import DoneIcon from '@material-ui/icons/Done';
import * as Yup from 'yup';

import {
  Modal,
  H2,
  Button,
  FormikInput,
  FormikCurrencyInput,
  H3,
} from '../../components';
import messages from './messages';


const validationSchema = (intl: IntlShape, max: number) => Yup.object().shape({
  amount: Yup.number()
    .max(max, intl.formatMessage(messages.refundAmountMaxError))
    .required(intl.formatMessage(messages.refundAmountRequiredError)),
});

interface Props {
  open: boolean,
  close: () => void,
  amount: number,
  refundPayment: (data: { amount: string, description: string }) => Promise<any>,
}

const RefundForm: React.FC<Props> = ({
  open,
  close,
  amount,
  refundPayment,
}) => {
  const [succeeded, setSucceeded] = useState(false);
  const intl = useIntl();

  return (
    <Modal handleClose={close} open={open}>
      {succeeded ? (
        <Box p={5} bgcolor="white" width={500} display="flex" flexDirection="column" alignItems="center">
          <Box color="#3772FF" fontSize={64}><DoneIcon fontSize="inherit" /></Box>
          <H2 mt={3}>Refund on its way</H2>
          <H3 mt={2}>Refund take 5-10 days to appear on client’s statement</H3>
        </Box>
      ) : (
        <Box p={3}>
          <H2 mb={2}>
            <FormattedMessage {...messages.refundModalTitle} />
          </H2>
          <Formik
            initialValues={{ amount: '', description: '' }}
            onSubmit={(values) => {
              refundPayment(values).then(() => {
                setSucceeded(true);
                setTimeout(() => {
                  setSucceeded(false);
                  close();
                }, 1000);
              });
            }}
            validationSchema={validationSchema(intl, amount / 100)}
          >
            {({ dirty, isValid, setFieldValue }) => (
              <Form style={{ minWidth: '500px' }}>
                <Box mb={3}>
                  <Field
                    name="amount"
                    component={FormikCurrencyInput}
                    withoutFormUpdate
                    decimalScale={2}
                    allowNegative={false}
                    onChange={(event: any) => {
                      setFieldValue('amount', Number(event.target.value.replace(/,/g, '').replace('$', '')));
                    }}
                    label={intl.formatMessage(messages.refundAmount)}
                  />
                </Box>
                <Box mb={3}>
                  <Field
                    fullWidth
                    name="description"
                    component={FormikInput}
                    label={intl.formatMessage(messages.description)}
                    helperText={intl.formatMessage(messages.optional)}
                  />
                </Box>
                <Box display="flex" justifyContent="flex-end">
                  <Box mr={2}>
                    <Button variant="text" onClick={close}>
                      <FormattedMessage {...messages.cancelButton} />
                    </Button>
                  </Box>
                  <Button type="submit" variant="text" disabled={!dirty || !isValid}>
                    <FormattedMessage {...messages.refund} />
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      )}
    </Modal>
  );
};

export default RefundForm;
