import Box from '@material-ui/core/Box';
import withStyles from '@material-ui/core/styles/withStyles';

export default withStyles({
  root: {
    color: '#011638',
    fontSize: '16px',
    lineHeight: '24px',
  },
})(Box);
