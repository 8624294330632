import React, { Component } from 'react';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import DoneIcon from '@material-ui/icons/Done';

import { FormattedMessage } from 'react-intl';
import { getUser as getUserAction } from '../../redux/actions/Auth';
import {
  Modal,
} from '../../components';
import Api from '../../api';
import messages from './messages';
import Content from './Content';
import { Provider } from './Context';

interface Props {
  userData: {
    email: string,
    id: string,
    first_name: string,
    last_name: string,
  },
  getUser: () => void,
  userRole: string,
}

interface State {
  passwordActive: boolean,
  loading: boolean,
  isError: boolean,
  updateUserActive: boolean,
  successModalOpen: boolean,
}

class Container extends Component<Props, State> {
  state = {
    passwordActive: false,
    updateUserActive: false,
    loading: false,
    isError: false,
    successModalOpen: false,
  };

  toggleSuccessModal = () => this.setState((prevState) => ({ successModalOpen: !prevState.successModalOpen }));

  togglePasswordForm = () => this.setState((prevState) => ({ passwordActive: !prevState.passwordActive }));

  toggleUserSection = () => this.setState((prevState) => ({ updateUserActive: !prevState.updateUserActive }));

  handlePassword = (values: any) => {
    const { userData } = this.props;
    this.setState({ loading: true }, () => {
      Api.users.changeUserPassword({
        email: userData.email,
        current_password: values.currentPassword,
        password_confirm: values.newPassword,
        password: values.confirmPassword,
      }).then(() => {
        this.setState({ successModalOpen: true, loading: false }, () => {
          setTimeout(() => {
            this.setState({ successModalOpen: false, isError: false, passwordActive: false });
          }, 1000);
        });
      }).catch(() => {
        this.setState({ loading: false, isError: true });
      });
    });
  };

  handleInfoChange = (values: any) => {
    const { userData, getUser } = this.props;
    Api.userProfiles.getAccountInfo(userData.id)
      .then(({ data: { ...rest } }) => Api.userProfiles.updateAccountInfo({
        values: {
          ...rest,
          first_name: values.firstName,
          last_name: values.lastName,
          ...(values.source && { source: values.source }),
        },
        id: userData.id
      }))
      .then(() => {
        getUser();
      })
      .then(() => this.setState({ successModalOpen: true }, () => {
        setTimeout(() => {
          this.setState({ successModalOpen: false, updateUserActive: false });
        }, 1000);
      }));
  };

  render() {
    const {
      state: {
        passwordActive,
        loading,
        isError,
        successModalOpen,
        updateUserActive,
      },
      props: { userData, userRole }
    } = this;

    return (
      <Provider
        value={{
          userData,
          passwordActive,
          loading,
          updateUserActive,
          isError,
          userRole,
          toggleUserSection: this.toggleUserSection,
          handlePasswordChange: this.handlePassword,
          togglePasswordForm: this.togglePasswordForm,
          handleInfoChange: this.handleInfoChange,
        }}
      >
        <Content />
        <Modal
          open={successModalOpen}
          handleClose={this.toggleSuccessModal}
        >
          <Box p={5} bgcolor="white" width={450} display="flex" flexDirection="column" alignItems="center">
            <Box color="#3772FF" fontSize={64}><DoneIcon fontSize="inherit" /></Box>
            <Box mt={3} fontSize={24} color="#011638"><FormattedMessage {...messages.changesSaved} /></Box>
          </Box>
        </Modal>
      </Provider>
    );
  }
}

const mapStateToProps = (state: any) => ({
  userData: state.auth.userData,
  userRole: state.auth.userRole,
});

export default connect(mapStateToProps, { getUser: getUserAction })(Container);
