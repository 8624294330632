import React from 'react';
import { FormattedMessage } from 'react-intl';
import Box from '@material-ui/core/Box';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';


import messages from '../../messages';
import { Button, FormikInput, H2 } from '../../../../components';
import { REGEXP } from '../../../../constants/validation';
import { withParentData } from '../../Context';
import InfoBox from '../InfoBox';

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .matches(REGEXP.PASSWORD, 'incorrect password')
    .required('Please provide your current password.'),
  newPassword: Yup.string()
    .required('Please provide a new password.')
    .notOneOf([Yup.ref('currentPassword'), undefined], 'Please create a new password.')
    .matches(REGEXP.PASSWORD, 'incorrect password'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), undefined], 'Passwords do not match')
    .required('Please provide a password.'),
});

interface Props {
  passwordActive: boolean,
  loading: boolean,
  isError: boolean,
  togglePasswordForm: () => void,
  handlePasswordChange: (values: {}) => void,
}

const Password: React.FC<Props> = ({
  passwordActive,
  togglePasswordForm,
  loading,
  isError,
  handlePasswordChange,
}) => (
  <Box>
    <H2 mb={2}><FormattedMessage {...messages.changePassword} /></H2>
    {!passwordActive ? (
      <Box width="50%">
        <InfoBox label="Current password" value="********" />
        <Box mt={3}>
          <Button variant="text" onClick={togglePasswordForm}>
            <FormattedMessage {...messages.changePassword} />
          </Button>
        </Box>
      </Box>
    ) : (
      <Formik
        initialValues={{
          currentPassword: '',
          newPassword: '',
          confirmPassword: ''
        }}
        onSubmit={handlePasswordChange}
        validationSchema={validationSchema}
        enableReinitialize
        initialErrors={isError ? { currentPassword: 'wrong current password' } : {}}
        initialTouched={isError ? { currentPassword: true } : {}}
      >
        {({ isValid, dirty }) => (
          <Form>
            <Box width="50%" mb={3}>
              <Field
                component={FormikInput}
                name="currentPassword"
                label="Current password"
                type="password"
                fullWidth
              />
            </Box>
            <Box width="50%" mb={3}>
              <Field
                component={FormikInput}
                name="newPassword"
                fullWidth
                label="New password"
                type="password"
              />
            </Box>
            <Box width="50%" mb={3}>
              <Field
                component={FormikInput}
                name="confirmPassword"
                label="Confirm password"
                fullWidth
                type="password"
              />
            </Box>
            <Box width="50%" mb={3}>
              <Button
                type="submit"
                variant="text"
                disabled={!dirty || !isValid}
                loading={loading}
              >
                <FormattedMessage {...messages.saveChangesButton} />
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    )}
  </Box>
);

export default withParentData(Password);
