import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { FormattedMessage, IntlShape } from 'react-intl';
import { Field, Form, Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe } from '@stripe/react-stripe-js';

import { stripePushKey } from '../../../../constants';
import messages from './messages';
import { Button, FormikInput, H1 } from '../../../../components';
import { withParentData } from '../../Context';
import validationSchema from './validationSchema';

interface Props {
  submitBanking: (values: {} | null) => void,
  goToBackStep: () => void,
  fetchInitBankingData: () => void,
  companyData: {
    name: string,
  },
  bankingAccountData: {
    account_holder_name: string,
    routing_number: string,
    last4: string,
  },
  intl: IntlShape,
  loading: boolean,
}

const BankingDetailsStep: React.FC<Props> = ({
  submitBanking: submit,
  intl,
  goToBackStep,
  fetchInitBankingData,
  companyData,
  loading,
  bankingAccountData,
}) => {
  useEffect(() => {
    fetchInitBankingData();
  }, [fetchInitBankingData]);
  const accountHolder = companyData.name || '';
  const stripe = useStripe();

  return (
    <Box width={600}>
      <H1 mb={3}><FormattedMessage {...messages.pageTitle} /></H1>
      <Box
        color="#6D6E71"
        mb={3}
      >
        <FormattedMessage {...messages.pageDescription} />
      </Box>
      <Box
        color="#6D6E71"
        mb={3}
      >
        Currently, we accept checking accounts only.
      </Box>
      <Formik
        initialValues={{
          accountHolder: bankingAccountData.account_holder_name || accountHolder,
          typeOfBusiness: 'Company',
          routingNumber: bankingAccountData.routing_number || '',
          accountNumber: bankingAccountData.last4 ? `******${bankingAccountData.last4}` : '',
          accountNumberConfirmation: bankingAccountData.last4 ? `******${bankingAccountData.last4}` : '',
        }}
        enableReinitialize
        validationSchema={validationSchema(intl)}
        onSubmit={async (values) => {
          if (stripe) {
            const { token } = await stripe.createToken('bank_account', {
              country: 'US',
              currency: 'usd',
              routing_number: values.routingNumber,
              account_number: values.accountNumber,
              account_holder_name: values.accountHolder,
              account_holder_type: 'individual',
            });
            submit({ ...values, source: token?.id });
          }
        }}
      >
        {({
          isValid,
          dirty,
          values,
          setFieldTouched,
          setFieldValue
        }) => {
          const handleFieldChange = () => {
            if (Object.keys(bankingAccountData).length !== 0) {
              if (values.accountNumber.includes('*')) {
                setFieldTouched('accountNumber', true);
                setFieldValue('accountNumber', '');
              }
              if (values.accountNumberConfirmation.includes('*')) {
                setFieldTouched('accountNumberConfirmation', true);
                setFieldValue('accountNumberConfirmation', '');
              }
            }
          };
          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    component={FormikInput}
                    onChange={handleFieldChange}
                    label={intl.formatMessage(messages.accountHolderField)}
                    name="accountHolder"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={FormikInput}
                    label={intl.formatMessage(messages.typeOfBusinessField)}
                    name="typeOfBusiness"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    onChange={handleFieldChange}
                    component={FormikInput}
                    label={intl.formatMessage(messages.routingNumberField)}
                    name="routingNumber"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={FormikInput}
                    label={intl.formatMessage(messages.accountNumberField)}
                    name="accountNumber"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={FormikInput}
                    label={intl.formatMessage(messages.accountNumberConfirmationField)}
                    name="accountNumberConfirmation"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Box mt={5} textAlign="end" display="flex" flexDirection="row" justifyContent="flex-end">
                <Box mr={3}>
                  <Button onClick={goToBackStep}>
                    <FormattedMessage {...messages.backButton} />
                  </Button>
                </Box>
                <Button
                  type={dirty ? 'submit' : 'button'}
                  onClick={() => {
                    if (!dirty) {
                      submit(null);
                    }
                  }}
                  variant="contained"
                  disabled={Object.keys(bankingAccountData).length === 0 ? (!dirty || !isValid) : !isValid}
                  loading={loading}
                >
                  <FormattedMessage {...messages.submitButton} />
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

const stripePromise = loadStripe(stripePushKey() || '');

const StripeWrapper: React.FC<Props> = (props) => (
  <Elements stripe={stripePromise}>
    <BankingDetailsStep {...props} />
  </Elements>
);

export default withParentData(StripeWrapper);
