import { defineMessages } from 'react-intl';

export default defineMessages({
  accountInfoStep: {
    id: 'onBoarding.accountInfoStep',
    defaultMessage: 'Account Information',
    description: 'Navigation'
  },
  requiredMassage: {
    id: 'onBoarding.requiredFieldMassage',
    defaultMessage: 'Please fill in all required fields.',
    description: 'Message'
  },
  optionText: {
    id: 'onBoarding.accountInfoStep.option',
    defaultMessage: 'Please enter one of the two:',
    description: 'Navigation'
  },
  accountInfoDescription: {
    id: 'onBoarding.accountInfoDescription',
    defaultMessage: 'The bank account information you provide below will be used to receive',
    description: 'Description'
  },
  firstNameField: {
    id: 'fields.firstName',
    defaultMessage: 'First name',
    description: 'Field label'
  },
  firstNameError: {
    id: 'fields.firstName.error',
    defaultMessage: 'First name is too long.',
    description: 'Field label'
  },
  firstNameInvalidError: {
    id: 'fields.firstName.invalidError',
    defaultMessage: 'First name contains invalid symbols.',
    description: 'Field label'
  },
  lastNameField: {
    id: 'fields.lastName',
    defaultMessage: 'Last name',
    description: 'Field label'
  },
  lastNameError: {
    id: 'fields.lastName.error',
    defaultMessage: 'Last name is too long.',
    description: 'Field label'
  },
  lastNameInvalidError: {
    id: 'fields.lastName.invalidError',
    defaultMessage: 'Last name contains invalid symbols.',
    description: 'Field label'
  },
  dateOfBirthField: {
    id: 'fields.dateOfBirth',
    defaultMessage: 'Date of birth',
    description: 'Field label'
  },
  secNumberField: {
    id: 'fields.secNumber',
    defaultMessage: 'Social security number',
    description: 'Field label'
  },
  secNumberError: {
    id: 'fields.secNumber.Error',
    defaultMessage: 'Social security number should contain nine digits.',
    description: 'Field label'
  },
  cityField: {
    id: 'fields.city',
    defaultMessage: 'City',
    description: 'Field label'
  },
  stateField: {
    id: 'fields.state',
    defaultMessage: 'State',
    description: 'Field label'
  },
  zipCodeField: {
    id: 'fields.zipCode',
    defaultMessage: 'Zip Code',
    description: 'Field label'
  },
  streetAddressField: {
    id: 'fields.streetAddress',
    defaultMessage: 'Company street address',
    description: 'Field label'
  },
  phoneNumberField: {
    id: 'fields.phoneNumber',
    defaultMessage: 'Phone number',
    description: 'Field label'
  },
  backButton: {
    id: 'buttons.back',
    defaultMessage: 'Back',
    description: 'Button label'
  },
  submitButton: {
    id: 'buttons.submit',
    defaultMessage: 'Submit',
    description: 'Button label'
  },
  businessWebsiteField: {
    id: 'fields.businessWebsite',
    defaultMessage: 'Your business website',
    description: 'Button label'
  },
  professionalOccupationField: {
    id: 'fields.professionalOccupation',
    defaultMessage: 'Your professional occupation',
    description: 'Button label'
  },
  optional: {
    id: 'optional',
    description: 'Label',
    defaultMessage: 'Optional',
  },
  companyStreetAddressMinError: {
    id: 'fields.companyStreetAddress.minError',
    defaultMessage: 'Street address should be at least 3 letters long.',
    description: 'Field label'
  },
  companyStreetAddressInvalidError: {
    id: 'fields.companyStreetAddress.minError',
    defaultMessage: 'Street address contains invalid symbols.',
    description: 'Field label'
  },
  companyStreetAddressMaxError: {
    id: 'fields.companyStreetAddress.maxError',
    defaultMessage: 'Street address is too long.',
    description: 'Field label'
  },
  zipCodeErrorField: {
    id: 'fields.zipCode.error',
    defaultMessage: 'Zip code should contain from 5 up to 9 digits.',
    description: 'Field label'
  },
  phoneNumberError: {
    id: 'fields.phoneNumber.error',
    defaultMessage: 'Phone number should contain only digits.',
    description: 'Field label'
  },

});
