import React from 'react';
import Box from '@material-ui/core/Box';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import { FormattedMessage } from 'react-intl';

import {
  H1,
  H2,
  H3Dark,
  Table,
  EmptyTableMessageContainer,
  Button,
  Modal,
  BillingForm,
} from '../../components';
import messages from './messages';

const Message: React.FC<{ onAddCard: () => void }> = ({ onAddCard }) => (
  <EmptyTableMessageContainer>
    <H2 mb={3}><FormattedMessage {...messages.noCards} /></H2>
    <Button onClick={onAddCard} variant="text">
      <FormattedMessage {...messages.addCreditCard} />
    </Button>
  </EmptyTableMessageContainer>
);

const columns = [
  {
    Header: 'Status',
    accessor: (row: any) => (row.is_default && row.card.status !== 'expired' ? 'Default' : row.card.status),
    id: 'status',
  },
  {
    Header: 'Card name',
    accessor: (row: any) => row.billing_details.name,
    id: 'name',
  },
  {
    Header: 'Card number',
    accessor: (row: any) => (`---- ---- ---- ${row.card.last4}`),
    id: 'last4',
  },
  {
    Header: 'Expires',
    accessor: (row: any) => (`${row.card.exp_month}/${row.card.exp_year}`),
    id: 'expires',
  },
];

interface Props {
  toggleModal: () => void,
  modalOpen: boolean,
  onAddCard:(values: {} | null) => void,
  clientAdded: boolean,
  cards: {}[],
  actions: any,
}

const Content: React.FC<Props> = ({
  toggleModal,
  modalOpen,
  onAddCard,
  clientAdded,
  cards,
  actions,
}) => (
  <Box mt={2}>
    <H1><FormattedMessage {...messages.pageTitle} /></H1>
    <Box display="flex" justifyContent="space-between" mb={2} mt={5}>
      <H2><FormattedMessage {...messages.creditCards} /></H2>
      {cards.length !== 0 && (
        <Button onClick={toggleModal} variant="text">
          <AddIcon />
          <span><FormattedMessage {...messages.addCreditCardButton} /></span>
        </Button>
      )}
    </Box>
    <Table
      columns={columns}
      data={cards}
      noItemsMessage={<Message onAddCard={toggleModal} />}
      actions={actions}
      errorRowMessage="This credit card has expired. Please add another credit card  to make payments."
      getRowError={({ original }: any) => original.card.status === 'expired'}
    />
    <Modal
      open={modalOpen}
      handleClose={toggleModal}
    >
      <Box p={3} maxHeight={630} maxWidth={700} overflow="auto">
        {clientAdded ? (
          <Box p={5} bgcolor="white" width={450} display="flex" flexDirection="column" alignItems="center">
            <Box color="#3772FF" fontSize={64}><DoneIcon fontSize="inherit" /></Box>
            <H2 mt={3}><FormattedMessage {...messages.creditCardAdded} /></H2>
          </Box>
        ) : (
          <>
            <H2 mb={3}><FormattedMessage {...messages.addCreditCard} /></H2>
            <H3Dark fontWeight={500} mb={1}><FormattedMessage {...messages.billingAddress} /></H3Dark>
            <BillingForm
              submitBilling={onAddCard}
              goToBackStep={toggleModal}
              billingInfo={{}}
              accountInfo={{}}
              backButtonLabel="cancel"
              submitButtonLabel="add"
              submitButtonProps={{ variant: 'text' }}
              backButtonProps={{ variant: 'text' }}
            />
          </>
        )}
      </Box>
    </Modal>
  </Box>
);

export default Content;
