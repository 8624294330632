import React, { useEffect } from 'react';
import { FieldInputProps, FormikProps } from 'formik';

import Select from '../Select';

interface Props {
  field: FieldInputProps<any>,
  form: FormikProps<any>,
  helperText?: string,
  onChange?: () => void,
  type?: string,
  options: {value: string, label: string}[],
}

const FormikSelect: React.FC<Props> = ({
  field,
  form,
  helperText,
  onChange,
  ...rest
}) => {
  const { name } = field;
  const { setFieldValue } = form;
  useEffect(() => {
    if (field.value) {
      setFieldValue(name, field.value);
    }
  }, [field.value, name, setFieldValue]);
  const handleChange = (res: { value: string }) => {
    if (onChange) {
      onChange();
    }
    form.setFieldValue(name, res.value);
    form.setFieldTouched(name, true);
  };
  const isError = Boolean(form.touched[name] && form.errors[name]);
  const helper = helperText || (isError ? `${form.errors[name]}` : undefined);

  return (
    <Select
      {...rest}
      onBlur={() => {
        form.setFieldTouched(name, true);
      }}
      error={isError}
      helperText={helper}
      value={field.value}
      onChange={handleChange}
    />
  );
};

export default FormikSelect;
