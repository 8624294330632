import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'moment';

import { FormattedMessage } from 'react-intl';
import { Tables, InvitationLink } from './components';
import {
  Modal, Button, RequestPaymentModal, H3Dark, Link
} from '../../components';
import messages from './messages';
import { ROLES } from '../../constants';

const InfoBox = withStyles({
  root: {
    minHeight: 192,
    padding: 24,
    boxShadow: '4px 10px 20px rgba(55, 114, 255, 0.1)',
    borderRadius: 4,
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
  },
})(Box);

interface Props {
  clientInfo: {
    name: string,
    email: string,
    is_active: boolean,
    added_on: string,
    advisor: string,
    total_revenue: number,
    advisor_name: number,
  },
  modalOpen: boolean,
  requestModalOpen: boolean,
  toggleRequestModal: () => void,
  toggleDeleteClientModal: () => void,
  toggleChangeAdvisorModal: () => void,
  accountVerified: boolean,
  accountSubscribed: boolean,
  userRole: string,
}

const Content: React.FC<Props> = ({
  clientInfo,
  modalOpen,
  requestModalOpen,
  toggleRequestModal,
  toggleDeleteClientModal,
  accountVerified,
  accountSubscribed,
  userRole,
  toggleChangeAdvisorModal,
}) => {
  const tabletView = useMediaQuery('(max-width:1024px)');
  return (
    <Box>
      {clientInfo.is_active && (
        <Box mt={3} mb={6}>
          <Button
            variant="contained"
            onClick={toggleRequestModal}
            shouldValidateAccount
            accountVerified={accountVerified && accountSubscribed}
            modalContent={(
              <H3Dark p={3} maxWidth={600}>
                {!accountVerified ? (
                  <Box>
                    <span>
                      Sorry but you are unable to request payment until your ChalicePay account is verified.
                      Please check your
                      {' '}
                      <Link href="/settings">Company Settings</Link>
                    </span>
                  </Box>
                ) : (
                  !accountSubscribed && (
                    <Box>
                      <span>
                        Sorry but you are unable to make action until your ChalicePay subscription is unpaid.
                        Please check your
                      </span>
                      {' '}
                      <Link href="/settings">Bank Details</Link>
                    </Box>
                  )
                )}
              </H3Dark>
            )}
          >
            <FormattedMessage {...messages.requestButton} />
          </Button>
        </Box>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} style={{ order: tabletView ? 2 : 1 }}>
          <InfoBox mb={3}>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
              <Box fontSize={40} lineHeight="48px">{clientInfo.name}</Box>
              <Box color="#3772FF" fontSize={12}>{!clientInfo.is_active ? 'Unregistered' : 'Registered'}</Box>
            </Box>
            <Box color="#6D6E71">{clientInfo.email}</Box>
            {userRole === ROLES.ADVISOR ? (
              <Box color="#6D6E71">
                {'Advisor: '}
                {clientInfo.advisor_name}
              </Box>
            ) : (
              <Box color="#6D6E71">
                {'Advisor: '}
                <Link href={`/users/advisors/${clientInfo.advisor}`}>{clientInfo.advisor_name}</Link>
              </Box>
            )}
            <Box mt={3} display="flex">
              {userRole !== ROLES.ADVISOR && (
                <Box mr={3}><Button onClick={toggleChangeAdvisorModal}>Change Advisor</Button></Box>
              )}
              <Button onClick={toggleDeleteClientModal}>Delete Client</Button>
            </Box>
          </InfoBox>
          <Grid container spacing={3}>
            <Grid item xs={6} md={12}>
              <InfoBox>
                <Box color="#3772FF" fontWeight={500}>
                  <FormattedMessage {...messages.totalRevenue} />
                </Box>
                <Box
                  fontSize={40}
                  display="flex"
                  flex={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <span>$</span>
                  {clientInfo.total_revenue / 100}
                </Box>
              </InfoBox>
            </Grid>
            <Grid item xs={6} md={12}>
              <InfoBox>
                <Box color="#3772FF" fontWeight={500}>
                  <FormattedMessage {...messages.addedOn} />
                </Box>
                <Box
                  fontSize={40}
                  display="flex"
                  flex={1}
                  justifyContent="center"
                  alignItems="center"
                  style={{ opacity: '0.87' }}
                >
                  {moment(clientInfo.added_on).format('MM/DD/YYYY') }
                </Box>
              </InfoBox>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8} style={{ order: tabletView ? 1 : 2 }}>
          {!clientInfo.is_active && (
            <InvitationLink />
          )}
          <Tables />
        </Grid>
      </Grid>
      <Modal open={modalOpen} handleClose={() => {}}>
        <Box p={3}>
          <FormattedMessage {...messages.invitationLinkSuccess} />
        </Box>
      </Modal>
      <RequestPaymentModal
        open={requestModalOpen}
        close={toggleRequestModal}
      />
    </Box>
  );
};

export default Content;
