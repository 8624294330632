import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import DoneIcon from '@material-ui/icons/Done';
import { Form, Field, Formik } from 'formik';

import Modal from '../../Modal';
import Api from '../../../api';
import H2 from '../../H2';
import H3 from '../../H3';
import Button from '../../Button';
import Navigation from '../../PaymentNavigation';
import FormikSelect from '../../Controls/FormikSelect';

interface Props {
  open: boolean,
  close: () => void,
  id: string,
  callback: () => void,
  advisorHasClients: boolean,
}

const DeleteModal: React.FC<Props> = ({
  open,
  close,
  id,
  callback,
  advisorHasClients,
}) => {
  const [doneStatus, setDoneStatus] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [advisorsList, setAdvisorList] = useState([]);

  useEffect(() => {
    Api.advisors.getAllAdvisors().then(({ data }) => setAdvisorList(data));
  }, []);

  useEffect(() => {
    if (open) {
      setDoneStatus(false);
    }
  }, [open]);

  useEffect(() => {
    if (advisorHasClients) {
      setActiveStep(0);
    } else {
      setActiveStep(1);
    }
  }, [id, advisorHasClients]);

  const handleDelete = () => {
    Api.advisors.deleteAdvisor(id)
      .then(() => {
        setDoneStatus(true);
        setTimeout(() => {
          close();
          callback();
        }, 1000);
      });
  };

  if (doneStatus) {
    return (
      <Modal
        open={open}
        handleClose={close}
      >
        <Box p={5} bgcolor="white" width={450} display="flex" flexDirection="column" alignItems="center">
          <Box color="#3772FF" fontSize={64}><DoneIcon fontSize="inherit" /></Box>
          <H2 mt={3}>
            Advisor Deleted
          </H2>
        </Box>
      </Modal>
    );
  }

  const reasignAdvisors = ({ advisor }: { advisor: string }) => {
    Api.advisors.reassignAdvisors({
      old_advisor: id,
      new_advisor: advisor,
    }).then(() => {
      setActiveStep(1);
    });
  };

  return (
    <Modal
      open={open}
      handleClose={close}
    >
      <Box p={3} width={500}>
        {advisorHasClients && (
          <Box mb={3}>
            <Navigation
              activeStep={activeStep}
              steps={['Reassign Clients', 'Delete Advisor']}
            />

          </Box>
        )}
        {activeStep === 0 && (
          <Box>
            <H3 mb={3}>
              The advisor you want to delete has clients. Please select another advisor who will manage those clients.
            </H3>
            <Formik
              initialValues={{ advisor: '' }}
              onSubmit={reasignAdvisors}
            >
              {({ dirty, isValid }) => (
                <Form>
                  <Field
                    component={FormikSelect}
                    name="advisor"
                    label="Advisor"
                    options={advisorsList
                      .filter((item: any) => item.id !== id && item.is_active)
                      .map((item: any) => ({ value: item.id, label: item.name }))}
                  />
                  <Box mt={3} display="flex" justifyContent="flex-end">
                    <Box>
                      <Button variant="text" onClick={close}>cancel</Button>
                    </Box>
                    <Button variant="text" type="submit" disabled={!dirty || !isValid}>
                      next
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        )}
        {activeStep === 1 && (
          <>
            <H2>Delete advisor?</H2>
            <H3 mt={2}>Are you sure? The advisor you want to delete will not be able to log in anymore.</H3>
            <Box display="flex" justifyContent="flex-end" mt={3}>
              <Box mr={2}><Button variant="text" onClick={close}>cancel</Button></Box>
              <Button variant="text" color="secondary" onClick={handleDelete}>delete</Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default DeleteModal;
