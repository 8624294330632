import { connect } from 'react-redux';

import Container from './Container';
import { fetchInitData, refetchCompletedTransfers, refetchPendingTransfers } from '../../redux/actions/Transfers';

const mapDispatchToProps = {
  fetchInitData,
  refetchCompletedTransfers,
  refetchPendingTransfers,
};

const mapStateToProps = (state: any) => ({
  pendingTransfers: state.transfers.pendingTransfers,
  pendingTotal: state.transfers.pendingTotal,
  pendingPagination: state.transfers.pendingPagination,
  pendingSort: state.transfers.pendingSort,
  completedTransfers: state.transfers.completedTransfers,
  completedTotal: state.transfers.completedTotal,
  completedPagination: state.transfers.completedPagination,
  completedSort: state.transfers.completedSort,
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
