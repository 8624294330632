import { GeneralState } from '../types/general';
import { AUTH_TYPES } from '../constants';

const initState: GeneralState = {
  intl: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
  },
  initLoading: true,
};

export default (state = initState, { type }: any) => {
  switch (type) {
    case 'finish_init_load':
      return {
        ...state,
        initLoading: false
      };
    case AUTH_TYPES.GET_USER_SUCCESS:
      return {
        ...state,
        initLoading: false,
      };
    case AUTH_TYPES.ERROR:
      return {
        ...state,
        initLoading: false,
      };
    default: {
      return state;
    }
  }
};
