import React from 'react';
import Box from '@material-ui/core/Box';

import {
  Tabs,
  Table,
} from '../../../../components';
import { withParentData } from '../../Context';
import {
  subscriptionColumns,
  singlePaymentsColumns,
  clientsColumns,
  EmptyMessage,
  tabs,
} from './data';

interface Props {
  activeTab: number,
  onTabChange: (val: number) => void,
  onPaymentRequest: () => void,
  singlePayments: {}[],
  singlePaymentsTotal: number,
  singlePaymentsPagination: { page: number },
  refetchSinglePaymentData: (data: any) => void,
  refetchSubscriptionData: (data: any) => void,
  refetchClients: (data: any) => void,
  singlePaymentsSort: { id: string, firstDirection: boolean },
  singlePaymentActions: any,
  subscriptionActions: any,
  clientActions: any,
  subscriptions: {}[],
  subscriptionsTotal: number,
  subscriptionsPagination: { page: number },
  subscriptionsSort: { id: string, firstDirection: boolean },
  clients: {}[],
  clientsTotal: number,
  clientsPagination: { page: number },
  clientsSort: { id: string, firstDirection: boolean },
}

const MyComponent: React.FC<Props> = ({
  activeTab,
  onTabChange,
  singlePayments,
  singlePaymentsTotal,
  singlePaymentsSort,
  singlePaymentsPagination,
  refetchSinglePaymentData,
  singlePaymentActions,
  subscriptionsTotal,
  subscriptionsSort,
  subscriptionsPagination,
  subscriptions,
  refetchSubscriptionData,
  subscriptionActions,
  clientsTotal,
  clientsSort,
  clientsPagination,
  clients,
  refetchClients,
  clientActions,
}) => (
  <Box>
    <Tabs
      tabs={tabs}
      onChange={onTabChange}
      value={activeTab}
    />
    <Box mt={3}>
      {activeTab === 0 && (
        <Table
          columns={subscriptionColumns}
          noItemsMessage={<EmptyMessage activeTab={activeTab} />}
          data={subscriptions}
          pagination={subscriptionsPagination}
          sort={subscriptionsSort}
          countTotal={subscriptionsTotal}
          actions={subscriptionActions}
          withPagination
          refetchData={refetchSubscriptionData}
        />
      )}
      {activeTab === 1 && (
        <Table
          pagination={singlePaymentsPagination}
          sort={singlePaymentsSort}
          countTotal={singlePaymentsTotal}
          refetchData={refetchSinglePaymentData}
          columns={singlePaymentsColumns}
          noItemsMessage={<EmptyMessage activeTab={activeTab} />}
          data={singlePayments}
          actions={singlePaymentActions}
          withPagination
        />
      )}
      {activeTab === 2 && (
        <Table
          pagination={clientsPagination}
          sort={clientsSort}
          countTotal={clientsTotal}
          refetchData={refetchClients}
          columns={clientsColumns}
          noItemsMessage={<EmptyMessage activeTab={activeTab} />}
          data={clients}
          actions={clientActions}
          withPagination
        />
      )}
    </Box>
  </Box>
);

export default withParentData(MyComponent);
