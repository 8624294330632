import {
  put, takeLatest, call, select
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { paymentModalError } from '../../actions/PaymentModal';
import { refetchSinglePayments } from '../../actions/ClientProfile';
import { refetchPayments } from '../../actions/SinglePayments';
import { PAYMENT_MODAL_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker({ payload, callback }: any): SagaIterator<void> {
  try {
    yield call(Api.payments.createSinglePayment, payload);

    const { router, clientProfile, singlePayments } = yield select();
    if (router.location.pathname === '/billing/single-payments') {
      yield put(refetchPayments({
        ...(singlePayments.status && { status: singlePayments.status }),
        sort: singlePayments.sort,
        pagination: singlePayments.pagination,
      }));
    } else {
      yield put(refetchSinglePayments({
        sort: clientProfile.singlePaymentsSort,
        pagination: clientProfile.singlePaymentsPagination,
      }));
    }

    callback();
  } catch (e) {
    yield put(paymentModalError(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(PAYMENT_MODAL_TYPES.CREATE_SINGLE_PAYMENT, worker);
}
