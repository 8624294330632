import React from 'react';
import Box from '@material-ui/core/Box';
import { FormattedMessage, IntlShape } from 'react-intl';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';

import messages from './messages';

import {
  Button,
  FormikInput,
  Link,
  H1,
} from '../../../../components';
import { withParentData } from '../../Context';
import { REGEXP } from '../../../../constants/validation';

const validationSchema = (intl: IntlShape) => Yup.object().shape({
  email: Yup.string()
    .matches(REGEXP.EMAIL, intl.formatMessage(messages.invalidEmailMessage))
    .required(intl.formatMessage(messages.emailRequiredMessage)),
});

interface Props {
  email: string,
  onSubmit: (values: {}) => void,
  intl: IntlShape,
  emailError?: boolean,
  loading?: boolean,
  login: () => void,
}

const EnterEmailForm: React.FC<Props> = ({
  email,
  onSubmit,
  intl,
  loading,
  emailError,
  login,
}) => (
  <Box height="100%" display="flex" justifyContent="center" alignItems="center">
    <Box width={{
      xs: 560,
      md: 400,
      lg: 600,
    }}
    >
      <H1 mb={3} mt={6}>
        <FormattedMessage {...messages.getStartedMessage} />
      </H1>
      <Formik
        initialValues={{ email }}
        onSubmit={onSubmit}
        validationSchema={validationSchema(intl)}
      >
        {({ dirty, isValid }) => (
          <Form>
            <Field
              component={FormikInput}
              label={intl.formatMessage(messages.emailLabel)}
              name="email"
              fullWidth
            />
            {emailError && (
              <Box color="#E20338" mt={2}>
                <FormattedMessage {...messages.emailAlreadyExistMessage} />
                <Link onClick={login}><FormattedMessage {...messages.emailAlreadyExistLink} /></Link>
              </Box>
            )}
            <Box mt={5} mb={10}>
              <Button disabled={!dirty || !isValid} loading={loading} type="submit" variant="contained">
                <FormattedMessage {...messages.sendButton} />
              </Button>
            </Box>
            <Box>
              <FormattedMessage {...messages.alreadyHaveAccountMessage} />
              <Box fontSize={16} fontWeight={500} display="inline">
                <Link onClick={login}>
                  <FormattedMessage {...messages.loginLink} />
                </Link>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  </Box>
);

export default withParentData(EnterEmailForm);
