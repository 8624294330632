import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { injectIntl, IntlShape } from 'react-intl';
import moment from 'moment';

import Modal from '../Modal';
import { Provider } from './Context';
import Content from './Content';

interface Props extends RouteComponentProps {
  open: boolean,
  close: () => void,
  getClients: () => void,
  clients: {}[],
  userId: string,
  createSinglePayment: (data: {}, callback: () => void) => void,
  createSubscription: (data: {}, callback: () => void) => void,
  intl: IntlShape,
}

interface State {
  activeStep: number,
  isSinglePayment: boolean,
  loading: boolean,
  selectedClient: number | string,
  selectedAmount: number | string,
  selectedPeriod: number | string,
  selectedDueDate: string,
  selectedFrequency: string,
  description: string,
}

class Container extends Component<Props, State> {
  state = {
    activeStep: 0,
    loading: false,
    isSinglePayment: false,
    selectedClient: '',
    selectedPeriod: '',
    selectedDueDate: '',
    description: 'Retainer Fee',
    selectedAmount: '',
    selectedFrequency: '',
  };

  componentDidMount() {
    const { getClients } = this.props;

    getClients();
  }

  setStep = (step: number) => this.setState({ activeStep: step });

  setPaymentType = (isSinglePayment: boolean) => this.setState((prevState) => ({
    isSinglePayment,
    description: isSinglePayment ? 'One-time payment for the financial advice service' : 'Retainer Fee',
    selectedDueDate: isSinglePayment ? prevState.selectedDueDate : '',
  }));

  handleSubmitRequestTypeForm = (values: any) => {
    this.setState({
      selectedClient: values.client,
      ...(values.dueDate && { selectedDueDate: values.dueDate }),
      ...(values.period && { selectedPeriod: values.period }),
      ...(values.frequency && { selectedFrequency: values.frequency }),
      activeStep: 1,
    });
  };

  handleSubmitDetailsForm = (values: any) => {
    this.setState({
      selectedAmount: values.amount,
      description: values.description,
      activeStep: 2,
    });
  };

  goToPrevStep = () => this.setState((prevState) => ({ activeStep: prevState.activeStep - 1 }));

  handleFinish = () => {
    const { userId, createSinglePayment, createSubscription } = this.props;
    const {
      selectedDueDate,
      selectedClient,
      selectedAmount,
      description,
      isSinglePayment,
      selectedPeriod,
      selectedFrequency,
    } = this.state;

    this.setState({ loading: true }, () => {
      if (isSinglePayment) {
        createSinglePayment({
          amount: Number(selectedAmount) * 100,
          due_date: moment(selectedDueDate),
          description,
          advisor: userId,
          client: selectedClient,
        }, () => this.setState({ activeStep: 3, loading: false }));
      } else {
        createSubscription({
          frequency: selectedFrequency,
          advisor: userId,
          client: selectedClient,
          type: 'advisor',
          amount: Number(selectedAmount) * 100,
          description,
          date_created: moment().format(),
          ...(Number(selectedPeriod) && { date_end: moment().add({ month: Number(selectedPeriod) }) }),
        }, () => this.setState({ activeStep: 3, loading: false }));
      }
    });
  };

  handleSuccess = () => {
    const { close } = this.props;

    close();
    this.setState({
      activeStep: 0,
      isSinglePayment: false,
      selectedClient: '',
      selectedDueDate: '',
      description: 'Retainer Fee',
      selectedAmount: '',
    });
  };

  render() {
    const {
      state: {
        activeStep,
        loading,
        isSinglePayment,
        selectedAmount,
        selectedClient,
        selectedDueDate,
        description,
        selectedPeriod,
      },
      props: {
        open,
        close,
        clients,
        match,
        intl,
      }
    } = this;

    const isVerified = true;

    return (
      <Modal
        open={open}
        handleClose={close}
      >
        <Provider
          value={{
            intl,
            activeStep,
            loading,
            isSinglePayment,
            setPaymentType: this.setPaymentType,
            submitRequestTypeForm: this.handleSubmitRequestTypeForm,
            submitDetailsForm: this.handleSubmitDetailsForm,
            goToPrevStep: this.goToPrevStep,
            clients,
            // @ts-ignore
            clientId: match.params.id,
            selectedAmount,
            selectedClient,
            selectedDueDate,
            selectedPeriod,
            description,
            onFinish: this.handleFinish,
            onSuccess: this.handleSuccess,
          }}
        >
          <Content
            activeStep={activeStep}
            close={close}
            isVerified={isVerified}
          />
        </Provider>
      </Modal>
    );
  }
}

export default injectIntl(withRouter(Container));
