import {SINGLE_PAYMENTS_TYPES, TRANSFERS_TYPES} from '../constants';

const initState = {
  loading: false,
  error: {},
  pendingTransfers: [],
  pendingTotal: 0,
  pendingPagination: {
    page: 1
  },
  pendingSort: {
    id: null,
    firstDirection: false,
  },
  completedTransfers: [],
  completedTotal: 0,
  completedPagination: {
    page: 1
  },
  completedSort: {
    id: null,
    firstDirection: false,
  },
};

export default (state = initState, { type, payload }: any) => {
  switch (type) {
    case TRANSFERS_TYPES.FETCH_INIT_DATA:
      return {
        ...state,
        loading: true,
      };
    case TRANSFERS_TYPES.FETCH_INIT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        completedTotal: payload.completed.count,
        completedTransfers: payload.completed.results,
        pendingTotal: payload.pending.count,
        pendingTransfers: payload.pending.results,
      };
    case TRANSFERS_TYPES.REFETCH_PENDING_TRANSFERS_SUCCESS:
      return {
        ...state,
        pendingSort: payload.sort,
        pendingPagination: payload.pagination,
        pendingTransfers: payload.data.results,
        pendingTotal: payload.data.count,
      };
    case TRANSFERS_TYPES.REFETCH_COMPLETED_TRANSFERS_SUCCESS:
      return {
        ...state,
        completedSort: payload.sort,
        completedPagination: payload.pagination,
        completedTransfers: payload.data.results,
        completedTotal: payload.data.count,
      };
    case TRANSFERS_TYPES.ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default: {
      return state;
    }
  }
};
