import React from 'react';
import Box from '@material-ui/core/Box';

import { FormattedMessage } from 'react-intl';
import { Tabs, H1 } from '../../components';
import { BankDetails, BillingSection, CompanyInfo } from './components';
import messages from './messages';
import { ROLES } from '../../constants';

interface Props {
  activeTab: number,
  onTabChange: (val: number) => void,
  userRole: string,
}

const tabs = [
  {
    label: 'Company'
  },
  {
    label: 'Bank details'
  },
  {
    label: 'Billing'
  },
];

const MyComponent: React.FC<Props> = ({ activeTab, onTabChange, userRole }) => (
  <Box mt={2}>
    <H1 mb={3}><FormattedMessage {...messages.pageTitle} /></H1>
    {userRole === ROLES.ACCOUNT_OWNER && (
      <Tabs
        value={activeTab}
        onChange={onTabChange}
        tabs={tabs}
      />
    )}
    {activeTab === 0 && <CompanyInfo />}
    {activeTab === 1 && userRole === ROLES.ACCOUNT_OWNER && <BankDetails />}
    {activeTab === 2 && userRole === ROLES.ACCOUNT_OWNER && <BillingSection />}
  </Box>
);

export default MyComponent;
