import {
  put, takeLatest, call, select
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { clientProfileError, getClientInfo } from '../../actions/ClientProfile';
import { CLIENT_PROFILE_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker({ payload }: any): SagaIterator<void> {
  try {
    const { clientProfile } = yield select();
    const { data } = yield call(Api.clients.resendVerificationEmail, clientProfile.clientInfo.email);
    yield put(getClientInfo(data.id));
    yield payload();
  } catch (e) {
    yield put(clientProfileError(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(CLIENT_PROFILE_TYPES.SEND_LINK, worker);
}
