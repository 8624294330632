import React from 'react';

interface Props {
  color?: string
}

const Icon: React.FC<Props> = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      fill={color || '#011638'}
      d="M12 16a4.672 4.672 0 004.667-4.667A4.672 4.672 0 0012 6.667a4.672 4.672 0 00-4.667 4.666A4.672 4.672 0 0012 16zm0-6.667c1.107 0 2 .894 2 2 0 1.107-.893 2-2 2s-2-.893-2-2c0-1.106.893-2 2-2zm0 9c-3.12 0-9.333 1.56-9.333 4.667v1c0 .733.6 1.333 1.333 1.333h16c.733 0 1.333-.6 1.333-1.333v-1c0-3.107-6.213-4.667-9.333-4.667zm-6.213 4.334C6.907 21.893 9.613 21 12 21s5.093.893 6.213 1.667H5.787zm15.6-4.254C22.933 19.533 24 21.027 24 23v2.333h4c.733 0 1.333-.6 1.333-1.333v-1c0-2.693-4.666-4.227-7.946-4.587zM20 16a4.672 4.672 0 004.667-4.667A4.672 4.672 0 0020 6.667c-.72 0-1.387.173-2 .466a7.254 7.254 0 011.333 4.2c0 1.56-.493 3.014-1.333 4.2.613.294 1.28.467 2 .467z"
    />
  </svg>
);

export default Icon;
