import { put, takeLatest, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { toastr } from 'react-redux-toastr';

import { onBoardingError, submitCompanyStepSuccess, setStep } from '../../actions/onBoaring';
import { SubmitCompanyStepAction } from '../../actions/onBoaring/types';
import { ONBOARDING_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker({ payload }: SubmitCompanyStepAction): SagaIterator<void> {
  try {
    const { data } = yield call(Api.userProfiles.getOrganizations);
    if (data.results.length === 0) {
      const res = yield call(Api.userProfiles.createCompany, payload);
      yield put(submitCompanyStepSuccess(res.data));
    } else {
      const res = yield call(Api.userProfiles.updateCompany, payload);
      yield put(submitCompanyStepSuccess(res.data));
    }

    yield put(setStep(1));
  } catch (e) {
    yield put(onBoardingError(e.message));
    toastr.error('Please check the entered data', e.response.data.detail.slice(28));
  }
}

export default function* watcher() {
  yield takeLatest(ONBOARDING_TYPES.SUBMIT_COMPANY_STEP, worker);
}
