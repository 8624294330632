import React from 'react';
import Box from '@material-ui/core/Box';
import DoneIcon from '@material-ui/icons/Done';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
import { Button, H1, H3 } from '../../../../components';
import { withParentData } from '../../Context';

interface Props {
  goToNextStep: () => void,
}

const VefiredMessage = ({ goToNextStep }: Props) => (
  <Box width={{
    xs: 530,
    md: 400,
    lg: 600,
  }}
  >
    <Box color="#3772FF" fontSize={64}><DoneIcon fontSize="inherit" /></Box>
    <H1 mb={3} mt={5}>
      <FormattedMessage {...messages.bankingDetailsStepConfirmation} />
    </H1>
    <H3>
      <FormattedMessage {...messages.bankingConfirmDetails1} />
    </H3>
    <Box mt="54px">
      <Button variant="contained" onClick={goToNextStep}><FormattedMessage {...messages.nextButton} /></Button>
    </Box>
  </Box>
);

export default withParentData(VefiredMessage);
