import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, Form, Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardNumberElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import messages from './messages';
import Button from '../Button';
import FormikInput from '../Controls/FormikInput';
import FormikSelect from '../Controls/FormikSelect';
import StripeElement from '../StripeElement';
import { STATES, stripePushKey } from '../../constants';
import validationSchema from './validationSchema';

interface Props {
  submitBilling: (values: {} | null) => void,
  goToBackStep: () => void,
  billingInfo: any,
  accountInfo: any,
  backButtonProps?: {},
  submitButtonProps?: {},
  backButtonLabel?: string,
  submitButtonLabel?: string,
  loading?: boolean,
}

const BillingForm: React.FC<Props> = ({
  submitBilling: submit,
  goToBackStep,
  billingInfo,
  accountInfo,
  backButtonProps = {},
  submitButtonProps = {},
  backButtonLabel,
  submitButtonLabel,
  loading,
}) => {
  const accountHolder = accountInfo.first_name ? `${accountInfo.first_name} ${accountInfo.last_name}` : '';
  const [completed, setCompleted] = useState({});
  const [isCardEdit, setCardEdit] = useState(true);
  const stripe = useStripe();
  const elements = useElements();
  const [touched, setTouched] = useState({});
  const intl = useIntl();

  useEffect(() => {
    if (Object.keys(billingInfo).length !== 0) {
      setCardEdit(false);
    }
  }, [billingInfo]);

  const handleFieldTouched = (name: string) => {
    setTouched(prev => ({
      ...prev,
      [name]: true,
    }));
  };

  const handleStripeFieldChange = (name: string, isCompleted: boolean) => {
    setTouched(prev => ({
      ...prev,
      [name]: true,
    }));
    setCompleted(prev => ({
      ...prev,
      [name]: isCompleted,
    }));
    if (Object.keys(completed).length === 3 && Object.values(completed).includes(false)) {
      setTouched({});
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        streetAddress: billingInfo.billing_details ? billingInfo.billing_details.address.line1 : '',
        city: billingInfo.billing_details ? billingInfo.billing_details.address.city : '',
        state: billingInfo.billing_details ? billingInfo.billing_details.address.state : '',
        zipCode: billingInfo.billing_details ? billingInfo.billing_details.address.postal_code : '',
        cardHolderName: billingInfo.billing_details ? billingInfo.billing_details.name : accountHolder,
      }}
      validationSchema={validationSchema(intl)}
      onSubmit={async (values) => {
        console.log('handle_subm');
        if (!stripe || !elements) return;
        const card = elements.getElement(CardNumberElement);
        if (!card) return;
        const { token } = await stripe.createToken(card, {
          address_line1: values.streetAddress,
          address_city: values.city,
          address_zip: `${values.zipCode}`,
          address_state: values.state,
          name: values.cardHolderName,
        });
        submit({
          ...values,
          source: token ? token.id : '',
        });
      }}
    >
      {({ dirty, isValid }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Field
                component={FormikInput}
                label={intl.formatMessage(messages.streetAddressField)}
                name="streetAddress"
                onChange={() => {
                  setTouched({ card: true, exp: true, cvc: true });
                  setCardEdit(true);
                }}
                fullWidth
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Field
                component={FormikInput}
                label={intl.formatMessage(messages.cityField)}
                onChange={() => {
                  setTouched({ card: true, exp: true, cvc: true });
                  setCardEdit(true);
                }}
                name="city"
                fullWidth
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Field
                name="state"
                component={FormikSelect}
                onChange={() => {
                  setTouched({ card: true, exp: true, cvc: true });
                  setCardEdit(true);
                }}
                label={intl.formatMessage(messages.stateField)}
                options={STATES}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Field
                component={FormikInput}
                onChange={() => {
                  setTouched({ card: true, exp: true, cvc: true });
                  setCardEdit(true);
                }}
                label={intl.formatMessage(messages.zipCodeField)}
                name="zipCode"
                type="number"
                fullWidth
              />
            </Grid>
          </Grid>
          <Box
            fontWeight={500}
            color="#011638"
            mt="40px"
            mb="11px"
          >
            <FormattedMessage {...messages.creditCardDetails} />
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Field
                component={FormikInput}
                onChange={() => setTouched({ card: true, exp: true, cvc: true })}
                label={intl.formatMessage(messages.CardholderNameField)}
                name="cardHolderName"
                fullWidth
              />
            </Grid>
            {!isCardEdit && (
            <Grid item xs={12} container alignItems="center">
              <Box mr={3}>
                <span><FormattedMessage {...messages.last4Ending} /></span>
                <span>{billingInfo.card ? billingInfo.card.last4 : ''}</span>
              </Box>
              <Button onClick={() => setCardEdit(true)}>
                <FormattedMessage {...messages.useDifferentCard} />
              </Button>
            </Grid>
            )}
            {isCardEdit && (
            <>
              <Grid item xs={12}>
                <StripeElement
                  onTouched={handleFieldTouched}
                  onChange={handleStripeFieldChange}
                  type="card"
                  error={intl.formatMessage(messages.cardNumberMaxError)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <StripeElement
                  onTouched={handleFieldTouched}
                  onChange={handleStripeFieldChange}
                  type="exp"
                  error={intl.formatMessage(messages.expiryDateError)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <StripeElement
                  onTouched={handleFieldTouched}
                  onChange={handleStripeFieldChange}
                  type="cvc"
                  error={intl.formatMessage(messages.cvcFieldError)}
                />
              </Grid>
            </>
            )}
          </Grid>
          <Box mt={3} textAlign="end" display="flex" flexDirection="row" justifyContent="flex-end">
            <Box mr={3}>
              <Button onClick={goToBackStep} {...backButtonProps}>
                {backButtonLabel || <FormattedMessage {...messages.backButton} />}
              </Button>
            </Box>
            <Button
              type={dirty || Object.keys(touched).length !== 0 ? 'submit' : 'button'}
              onClick={() => {
                // console.log('onCLick');
                if (!dirty) {
                  submit(null);
                }
              }}
              variant="contained"
              {...submitButtonProps}
              loading={loading}
              disabled={
                  Object.keys(billingInfo).length === 0 ? (
                    !dirty
                    || !isValid
                    || Object.keys(completed).length !== 3
                    || Object.values(completed).includes(false)
                  ) : (
                    !isValid
                    || Object.keys(touched).length !== 0
                  )
                }
            >
              {submitButtonLabel || <FormattedMessage {...messages.submitButton} />}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

const stripePromise = loadStripe(stripePushKey() || '');

const StripeWrapper: React.FC<Props> = (props) => (
  <Elements stripe={stripePromise}>
    <BillingForm {...props} />
  </Elements>
);

export default StripeWrapper;
