import {
  put, takeLatest, call,
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { toastr } from 'react-redux-toastr';

import { onBoardingError, setStep, submitCompanyStepSuccess } from '../../actions/onBoaring';
import { ONBOARDING_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker({ payload }: any): SagaIterator<void> {
  try {
    const { data } = yield call(Api.userProfiles.getBankingAccounts);
    if (data.length === 0) {
      const res = yield call(Api.userProfiles.createBankingAccount, payload);
      yield put(submitCompanyStepSuccess(res.data));
      yield put(setStep(2.5));
    } else {
      const res = yield call(Api.userProfiles.updateBankingAccount, { values: payload, id: data[0].id });
      yield put(submitCompanyStepSuccess(res.data));
      yield put(setStep(3));
    }
  } catch (e) {
    yield put(onBoardingError(e.message));
    toastr.error('Please check the entered data', e.response.data.detail.slice(28));
  }
}

export default function* watcher() {
  yield takeLatest(ONBOARDING_TYPES.SUBMIT_BANKING_STEP, worker);
}
