import React from 'react';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import { FormattedMessage } from 'react-intl';

import {
  Button,
  H2,
  H3,
  Table,
  BillingForm,
  Modal,
  H3Dark,
} from '../../../../components';
import { cardTableColumn, transferTableColumn } from './data';
import { withParentData } from '../../Context';
import messages from '../../messages';
import Form from '../AddTransferModal';

interface Props {
  bankingList: {}[],
  billingList: {}[],
  onCreditCardOpen: () => void,
  onAddCard: (values: {} | null) => void,
  onTransferAccountAdd: () => void,
  addCardModalOpen: boolean,
  cardAdded: boolean,
  creditTableActions: any,
  transferActions: any,
}

const BankingTab: React.FC<Props> = ({
  billingList,
  bankingList,
  onCreditCardOpen,
  onAddCard,
  onTransferAccountAdd,
  addCardModalOpen,
  cardAdded,
  creditTableActions,
  transferActions,
}) => (
  <Box>
    <H2 mt={5}><FormattedMessage {...messages.transferAccounts} /></H2>
    <H3 mt={1}><FormattedMessage {...messages.bankInfo} /></H3>
    <Box mt={1} display="flex" justifyContent="flex-end">
      <Button variant="text" onClick={onTransferAccountAdd}>
        <AddIcon />
        <span><FormattedMessage {...messages.addTransferAccount} /></span>
      </Button>
    </Box>
    <Box mt={2}>
      <Table
        data={bankingList}
        columns={transferTableColumn}
        actions={transferActions}
        errorRowMessage="Please make make another transfer bank account default to accept transfers from your clients."
        getRowError={({ original }: any) => original.status === 'errored'}
      />
    </Box>
    <H2 mt={5}><FormattedMessage {...messages.cards} /></H2>
    <H3 mt={1}><FormattedMessage {...messages.cardsInfo} /></H3>
    <Box mt={1} display="flex" justifyContent="flex-end">
      <Button variant="text" onClick={onCreditCardOpen}>
        <AddIcon />
        <span><FormattedMessage {...messages.addCreditCard} /></span>
      </Button>
    </Box>
    <Box mt={2}>
      <Table
        data={billingList}
        columns={cardTableColumn}
        actions={creditTableActions}
        errorRowMessage="This credit card has expired. Please add another credit card for billing."
        getRowError={({ original }: any) => original.card.status === 'expired'}
      />
    </Box>
    <Form />
    <Modal
      open={addCardModalOpen}
      handleClose={onCreditCardOpen}
    >
      <Box p={3} maxHeight={630} maxWidth={700} overflow="auto">
        {cardAdded ? (
          <Box p={5} bgcolor="white" width={450} display="flex" flexDirection="column" alignItems="center">
            <Box color="#3772FF" fontSize={64}><DoneIcon fontSize="inherit" /></Box>
            <H2 mt={3}>Billing credit card added</H2>
          </Box>
        ) : (
          <>
            <H2 mb={3}>Add Billing Credit card</H2>
            <H3Dark fontWeight={500} mb={1}><FormattedMessage {...messages.billingAddress} /></H3Dark>
            <BillingForm
              submitBilling={onAddCard}
              goToBackStep={onCreditCardOpen}
              billingInfo={{}}
              accountInfo={{}}
              backButtonLabel="cancel"
              submitButtonLabel="add"
              submitButtonProps={{ variant: 'text' }}
              backButtonProps={{ variant: 'text' }}
            />
          </>
        )}
      </Box>
    </Modal>
  </Box>
);

export default withParentData(BankingTab);
