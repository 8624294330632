import Link from '@material-ui/core/Link';
import withStyles from '@material-ui/core/styles/withStyles';

export default withStyles({
  root: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
  }
})(Link);
