import { ADVISOR_PROFILE_TYPES } from '../constants';

const initState = {
  info: {},
  loading: false,
  error: {},
  singlePayments: [],
  singlePaymentsTotal: 0,
  singlePaymentsPagination: {
    page: 1
  },
  singlePaymentsSort: {
    id: null,
    firstDirection: false,
  },
  subscriptions: [],
  subscriptionsTotal: 0,
  subscriptionsPagination: {
    page: 1
  },
  subscriptionsSort: {
    id: null,
    firstDirection: false,
  },
  clients: [],
  clientsTotal: 0,
  clientsPagination: {
    page: 1
  },
  clientsSort: {
    id: null,
    firstDirection: false,
  },
};

export default (state = initState, { type, payload }: any) => {
  switch (type) {
    case ADVISOR_PROFILE_TYPES.GET_INFO:
      return {
        ...state,
        loading: true,
      };
    case ADVISOR_PROFILE_TYPES.GET_SINGLE_PAYMENTS_SUCCESS:
      return {
        ...state,
        singlePayments: payload.results,
        singlePaymentsTotal: payload.count,
      };
    case ADVISOR_PROFILE_TYPES.GET_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptions: payload.results,
        subscriptionsTotal: payload.count,
      };
    case ADVISOR_PROFILE_TYPES.REFETCH_SINGLE_PAYMENTS_SUCCESS:
      return {
        ...state,
        singlePaymentsSort: payload.sort,
        singlePaymentsPagination: payload.pagination,
        singlePayments: payload.data.results,
        singlePaymentsTotal: payload.data.count,
      };
    case ADVISOR_PROFILE_TYPES.GET_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: payload.results,
        clientsTotal: payload.count,
      };
    case ADVISOR_PROFILE_TYPES.REFETCH_CLIENTS_SUCCESS:
      return {
        ...state,
        clientsSort: payload.sort,
        clientsPagination: payload.pagination,
        clients: payload.data.results,
        clientsTotal: payload.data.count,
      };
    case ADVISOR_PROFILE_TYPES.REFETCH_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptionsSort: payload.sort,
        subscriptionsPagination: payload.pagination,
        subscriptions: payload.data.results,
        subscriptionsTotal: payload.data.count,
      };
    case ADVISOR_PROFILE_TYPES.GET_INFO_SUCCESS:
      return {
        ...state,
        info: payload,
        loading: false,
      };
    case ADVISOR_PROFILE_TYPES.ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default: {
      return state;
    }
  }
};
