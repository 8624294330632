import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';

import './styles.scss';

interface Props {
  disabled?: boolean,
  error?: boolean,
  label?: string,
  helperText?: string,
  type?: string,
  fullWidth?: boolean,
  value: string,
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  handleBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  endAdornment?: React.ReactNode,
  startAdornment?: React.ReactNode,
}
const height = 56;

const CustomTextField: React.FC<Props> = ({
  helperText,
  error,
  value,
  endAdornment,
  startAdornment,
  disabled,
  onFocus,
  handleBlur,
  ...rest
}) => {
  const [labelOffset, setLabelOffset] = useState(0);
  const [focused, setFocused] = useState(false);
  useEffect(() => {
    if (!value) {
      setLabelOffset(0);
    }
  }, [value]);

  return (
    <TextField
      {...rest}
      disabled={disabled}
      helperText={helperText}
      value={value}
      error={error}
      variant="filled"
      autoComplete="off"
      style={{ height, marginBottom: helperText ? '20px' : 0 }}
      InputLabelProps={{
        style: {
          fontSize: '16px',
          paddingLeft: '2px',
          height,
          ...(value && !focused && !error && { color: '#011638' }),
          ...(!value && !focused && !error && { color: '#6D6E71' }),
          top: `${labelOffset}px`,
        },
      }}
      InputProps={{
        ...(endAdornment && { endAdornment }),
        ...(startAdornment && { startAdornment })
      }}
      /* eslint-disable-next-line react/jsx-no-duplicate-props */
      inputProps={{
        onFocus: (e: React.FocusEvent<HTMLInputElement>) => {
          if (onFocus) {
            onFocus(e);
          }
          setLabelOffset(-5);
          setFocused(true);
        },
        onBlur: (e) => {
          if (handleBlur) {
            handleBlur(e);
          }
          setLabelOffset(value ? -5 : 0);
          setFocused(false);
        },
        style: {
          color: disabled ? '#BCBEC0' : '#6D6E71',
          fontSize: '16px',
          height,
          padding: '0 14px',
          position: 'relative',
          top: !value ? 0 : 5,
        },
      }}
    />
  );
};

export default CustomTextField;
