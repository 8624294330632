// @ts-ignore
import visa from 'payment-icons/min/flat/visa.svg';
// @ts-ignore
import unionpay from 'payment-icons/min/flat/unionpay.svg';
// @ts-ignore
import jcb from 'payment-icons/min/flat/jcb.svg';
// @ts-ignore
import diners from 'payment-icons/min/flat/diners.svg';
// @ts-ignore
import discover from 'payment-icons/min/flat/discover.svg';
// @ts-ignore
import amex from 'payment-icons/min/flat/amex.svg';
// @ts-ignore
import mastercard from 'payment-icons/min/flat/mastercard.svg';

export default (logo: string) => {
  switch (logo) {
    case 'visa':
      return visa;
    case 'unionpay':
      return unionpay;
    case 'jcb':
      return jcb;
    case 'diners':
      return diners;
    case 'discover':
      return discover;
    case 'amex':
      return amex;
    case 'mastercard':
      return mastercard;
    default:
      return '';
  }
};
