import { CLIENT_PROFILE_TYPES } from '../../constants';

export const fetchInitData = (id: string) => ({
  type: CLIENT_PROFILE_TYPES.FETCH_INIT_DATA,
  payload: id
});

export const fetchInitDataSuccess = () => ({
  type: CLIENT_PROFILE_TYPES.FETCH_INIT_DATA_SUCCESS,
});

export const getClientInfo = (id: string) => ({
  type: CLIENT_PROFILE_TYPES.GET_CLIENT_INFO,
  payload: id
});

export const getClientInfoSuccess = (payload: any) => ({
  type: CLIENT_PROFILE_TYPES.GET_CLIENT_INFO_SUCCESS,
  payload,
});

export const getClientSinglePayments = (id: string) => ({
  type: CLIENT_PROFILE_TYPES.GET_SINGLE_PAYMENTS,
  payload: id
});

export const getClientSinglePaymentsSuccess = (payload: any) => ({
  type: CLIENT_PROFILE_TYPES.GET_SINGLE_PAYMENTS_SUCCESS,
  payload,
});

export const refetchSinglePayments = (payload: any) => ({
  type: CLIENT_PROFILE_TYPES.REFETCH_SINGLE_PAYMENTS,
  payload
});

export const refetchSinglePaymentsSuccess = (payload: any) => ({
  type: CLIENT_PROFILE_TYPES.REFETCH_SINGLE_PAYMENTS_SUCCESS,
  payload
});

export const getClientSubscription = (id: string) => ({
  type: CLIENT_PROFILE_TYPES.GET_SUBSCRIPTIONS,
  payload: id
});

export const getClientSubscriptionSuccess = (payload: any) => ({
  type: CLIENT_PROFILE_TYPES.GET_SUBSCRIPTIONS_SUCCESS,
  payload,
});

export const refetchSubscription = (payload: any) => ({
  type: CLIENT_PROFILE_TYPES.REFETCH_SUBSCRIPTIONS,
  payload
});

export const refetchSubscriptionSuccess = (payload: any) => ({
  type: CLIENT_PROFILE_TYPES.REFETCH_SUBSCRIPTIONS_SUCCESS,
  payload
});

export const sendLink = (callback: any) => ({
  type: CLIENT_PROFILE_TYPES.SEND_LINK,
  payload: callback
});

export const clientProfileError = (error: any) => ({
  type: CLIENT_PROFILE_TYPES.ERROR,
  payload: error
});
