import axios from './axiosInstance';
import { fetchWithFilters } from './helpers';

export default {
  createAdvisor: (data: {}) => axios.post('/users/advisors/', data),
  getAdvisors: () => axios.get('/users/advisors/'),
  resendVerificationEmail: (email: string) => axios.post('/users/clients/re-send-verification-email/', { email }),
  refetchAdvisors: (data: any) => fetchWithFilters('/users/advisors/', data),
  getAdvisorById: (id: string) => axios.get(`/users/advisors/${id}/`),
  getAdvisorClients: (id: string) => axios.get('/users/clients/', { params: { advisor: id } }),
  updateAdvisor: (id: string, data: {}) => axios.put(`/users/advisors/${id}/`, data),
  getAllAdvisors: () => axios.get('/users/advisors/get-all/'),
  deleteAdvisor: (id: string) => axios.delete(`users/advisors/${id}`),
  reassignAdvisors: (data: {}) => axios.post('/users/advisors/reassign-clients/', data)
};
