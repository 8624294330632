import Tab from '@material-ui/core/Tab';
import withStyles from '@material-ui/core/styles/withStyles';

export default withStyles(theme => ({
  root: {
    height: 48,
    boxSizing: 'border-box',
    '&:hover': {
      background: theme.palette.warning.contrastText
    },
    '&:focused': {
      background: theme.palette.primary.light
    },
  },
  wrapper: {
    textTransform: 'capitalize',
    fontSize: 16
  }
}))(Tab);
