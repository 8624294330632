import {
  put, takeLatest, call, select,
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { settingsError, refetchBillingHistorySuccess } from '../../actions/Settings';
import { SETTINGS_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker({ payload }: any): SagaIterator<void> {
  try {
    const { auth } = yield select();
    const { data } = yield call(Api.payments.refetchBillingHistory, {
      data: payload,
      id: auth.userData.id,
    });
    yield put(refetchBillingHistorySuccess({
      ...payload,
      data,
    }));
  } catch (e) {
    yield put(settingsError(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(SETTINGS_TYPES.REFETCH_BILLING_HISTORY, worker);
}
