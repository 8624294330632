import { put, takeLatest, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { advisorsError, refetchAdvisorsSuccess } from '../../actions/Advisors';
import { ADVISORS_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker({ payload }: any): SagaIterator<void> {
  try {
    const { data } = yield call(Api.advisors.refetchAdvisors, payload);
    yield put(refetchAdvisorsSuccess({
      ...payload,
      data,
    }));
  } catch (e) {
    yield put(advisorsError(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(ADVISORS_TYPES.REFETCH_ADVISORS_REQUEST, worker);
}
