import React from 'react';
import moment from 'moment';

import Box from '@material-ui/core/Box';
import {
  EmptyTableMessageContainer,
  Button,
  H2,
  Link,
  H3Dark,
  CurrencyProvider,
} from '../../../../components';

export const subscriptionColumns = [
  {
    Header: 'Status',
    accessor: (row: any) => <span style={{ textTransform: 'uppercase' }}>{row.status.replace(/_/g, ' ')}</span>,
    id: 'status',
  },
  {
    Header: 'Frequency',
    accessor: 'frequency',
  },
  {
    Header: 'Advisor',
    accessor: 'advisor_name',
  },
  {
    Header: 'Amount',
    accessor: (row: any) => <CurrencyProvider value={row.amount / 100} />,
    id: 'amount',
  },
  {
    Header: 'Start Date',
    accessor: (row: any) => (row.date_started ? moment(row.date_started).format('MM/DD/YYYY') : '-'),
    id: 'date_started',
  },
];

export const singlePaymentsColumns = [
  {
    Header: 'Status',
    accessor: (row: any) => <span style={{ textTransform: 'uppercase' }}>{row.status.replace(/_/g, ' ')}</span>,
    id: 'status',
  },
  {
    Header: 'Invoice ID',
    accessor: (row: any) => (
      <Link href={`/billing/single-payments/invoice/${row.id}`}>
        {`#${row.number}`}
      </Link>
    ),
    id: 'number',
  },
  {
    Header: 'Advisor',
    accessor: 'advisor_name',
  },
  {
    Header: 'Amount',
    accessor: (row: any) => (`$${row.amount / 100}`),
    id: 'amount',
  },
  {
    Header: 'Due Date',
    accessor: (row: any) => moment(row.due_date).format('MM/DD/YYYY'),
    id: 'due_date',
  },
];

export const tabs = [
  {
    label: 'One time payments'
  },
  {
    label: 'Subscriptions'
  },
];

interface EmptyMessageProps {
  isActive: boolean,
  onClick: () => void,
  accountVerified: boolean,
  accountSubscribed: boolean,
  isSubscription: boolean,
}

export const EmptyMessage = ({
  isActive,
  onClick,
  accountSubscribed,
  accountVerified,
  isSubscription,
}: EmptyMessageProps) => (
  <EmptyTableMessageContainer>
    {isSubscription ? <H2>No Subscriptions, yet</H2> : <H2>No One Time Payments, yet</H2>}
    {isActive && (
      <Button
        onClick={onClick}
        variant="text"
        shouldValidateAccount
        accountVerified={accountVerified && accountSubscribed}
        modalContent={(
          <H3Dark p={3} maxWidth={600}>
            {!accountVerified ? (
              <Box>
                <span>
                  Sorry but you are unable to request payment until your ChalicePay account is verified.
                  Please check your
                  {' '}
                  <Link href="/settings">Company Settings</Link>
                </span>
              </Box>
            ) : (
              !accountSubscribed && (
                <Box>
                  <span>
                    Sorry but you are unable to make action until your ChalicePay subscription is unpaid.
                    Please check your
                  </span>
                  {' '}
                  <Link href="/settings">Bank Details</Link>
                </Box>
              )
            )}
          </H3Dark>
        )}
      >
        request payment
      </Button>
    )}
  </EmptyTableMessageContainer>
);
