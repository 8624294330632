import React from 'react';
import { MoonLoader } from 'react-spinners';
import Box from '@material-ui/core/Box';
import { FormattedMessage } from 'react-intl';

import { GetStartedOnBoardingContainer } from '../../components';
import { TermsSection, Form } from './components';
import messages from './messages';

interface Props {
  termsOpen: boolean,
  loading: boolean,
  linkExpired: boolean,
}

const Content: React.FC<Props> = ({
  termsOpen,
  loading,
  linkExpired,
}) => (
  <GetStartedOnBoardingContainer>
    <Box height="100%" display="flex" justifyContent="center" alignItems="center">
      <MoonLoader
        loading={!linkExpired && loading}
        size={100}
      />
      {(!loading && !linkExpired) && (termsOpen ? <TermsSection /> : <Form />)}
      {linkExpired && <Box><FormattedMessage {...messages.expiredLinkMessage} /></Box>}
    </Box>
  </GetStartedOnBoardingContainer>
);

export default Content;
