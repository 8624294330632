import { put, takeLatest, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { walletError, getCards } from '../../actions/Wallet';
import { WALLET_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker({ payload, callback }: any): SagaIterator<void> {
  try {
    yield call(Api.userProfiles.createBillingAccount, payload);
    yield put(getCards());
    callback(true);
  } catch (e) {
    yield put(walletError(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(WALLET_TYPES.CREATE_CARD, worker);
}
