import {
  put,
  takeLatest,
  call,
  all,
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { fetchInitDataSuccess, transfersError } from '../../actions/Transfers';
import { TRANSFERS_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker(): SagaIterator<void> {
  try {
    const requestObject = {
      completed: call(Api.payments.getCompletedTransfers),
      pending: call(Api.payments.getPendingTransfers),
    };

    const {
      completed,
      pending
    } = yield all(requestObject);

    yield put(fetchInitDataSuccess({
      pending: pending.data,
      completed: completed.data,
    }));
  } catch (e) {
    yield put(transfersError(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(TRANSFERS_TYPES.FETCH_INIT_DATA, worker);
}
