import states from './states';

export const ROLES = {
  CLIENT: 'client',
  ADVISOR: 'advisor',
  ACCOUNT_OWNER: 'account_owner',
  MANAGING_ADVISOR: 'managing_advisor',
};

const devPortalUrl = 'dev.insart.chalicevip.com';
const testPortalUrl = 'test.insart.chalicevip.com';
const uitPortalUrl = 'uat.insart.chalicevip.com';
const stagePortalUrl = 'staging.app.chalicepay.com';
const localPortalUrl = 'localhost';
const devApiUrl = 'http://dev.insart.chalicevip.com/api';
const testApiUrl = 'http://test.insart.chalicevip.com/api';
const stageApiUrl = 'https://staging.app.chalicepay.com/stage/api';
const uitApiUrl = 'http://uat.insart.chalicevip.com/api';
const localApiUrl = '/api';

const stripeDevPushKey = process.env.REACT_APP_DEV_PUSH_KEY;
const stripeTestPushKey = process.env.REACT_APP_TEST_PUSH_KEY;
const stripeUitPushKey = process.env.REACT_APP_UIT_PUSH_KEY;
const stripeStagingPushKey = process.env.REACT_APP_STAGING_PUSH_KEY;


export const stripePushKey = () => {
  const { origin } = window.location;
  switch (true) {
    case origin.includes(localPortalUrl):
      return stripeDevPushKey;
    case origin.includes(devPortalUrl):
      return stripeDevPushKey;
    case origin.includes(testPortalUrl):
      return stripeTestPushKey;
    case origin.includes(uitPortalUrl):
      return stripeUitPushKey;
    case origin.includes(stagePortalUrl):
      return stripeStagingPushKey;
    default:
      return stripeTestPushKey;
  }
};

const getApiUrl = () => {
  const { origin } = window.location;
  switch (true) {
    case origin.includes(localPortalUrl):
      return localApiUrl;
    case origin.includes(devPortalUrl):
      return devApiUrl;
    case origin.includes(testPortalUrl):
      return testApiUrl;
    case origin.includes(uitPortalUrl):
      return uitApiUrl;
    case origin.includes(stagePortalUrl):
      return stageApiUrl;
    default:
      return testApiUrl;
  }
};

export const apiUrl = getApiUrl();

export const STATES = states.map(item => ({ label: item.name, value: item.abbreviation }));

export const PAID_INVOICE_STATUS = 1;
export const UNPAID_INVOICE_STATUS = 2;
export const VOID_INVOICE_STATUS = 3;
export const CANCELED_INVOICE_STATUS = 4;
export const FAILED_INVOICE_STATUS = 5;
export const PAST_DUE_INVOICE_STATUS = 6;
export const REFUNDED_INVOICE_STATUS = 7;

export const invoiceStatuses = {
  [PAID_INVOICE_STATUS]: 'Paid',
  [UNPAID_INVOICE_STATUS]: 'Unpaid',
  [CANCELED_INVOICE_STATUS]: 'Canceled',
  [VOID_INVOICE_STATUS]: 'Void',
  [FAILED_INVOICE_STATUS]: 'Failed',
  [PAST_DUE_INVOICE_STATUS]: 'Past Due',
  [REFUNDED_INVOICE_STATUS]: 'Refunded in full',
  8: 'Refunded in part',
};

export const occupationOptions = [
  { label: 'None', value: '0' },
  { label: 'Independent Registered Investment Advisor', value: 1 },
  { label: 'Sole Proprietorship', value: 2 },
  { label: 'Certified Financial Planner', value: 3 },
];

export const chaliceFee: number = Number(process.env.REACT_APP_CHALICE_FEE);
