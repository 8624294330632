import {
  put,
  takeLatest,
  call,
  all
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { settingsError, getBankingDetailsSuccess } from '../../actions/Settings';
import { SETTINGS_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker(): SagaIterator<void> {
  try {
    const requestObject = {
      billing: call(Api.settings.getCreditCards),
      banking: call(Api.settings.getBanks),
    };
    const responseObject = yield all(requestObject);
    yield put(getBankingDetailsSuccess(responseObject));
  } catch (e) {
    yield put(settingsError(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(SETTINGS_TYPES.GET_BANK_DETAILS, worker);
}
