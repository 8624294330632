import { put, takeLatest, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { getSubscriptionsSuccess, subscriptionsError } from '../../actions/Subscriptions';
import { SUBSCRIPTIONS_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker(): SagaIterator<void> {
  try {
    const { data } = yield call(Api.subscriptions.getSubscriptions);
    yield put(getSubscriptionsSuccess(data));
  } catch (e) {
    yield put(subscriptionsError(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(SUBSCRIPTIONS_TYPES.GET_SUBSCRIPTIONS, worker);
}
