import React from 'react';
import { FieldInputProps, FormikProps } from 'formik';
import { Moment } from 'moment';

import DatePicker from '../DatePicker';

interface Props {
  field: FieldInputProps<any>,
  form: FormikProps<any>,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void,
  helperText?: string,
  type?: string,
}

const FormikDatePicker: React.FC<Props> = ({
  field,
  onChange,
  form,
  helperText,
  onFocus,
  ...rest
}) => {
  const { name } = field;
  const onChangeHandle = (date: Moment) => {
    form.setFieldTouched(name, true);
    form.setFieldValue(name, date.format('YYYY-MM-DD'));
  };
  const isError = Boolean(form.touched[name] && form.errors[name]);
  const helper = helperText || (isError ? `${form.errors[name]}` : undefined);

  return (
    <DatePicker
      helperText={helper}
      error={isError}
      {...field}
      {...rest}
      onBlur={() => form.setFieldTouched(name, true)}
      onChange={onChangeHandle}
    />
  );
};

export default FormikDatePicker;
