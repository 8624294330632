/* eslint-disable implicit-arrow-linebreak */
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { injectIntl, IntlShape } from 'react-intl';
import Box from '@material-ui/core/Box';
import { MoonLoader } from 'react-spinners';

import Content from './Content';
import { Provider } from './Context';
import { CompanyFormData } from '../../redux/actions/onBoaring/types';
import { ROLES } from '../../constants';

interface Props extends RouteComponentProps {
  intl: IntlShape,
  logout: () => void,
  getOrganization: () => void,
  getAccountInfo: () => void,
  getBankingAccount: () => void,
  getBillingInfo: () => void,
  getUser: () => void,
  companyData: {
    id: string
  },
  bankingAccountData: {},
  accountInfo: any,
  billingInfo: any,
  setStep: (step: number) => void,
  step: number,
  userId: string,
  userRole: string,
  userEmail: string,
  loading: boolean,
  submitCompanyStep: (payload: CompanyFormData) => void,
  submitAccountInfoStep: (data: any) => void,
  submitBankingStep: (data: any) => void,
  submitBillingStep: (data: any) => void,
  finishOnBoarding: (data: 5) => void,
}
interface State {
  confirmPopupOpen: boolean,
  values: CompanyFormData | {},
}

class Container extends Component<Props, State> {
  state = {
    confirmPopupOpen: false,
    values: {},
  };

  componentDidMount() {
    const {
      getUser,
      step,
      userRole,
      history
    } = this.props;
    getUser();

    if (userRole === ROLES.CLIENT) {
      history.push('/transactions/subscriptions');
    }

    if (step >= 5 || userRole !== ROLES.ACCOUNT_OWNER) {
      history.push('/users/clients');
    }
  }

  handleSetCompanySubmit = (values: any) => {
    const { submitCompanyStep, companyData } = this.props;
    if (Object.keys(companyData).length === 0) {
      this.setState({
        values: {
          name: values.companyName,
          contacts: {
            phone_number: values.phoneNumber,
            web_site: values.companySite,
          },
          address: {
            street: values.companyStreetAddress,
            city: values.city,
            zip_code: `${values.zipCode}`,
            state: values.state,
          },
          employee_id: values.employerIN,
          type: 1,
        },
        confirmPopupOpen: true,
      });
    } else {
      submitCompanyStep({
        id: companyData.id,
        name: values.companyName,
        contacts: {
          // @ts-ignore
          id: companyData.contacts.id,
          phone_number: values.phoneNumber,
          web_site: values.companySite,
        },
        address: {
          // @ts-ignore
          id: companyData.address.id,
          street: values.companyStreetAddress,
          city: values.city,
          zip_code: `${values.zipCode}`,
          state: values.state,
        },
        employee_id: values.employerIN,
        type: 1,
      });
    }
  };

  onPopupSubmit = () => {
    const { submitCompanyStep } = this.props;
    const { values } = this.state;
    // @ts-ignore
    this.setState({ confirmPopupOpen: false }, () => submitCompanyStep(values));
  };

  handleSetUserInfoSubmit = (values: any) => {
    if (!values) {
      return this.setNextStep(2);
    }

    const {
      submitAccountInfoStep, userId, accountInfo, companyData
    } = this.props;
    const requestData = {
      user: userId,
      organization: companyData.id,
      first_name: values.firstName,
      last_name: values.lastName,
      date_of_birth: values.dateOfBirth,
      occupation: Number(values.professionalOccupation),
      address: {
        street: values.companyStreetAddress,
        city: values.city,
        zip_code: `${values.zipCode}`,
        state: values.state,
      },
      source: values.source,
      contacts: {
        phone_number: values.phoneNumber,
        web_site: values.businessWebsite,
      }
    };

    if (accountInfo && accountInfo.address && accountInfo.address.id) {
      // @ts-ignore
      requestData.address.id = accountInfo.address.id;
    }
    if (accountInfo && accountInfo.contacts && accountInfo.contacts.id) {
      // @ts-ignore
      requestData.contacts.id = accountInfo.contacts.id;
    }
    return submitAccountInfoStep(requestData);
  };

  togglePopup = () => this.setState((prevState) => ({ confirmPopupOpen: !prevState.confirmPopupOpen }));

  setNextStep = (step?: number) => {
    const { setStep, step: activeStep } = this.props;
    if (step) {
      setStep(step);
    } else {
      if (activeStep === 2) return setStep(2.5);
      if (activeStep === 2.5) return setStep(3);
      return setStep(activeStep + 1);
    }
    return null;
  };

  submitBanking = (values: any) => {
    const { submitBankingStep, companyData } = this.props;
    if (!values) {
      return this.setNextStep(3);
    }
    submitBankingStep({
      organisation_id: companyData.id,
      account_holder_name: values.accountHolder,
      account_holder_type: 0,
      source: values.source
    });
    return null;
  };

  submitBilling = (values: any | null) => {
    const { submitBillingStep, companyData } = this.props;
    if (!values) {
      return this.setNextStep(4);
    }

    submitBillingStep({
      content_type_id: companyData.id,
      content_type: 'organisation',
      address: {
        street: values.streetAddress,
        city: values.city,
        zip_code: `${values.zipCode}`,
        state: values.state,
      },
      source: values.source,
      cardholder_name: values.cardHolderName,
    });
    return null;
  };

  handleGoBack = () => {
    const { setStep, step } = this.props;
    setStep(step === 2.5 ? 2 : step - 1);
  };

  logout = () => {
    const { logout } = this.props;
    logout();
  };

  fetchInitCompanyData = () => {
    const { getOrganization } = this.props;
    getOrganization();
  };

  fetchInitAccountInfoData = () => {
    const { getAccountInfo } = this.props;
    getAccountInfo();
  };

  fetchInitBankingData = () => {
    const { getAccountInfo, getBankingAccount, getOrganization } = this.props;
    getAccountInfo();
    getOrganization();
    getBankingAccount();
  };

  fetchInitBillingData = () => {
    const { getOrganization, getBillingInfo, getAccountInfo } = this.props;
    getOrganization();
    getBillingInfo();
    getAccountInfo();
  };

  fetchInitReviewData = () => {
    const {
      getOrganization,
      getBillingInfo,
      getAccountInfo,
      getBankingAccount,
    } = this.props;
    getOrganization();
    getBillingInfo();
    getAccountInfo();
    getBankingAccount();
  };

  handleConfirmOnboarding = () => {
    const { finishOnBoarding } = this.props;
    finishOnBoarding(5);
  };

  render() {
    const { confirmPopupOpen } = this.state;
    const {
      intl,
      step,
      companyData,
      accountInfo,
      loading,
      bankingAccountData,
      billingInfo,
      userId,
      userEmail,
    } = this.props;

    if (!userId || step > 4) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh" width="100%">
          <MoonLoader
            size={100}
            color="#011638"
          />
        </Box>
      );
    }

    return (
      <Provider
        value={{
          activeStep: step,
          intl,
          loading,
          companyData,
          accountInfo,
          bankingAccountData,
          billingInfo,
          userEmail,
          goToNextStep: () => this.setNextStep(),
          goToBackStep: this.handleGoBack,
          onStepSubmit: () => {},
          submitCompany: this.handleSetCompanySubmit,
          submitAccountInfo: this.handleSetUserInfoSubmit,
          submitBanking: this.submitBanking,
          submitBilling: this.submitBilling,
          fetchInitCompanyData: this.fetchInitCompanyData,
          fetchInitAccountInfoData: this.fetchInitAccountInfoData,
          fetchInitBankingData: this.fetchInitBankingData,
          fetchInitBillingData: this.fetchInitBillingData,
          fetchInitReviewData: this.fetchInitReviewData,
          onConfirmOnboarding: this.handleConfirmOnboarding,
          logout: this.logout,
          confirmPopupOpen,
          togglePopup: this.togglePopup,
          onPopupSubmit: this.onPopupSubmit,
        }}
      >
        <Content
          logout={this.logout}
          activeStep={step}
        />
      </Provider>
    );
  }
}

export default injectIntl(Container);
