import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage, IntlShape } from 'react-intl';

import { withParentData } from '../../Context';
import H2 from '../../../H2';
import FormikInput from '../../../Controls/FormikInput';
import FormikCurrencyInput from '../../../Controls/FormikCurrencyInput';
import Button from '../../../Button';
import messages from '../../messages';

interface Props {
  goToPrevStep: () => void,
  selectedAmount: string | null,
  description: string,
  submitDetailsForm: (values: {}) => void,
  isSinglePayment: boolean,
  intl: IntlShape,
}

const validationSchema = Yup.object().shape({
  amount: Yup.number()
    .required('Please fill in all required fields'),
  description: Yup.string()
    .required('Please fill in all required fields')
    .max(70, 'The max number of characters is 70'),
});

const PaymentDetailsForm: React.FC<Props> = ({
  goToPrevStep,
  selectedAmount,
  description,
  submitDetailsForm: submit,
  intl,
  isSinglePayment,
}) => (
  <Formik
    initialValues={{
      amount: selectedAmount,
      description,
    }}
    validationSchema={validationSchema}
    onSubmit={submit}
    enableReinitialize
  >
    {({ isValid, dirty, setFieldValue }) => (
      <Form>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <Box
              height={80}
              border="1px solid #3772FF"
              borderRadius={4}
              display="flex"
              alignItems="center"
              px={3}
              color="#3772FF"
              // @ts-ignore
              css={{ textTransform: 'uppercase' }}
            >
              <Radio color="primary" checked size="medium" />
              <span><FormattedMessage {...messages.creditCard} /></span>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {isSinglePayment
              ? (<H2><FormattedMessage {...messages.singlePayment} /></H2>)
              : (<H2>Subscription</H2>)}
          </Grid>
          <Grid item xs={12}>
            <Field
              component={FormikCurrencyInput}
              name="amount"
              label={intl.formatMessage(messages.paymentAmountField)}
              decimalScale={2}
              allowNegative={false}
              withoutFormUpdate
              onChange={(event: any) => {
                setFieldValue('amount', Number(event.target.value.replace(/,/g, '').replace('$', '')) || '');
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              component={FormikInput}
              name="description"
              label={intl.formatMessage(messages.descriptionField)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} container justify="flex-end">
            <Box mr={3}>
              <Button onClick={goToPrevStep}>
                <FormattedMessage {...messages.previousButton} />
              </Button>
            </Box>
            <Button
              type="submit"
              variant="contained"
              disabled={selectedAmount && description ? !isValid : (!dirty || !isValid)}
            >
              <FormattedMessage {...messages.continueButton} />
            </Button>
          </Grid>
        </Grid>
      </Form>
    )}
  </Formik>
);

export default withParentData(PaymentDetailsForm);
