import React from 'react';
import Box from '@material-ui/core/Box';
import { FormattedMessage } from 'react-intl';

import {
  Button, EmptyTableMessageContainer, H3Dark, Link
} from '../../../../components';
import { ROLES } from '../../../../constants';
import messages from '../../messages';

interface Props {
  onRequestPayment: () => void,
  userRole: string,
  accountSubscribed: boolean,
  accountVerified: boolean,
}

const Message: React.FC<Props> = ({
  onRequestPayment,
  userRole,
  accountVerified,
  accountSubscribed
}) => (
  <EmptyTableMessageContainer>
    <Box
      color="#011638"
      fontSize={24}
      fontWeight={500}
      lineHeight="32px"
      mb={3}
    >
      <FormattedMessage {...messages.noPayments} />
    </Box>
    {userRole !== ROLES.CLIENT && (
      <Button
        onClick={onRequestPayment}
        variant="text"
        shouldValidateAccount
        accountVerified={accountVerified && accountSubscribed}
        modalContent={(
          <H3Dark p={3} maxWidth={600}>
            {!accountVerified ? (
              <Box>
                <span>
                  Sorry but you are unable to request payment until your ChalicePay account is verified.
                  Please check your
                  {' '}
                  <Link href="/settings">Company Settings</Link>
                </span>
              </Box>
            ) : (
              !accountSubscribed && (
              <Box>
                <span>
                  Sorry but you are unable to make action until your ChalicePay subscription is unpaid.
                  Please check your
                </span>
                {' '}
                <Link href="/settings">Bank Details</Link>
              </Box>
              )
            )}
          </H3Dark>
        )}
      >
        <FormattedMessage {...messages.requestButton} />
      </Button>
    )}
  </EmptyTableMessageContainer>
);

export default Message;
