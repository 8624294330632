import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';

const Message: React.FC = ({ children }) => (
  <TableRow>
    <TableCell colSpan={100}>
      <Box
        p={3}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        {children}
      </Box>
    </TableCell>
  </TableRow>
);

export default Message;
