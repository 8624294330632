/* eslint-disable @typescript-eslint/quotes  */
import { defineMessages } from 'react-intl';

export default defineMessages({
  requestButton: {
    id: 'buttons.requestButton',
    defaultMessage: 'Request payment',
    description: 'Button label'
  },
  singlePayments: {
    id: 'singlePayments',
    defaultMessage: 'One Time payments',
    description: 'Label'
  },
  noPayments: {
    id: 'singlePayments.noPayments',
    defaultMessage: 'No One Time Payments, yet',
    description: 'Label'
  },
});
