import { defineMessages } from 'react-intl';

export default defineMessages({
  billingStep: {
    id: 'onBoarding.billingStep',
    defaultMessage: 'ChalicePay Billing',
    description: 'Navigation'
  },
  billingAddress: {
    id: 'onBoarding.billingStep.billingAddress',
    defaultMessage: 'Billing Address',
    description: 'Text'
  },
});
