import { put, takeLatest, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { getSinglePaymentsSuccess, singlePaymentsError } from '../../actions/SinglePayments';
import { SINGLE_PAYMENTS_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker(): SagaIterator<void> {
  try {
    const { data } = yield call(Api.payments.getPayments);
    yield put(getSinglePaymentsSuccess(data));
  } catch (e) {
    yield put(singlePaymentsError(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(SINGLE_PAYMENTS_TYPES.GET_PAYMENTS, worker);
}
