import { SINGLE_PAYMENTS_TYPES } from '../constants';

const initState = {
  loading: false,
  error: {},
  payments: [],
  total: 0,
  status: '',
  query: '',
  pagination: {
    page: 1
  },
  sort: {
    id: null,
    firstDirection: false,
  },
};

export default (state = initState, { type, payload }: any) => {
  switch (type) {
    case SINGLE_PAYMENTS_TYPES.GET_PAYMENTS:
      return { ...state, loading: true };
    case SINGLE_PAYMENTS_TYPES.GET_PAYMENTS_SUCCESS:
      return {
        ...state,
        payments: payload.results,
        total: payload.count,
      };
    case SINGLE_PAYMENTS_TYPES.REFETCH_PAYMENTS_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { data, ...rest } = payload;
      return {
        ...state,
        ...rest,
        payments: data.results,
        total: data.count,
      };
    case SINGLE_PAYMENTS_TYPES.ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default: {
      return state;
    }
  }
};
