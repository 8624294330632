import React from 'react';
import Box from '@material-ui/core/Box';
import DoneIcon from '@material-ui/icons/Done';
import { FormattedMessage } from 'react-intl';

import H1 from '../../../H1';
import H3 from '../../../H3';
import Button from '../../../Button';
import { withParentData } from '../../Context';
import messages from '../../messages';

interface Props {
  onSuccess: () => void,
}

const SuccessScreen: React.FC<Props> = ({ onSuccess }) => (
  <Box display="flex" alignItems="center" flexDirection="column">
    <DoneIcon style={{ color: '#3772FF', fontSize: '50px' }} />
    <H1 mt={8}><FormattedMessage {...messages.successTitle} /></H1>
    <H3 maxWidth={450} mt={3}><FormattedMessage {...messages.successMessage} /></H3>
    <H3 maxWidth={450} mt={3} mb="40px">
      We charge 3.5% + $0.30 per transaction each time you accept a credit or debit card payment.
    </H3>
    <Box>
      <Button onClick={onSuccess} variant="contained">
        <FormattedMessage {...messages.okButton} />
      </Button>
    </Box>
  </Box>
);

export default withParentData(SuccessScreen);
