import { connect } from 'react-redux';

import {
  getInfo,
  sendLink,
  getSinglePayments,
  refetchSinglePayments,
  getSubscription,
  refetchSubscription,
  getClients,
  refetchClients,
} from '../../redux/actions/AdvisorProfile';
import Container from './Container';

const mapStateToProps = (state: any) => ({
  advisorInfo: state.advisorProfile.info,
  singlePayments: state.advisorProfile.singlePayments,
  singlePaymentsTotal: state.advisorProfile.singlePaymentsTotal,
  singlePaymentsPagination: state.advisorProfile.singlePaymentsPagination,
  singlePaymentsSort: state.advisorProfile.singlePaymentsSort,
  subscriptions: state.advisorProfile.subscriptions,
  subscriptionsTotal: state.advisorProfile.subscriptionsTotal,
  subscriptionsPagination: state.advisorProfile.subscriptionsPagination,
  subscriptionsSort: state.advisorProfile.subscriptionsSort,
  clients: state.advisorProfile.clients,
  clientsTotal: state.advisorProfile.clientsTotal,
  clientsPagination: state.advisorProfile.clientsPagination,
  clientsSort: state.advisorProfile.clientsSort,
});

const mapDispatchToProps = {
  getInfo,
  sendLink,
  getSinglePayments,
  refetchSinglePayments,
  getSubscription,
  refetchSubscription,
  getClients,
  refetchClients,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
