import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import withStyles from '@material-ui/core/styles/withStyles';

// @ts-ignore
import logo from '../../metadata/img/logo.png';

const Container = withStyles({ root: { height: '100vh' } })(Grid);

interface Props {
  renderLeft: () => React.ReactNode,
  renderRight: () => React.ReactNode,
}

const OnboardingContainer: React.FC<Props> = ({ renderLeft, renderRight }) => (
  <Container container>
    <Grid item xs={12} md={6}>
      <Box
        bgcolor="#011638"
        height="100%"
        position="relative"
        pt="75px"
      >
        <img
          src={logo}
          alt="logo"
          style={{
            width: '265px',
            position: 'absolute',
            transform: 'translateX(-50%)',
            left: '50%',
            top: '24px'
          }}
        />
        {renderLeft()}
      </Box>
    </Grid>
    <Grid item xs={12} md={6}>{renderRight()}</Grid>
  </Container>
);
export default OnboardingContainer;
