import { WALLET_TYPES } from '../../constants';

export const createCard = (payload: any, callback: (state: boolean) => void) => ({
  type: WALLET_TYPES.CREATE_CARD,
  payload,
  callback
});

export const createCardSuccess = (payload: any) => ({
  type: WALLET_TYPES.CREATE_CARD_SUCCESS,
  payload
});

export const getCards = () => ({
  type: WALLET_TYPES.GET_CARDS,
});

export const getCardsSuccess = (payload: any) => ({
  type: WALLET_TYPES.GET_CARDS_SUCCESS,
  payload,
});


export const walletError = (error: any) => ({
  type: WALLET_TYPES.ERROR,
  payload: error
});
