import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

import messages from './messages';

export default (intl: IntlShape) => Yup.object().shape({
  accountHolder: Yup.string()
    .required(intl.formatMessage(messages.requiredMassage))
    .max(71, intl.formatMessage(messages.accountHolderMaxError)),
  routingNumber: Yup.string()
    .matches(/^[0-9]{9}$/, intl.formatMessage(messages.routingNumberError))
    .required(intl.formatMessage(messages.requiredMassage)),
  accountNumber: Yup.string()
    .matches(/^[0-9]{6,17}$/, intl.formatMessage(messages.accountNumberError))
    .required(intl.formatMessage(messages.requiredMassage)),
  accountNumberConfirmation: Yup.string()
    .oneOf([Yup.ref('accountNumber'), undefined], intl.formatMessage(messages.accountNumberConfirmationError))
    .required(intl.formatMessage(messages.requiredMassage)),
});
