import axios from './axiosInstance';

export default {
  getOrganizations: () => axios.get('/user-profiles/organisation/'),
  getOrganizationById: (id: string) => axios.get(`/user-profiles/organisation/${id}`),
  createCompany: (values: {}) => axios.post('/user-profiles/organisation/', values),
  updateCompany: ({ id, ...values }: any) => axios.put(`/user-profiles/organisation/${id}/`, values),
  getBankingAccounts: () => axios.get('/user-profiles/banking-detail/'),
  createBankingAccount: (values: {}) => axios.post('/user-profiles/banking-detail/', values),
  updateBankingAccount: ({ values, id }: { values: {}, id: string }) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    axios.put(`/user-profiles/banking-detail/${id}/`, values),
  getAccountInfo: (id: any) => axios.get(`/user-profiles/profile/${id}/`),
  updateAccountInfo: ({ values, id }: any) => axios.put(`/user-profiles/profile/${id}/`, values),
  updateAccountInfoField: ({ values, id }: any) => axios.patch(`/user-profiles/profile/${id}/`, values),
  getBilling: () => axios.get('/user-profiles/billing-detail/'),
  createBillingAccount: (values: any) => axios.post('/user-profiles/billing-detail/', values),
  updateUserDocument: (data: {}, id: string) => axios.post(
    `/user-profiles/profile/${id}/upload-identity-document/`, data
  ),
  updateBillingAccount: ({ values, id }: any) => axios.put(`/user-profiles/billing-detail/${id}/`, values),
  makeCreditCardDefault: (id: string) => axios.post(`/user-profiles/billing-detail/${id}/make-default/`),
  makeTransferAccountDefault: (id: string) => axios.post(`/user-profiles/banking-detail/${id}/make-default/`),
  deleteCreditCard: (id: string) => axios.delete(`/user-profiles/billing-detail/${id}/`),
  deleteBankAccount: (id: string) => axios.delete(`/user-profiles/banking-detail/${id}/`),
};
