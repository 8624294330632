/* eslint-disable @typescript-eslint/quotes  */
import { defineMessages } from 'react-intl';

export default defineMessages({
  backButton: {
    id: 'buttons.backButton',
    defaultMessage: 'Back',
    description: 'Button label'
  },
  singlePayment: {
    id: 'singlePayment',
    defaultMessage: 'One Time payment',
    description: 'Label'
  },
  amount: {
    id: 'invoiceDetails.amount',
    defaultMessage: 'Amount',
    description: 'Label'
  },
  status: {
    id: 'invoiceDetails.status',
    defaultMessage: 'Status',
    description: 'Label'
  },
  invoiceId: {
    id: 'invoiceDetails.invoiceId',
    defaultMessage: 'Invoice ID',
    description: 'Label'
  },
  client: {
    id: 'invoiceDetails.client',
    defaultMessage: 'Client',
    description: 'Label'
  },
  paid: {
    id: 'invoiceDetails.paid',
    defaultMessage: 'Paid',
    description: 'Label'
  },
  canceled: {
    id: 'invoiceDetails.canceled',
    defaultMessage: 'Canceled',
    description: 'Label'
  },
  refund: {
    id: 'invoiceDetails.refund',
    defaultMessage: 'Refund',
    description: 'Label'
  },
  advisor: {
    id: 'invoiceDetails.advisor',
    defaultMessage: 'Advisor',
    description: 'Label'
  },
  due: {
    id: 'invoiceDetails.due',
    defaultMessage: 'Due',
    description: 'Label'
  },
  billed: {
    id: 'invoiceDetails.billed',
    defaultMessage: 'Billed',
    description: 'Label'
  },
  description: {
    id: 'invoiceDetails.description',
    defaultMessage: 'Description',
    description: 'Label'
  },
  refundModalTitle: {
    id: 'invoiceDetails.refundModalTitle',
    defaultMessage: 'Refund payment to Client',
    description: 'Label'
  },
  refundAmount: {
    id: 'invoiceDetails.refundAmount',
    defaultMessage: 'Refund amount',
    description: 'Label'
  },
  refundAmountRequiredError: {
    id: 'invoiceDetails.refundAmountRequiredError',
    defaultMessage: 'You must enter the refund amount first',
    description: 'Error'
  },
  refundAmountMaxError: {
    id: 'invoiceDetails.refundAmountMaxError',
    defaultMessage: 'The refund exceeds the invoice amount',
    description: 'Error'
  },
  optional: {
    id: 'optional',
    description: 'Label',
    defaultMessage: 'Optional',
  },
  cancelButton: {
    id: 'buttons.cancel',
    defaultMessage: 'Cancel',
    description: 'Button label'
  },
});
