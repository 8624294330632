import { connect } from 'react-redux';

import Container from './Container';
import { getCompanyDetails } from '../../redux/actions/Settings';
import {
  createClient,
  getClients,
  refetchClients,
  getAllAdvisors,
} from '../../redux/actions/Clients';

const mapStateToProps = (state: any) => ({
  step: state.auth.userData.onboarding_status,
  userData: state.auth.userData,
  clientsList: state.clients.clientsList,
  advisorsList: state.clients.advisorsList,
  error: state.clients.error,
  sort: state.clients.sort,
  pagination: state.clients.pagination,
  clientsTotal: state.clients.clientsTotal,
  userRole: state.auth.userRole,
  accountSubscribed: state.settings.companyInfo.subscribed,
});

const mapDispatchToProps = {
  getCompanyDetails,
  createClient,
  getClients,
  refetchClients,
  getAllAdvisors,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
