import { OnBoardingState } from '../types/onBoarding';
import { ONBOARDING_TYPES, AUTH_TYPES } from '../constants';
import { AuthActionTypes } from '../actions/Auth/types';

const initState: OnBoardingState = {
  getStartedEmail: '',
  error: null,
  emailVerificationMessageSend: false,
  loading: false,
  verifiedEmailData: {},
  linkExpired: false,
  companyData: {},
  accountInfo: {
    last_name: '',
    first_name: '',
  },
  bankingAccountData: {},
  billingInfo: {},
  step: 0,
};

export default (
  state = initState,
  { type, payload }: AuthActionTypes
) => {
  switch (type) {
    case ONBOARDING_TYPES.SING_IN_WITH_EMAIL_REQUEST:
      return {
        ...state,
        getStartedEmail: payload,
        loading: true,
      };
    case ONBOARDING_TYPES.SING_IN_WITH_EMAIL_SUCCESS: {
      return {
        ...state,
        emailVerificationMessageSend: true,
        loading: false,
      };
    }
    case ONBOARDING_TYPES.VERIFY_EMAIL_SUCCESS:
      if (payload) {
        return {
          ...state,
          loading: false,
          verifiedEmailData: payload,
        };
      }
      return {
        ...state,
        loading: false,
        linkExpired: true,
      };
    case ONBOARDING_TYPES.SET_UP_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ONBOARDING_TYPES.SUBMIT_ACCOUNT_INFO_STEP:
      return {
        ...state,
        loading: true,
      };
    case ONBOARDING_TYPES.SUBMIT_BILLING_STEP:
      return {
        ...state,
        loading: true,
      };
    case ONBOARDING_TYPES.SUBMIT_BILLING_STEP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ONBOARDING_TYPES.SUBMIT_ACCOUNT_INFO_STEP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ONBOARDING_TYPES.SUBMIT_BANKING_STEP:
      return {
        ...state,
        loading: true,
      };
    case ONBOARDING_TYPES.SUBMIT_BANKING_STEP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ONBOARDING_TYPES.SUBMIT_COMPANY_STEP:
      return {
        ...state,
        loading: true,
      };
    case ONBOARDING_TYPES.SUBMIT_COMPANY_STEP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ONBOARDING_TYPES.SET_UP_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ONBOARDING_TYPES.SET_STEP:
      return {
        ...state,
        step: payload
      };
    case AUTH_TYPES.GET_USER_SUCCESS:
      return {
        ...state,
        // @ts-ignore
        step: payload.onboarding_status,
      };
    case ONBOARDING_TYPES.GET_COMPANY_SUCCESS:
      return {
        ...state,
        companyData: payload,
      };
    case ONBOARDING_TYPES.GET_BANKING_ACCOUNT_SUCCESS:
      return {
        ...state,
        bankingAccountData: payload
      };
    case ONBOARDING_TYPES.GET_ACCOUNT_INFO_SUCCESS:
      return {
        ...state,
        accountInfo: payload,
      };
    case ONBOARDING_TYPES.GET_BILLING_INFO_SUCCESS:
      return {
        ...state,
        billingInfo: payload,
      };
    case ONBOARDING_TYPES.ONBOARDING_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default: {
      return state;
    }
  }
};
