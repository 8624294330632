import React from 'react';
import { FieldInputProps, FormikProps } from 'formik';

import CurrencyInput from '../CurrencyInput';

interface Props {
  field: FieldInputProps<any>,
  form: FormikProps<any>,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void,
  helperText?: string,
  type?: string,
  showErrorFirst?: boolean,
  withoutFormUpdate?: boolean,
}

const FormikInput: React.FC<Props> = ({
  field,
  onChange,
  form,
  helperText,
  onFocus,
  showErrorFirst,
  withoutFormUpdate,
  ...rest
}) => {
  const onChangeHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
    if (!withoutFormUpdate) {
      field.onChange(event);
    }
  };

  const { name } = field;
  const isError = Boolean(form.touched[name] && form.errors[name]);
  const helper = showErrorFirst
    ? (isError ? `${form.errors[name]}` : undefined) || helperText
    : helperText || (isError ? `${form.errors[name]}` : undefined);

  return (
    <CurrencyInput
      error={isError}
      helperText={helper}
      {...field}
      {...rest}
      onChange={onChangeHandle}
    />
  );
};

export default FormikInput;
