import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { FormattedMessage } from 'react-intl';
import { Tables, InvitationLink } from './components';
import {
  Modal,
  H3Dark,
  Button,
  H3,
} from '../../components';
import messages from './messages';
import { getAdvisorConvertedRole } from '../../helpers';

const InfoBox = withStyles({
  root: {
    minHeight: 192,
    padding: 24,
    boxShadow: '4px 10px 20px rgba(55, 114, 255, 0.1)',
    borderRadius: 4,
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
  },
})(Box);

interface Props {
  advisorInfo: any,
  modalOpen: boolean,
  onEditProfile: () => void,
  onDeleteProfile: () => void,
}

const Content: React.FC<Props> = ({
  advisorInfo,
  modalOpen,
  onDeleteProfile,
  onEditProfile,
}) => {
  const tabletView = useMediaQuery('(max-width:1024px)');
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} style={{ order: tabletView ? 2 : 1 }}>
          <InfoBox mb={3}>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
              <Box fontSize={40} lineHeight="48px">{advisorInfo.name}</Box>
              <Box color="#3772FF" fontSize={12}>{!advisorInfo.is_active ? 'Unregistered' : 'Registered'}</Box>
            </Box>
            <Box color="#6D6E71">{advisorInfo.email}</Box>
            <H3Dark mt={1}>{advisorInfo.role && getAdvisorConvertedRole(advisorInfo.role[0])}</H3Dark>
            <Box display="flex" justifyContent="space-between" mt={3}>
              <Button variant="text" onClick={onEditProfile}>EDIT PROFILE</Button>
              <Button variant="text" onClick={onDeleteProfile}>DELETE ADVISOR</Button>
            </Box>
          </InfoBox>
          <Grid container spacing={3}>
            <Grid item xs={6} md={12}>
              <InfoBox>
                <Box color="#3772FF" fontWeight={500}>
                  <FormattedMessage {...messages.totalRevenue} />
                </Box>
                <Box
                  fontSize={40}
                  display="flex"
                  flex={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <span>$</span>
                  {advisorInfo.total_revenue / 100}
                </Box>
              </InfoBox>
            </Grid>
            <Grid item xs={6} md={12}>
              <InfoBox>
                <Box display="flex" justifyContent="space-between">
                  <Box color="#3772FF" fontWeight={500}>
                    Clients
                  </Box>
                  <Box display="flex">
                    <H3 mr={1}>{`${advisorInfo.registered_clients_count} registered`}</H3>
                    <H3>{`${advisorInfo.unregistered_clients_count} unregistered`}</H3>
                  </Box>
                </Box>
                <Box
                  fontSize={40}
                  display="flex"
                  flex={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  {advisorInfo.registered_clients_count + advisorInfo.unregistered_clients_count}
                </Box>
              </InfoBox>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8} style={{ order: tabletView ? 1 : 2 }}>
          {!advisorInfo.is_active && (
            <InvitationLink />
          )}
          <Tables />
        </Grid>
      </Grid>
      <Modal open={modalOpen} handleClose={() => {}}>
        <Box p={3}>
          <FormattedMessage {...messages.invitationLinkSuccess} />
        </Box>
      </Modal>
    </Box>
  );
};

export default Content;
