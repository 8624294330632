import React from 'react';
import Box from '@material-ui/core/Box';

import { Tabs } from '../../../../components';
import { withParentData } from '../../Context';

export const tabs = [
  {
    label: 'All subscriptions',
  },
  {
    label: 'Active',
  },
  {
    label: 'Inactive',
  },
  {
    label: 'Completed',
  },
  {
    label: 'Canceled',
  },
  {
    label: 'Past due',
  },
  {
    label: 'Unpaid',
  },
  {
    label: 'Incomplete',
  },
];

interface Props {
  activeTab: number,
  onTabChange: (val: number) => void,
}

const MyComponent: React.FC<Props> = ({
  activeTab,
  onTabChange
}) => (
  <Box mt={3}>
    <Tabs
      value={activeTab}
      onChange={onTabChange}
      tabs={tabs}
      variant="scrollable"
      scrollButtons="auto"
    />
  </Box>
);

export default withParentData(MyComponent);
