import { defineMessages } from 'react-intl';

export default defineMessages({
  getStartedMessage: {
    id: 'onBoarding.getStartedMessage',
    defaultMessage: 'Let’s get started',
    description: 'Get started message'
  },
  emailLabel: {
    id: 'fields.email',
    defaultMessage: 'Email',
    description: 'Email TextField Label'
  },
  sendButton: {
    id: 'onBoarding.getStartedButton',
    defaultMessage: 'get started',
    description: 'Button label'
  },
  alreadyHaveAccountMessage: {
    id: 'onBoarding.alreadyHaveAccountMessage',
    defaultMessage: 'Already have an account? ',
    description: 'Already have an account message'
  },
  loginLink: {
    id: 'onBoarding.loginLink',
    defaultMessage: 'Log In',
    description: 'Login link'
  },
  emailAlreadyExistMessage: {
    id: 'onBoarding.emailAlreadyExistMessage',
    defaultMessage: 'Account with this email already exists. If this is you, please ',
    description: 'Already have an account message'
  },
  emailAlreadyExistLink: {
    id: 'onBoarding.emailAlreadyExistLink',
    defaultMessage: 'log in here ',
    description: 'Already have an account message'
  },
  emailRequiredMessage: {
    id: 'fields.email.requiredError',
    defaultMessage: 'Please provide an email address.',
    description: 'Validation error message',
  },
  invalidEmailMessage: {
    id: 'fields.email.invalid',
    defaultMessage: 'Please enter a valid email address.',
    description: 'Validation error message',
  },
});
