import { defineMessages } from 'react-intl';

export default defineMessages({
  requestButton: {
    id: 'buttons.requestButton',
    defaultMessage: 'Request payment',
    description: 'Button label'
  },
  previousButton: {
    id: 'buttons.previous',
    defaultMessage: 'previous',
    description: 'Button label'
  },
  finishButton: {
    id: 'buttons.finish',
    defaultMessage: 'finish',
    description: 'Button label'
  },
  continueButton: {
    id: 'buttons.continue',
    defaultMessage: 'continue',
    description: 'Button label'
  },
  okButton: {
    id: 'buttons.ok',
    defaultMessage: 'ok',
    description: 'Button label'
  },
  successMessage: {
    id: 'requestModal.success',
    defaultMessage: 'Your Client will receive email to make payment or activate subscription soon.',
    description: 'Success text'
  },
  successTitle: {
    id: 'requestModal.successTitle',
    defaultMessage: 'Payment is requested',
    description: 'Success text'
  },
  selectClientStep: {
    id: 'requestModal.selectClientStep',
    defaultMessage: 'Select Your Client',
    description: 'Onboarding title'
  },
  paymentDetailsStep: {
    id: 'requestModal.paymentDetailsStep',
    defaultMessage: 'Payment Details',
    description: 'Onboarding title'
  },
  reviewStep: {
    id: 'requestModal.reviewStep',
    defaultMessage: 'Review & Send',
    description: 'Onboarding title'
  },
  creditCard: {
    id: 'requestModal.creditCard',
    defaultMessage: 'Credit card',
    description: 'Card label'
  },
  singlePayment: {
    id: 'requestModal.singlePayments',
    defaultMessage: 'One Time Payment',
    description: 'text',
  },
  subscription: {
    id: 'requestModal.subscription',
    defaultMessage: 'Subscription',
    description: 'text',
  },
  singlePaymentType: {
    id: 'requestModal.singlePaymentType',
    defaultMessage: 'One Time',
    description: 'text',
  },
  subscriptionPaymentType: {
    id: 'requestModal.subscriptionPaymentType',
    defaultMessage: 'Subscription',
    description: 'text',
  },
  descriptionField: {
    id: 'fields.description',
    defaultMessage: 'Description',
    description: 'Field label'
  },
  paymentAmountField: {
    id: 'fields.paymentAmount',
    defaultMessage: 'Payment amount, $',
    description: 'Field label'
  },
});
