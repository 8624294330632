import { put, takeLatest, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { advisorProfileError, getClientsSuccess } from '../../actions/AdvisorProfile';
import { ADVISOR_PROFILE_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker({ payload }: any): SagaIterator<void> {
  try {

    const { data } = yield call(Api.advisors.getAdvisorClients, payload);
    yield put(getClientsSuccess(data));
  } catch (e) {
    yield put(advisorProfileError(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(ADVISOR_PROFILE_TYPES.GET_CLIENTS, worker);
}
