export const ONBOARDING_TYPES = {
  SING_IN_WITH_EMAIL_REQUEST: 'onboarding_sign_in_with_email_request',
  SING_IN_WITH_EMAIL_SUCCESS: 'onboarding_sign_in_with_email_success',
  SET_UP_PASSWORD_REQUEST: 'onboarding_set_up_password_request',
  SET_UP_PASSWORD_SUCCESS: 'onboarding_set_up_password_success',
  VERIFY_EMAIL_REQUEST: 'onboarding_verify_email_request',
  VERIFY_EMAIL_SUCCESS: 'onboarding_verify_email_success',
  GO_TO_LOGIN: 'go_to_login',
  LOGOUT: 'logout',
  SET_STEP: 'onboarding_set_step',
  SUBMIT_COMPANY_STEP: 'onboarding_submit_company_step',
  SUBMIT_COMPANY_STEP_SUCCESS: 'onboarding_submit_company_step_success',
  SUBMIT_ACCOUNT_INFO_STEP: 'onboarding_submit_account_info_step',
  SUBMIT_ACCOUNT_INFO_STEP_SUCCESS: 'onboarding_submit_account_info_step_success',
  SUBMIT_BANKING_STEP: 'onboarding_submit_banking_step',
  SUBMIT_BANKING_STEP_SUCCESS: 'onboarding_submit_banking_step_success',
  SUBMIT_BILLING_STEP: 'onboarding_submit_billing_step',
  SUBMIT_BILLING_STEP_SUCCESS: 'onboarding_submit_billing_step_success',
  GET_COMPANY: 'onboarding_get_company',
  GET_COMPANY_SUCCESS: 'onboarding_get_company_success',
  GET_BANKING_ACCOUNT: 'onboarding_get_banking_account',
  GET_BANKING_ACCOUNT_SUCCESS: 'onboarding_get_banking_account_success',
  FINISH_ONBOARDING: 'onboarding_finish_onboarding',
  FINISH_ONBOARDING_SUCCESS: 'onboarding_finish_onboarding_success',
  GET_ACCOUNT_INFO: 'onboarding_get_account_info',
  GET_ACCOUNT_INFO_SUCCESS: 'onboarding_get_account_info_success',
  GET_BILLING_INFO: 'onboarding_get_billing_info',
  GET_BILLING_INFO_SUCCESS: 'onboarding_get_billing_info_success',
  ONBOARDING_ERROR: 'onboarding_error',
};

export const AUTH_TYPES = {
  GET_USER: 'get_user',
  GET_USER_SUCCESS: 'get_user_success',
  ERROR: 'auth_error'
};

export const CLIENT_TYPES = {
  CREATE_CLIENT: 'create_client',
  CREATE_CLIENT_SUCCESS: 'create_client_success',
  GET_CLIENTS: 'get_clients',
  GET_CLIENTS_SUCCESS: 'get_clients_success',
  REFETCH_CLIENTS_REQUEST: 'refetch_clients_request',
  REFETCH_CLIENTS_SUCCESS: 'refetch_clients_success',
  GET_ALL_ADVISORS: 'clients_get_all_advisors',
  GET_ALL_ADVISORS_SUCCESS: 'clients_get_all_advisors_success',
  ERROR: 'clients_error',
};

export const ADVISORS_TYPES = {
  CREATE_ADVISOR: 'create_advisor',
  CREATE_ADVISOR_SUCCESS: 'create_advisor_success',
  GET_ADVISORS: 'get_advisors',
  GET_ADVISORS_SUCCESS: 'get_advisors_success',
  REFETCH_ADVISORS_REQUEST: 'refetch_advisors_request',
  REFETCH_ADVISORS_SUCCESS: 'refetch_advisors_success',
  ERROR: 'advisors_error',
};

export const CLIENT_PROFILE_TYPES = {
  FETCH_INIT_DATA: 'client_profile_fetch_init_data',
  FETCH_INIT_DATA_SUCCESS: 'client_profile_fetch_init_data_success',
  GET_CLIENT_INFO: 'get_client_info',
  GET_CLIENT_INFO_SUCCESS: 'get_client_info_success',
  GET_SINGLE_PAYMENTS: 'clients_get_single_payments',
  GET_SINGLE_PAYMENTS_SUCCESS: 'clients_get_single_payments_success',
  REFETCH_SINGLE_PAYMENTS: 'clients_refetch_single_payments',
  REFETCH_SINGLE_PAYMENTS_SUCCESS: 'clients_refetch_single_payments_success',
  GET_SUBSCRIPTIONS: 'clients_get_subscription',
  GET_SUBSCRIPTIONS_SUCCESS: 'clients_get_subscription_success',
  REFETCH_SUBSCRIPTIONS: 'clients_refetch_subscription',
  REFETCH_SUBSCRIPTIONS_SUCCESS: 'clients_refetch_subscription_success',
  SEND_LINK: 'send_link',
  SEND_LINK_SUCCESS: 'send_link_success',
  ERROR: 'client_profile_error',
};

export const ADVISOR_PROFILE_TYPES = {
  GET_INFO: 'advisor_profile_get_info',
  GET_INFO_SUCCESS: 'advisor_profile_get_info_success',
  GET_SINGLE_PAYMENTS: 'advisor_profile_get_single_payments',
  GET_SINGLE_PAYMENTS_SUCCESS: 'advisor_profile_get_single_payments_success',
  REFETCH_SINGLE_PAYMENTS: 'advisor_profile_refetch_single_payments',
  REFETCH_SINGLE_PAYMENTS_SUCCESS: 'advisor_profile_refetch_single_payments_success',
  GET_CLIENTS: 'advisor_profile_get_clients',
  GET_CLIENTS_SUCCESS: 'advisor_profile_get_clients_success',
  REFETCH_CLIENTS: 'advisor_profile_refetch_clients',
  REFETCH_CLIENTS_SUCCESS: 'advisor_profile_refetch_clients_success',
  GET_SUBSCRIPTIONS: 'advisor_profile_get_subscription',
  GET_SUBSCRIPTIONS_SUCCESS: 'advisor_profile_get_subscription_success',
  REFETCH_SUBSCRIPTIONS: 'advisor_profile_refetch_subscription',
  REFETCH_SUBSCRIPTIONS_SUCCESS: 'advisor_profile_refetch_subscription_success',
  SEND_LINK: 'advisor_profile_send_link',
  SEND_LINK_SUCCESS: 'advisor_profile_send_link_success',
  ERROR: 'advisor_profile_profile_error',
};

export const SETTINGS_TYPES = {
  FETCH_INIT_DATA: 'settings_fetch_init_data',
  FETCH_INIT_DATA_SUCCESS: 'settings_fetch_init_data_success',
  GET_APP_SUBSCRIPTION: 'settings_get_app_subscription',
  GET_APP_SUBSCRIPTION_SUCCESS: 'settings_get_app_subscription_success',
  GET_COMPANY_DETAILS: 'settings_get_company_details',
  GET_COMPANY_DETAILS_SUCCESS: 'settings_get_company_details_success',
  GET_BILLING_HISTORY: 'settings_get_billing_history',
  GET_BILLING_HISTORY_SUCCESS: 'settings_get_billing_history_success',
  REFETCH_BILLING_HISTORY: 'settings_refetch_billing_history',
  REFETCH_BILLING_HISTORY_SUCCESS: 'settings_refetch_billing_history_success',
  GET_BANK_DETAILS: 'settings_get_banking_details',
  GET_BANK_DETAILS_SUCCESS: 'settings_get_banking_details_success',
  ERROR: 'settings_error',
};

export const WALLET_TYPES = {
  CREATE_CARD: 'wallet_create_card',
  CREATE_CARD_SUCCESS: 'wallet_create_card_success',
  GET_CARDS: 'wallet_get_cards',
  GET_CARDS_SUCCESS: 'wallet_get_cards_success',
  ERROR: 'wallet_error',
};

export const PAYMENT_MODAL_TYPES = {
  GET_CLIENTS: 'payment_modal_get_clients',
  GET_CLIENTS_SUCCESS: 'payment_modal_get_clients_success',
  CREATE_SINGLE_PAYMENT: 'payment_modal_create_single_payment',
  CREATE_SINGLE_PAYMENT_SUCCESS: 'payment_modal_create_single_payment_success',
  ERROR: 'payment_modal_error'
};

export const INVOICE_TYPES = {
  GET_INVOICE_INFO: 'invoice_get_invoice_info',
  GET_INVOICE_INFO_SUCCESS: 'invoice_get_invoice_info_success',
  ERROR: 'invoice_error',
};

export const SINGLE_PAYMENTS_TYPES = {
  GET_PAYMENTS: 'single_payments_get_payments',
  GET_PAYMENTS_SUCCESS: 'single_payments_get_payments_success',
  REFETCH_PAYMENTS: 'single_payments_refetch_payments',
  REFETCH_PAYMENTS_SUCCESS: 'single_payments_refetch_payments_success',
  ERROR: 'single_payments_error',
};

export const TRANSFERS_TYPES = {
  FETCH_INIT_DATA: 'transfers_fetch_init_data',
  FETCH_INIT_DATA_SUCCESS: 'transfers_fetch_init_data_success',
  REFETCH_PENDING_TRANSFERS: 'transfers_refetch_pending_transfers',
  REFETCH_PENDING_TRANSFERS_SUCCESS: 'transfers_refetch_pending_transfers_success',
  REFETCH_COMPLETED_TRANSFERS: 'transfers_refetch_completed_transfers',
  REFETCH_COMPLETED_TRANSFERS_SUCCESS: 'transfers_refetch_completed_transfers_success',
  REFETCH_PAYMENTS: 'transfers_refetch_payments',
  REFETCH_PAYMENTS_SUCCESS: 'transfers_refetch_payments_success',
  ERROR: 'transfers_error',
};

export const TRANSFER_DETAILS_TYPES = {
  FETCH_INIT_DATA: 'transfer_details_fetch_init_data',
  FETCH_INIT_DATA_SUCCESS: 'transfer_details_fetch_init_data_success',
  REFETCH_DATA: 'transfer_details_refetch_data',
  REFETCH_DATA_SUCCESS: 'transfer_details_refetch_data_success',
  ERROR: 'transfer_details_error',
};

export const SUBSCRIPTIONS_TYPES = {
  GET_SUBSCRIPTIONS: 'subscriptions_get_subscriptions',
  GET_SUBSCRIPTIONS_SUCCESS: 'subscriptions_get_subscriptions_success',
  REFETCH_SUBSCRIPTIONS: 'subscriptions_refetch_subscriptions',
  REFETCH_SUBSCRIPTIONS_SUCCESS: 'subscriptions_refetch_subscriptions_success',
  CREATE_SUBSCRIPTION: 'subscriptions_create_subscription',
  CREATE_SUBSCRIPTION_SUCCESS: 'subscriptions_create_subscription_success',
  ERROR: 'subscriptions_error',
};

export const SUBSCRIPTION_DETAILS_TYPES = {
  GET_INFO: 'subscription_details_get_info',
  GET_INFO_SUCCESS: 'subscription_details_get_info_success',
  GET_PAYMENTS: 'subscription_details_get_payments',
  GET_PAYMENTS_SUCCESS: 'subscription_details_get_payments_success',
  REFETCH_PAYMENTS: 'subscription_details_refetch_payments',
  REFETCH_PAYMENTS_SUCCESS: 'subscription_details_refetch_payments_success',
  ERROR: 'subscription_details_error',
};
