import {
  put, takeLatest, call
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { clientsError, getClientsSuccess } from '../../actions/Clients';
import { CLIENT_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker(): SagaIterator<void> {
  try {
    const { data } = yield call(Api.clients.getClients);
    yield put(getClientsSuccess(data));
  } catch (e) {
    yield put(clientsError(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(CLIENT_TYPES.GET_CLIENTS, worker);
}
