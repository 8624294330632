import { put, takeLatest, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { authError, getUserSuccess } from '../../actions/Auth';
import { AUTH_TYPES } from '../../constants';
import Api from '../../../api';
import { ROLES } from '../../../constants';

export function* worker(): SagaIterator<void> {
  try {
    const { data } = yield call(Api.users.getUsers);
    const info = data.results[0];
    if (info.onboarding_status > 1 || (info.onboarding_status === 0 && info.groups[0].name !== ROLES.ACCOUNT_OWNER)) {
      const { data: accountInfo } = yield call(Api.userProfiles.getAccountInfo, info.id);
      yield put(getUserSuccess({ ...info, ...accountInfo }));
    } else {
      yield put(getUserSuccess(info));
    }
  } catch (e) {
    yield put(authError(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(AUTH_TYPES.GET_USER, worker);
}
