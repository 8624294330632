import { all } from 'redux-saga/effects';

import onBoardingSagas from './onBoarding';
import Auth from './Auth';
import Advisors from './Advisors';
import AdvisorProfile from './AdvisorProfile';
import Clients from './Clients';
import ClientProfile from './ClientProfile';
import Settings from './Settings';
import Wallet from './Wallet';
import PaymentModal from './PaymentModal';
import Invoice from './Invoice';
import SinglePayments from './SinglePayments';
import Transfers from './Transfers';
import Subscriptions from './Subscriptions';
import SubscriptionDetails from './SubscriptionDetails';
import TransferDetails from './TransferDetails';

export default function* rootSaga() {
  yield all([
    onBoardingSagas,
    Auth,
    Advisors,
    AdvisorProfile,
    Clients,
    ClientProfile,
    Settings,
    Wallet,
    PaymentModal,
    Invoice,
    SinglePayments,
    Transfers,
    Subscriptions,
    SubscriptionDetails,
    TransferDetails,
  ]);
}
