import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import Box from '@material-ui/core/Box';

import Link from '../Link';
import H3Dark from '../H3Dark';

interface DropResult {
  url: string | ArrayBuffer | null,
  type: string,
  name: string,
  size: number
}

interface Props {
  multiple?: boolean,
  accept?: string,
  withBase64?: boolean,
  getInitFiles?: (data: File[]) => void,
  onFilesDropped?: (data: DropResult[]) => void,
}

const DropZoneInput: React.FC<Props> = ({
  onFilesDropped,
  multiple,
  withBase64 = true,
  getInitFiles,
}) => {
  const results: DropResult[] = [];
  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (getInitFiles) {
      getInitFiles(acceptedFiles);
    }
    if (withBase64) {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onload = (event) => {
          if (event.target instanceof FileReader) {
            results.push({
              url: event.target.result,
              type: file.type,
              name: file.name,
              size: file.size,
            });
          }
          if (results.length === acceptedFiles.length && onFilesDropped) {
            onFilesDropped(results);
          }
        };
        reader.readAsDataURL(file);
      });
    }
  }, [onFilesDropped, getInitFiles]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop, multiple,
  });
  return (
    <Box
      border="1px dashed #3772FF"
      borderRadius={4}
      textAlign="center"
      p={3}
      {...getRootProps()}
      style={{ cursor: 'pointer' }}
    >
      <input {...getInputProps()} />
      <H3Dark mb={2}>Drag & drop file here</H3Dark>
      <Link>Browse file</Link>
    </Box>
  );
};

DropZoneInput.defaultProps = {
  onFilesDropped: () => {},
  multiple: true,
};

export default DropZoneInput;
