import { all, fork } from 'redux-saga/effects';

import createAdvisor from './createAdvisor';
import getAdvisors from './getAdvisors';
import refetchAdvisors from './refetchAdvisors';

export default all([
  fork(createAdvisor),
  fork(getAdvisors),
  fork(refetchAdvisors),
]);
