/* eslint-disable @typescript-eslint/quotes  */
import { defineMessages } from 'react-intl';

export default defineMessages({
  checkEmailTitle: {
    id: 'onBoarding.checkEmailMessage',
    defaultMessage: 'Check your email',
    description: 'Check email message'
  },
  checkEmailDescription: {
    id: 'onBoarding.checkEmailDescription',
    // eslint-disable-next-line max-len
    defaultMessage: `Complete your account creation be clicking the verification link in the email we just sent you at {email}`,
    description: 'CheckEmail description'
  },
  resendLink: {
    id: 'onBoarding.resendLink',
    defaultMessage: 'Resend verification link ',
    description: 'Resend mail link'
  }
});
