import React from 'react';
import MuiMenu from '@material-ui/core/Menu';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiMenuItem from '@material-ui/core/MenuItem';

const Menu = withStyles({
  paper: {
    background: 'white',
    boxShadow: '4px 10px 20px rgba(55, 114, 255, 0.1)',
    borderRadius: 4,
    padding: '8px 0',
  },
})(MuiMenu);
const MenuItem = withStyles((theme) => ({
  root: {
    background: 'white',
    height: 48,
    padding: '0 16px',
    fontSize: 16,
    color: theme.palette.warning.dark,
    '&:hover': {
      background: theme.palette.background.default
    },
  },
}))(MuiMenuItem);

interface Props {
  open: boolean,
  anchorEl: Element | null,
  options: {
    label: string,
    // @ts-ignore
    onClick: (event: MouseEvent<HTMLLIElement, MouseEvent>) => void,
    disabled?: boolean,
  }[]
  onClose: (event: Event) => void
}

const CustomMenu = ({
  open,
  anchorEl,
  options,
  onClose
}: Props) => (
  <Menu
    anchorEl={anchorEl}
    open={open}
    elevation={0}
    getContentAnchorEl={null}
    onClose={onClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    MenuListProps={{
      style: {
        minWidth: 150,
        paddingTop: 0,
        paddingBottom: 0
      },
    }}
  >
    {options.map((option, index) => (
      <MenuItem
        className={option.disabled ? 'menu-item-disable' : ''}
        // eslint-disable-next-line
        key={index}
        onClick={option.onClick}
      >
        {option.label}
      </MenuItem>
    ))}
  </Menu>
);

export default CustomMenu;
