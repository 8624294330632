import withStyles from '@material-ui/core/styles/withStyles';
import Box from '@material-ui/core/Box';

export default withStyles({
  root: {
    padding: 24,
    marginTop: 24,
    boxShadow: '4px 10px 20px rgba(55, 114, 255, 0.1)',
    borderRadius: 4,
    maxWidth: 650,
    background: 'white'
  }
})(Box);
