import {
  put,
  call,
  select,
  throttle,
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { onBoardingError, singUpWithEmailSuccess } from '../../actions/onBoaring';
import { ONBOARDING_TYPES } from '../../constants';
import Api from '../../../api';
import { OnBoardingActionTypes } from '../../actions/onBoaring/types';

export function* worker({ payload }: OnBoardingActionTypes): SagaIterator<void> {
  if (typeof payload !== 'string') {
    return;
  }
  try {
    const store = yield select();
    if (store.onBoarding.emailVerificationMessageSend) {
      yield call(Api.users.resendVerificationEmail, payload);
    } else {
      yield call(Api.users.sendVerificationEmail, payload);
    }
    yield put(singUpWithEmailSuccess());
  } catch (e) {
    yield put(onBoardingError(e.message));
  }
}

export default function* watcher() {
  yield throttle(5000, ONBOARDING_TYPES.SING_IN_WITH_EMAIL_REQUEST, worker);
}
