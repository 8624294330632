import { all, fork } from 'redux-saga/effects';

import getSubscription from './getSubscription';
import getPayments from './getPayments';
import refetchPayments from './refetchPayments';

export default all([
  fork(getSubscription),
  fork(getPayments),
  fork(refetchPayments),
]);
