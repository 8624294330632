import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import Box from '@material-ui/core/Box';
import { FormattedMessage } from 'react-intl';

import H1 from '../../../H1';
import H3 from '../../../H3';
import Button from '../../../Button';
import messages from '../../messages';

const MyComponent: React.FC<RouteComponentProps> = ({ history }) => (
  <Box textAlign="center">
    <H1 mb={2}><FormattedMessage {...messages.noCardMessage} /></H1>
    <H3 mb={5}><FormattedMessage {...messages.noCardDescription} /></H3>
    <Button onClick={() => history.push('/wallet')} variant="contained">
      <FormattedMessage {...messages.wallet} />
    </Button>
  </Box>
);

export default withRouter(MyComponent);
