import React from 'react';
import Box from '@material-ui/core/Box';
import { FormattedMessage } from 'react-intl';

import {
  Button,
  Table,
  H1, H3Dark, Link,
} from '../../components';
import { Tabs, EmptyMessage } from './components';
import { ROLES } from '../../constants';
import messages from './messages';
import { advisorColumns, clientColumns } from './data';

interface Props {
  onRequestPayment: () => void,
  searchValue: string,
  onSearchChange:(value: string) => void,
  onSearchSubmit: (value: string) => void,
  userRole: string,
  total: number,
  payments: {}[],
  pagination: { page: number },
  refetchData: (data: any) => void,
  sort: { id: string, firstDirection: boolean },
  actions: {}[],
  accountVerified: boolean,
  accountSubscribed: boolean,
}

const MyComponent: React.FC<Props> = ({
  onRequestPayment,
  searchValue,
  onSearchChange,
  onSearchSubmit,
  userRole,
  payments,
  total,
  sort,
  refetchData,
  pagination,
  actions,
  accountSubscribed,
  accountVerified,
}) => (
  <Box mt={2}>
    {userRole !== ROLES.CLIENT && (
    <Button
      variant="contained"
      onClick={onRequestPayment}
      shouldValidateAccount
      accountVerified={accountVerified && accountSubscribed}
      modalContent={(
        <H3Dark p={3} maxWidth={600}>
          {!accountVerified ? (
            <Box>
              <span>
                Sorry but you are unable to request payment until your ChalicePay account is verified.
                Please check your
                {' '}
                <Link href="/settings">Company Settings</Link>
              </span>
            </Box>
          ) : (
            !accountSubscribed && (
              <Box>
                <span>
                  Sorry but you are unable to make action until your ChalicePay subscription is unpaid.
                  Please check your
                </span>
                {' '}
                <Link href="/settings">Bank Details</Link>
              </Box>
            )
          )}
        </H3Dark>
      )}
    >
      <FormattedMessage {...messages.requestButton} />
    </Button>
    )}
    <H1 mt={5}>
      <FormattedMessage {...messages.singlePayments} />
    </H1>
    <Tabs />
    <Box mt={2}>
      <Table
        data={payments}
        pagination={pagination}
        sort={sort}
        refetchData={refetchData}
        countTotal={total}
        columns={userRole !== ROLES.CLIENT ? advisorColumns(userRole) : clientColumns}
        withPagination
        actions={actions}
        noItemsMessage={(
          <EmptyMessage
            accountVerified={accountVerified}
            accountSubscribed={accountSubscribed}
            onRequestPayment={onRequestPayment}
            userRole={userRole}
          />
        )}
        withSearch={userRole !== ROLES.CLIENT}
        searchValue={searchValue}
        onSearchChange={onSearchChange}
        onSearchSubmit={onSearchSubmit}
      />
    </Box>
  </Box>
);

export default MyComponent;
