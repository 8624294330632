import { CLIENT_TYPES } from '../../constants';

export const createClient = (payload: { last_name: string, first_name: string, email: string },
  callback: (error: boolean) => void) => ({
  type: CLIENT_TYPES.CREATE_CLIENT,
  payload,
  callback,
});

export const createClientSuccess = (payload: any) => ({
  type: CLIENT_TYPES.CREATE_CLIENT_SUCCESS,
  payload,
});

export const getClients = () => ({
  type: CLIENT_TYPES.GET_CLIENTS,
});

export const getClientsSuccess = (payload: any) => ({
  type: CLIENT_TYPES.GET_CLIENTS_SUCCESS,
  payload,
});

export const getAllAdvisors = () => ({
  type: CLIENT_TYPES.GET_ALL_ADVISORS,
});

export const getAllAdvisorsSuccess = (payload: any) => ({
  type: CLIENT_TYPES.GET_ALL_ADVISORS_SUCCESS,
  payload,
});

export const refetchClients = (payload: any) => ({
  type: CLIENT_TYPES.REFETCH_CLIENTS_REQUEST,
  payload
});

export const refetchClientsSuccess = (payload: any) => ({
  type: CLIENT_TYPES.REFETCH_CLIENTS_SUCCESS,
  payload
});

export const clientsError = (error: any) => ({
  type: CLIENT_TYPES.ERROR,
  payload: error
});
