import React from 'react';

interface Props {
  color?: string
}

const Icon: React.FC<Props> = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      fill={color || '#011638'}
      d="M26.667 5.333H5.333A2.646 2.646 0 002.68 8l-.013 16a2.657 2.657 0 002.666 2.667h21.334A2.658 2.658 0 0029.333 24V8a2.657 2.657 0 00-2.666-2.667zM25.333 24H6.667c-.734 0-1.334-.6-1.334-1.333V16h21.334v6.667c0 .733-.6 1.333-1.334 1.333zm1.334-13.333H5.333V9.333C5.333 8.6 5.933 8 6.667 8h18.666c.734 0 1.334.6 1.334 1.333v1.334z"
    />
  </svg>
);

export default Icon;
