import React, { Component } from 'react';
import {
  DatePicker,
  // @ts-ignore
  MuiTextFieldProps,
  DayProps
} from '@material-ui/pickers';
import Box from '@material-ui/core/Box';
import moment, { Moment } from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';

import { DatePickerIcon } from '../../metadata/icons';
import styles from './styles.module.scss';
import TextField from '../TextField';

const Day = withStyles({
  root: {
    width: 32,
    height: 32,
    margin: '0 2px',
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      background: '#E7EDFD',
      color: '#6D6E71',
    },
  },
})(Box);

const NonMonthDay = withStyles({
  root: {
    width: 32,
    height: 32,
    margin: '0 2px',
    borderRadius: '50%',
  },
})(Box);

interface Props {
  label?: string,
  helperText?: string,
  disabled?: boolean,
  error?: boolean,
  onChange: (val: Moment) => void,
  onBlur: () => void,
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void,
  value: string,
  maxDate?: Moment,
  minDate?: Moment,
}

interface State {
  open: boolean,
}

class CustomDatePicker extends Component<Props, State> {
  state = {
    open: false
  };

  handleDateChange = (selectedDate: Moment) => {
    const { onChange } = this.props;
    onChange(selectedDate);
  };

  renderInput = ({ ref, onChange }: MuiTextFieldProps) => {
    const { open } = this.state;
    const {
      disabled,
      label,
      value,
      helperText,
      error,
    } = this.props;
    const color = (error && '#E20338') || (disabled && '#BCBEC0') || (open && '#3772FF') || '#6D6E71';
    return (
      <div ref={ref} className={styles.inputContainer}>
        <TextField
          helperText={helperText}
          error={error}
          disabled={disabled}
          onChange={onChange}
          label={label}
          value={value ? moment(value).format('MMM D, YYYY') : ''}
          fullWidth
          endAdornment={(
            <Box width={24} height={24} position="relative">
              <DatePickerIcon
                onClick={this.togglePicker}
                color={color}
                style={{ position: 'absolute', zIndex: 1, cursor: 'pointer' }}
              />
            </Box>
          )}
        />
        <div className={styles.mask} onClick={this.togglePicker}>
          <div>
            {value ? moment(value).format('MMM D, YYYY') : ''}
          </div>
        </div>
      </div>
    );
  };

  handleDayChange = (day: Moment) => {
    const { onChange, minDate, maxDate } = this.props;
    if (minDate && day.set({ hour: 23, minute: 59, second: 59 }).isBefore(minDate)) {
      return;
    }
    if (maxDate && day.set({ hour: 23, minute: 59, second: 59 }).isAfter(maxDate)) {
      return;
    }
    this.setState({
      open: false,
    });
    onChange(day);
  };

  renderDay = (day: Moment | null, selectedDates: (Moment | null)[], data: DayProps) => {
    const { inCurrentMonth, today, selected } = data;
    if (!inCurrentMonth) {
      return <NonMonthDay key={day!.format!()} />;
    }
    return (
      <Day
        onClick={() => {
          if (day) {
            this.handleDayChange(day);
          }
        }}
        key={day!.format()}
        border={today ? '1px solid #3772FF' : 'none'}
        bgcolor={selected ? '#3772FF' : 'none'}
        color={selected ? '#fff' : '#6D6E71'}
      >
        {day!.date()}
      </Day>
    );
  };

  togglePicker = () => {
    const { disabled, onBlur } = this.props;
    if (disabled) return;
    const { open } = this.state;
    if (open) {
      onBlur();
    }
    this.setState((prevState) => ({ ...prevState, open: !prevState.open }));
  };

  render() {
    const { open } = this.state;
    const { value, maxDate, minDate } = this.props;

    return (
      <DatePicker
        maxDate={maxDate || null}
        minDate={minDate || null}
        autoOk
        open={open}
        onClose={this.togglePicker}
        label="label"
        className={styles.datePicker}
        value={value ? moment(value) : (minDate || maxDate || moment())}
        // @ts-ignore
        onChange={this.handleDateChange}
        renderDay={this.renderDay}
        renderInput={this.renderInput}
      />
    );
  }
}

export default CustomDatePicker;
