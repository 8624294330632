import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

import messages from './messages';
import { REGEXP } from '../../../../constants/validation';

export default (intl: IntlShape) => Yup.object().shape({
  firstName: Yup.string()
    .max(35, intl.formatMessage(messages.firstNameError))
    .matches(/^[a-zA-Z- ]{1,1000}$/, intl.formatMessage(messages.firstNameInvalidError))
    .required(intl.formatMessage(messages.requiredMassage)),
  lastName: Yup.string()
    .max(35, intl.formatMessage(messages.lastNameError))
    .matches(/^[a-zA-Z- ]{1,1000}$/, intl.formatMessage(messages.lastNameInvalidError))
    .required(intl.formatMessage(messages.requiredMassage)),
  dateOfBirth: Yup.string()
    .required(intl.formatMessage(messages.requiredMassage)),
  secNumber: Yup.string()
    .required(intl.formatMessage(messages.secNumberError))
    .matches(/[0-9\\*]{9}/, intl.formatMessage(messages.secNumberError))
    .max(9, intl.formatMessage(messages.secNumberError)),
  companyStreetAddress: Yup.string()
    .matches(/^[a-zA-Z0-9-\\,\\. ]{1,1000}$/, intl.formatMessage(messages.companyStreetAddressInvalidError))
    .required(intl.formatMessage(messages.requiredMassage))
    .min(3, intl.formatMessage(messages.companyStreetAddressMinError))
    .max(95, intl.formatMessage(messages.companyStreetAddressMaxError)),
  city: Yup.string()
    .required(intl.formatMessage(messages.requiredMassage)),
  state: Yup.string()
    .required(intl.formatMessage(messages.requiredMassage)),
  zipCode: Yup.string()
    .required(intl.formatMessage(messages.requiredMassage))
    .min(5, intl.formatMessage(messages.zipCodeErrorField))
    .max(9, intl.formatMessage(messages.zipCodeErrorField)),
  phoneNumber: Yup.string()
    .required(intl.formatMessage(messages.phoneNumberError))
    .matches(/^[^_]*$/, intl.formatMessage(messages.phoneNumberError)),
  businessWebsite: Yup.string()
    .matches(REGEXP.WEBSITE_URL, 'Please enter a valid website')
    .when(['professionalOccupation'], {
      is: (b) => !b,
      then: Yup.string().required(intl.formatMessage(messages.requiredMassage))
    }),
  professionalOccupation: Yup.string()
    .when(['businessWebsite'], {
      is: (a) => !a,
      then: Yup.string()
        .required(intl.formatMessage(messages.requiredMassage))
        .notOneOf(['0'], intl.formatMessage(messages.requiredMassage))
    })
}, [['businessWebsite', 'professionalOccupation']]);
