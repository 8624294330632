import React from 'react';
import Box from '@material-ui/core/Box';

import { withParentData } from '../../Context';
import {
  H2,
  Button,
  H3,
  H3Dark
} from '../../../../components';
import Form from '../EditAccountForm';

interface Props {
  companyInfo: {
    name: string,
    status: string,
  },
  openEditForm: () => void,
}

const CompanyInfo: React.FC<Props> = ({
  companyInfo,
  openEditForm,
}) => (
  <Box>
    <H2 mt={5}>
      General Information
    </H2>
    <Box
      mt={3}
      p={3}
      display="flex"
      width={450}
      boxShadow="4px 10px 20px rgba(55, 114, 255, 0.1)"
      borderRadius={4}
      bgcolor="white"
    >
      <H3Dark mr={3}>Company name</H3Dark>
      <H3>{companyInfo.name}</H3>
    </Box>
    {companyInfo.status === 'pending' && (
      <Box mt={5}>
        <H2>Pending</H2>
        <H3 mt={3}>Please keep calm, your account is being verified. This may take some minutes.</H3>
        <Form />
      </Box>
    )}
    {companyInfo.status === 'unverified' && (
      <Box mt={5}>
        <H2 color="#E20338 !important">Something went wrong…</H2>
        <H3 mt={3} color="#E20338 !important">Your account verification has failed as we detected some invalid details. Please edit them for a successful verification.</H3>
      </Box>
    )}
    {companyInfo.status !== 'pending' && (
      <Box mt={5}>
        <Button onClick={openEditForm} variant="contained">edit account details</Button>
        <Form />
      </Box>
    )}
  </Box>
);

export default withParentData(CompanyInfo);
