import { put, takeLatest, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { getClientsSuccess, paymentModalError } from '../../actions/PaymentModal';
import { PAYMENT_MODAL_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker(): SagaIterator<void> {
  try {
    const { data } = yield call(Api.clients.getAllClients);
    yield put(getClientsSuccess(
      data.filter((item: any) => item.is_active).map((item: any) => ({ label: item.name, value: item.id }))
    ));
  } catch (e) {
    yield put(paymentModalError(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(PAYMENT_MODAL_TYPES.GET_CLIENTS, worker);
}
