import React, { Component } from 'react';

import Content from './Content';
import { apiUrl } from '../../constants';

interface Props {
  fetchInitData: () => void,
  refetchPendingTransfers: (data: any) => void,
  refetchCompletedTransfers: (data: any) => void,
  pendingTransfers: {}[],
  pendingTotal: number,
  pendingPagination: { page: number },
  pendingSort: { id: string, firstDirection: boolean },
  completedTransfers: {}[],
  completedTotal: number,
  completedPagination: { page: number },
  completedSort: { id: string, firstDirection: boolean },
}

class Container extends Component<Props> {
  componentDidMount() {
    const { fetchInitData } = this.props;

    fetchInitData();
  }

  handleRefetchPendingData = (data: any) => {
    const { refetchPendingTransfers } = this.props;
    return refetchPendingTransfers(data);
  };

  handleRefetchCompletedData = (data: any) => {
    const { refetchCompletedTransfers } = this.props;
    return refetchCompletedTransfers(data);
  };

  downloadTransfers = () => {
    window.open(`${apiUrl}/payments/transfers-csv`);
  };

  render() {
    const {
      pendingPagination,
      pendingSort,
      pendingTotal,
      pendingTransfers,
      completedPagination,
      completedSort,
      completedTotal,
      completedTransfers,
    } = this.props;
    return (
      <Content
        downloadTransfers={this.downloadTransfers}
        pendingPagination={pendingPagination}
        pendingSort={pendingSort}
        pendingTransfers={pendingTransfers}
        pendingTotal={pendingTotal}
        completedPagination={completedPagination}
        completedSort={completedSort}
        completedTotal={completedTotal}
        completedTransfers={completedTransfers}
        onRefetchPendingData={this.handleRefetchPendingData}
        onRefetchCompletedData={this.handleRefetchCompletedData}
      />
    );
  }
}

export default Container;
