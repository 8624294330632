import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');
interface Props {
  open: boolean,
  handleClose: () => void,
}
const CustomModal: React.FC<Props> = ({
  open,
  handleClose,
  children
}) => {
  const customStyles = {
    overlay: {
      zIndex: 1000,
      background: 'rgba(1, 22, 56, 0.32',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      borderRadius: 0,
      display: 'flex',
      overflow: 'initial',
      transform: 'translate(-50%, -50%)',
      padding: 0,
    }
  };
  return (
    <div>
      <Modal
        style={customStyles}
        isOpen={open}
        onRequestClose={handleClose}
      >
        {children}
      </Modal>
    </div>
  );
};

export default CustomModal;
