import { put, takeLatest, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { clientProfileError, getClientInfoSuccess } from '../../actions/ClientProfile';
import { CLIENT_PROFILE_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker({ payload }: { payload: string }): SagaIterator<void> {
  try {
    const { data } = yield call(Api.clients.getClientById, payload);
    yield put(getClientInfoSuccess(data));
  } catch (e) {
    yield put(clientProfileError(e.message));
  }
}

export default function* watcher() {
  // @ts-ignore
  yield takeLatest(CLIENT_PROFILE_TYPES.GET_CLIENT_INFO, worker);
}
