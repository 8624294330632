import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

import { Provider } from './Context';
import Content from './Content';
import { tabs } from './components/Tabs';

import { ROLES } from '../../constants';
import { RequestPaymentModal, MakePaymentModal, CancelInvoiceModal } from '../../components';

interface MatchParams {
  id: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  userRole: string,
  getSubscriptions: () => void,
  getCompanyDetails: () => void,
  refetchSubscriptions: (data: {}) => void,
  step: number,
  subscriptions: {}[],
  total: number,
  pagination: { page: number },
  sort: { id: string, firstDirection: boolean },
  accountVerified: boolean,
  accountSubscribed: boolean,
}

interface State {
  activeTab: number,
  searchValue: string,
  requestModalOpen: boolean,
  makePaymentModalOpen: boolean,
  cancelModalOpen: boolean,
  paymentId: string,
  cancelPaymentId: string,
  cancelPaymentStatus: string,
  nextBilledDate: string,
}

class Container extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeTab: 0,
      searchValue: '',
      requestModalOpen: false,
      cancelModalOpen: false,
      nextBilledDate: '',
      makePaymentModalOpen: Boolean(props.match.params.id),
      paymentId: '',
      cancelPaymentId: '',
      cancelPaymentStatus: '',
    };
  }

  componentDidMount() {
    const {
      userRole,
      step,
      history,
      getSubscriptions,
      getCompanyDetails,
    } = this.props;

    if (userRole === ROLES.ACCOUNT_OWNER && step < 5) {
      history.push('/onBoarding');
    }

    getCompanyDetails();
    getSubscriptions();
  }

  get actions() {
    const { history, userRole } = this.props;
    if (userRole === ROLES.CLIENT) {
      return [
        {
          label: 'View Subscription',
          onClick: (row: any) => history.push(`/transactions/single-payments/subscription-details/${row.id}`),
        },
        {
          label: 'Activate',
          onClick: (row: any) => this.setState({ makePaymentModalOpen: true, paymentId: row.id }),
          getIsHidden: (row: any) => row.status !== 'inactive',
        },
        {
          label: 'Cancel Subscription',
          onClick: (row: any) => this.setState({
            cancelModalOpen: true,
            cancelPaymentId: row.id,
            nextBilledDate: row.next_bill_date,
            cancelPaymentStatus: row.status,
          }),
          getIsHidden: (row: any) => row.status !== 'inactive'
            && row.status !== 'active'
            && row.status !== 'past_due'
            && row.status !== 'incomplete'
            && row.status !== 'unpaid',
        }
      ];
    }
    return [
      {
        label: 'View Subscription',
        onClick: (row: any) => history.push(`/billing/single-payments/subscription-details/${row.id}`),
      },
      {
        label: 'Cancel Subscription',
        onClick: (row: any) => this.setState({
          cancelModalOpen: true,
          cancelPaymentId: row.id,
          nextBilledDate: row.next_bill_date,
          cancelPaymentStatus: row.status,
        }),
        getIsHidden: (row: any) => row.status !== 'inactive'
          && row.status !== 'active'
          && row.status !== 'past_due'
          && row.status !== 'incomplete'
          && row.status !== 'unpaid',
      }
    ];
  }

  get status() {
    let status = null;
    const { activeTab } = this.state;
    switch (tabs[activeTab].label) {
      case 'Active':
        status = 'active';
        break;
      case 'Inactive':
        status = 'inactive';
        break;
      case 'Completed':
        status = 'completed';
        break;
      case 'Canceled':
        status = 'canceled';
        break;
      case 'Past due':
        status = 'past_due';
        break;
      case 'Unpaid':
        status = 'unpaid';
        break;
      case 'Incomplete':
        status = 'incomplete';
        break;
      default:
        break;
    }
    return status;
  }

  handleTabChange = (value: number) => {
    const { refetchSubscriptions } = this.props;
    this.setState({ activeTab: value }, () => {
      const { activeTab, searchValue } = this.state;
      if (activeTab) {
        return refetchSubscriptions({
          pagination: {
            page: 1
          },
          sort: {
            id: null,
            firstDirection: false,
          },
          search: searchValue,
          status: this.status,
        });
      }
      return refetchSubscriptions({
        pagination: {
          page: 1
        },
        sort: {
          id: null,
          firstDirection: false,
        },
        search: searchValue,
      });
    });
  };

  handleSearchSubmit = (value: string) => {
    const { pagination, sort, refetchSubscriptions } = this.props;
    refetchSubscriptions({
      search: value,
      pagination,
      sort,
      status: this.status,
    });
  };

  handleSearchChange = (value: string) => this.setState({ searchValue: value });

  toggleRequestModal = () => this.setState((prevState) => ({ requestModalOpen: !prevState.requestModalOpen }));

  toggleCancelModal = () => this.setState((prevState) => ({ cancelModalOpen: !prevState.cancelModalOpen }));

  toggleMakePaymentModal = () => this.setState((prevState) => ({
    makePaymentModalOpen: !prevState.makePaymentModalOpen
  }));

  handleCancelInvoice = () => {
    const { pagination, sort, refetchSubscriptions } = this.props;
    const { searchValue } = this.state;
    refetchSubscriptions({
      search: searchValue,
      pagination,
      sort,
      status: this.status,
    });
  };

  handleRefetchData = (data: any) => {
    const { refetchSubscriptions } = this.props;
    const { activeTab, searchValue } = this.state;

    if (!activeTab) {
      return refetchSubscriptions({
        ...data,
        search: searchValue,
      });
    }
    return refetchSubscriptions({
      ...data,
      status: this.status,
      search: searchValue,
    });
  };

  render() {
    const {
      props: {
        userRole,
        total,
        subscriptions,
        pagination,
        sort,
        accountVerified,
        accountSubscribed,
      },
      state: {
        activeTab,
        searchValue,
        requestModalOpen,
        makePaymentModalOpen,
        paymentId,
        cancelPaymentId,
        cancelModalOpen,
        nextBilledDate,
        cancelPaymentStatus,
      }
    } = this;

    return (
      <Provider
        value={{
          accountVerified,
          accountSubscribed,
          activeTab,
          onTabChange: this.handleTabChange,
          userRole,
        }}
      >
        <Content
          accountSubscribed={accountSubscribed}
          accountVerified={accountVerified}
          pagination={pagination}
          actions={this.actions}
          sort={sort}
          refetchData={this.handleRefetchData}
          subscriptions={subscriptions}
          total={total}
          userRole={userRole}
          searchValue={searchValue}
          onSearchSubmit={this.handleSearchSubmit}
          onSearchChange={this.handleSearchChange}
          onRequestPayment={this.toggleRequestModal}
        />
        {userRole !== ROLES.CLIENT && (
          <RequestPaymentModal
            open={requestModalOpen}
            close={this.toggleRequestModal}
          />
        )}
        <CancelInvoiceModal
          info={subscriptions.find((item: any) => item.id === cancelPaymentId)}
          status={cancelPaymentStatus}
          userRole={userRole}
          isSubscription
          open={cancelModalOpen}
          close={this.toggleCancelModal}
          id={cancelPaymentId}
          callback={this.handleCancelInvoice}
          nextBilledDate={nextBilledDate}
        />
        {userRole === ROLES.CLIENT && (
          <MakePaymentModal
            isSubscription
            open={makePaymentModalOpen}
            close={this.toggleMakePaymentModal}
            paymentId={paymentId}
          />
        )}
      </Provider>
    );
  }
}

export default Container;
