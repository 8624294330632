import { connect } from 'react-redux';

import Container from './Container';
import {
  fetchInitData,
  getCompanyDetails,
  refetchBillingHistory,
  getBankingDetails,
  getAppSubscription,
} from '../../redux/actions/Settings';
import { createCard } from '../../redux/actions/Wallet';

const mapDispatchToProps = {
  fetchInitData,
  getCompanyDetails,
  refetchBillingHistory,
  createCard,
  getBankingDetails,
  getAppSubscription,
};

const mapStateToProps = (state: any) => ({
  step: state.onBoarding.step,
  userRole: state.auth.userRole,
  bankingList: state.settings.bankingList,
  billingList: state.settings.billingList,
  appSubscription: state.settings.appSubscription,
  billingInvoices: state.settings.billingInvoices,
  billingInvoicesTotal: state.settings.billingInvoicesTotal,
  billingInvoicesPagination: state.settings.billingInvoicesPagination,
  billingInvoicesSort: state.settings.billingInvoicesSort,
  userId: state.auth.userData.id,
  companyInfo: state.settings.companyInfo,
  userData: state.auth.userData,
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
