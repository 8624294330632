import {
  put, takeLatest, call, select
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { transfersError, refetchDataSuccess } from '../../actions/TransferDetails';
import { TRANSFER_DETAILS_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker({ payload }: any): SagaIterator<void> {
  try {
    const { router } = yield select();
    const id = router.location.pathname.slice(16);
    const { data } = yield call(Api.payments.refetchCompletedTransfersInvoices, { id, data: payload });
    yield put(refetchDataSuccess({
      ...payload,
      data,
    }));
  } catch (e) {
    yield put(transfersError(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(TRANSFER_DETAILS_TYPES.REFETCH_DATA, worker);
}
