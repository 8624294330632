import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { MoonLoader } from 'react-spinners';

import routes from '../routes';
import PublicRoute from './PublicRoute';
import RestrictedRoute from './RestrictedRoute';
import { getCookie } from '../helpers';
import { getUser as getUserAction } from "../redux/actions/Auth";
import AccountDeletedScreen from "../screens/AccountDeletedScreen";

const isUserInitialyLogged = Boolean(getCookie('csrftoken'));

const Layout = ({ initLoading, userData, getUser, isUserNotFound }) => {
  const [isAuthorized, setAuthorizated] = useState(Boolean(getCookie('csrftoken')));
  const [userFetched, setUserFetched] = useState(false);
  let timer = null;
  const cookieHandler = () => {
    if (getCookie('csrftoken')) {
      setAuthorizated(true);
      clearInterval(timer);
    }
  };
  timer = setInterval(cookieHandler, 100);

  useEffect(() => {
    if (isAuthorized && Object.keys(userData).length === 0 && !isUserInitialyLogged) {
      getUser();
    }
  }, [isAuthorized]);

  if (!initLoading && isAuthorized && isUserNotFound) {
    return <AccountDeletedScreen />
  }

  if (initLoading || isAuthorized && Object.keys(userData).length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh" width="100%">
        <MoonLoader
          size={100}
          color="#011638"
        />
      </Box>
    );
  }

  const role = userData.groups ? userData.groups[0].name : '';

  return (
    <Switch>
      {routes.map((route) => {
        if (route.private) {
          return (
            <RestrictedRoute
              onBoardingStatus={userData.onboarding_status ? userData.onboarding_status : 0}
              isAuthorized={isAuthorized}
              key={route.path}
              userRole={role}
              {...route}
            />
          );
        }
        return (
          <PublicRoute
            isAuthorized={isAuthorized}
            key={route.path}
            {...route}
          />
        );
      })}
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  initLoading: state.general.initLoading,
  userData: state.auth.userData,
  isUserNotFound: Boolean(state.auth.error === 'Request failed with status code 404')
});

export default connect(mapStateToProps, { getUser: getUserAction })(Layout);
