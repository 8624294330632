import { connect } from 'react-redux';

import Container from './Container';
import { getInfo, getPayments, refetchPayments } from '../../redux/actions/SubscriptionDetails';

const mapStateToProps = (state: any) => ({
  userRole: state.auth.userRole,
  subscriptionInfo: state.subscriptionDetails.subscriptionInfo,
  payments: state.subscriptionDetails.payments,
  pagination: state.subscriptionDetails.pagination,
  sort: state.subscriptionDetails.sort,
  total: state.subscriptionDetails.total,
});

const mapDispatchToProps = {
  getInfo,
  getPayments,
  refetchPayments
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
