import {
  put, takeLatest, call, select
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { onBoardingError, getAccountInfoSuccess } from '../../actions/onBoaring';
import { ONBOARDING_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker(): SagaIterator<void> {
  try {
    const store = yield select();
    const { data } = yield call(Api.userProfiles.getAccountInfo, store.auth.userData.id);
    yield put(getAccountInfoSuccess(data));
  } catch (e) {
    yield put(onBoardingError(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(ONBOARDING_TYPES.GET_ACCOUNT_INFO, worker);
}
