import React from 'react';
import { Formik, Form, Field } from 'formik';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import moment from 'moment';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';

import FormikSelect from '../../../Controls/FormikSelect';
import FormikDatePicker from '../../../Controls/FormikDatePicker';
import Button from '../../../Button';
import { withParentData } from '../../Context';
import messages from '../../messages';

const validationSchema = Yup.object().shape({
  dueDate: Yup.string()
    .required('Please fill in due date'),
});

const periods = [
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' },
  { label: '10', value: '10' },
  { label: '11', value: '11' },
  { label: '12', value: '12' },
  { label: '-', value: ' ' },
];

const quarterlyPeriods = [
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '-', value: ' ' },
];

const Switch = withStyles({
  root: {
    textTransform: 'uppercase',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  }
})(Box);

interface Props {
  isSinglePayment: boolean,
  setPaymentType: (state: boolean) => void,
  clients: {
    label: string,
    value: number,
  }[],
  submitRequestTypeForm: (values: {}) => void,
  clientId?: string | undefined,
  selectedClient: number | null,
  selectedPeriod: string,
  selectedDueDate: string | null,
}

const RequestTypeForm: React.FC<Props> = ({
  isSinglePayment,
  setPaymentType,
  clients,
  submitRequestTypeForm: submit,
  clientId,
  selectedClient,
  selectedDueDate,
  selectedPeriod,
}) => {
  const client = selectedClient || clientId || (clients[0] && clients[0].value) || '';
  const initialSingleValues = {
    client,
    dueDate: selectedDueDate || '',
  };

  const initialSubscriptionValues = {
    client,
    frequency: 'monthly',
    period: selectedPeriod,
  };

  return (
    <Formik
      initialValues={isSinglePayment ? initialSingleValues : initialSubscriptionValues}
      onSubmit={submit}
      validationSchema={isSinglePayment && validationSchema}
      enableReinitialize
    >
      {({
        isValid, dirty, values, setFieldValue
      }) => (
        <Form>
          <Field
            name="client"
            component={FormikSelect}
            label="Clients"
            options={clients}
          />
          <Box mt={3}>
            <Grid container>
              <Grid item xs={6}>
                <Switch
                  borderRadius="4px 0px 0px 4px"
                  bgcolor={isSinglePayment ? '#3772FF' : 'white'}
                  color={!isSinglePayment ? '#6D6E71' : 'white'}
                  border={isSinglePayment ? 'none' : '1px solid #BCBEC0'}
                  onClick={() => setPaymentType(true)}
                >
                  <FormattedMessage {...messages.singlePaymentType} />
                </Switch>
              </Grid>
              <Grid item xs={6}>
                <Switch
                  bgcolor={!isSinglePayment ? '#3772FF' : 'white'}
                  color={isSinglePayment ? '#6D6E71' : 'white'}
                  border={!isSinglePayment ? 'none' : '1px solid #BCBEC0'}
                  borderRadius="0px 4px 4px 0px"
                  onClick={() => setPaymentType(false)}
                >
                  <FormattedMessage {...messages.subscriptionPaymentType} />
                </Switch>
              </Grid>
            </Grid>
          </Box>
          {isSinglePayment ? (
            <Box mt={3}>
              <Field
                component={FormikDatePicker}
                label="Due date"
                name="dueDate"
                minDate={moment()}
                maxDate={moment().set({ year: moment().year() + 4 })}
              />
            </Box>
          ) : (
            <Box>
              <Box mt={3}>
                <Field
                  component={FormikSelect}
                  label="Frequency"
                  name="frequency"
                  onChange={() => setFieldValue('period', '')}
                  options={[{ value: 'monthly', label: 'Monthly' }, { value: 'quarterly', label: 'Quarterly' }]}
                />
              </Box>
              <Box mt={3}>
                <Field
                  component={FormikSelect}
                  label="Billing periods"
                  name="period"
                  // @ts-ignore
                  options={values.frequency === 'monthly' ? periods : quarterlyPeriods}
                />
              </Box>
            </Box>
          )}
          <Box mt={3} display="flex" justifyContent="flex-end">
            <Button
              type="submit"
              variant="contained"
              disabled={isSinglePayment && (selectedClient && selectedDueDate ? !isValid : (!dirty || !isValid))}
            >
              <FormattedMessage {...messages.continueButton} />
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default withParentData(RequestTypeForm);
