import React, { useMemo } from 'react';
import {
  useTable,
  Row,
  Column,
  Cell,
  HeaderGroup,
} from 'react-table';
import MuiTable from '@material-ui/core/Table';
import Box from '@material-ui/core/Box';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Pagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import withStyles from '@material-ui/core/styles/withStyles';

import { ArrowIcon, ArrowDownIcon } from '../../metadata/icons';
import MenuActions from './MenuActions';
import Search from '../Search';

import styles from './DataTable.module.scss';

const TablePagination = withStyles({
  root: {
    '&  *': {
      fontSize: 16,
      color: '#6D6E71'
    },
  },
  selectRoot: {
    display: 'none',
  },
  select: {
    padding: '0 !important'
  },
  actions: {
    marginLeft: 28,
  },
})(Pagination);

interface Props {
  data: {}[],
  columns: Column<{}>[],
  withPagination?: boolean,
  actions?: {
    label?: string,
    getLabel?: (row: {}) => any,
    onClick?: (row: {}) => void,
    getIsDisabled?: (row: {}) => boolean,
    getIsHidden?: (row: {}) => boolean,
  }[],
  noItemsMessage?: React.ReactNode,
  sort?: {
    id: string,
    firstDirection: boolean,
  },
  pagination?: {
    page: number
  },
  countTotal?: number,
  refetchData?: (data: any) => void,
  withSearch?: boolean,
  searchValue?: string,
  errorRowMessage?: string,
  onSearchChange?:(value: string) => void,
  onSearchSubmit?: (value: string) => void,
  getRowError?: (row: {}) => boolean,
}

function Table({
  columns,
  data,
  withPagination,
  sort,
  actions = [],
  noItemsMessage,
  countTotal,
  refetchData,
  pagination = {
    page: 0,
  },
  withSearch,
  onSearchChange = () => {},
  onSearchSubmit = () => {},
  searchValue = '',
  getRowError = () => false,
  errorRowMessage,
}: Props) {
  const mutatedData = useMemo(() => {
    let mutData = [...data];
    if (actions.length) {
      mutData = mutData.map(item => {
        const mutatedItem = { ...item };
        // @ts-ignore
        mutatedItem.actions = <MenuActions options={actions} rowItem={item} />;
        return mutatedItem;
      });
    }
    return mutData;
  }, [data, actions]);
  const mutatedColumns = useMemo(() => {
    const mutColumns = [...columns];
    if (actions.length) {
      mutColumns.push({
        accessor: 'actions',
        Header: 'Actions',
        // @ts-ignore
        sortable: false,
        maxWidth: 120,
      });
    }
    return mutColumns;
  }, [columns]);

  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      columns: mutatedColumns,
      data: mutatedData,
    },
  );

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    if (refetchData) {
      refetchData({
        sort,
        pagination: {
          page: page + 1
        }
      });
    }
  };

  const handleChangeSort = (property: string) => {
    if (!sort || !refetchData) return null;
    const newSort = {
      id: property,
      firstDirection: true
    };

    if (sort.id === property && sort.firstDirection) {
      newSort.firstDirection = false;
    }

    return refetchData({ sort: newSort, pagination });
  };

  return (
    <div className={styles.container}>
      {withSearch && (
        <Box mb={1} display="flex" justifyContent="flex-end">
          <Box width="30%">
            <Search value={searchValue} onChange={onSearchChange} onSubmit={onSearchSubmit} />
          </Box>
        </Box>
      )}
      <MuiTable>
        <TableHead>
          {headerGroups.map((headerGroup: HeaderGroup<{}>) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(({
                getHeaderProps, render, id
              }) => (
                <TableCell {...getHeaderProps()} onClick={() => handleChangeSort(id)}>
                  <div>
                    {render('Header')}
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {sort?.id === id && id !== 'actions'
                      ? sort?.firstDirection
                        ? <ArrowIcon />
                        : <span style={{ transform: 'rotate(180deg)' }}><ArrowIcon /></span>
                      : ''}
                    {sort?.id !== id && id !== 'actions' && <div className="hover-sort"><ArrowIcon /></div>}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row: Row) => {
            prepareRow(row);
            return (
              <>
                <TableRow {...row.getRowProps()} className={getRowError(row) ? styles.test : ''}>
                  {row.cells.map((cell: Cell<{}, any>) => (
                    <TableCell {...cell.getCellProps()}>
                      {
                        // @ts-ignore
                        cell.isAggregated
                          ? cell.render('Aggregated')
                          : cell.render('Cell')
                      }
                    </TableCell>
                  ))}
                </TableRow>
                {getRowError(row) && (
                  <TableRow className={getRowError(row) ? styles.errorMessage : ''}>
                    <TableCell colSpan={5}>{errorRowMessage}</TableCell>
                  </TableRow>
                )}
              </>
            );
          })}
          {rows.length === 0 && noItemsMessage && noItemsMessage}
        </TableBody>
      </MuiTable>
      {withPagination && (
        <TablePagination
          labelRowsPerPage={null}
          // @ts-ignore
          component="div"
          count={countTotal || 0}
          rowsPerPage={10}
          page={pagination.page - 1}
          labelDisplayedRows={({ page, count }) => (`${countTotal === 0 ? 0 : (page + 1)} of ${Math.ceil(count / 10)}`)}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={() => {}}
          SelectProps={{
            IconComponent: ArrowDownIcon
          }}
        />
      )}
    </div>
  );
}

export default Table;
