import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import { CurrencyProvider, Link } from '../../components';
import messages from './messages';
import { ROLES } from '../../constants';

interface Props {
  invoiceInfo: any,
  userRole: string,
}

const Invoice: React.FC<Props> = ({ invoiceInfo, userRole }) => (
  <div style={{
    padding: '24px',
    background: 'white',
    boxShadow: '4px 10px 20px rgba(55, 114, 255, 0.1)'
  }}
  >
    <div style={{ padding: '40px', border: '1px solid #BCBEC0' }}>
      <div
        style={{
          borderBottom: '1px solid #BCBEC0',
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: '24px'
        }}
      >
        <div
          style={{
            fontWeight: 500,
            fontSize: '14px',
            color: '#3772FF',
            textTransform: 'uppercase',
            lineHeight: '24px'
          }}
        >
          {invoiceInfo.status?.replace(/_/g, ' ')}
        </div>
        <div style={{ textAlign: 'right' }}>
          <div style={{
            color: '#011638',
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 500,
          }}
          >
            {invoiceInfo.organisation}
          </div>
          {userRole === ROLES.ADVISOR || userRole === ROLES.CLIENT ? (
            <div style={{
              color: '#011638',
              fontSize: '16px',
              lineHeight: '24px',
              fontWeight: 500,
            }}
            >
              {invoiceInfo.advisor_name}
            </div>
          ) : (
            <Link href={`/users/advisors/${invoiceInfo.advisor}`}>{invoiceInfo.advisor_name}</Link>
          )}
          <div style={{ color: '#6D6E71', fontSize: '14px' }}>{invoiceInfo.advisor_email}</div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '40px' }}>
        <div style={{
          color: '#011638',
          fontWeight: 500,
          fontSize: '24px',
          lineHeight: '32px',
        }}
        >
          <FormattedMessage {...messages.invoice} />
        </div>
        <div style={{
          color: '#011638',
          fontWeight: 500,
          fontSize: '24px',
          lineHeight: '32px',
        }}
        >
          <CurrencyProvider value={invoiceInfo.amount / 100} />
        </div>
      </div>
      <div
        style={{
          color: '#3772FF',
          lineHeight: '17px',
          fontSize: '14px',
          fontWeight: 500,
          marginTop: '24px',
          marginBottom: '8px',
        }}
      >
        <FormattedMessage {...messages.billedTo} />
      </div>
      <div style={{
        color: '#011638',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 500,
        marginBottom: '16px'
      }}
      >
        {invoiceInfo.client_name}
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div
          style={{
            color: '#6D6E71',
            fontSize: 14,
            lineHeight: '22px',
            marginRight: 24,
            marginBottom: 16,
          }}
        >
          <span><FormattedMessage {...messages.invoiceId} /></span>
          <span style={{ color: '#011638' }}>
            <span>#</span>
            {invoiceInfo.number}
          </span>
        </div>
        <div
          style={{
            color: '#6D6E71',
            fontSize: 14,
            lineHeight: '22px',
            marginRight: 24,
            marginBottom: 16,
          }}
        >
          <span><FormattedMessage {...messages.billed} /></span>
          <span style={{ color: '#011638' }}>
            {moment(invoiceInfo.date_created).format('MM/DD/YYYY')}
          </span>
        </div>
        <div style={{
          color: '#6D6E71',
          fontSize: 14,
          lineHeight: '22px',
          marginRight: 24,
          marginBottom: 16,
        }}
        >
          <span><FormattedMessage {...messages.dueDate} /></span>
          <span style={{ color: '#011638' }}>
            {moment(invoiceInfo.due_date).format('MM/DD/YYYY')}
          </span>
        </div>
        {invoiceInfo.date_canceled && (
          <div style={{
            color: '#6D6E71',
            fontSize: 14,
            lineHeight: '22px',
            marginRight: 24,
            marginBottom: 16,
          }}
          >
            <span>Canceled: </span>
            <span style={{ color: '#011638' }}>
              {moment(invoiceInfo.date_canceled).format('MM/DD/YYYY')}
            </span>
          </div>
        )}
        {invoiceInfo.date_paid && (
          <div style={{
            color: '#6D6E71',
            fontSize: 14,
            lineHeight: '22px',
            marginRight: 24,
            marginBottom: 16,
          }}
          >
            <span>
              <FormattedMessage {...messages.paid} />
              <span>: </span>
            </span>
            <span style={{ color: '#011638' }}>
              {moment(invoiceInfo.date_paid).format('MM/DD/YYYY')}
            </span>
          </div>
        )}
        {invoiceInfo.date_refunded && (
          <div style={{
            color: '#6D6E71',
            fontSize: 14,
            lineHeight: '22px',
            marginRight: 24,
            marginBottom: 16,
          }}
          >
            <span>
              <span>Refund issued: </span>
            </span>
            <span style={{ color: '#011638' }}>
              {moment(invoiceInfo.date_paid).format('MM/DD/YYYY')}
            </span>
          </div>
        )}
        {Boolean(invoiceInfo.refund_amount) && (
          <div style={{
            color: '#6D6E71',
            fontSize: 14,
            lineHeight: '22px',
            marginRight: 24,
            marginBottom: 16,
          }}
          >
            <span>
              <span>Refund amount: </span>
            </span>
            <span style={{ color: '#011638' }}><CurrencyProvider value={invoiceInfo.refund_amount / 100} /></span>
          </div>
        )}
      </div>
      <div style={{ marginTop: '40px', paddingTop: '24px', borderTop: '1px solid #BCBEC0' }}>
        <div style={{
          color: '#011638',
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: 500,
        }}
        >
          <FormattedMessage {...messages.description} />
        </div>
        <div
          style={{
            color: '#6D6E71',
            fontSize: '16px',
            lineHeight: '24px',
            background: '#F5FAFE',
            padding: '8px',
            paddingRight: '0',
          }}
        >
          {invoiceInfo.description}
        </div>

        <div style={{
          color: '#011638',
          fontSize: '16px',
          lineHeight: '24px',
          marginTop: '24px',
        }}
        >
          <FormattedMessage {...messages.amount} />
        </div>
        <div style={{
          color: '#6D6E71',
          fontSize: '16px',
          lineHeight: '24px',
          background: '#F5FAFE',
          padding: '8px',
          paddingRight: '0',
        }}
        >
          $
          {invoiceInfo.amount / 100}
        </div>
        {invoiceInfo.refund_description && (
          <div>
            <div style={{
              color: '#011638',
              fontSize: '16px',
              lineHeight: '24px',
              marginTop: '24px',
            }}
            >
              Refund Comments
            </div>
            <div style={{
              color: '#6D6E71',
              fontSize: '16px',
              lineHeight: '24px',
              background: '#F5FAFE',
              padding: '8px',
              paddingRight: '0',
            }}
            >
              {invoiceInfo.refund_description}
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          marginTop: '24px',
          paddingTop: '24px',
          display: 'flex',
          justifyContent: 'flex-end',
          borderTop: '1px solid #BCBEC0',
        }}
      >
        <div style={{
          color: '#011638',
          fontSize: '16px',
          lineHeight: '24px',
          marginTop: '24px',
          marginRight: '24px',
        }}
        >
          <FormattedMessage {...messages.totalDue} />
        </div>
        <div style={{
          color: '#011638',
          fontSize: '16px',
          lineHeight: '24px',
          marginTop: '24px',
        }}
        >
          $
          {invoiceInfo.amount / 100}
        </div>
      </div>
    </div>
  </div>
);

export default Invoice;
