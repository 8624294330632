import { connect } from 'react-redux';

import { setUpPassword, verifyEmail } from '../../redux/actions/onBoaring';

import Container from './Container';

const mapStateToProps = ({ onBoarding }: any) => ({
  verifiedEmailData: onBoarding.verifiedEmailData,
  loading: onBoarding.loading,
  linkExpired: onBoarding.linkExpired
});

const mapDispatchToProps = {
  setUpPassword,
  verifyEmail
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
