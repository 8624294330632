import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { connect } from 'react-redux';

import moment from 'moment';
import Modal from '../../Modal';
import H2 from '../../H2';
import H3Dark from '../../H3Dark';
import Button from '../../Button';
import Api from '../../../api';
import { chaliceFee } from '../../../constants';

interface Props {
  open: boolean,
  close: () => void,
  callback: () => void,
  userId: string,
}

const NewAppSubscriptionModal: React.FC<Props> = ({
  open, close, userId, callback
}) => {
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [card, setCard] = useState({ stripe_id: '', is_default: false });

  useEffect(() => {
    Api.userProfiles.getBilling()
      .then(({ data }) => {
        setCard(data.find((item: any) => item.is_default));
      });
  }, []);

  const handleClose = () => {
    setDone(false);
    close();
  };

  const createSubscription = () => {
    setLoading(true);
    Api.subscriptions.createSubscription({
      frequency: 'monthly',
      stripe_id: card.stripe_id,
      client: userId,
      advisor: userId,
      type: 'platform',
      amount: chaliceFee * 100,
      date_created: moment().format(),
    }).then(() => {
      setLoading(false);
      setDone(true);
      callback();
    });
  };

  return (
    <Modal
      open={open}
      handleClose={handleClose}
    >
      {done ? (
        <Box p={3} textAlign="center">
          <H2 mb={3}>Thank you for your payment!</H2>
          <H3Dark mb={3}>Your ChalicePay subscription is activated.</H3Dark>
          <Button onClick={handleClose} variant="contained">ok</Button>
        </Box>
      ) : (
        <Box p={3} maxWidth={500}>
          <H2 mb={3}>New Subscription</H2>
          <H3Dark mb={3}>
            Our monthly fixed fee is $50.
            Please confirm your first charge to activate your subscription with ChalicePay.
          </H3Dark>
          <Box display="flex" justifyContent="space-between">
            <Button onClick={handleClose}>cancel</Button>
            <Button variant="contained" onClick={createSubscription} loading={loading}>Confirm</Button>
          </Box>
        </Box>
      )}
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  userId: state.auth.userData.id,
});

export default connect(mapStateToProps)(NewAppSubscriptionModal);
