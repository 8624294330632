import en from './lang/en_US.json';

const messages = {
  en
};

export default (locale: 'en') => ({
  locale,
  messages: messages[locale]
});
