import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { MoonLoader } from 'react-spinners';
import Box from '@material-ui/core/Box';

import Content from './Content';
import { Provider } from './Context';
import {
  CancelInvoiceModal,
  DeleteClientModal,
  H2,
  ChangeAdvisorModal,
} from '../../components';

interface MatchParams {
  id: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  fetchInitData: (id: string) => void,
  sendLink: (callback: any) => void,
  clientInfo: {
    name: string,
    email: string,
    is_active: boolean,
    added_on: string,
    id: string,
    invite_link: string,
    advisor: string,
    total_revenue: number,
    advisor_name: number,
  },
  refetchSinglePayments: (data: {}) => void,
  refetchSubscription: (data: {}) => void,
  getCompanyDetails: () => void,
  singlePayments: { id: string }[],
  singlePaymentsTotal: number,
  singlePaymentsPagination: {},
  singlePaymentsSort: {},
  subscriptions: { id: string }[],
  subscriptionsTotal: number,
  subscriptionsPagination: {},
  subscriptionsSort: {},
  isClientDeleted: boolean,
  accountVerified: boolean,
  accountSubscribed: boolean,
  loading: boolean,
  userRole: string,
}

interface State {
  activeTab: number,
  modalOpen: boolean,
  cancelInvoiceModalOpen: boolean,
  requestModalOpen: boolean,
  deleteClientModalOpen: boolean,
  changeAdvisorModalOpen: boolean,
  cancelInvoiceId: string,
  cancelInvoiceStatus: string,
  nextBilledDate: string | null,
}

class Container extends Component<Props, State> {
  state = {
    activeTab: 0,
    modalOpen: false,
    cancelInvoiceModalOpen: false,
    requestModalOpen: false,
    deleteClientModalOpen: false,
    changeAdvisorModalOpen: false,
    cancelInvoiceId: '',
    nextBilledDate: '',
    cancelInvoiceStatus: '',
  };

  componentDidMount() {
    const {
      fetchInitData,
      match,
      getCompanyDetails,
    } = this.props;

    fetchInitData(match.params.id);
    getCompanyDetails();
  }

  get singlePaymentActions() {
    const { history } = this.props;

    return [
      {
        label: 'View invoice',
        onClick: (row: any) => history.push(`/billing/single-payments/invoice/${row.id}`),
      },
      {
        label: 'View details',
        onClick: (row: any) => history.push(`/billing/single-payments/invoice-details/${row.id}`),
      },
      {
        label: 'Cancel invoice',
        onClick: (row: any) => this.setState({ cancelInvoiceId: row.id, cancelInvoiceModalOpen: true }),
        getIsHidden: (row: any) => row.status !== 'unpaid' && row.status !== 'past_due',
      }
    ];
  }

  get subscriptionActions() {
    const { history } = this.props;

    return [
      {
        label: 'View Subscription',
        onClick: (row: any) => history.push(`/billing/single-payments/subscription-details/${row.id}`),
      },
      {
        label: 'Cancel Subscription',
        onClick: (row: any) => this.setState({
          cancelInvoiceId: row.id,
          cancelInvoiceModalOpen: true,
          nextBilledDate: row.next_bill_date,
          cancelInvoiceStatus: row.status,
        }),
        getIsHidden: (row: any) => row.status !== 'inactive' && row.status !== 'active' && row.status !== 'failed',
      }
    ];
  }

  handleSendLink = () => {
    const { sendLink } = this.props;
    sendLink(this.onLinkSuccessfullySend);
  };

  onLinkSuccessfullySend = () => {
    this.setState({ modalOpen: true }, () => {
      setTimeout(() => this.setState({ modalOpen: false }), 1000);
    });
  };

  handleTabChange = (value: number) => this.setState({ activeTab: value });

  toggleRequestModal = () => this.setState((prevState) => ({ requestModalOpen: !prevState.requestModalOpen }));

  toggleCancelInvoiceModalOpen = () => this.setState((prevState) => ({
    cancelInvoiceModalOpen: !prevState.cancelInvoiceModalOpen
  }));

  handleRefetchSinglePaymentData = (data: any) => {
    const { refetchSinglePayments } = this.props;
    refetchSinglePayments(data);
  };

  handleRefetchSubscriptionData = (data: any) => {
    const { refetchSubscription } = this.props;
    refetchSubscription(data);
  };

  handleCancelPayment = () => {
    const { refetchSinglePayments, singlePaymentsSort, singlePaymentsPagination } = this.props;
    refetchSinglePayments({
      sort: singlePaymentsSort,
      pagination: singlePaymentsPagination
    });
  };

  toggleDeleteClientModal = () => this.setState((prevState) => ({
    deleteClientModalOpen: !prevState.deleteClientModalOpen
  }));

  toggleChangeAdvisorModal = () => this.setState((prevState) => ({
    changeAdvisorModalOpen: !prevState.changeAdvisorModalOpen
  }));

  render() {
    const {
      props: {
        clientInfo,
        singlePayments,
        singlePaymentsPagination,
        singlePaymentsSort,
        singlePaymentsTotal,
        subscriptions,
        subscriptionsPagination,
        subscriptionsSort,
        subscriptionsTotal,
        accountSubscribed,
        accountVerified,
        history,
        loading,
        isClientDeleted,
        userRole,
        match,
        fetchInitData,
      },
      state: {
        activeTab,
        modalOpen,
        requestModalOpen,
        cancelInvoiceModalOpen,
        cancelInvoiceId,
        nextBilledDate,
        cancelInvoiceStatus,
        deleteClientModalOpen,
        changeAdvisorModalOpen,
      }
    } = this;

    if (loading || isClientDeleted) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          {loading ? (
            <MoonLoader
              size={100}
              color="#011638"
            />
          ) : <H2>This client is deleted</H2>}
        </Box>
      );
    }

    return (
      <Provider
        value={{
          activeTab,
          link: clientInfo.invite_link,
          clientInfo,
          singlePayments,
          singlePaymentsPagination,
          singlePaymentsSort,
          singlePaymentsTotal,
          subscriptionsPagination,
          subscriptionsSort,
          subscriptions,
          subscriptionsTotal,
          accountSubscribed,
          accountVerified,
          onTabChange: this.handleTabChange,
          onLinkClick: this.handleSendLink,
          onPaymentRequest: this.toggleRequestModal,
          refetchSinglePaymentData: this.handleRefetchSinglePaymentData,
          refetchSubscriptionData: this.handleRefetchSubscriptionData,
          singlePaymentActions: this.singlePaymentActions,
          subscriptionActions: this.subscriptionActions,
        }}
      >
        <Content
          userRole={userRole}
          accountSubscribed={accountSubscribed}
          accountVerified={accountVerified}
          toggleRequestModal={this.toggleRequestModal}
          modalOpen={modalOpen}
          clientInfo={clientInfo}
          requestModalOpen={requestModalOpen}
          toggleDeleteClientModal={this.toggleDeleteClientModal}
          toggleChangeAdvisorModal={this.toggleChangeAdvisorModal}
        />
        <CancelInvoiceModal
          info={
            activeTab
              ? subscriptions.find((item) => item.id === cancelInvoiceId)
              : singlePayments.find((item) => item.id === cancelInvoiceId)
          }
          status={cancelInvoiceStatus}
          isSubscription={Boolean(activeTab)}
          userRole="advisor"
          open={cancelInvoiceModalOpen}
          close={this.toggleCancelInvoiceModalOpen}
          id={cancelInvoiceId}
          callback={this.handleCancelPayment}
          nextBilledDate={nextBilledDate}
        />
        <DeleteClientModal
          open={deleteClientModalOpen}
          close={this.toggleDeleteClientModal}
          id={clientInfo.id}
          callback={() => history.push('/users/clients')}
        />
        <ChangeAdvisorModal
          clientId={clientInfo.id}
          open={changeAdvisorModalOpen}
          close={this.toggleChangeAdvisorModal}
          id={clientInfo.advisor}
          callback={() => fetchInitData(match.params.id)}
        />
      </Provider>
    );
  }
}

export default Container;
