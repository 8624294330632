import React from 'react';
import moment from 'moment';

import { CurrencyProvider, Link } from '../../components';
import { ROLES } from '../../constants';

export const clientColumns = [
  {
    Header: 'Status',
    // @ts-ignore
    accessor: (row: any) => <span style={{ textTransform: 'uppercase' }}>{row.status.replace(/_/g, ' ')}</span>,
    id: 'status',
  },
  {
    Header: 'Advisor name',
    accessor: 'advisor_name',
  },
  {
    Header: 'Invoice ID',
    accessor: (row: any) => (
      <Link href={`/billing/single-payments/invoice/${row.id}`}>
        {`#${row.number}`}
      </Link>
    ),
    id: 'number'
  },
  {
    Header: 'Amount',
    accessor: (row: any) => <CurrencyProvider value={row.amount / 100} />,
    id: 'amount',
  },
  {
    Header: 'Due date',
    accessor: (row: any) => moment(row.due_date).format('MM/DD/YYYY'),
    id: 'due_date',
  },
];

export const advisorColumns = (userRole: string) => [
  {
    Header: 'Status',
    // @ts-ignore
    accessor: (row: any) => <span style={{ textTransform: 'uppercase' }}>{row.status.replace(/_/g, ' ')}</span>,
    id: 'status',
  },
  {
    Header: 'Client name',
    accessor: (row: any) => (
      <Link href={`/users/clients/${row.client}`}>
        {row.client_name}
      </Link>
    ),
    id: 'client_name',
  },
  {
    Header: 'Advisor name',
    accessor: userRole === ROLES.ADVISOR ? 'advisor_name' : (row: any) => (
      <Link href={`/users/advisors/${row.advisor}`}>
        {row.advisor_name}
      </Link>
    ),
    id: 'advisor_name',
  },
  {
    Header: 'Invoice ID',
    accessor: (row: any) => (
      <Link href={`/billing/single-payments/invoice/${row.id}`}>
        {`#${row.number}`}
      </Link>
    ),
    id: 'number'
  },
  {
    Header: 'Amount',
    accessor: (row: any) => <CurrencyProvider value={row.amount / 100} />,
    id: 'amount',
  },
  {
    Header: 'Due date',
    accessor: (row: any) => moment(row.due_date).format('MM/DD/YYYY'),
    id: 'due_date',
  },
];
