import { TRANSFERS_TYPES } from '../../constants';

export const fetchInitData = () => ({
  type: TRANSFERS_TYPES.FETCH_INIT_DATA,
});

export const fetchInitDataSuccess = (payload: any) => ({
  type: TRANSFERS_TYPES.FETCH_INIT_DATA_SUCCESS,
  payload
});

export const refetchPendingTransfers = (payload: any) => ({
  type: TRANSFERS_TYPES.REFETCH_PENDING_TRANSFERS,
  payload,
});

export const refetchPendingTransfersSuccess = (payload: any) => ({
  type: TRANSFERS_TYPES.REFETCH_PENDING_TRANSFERS_SUCCESS,
  payload
});

export const refetchCompletedTransfers = (payload: any) => ({
  type: TRANSFERS_TYPES.REFETCH_COMPLETED_TRANSFERS,
  payload,
});

export const refetchCompletedTransfersSuccess = (payload: any) => ({
  type: TRANSFERS_TYPES.REFETCH_COMPLETED_TRANSFERS_SUCCESS,
  payload
});

export const transfersError = (error: any) => ({
  type: TRANSFERS_TYPES.ERROR,
  payload: error,
});
