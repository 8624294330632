import axios from './axiosInstance';

export default {
  getBanks: () => axios.get('/user-profiles/banking-detail/').then(res => res.data),
  getCreditCards: () => axios.get('/user-profiles/billing-detail/').then(res => res.data),
  getOrganizations: () => axios.get('/user-profiles/organisation/').then(res => res.data.results[0]),
  fetchBillingHistory: (id: string | number) => axios.get(`/payments/invoices/${id}/my-billing-history/`)
    .then(res => res.data),
  getAppSubscription: () => axios.get('/payments/subscription/my-billing-subscription/').then(res => res.data),
};
