import React from 'react';
import Box from '@material-ui/core/Box';

import { H1, Tabs, BackButton } from '../../components';
import { Table, Info } from './components';
import { ROLES } from '../../constants';

const tabs = [
  { label: 'Overview' },
  { label: 'Payments' },
];

interface Props {
  activeTab: number,
  onTabChange: (val: number) => void,
  subscriptionStatus: string,
  userRole: string,
}

const Content: React.FC<Props> = ({
  activeTab,
  subscriptionStatus,
  onTabChange,
  userRole
}) => (
  <Box>
    <BackButton />
    <H1 mb={3} mt={2}>Subscription Details</H1>
    <Tabs
      tabs={tabs}
      value={activeTab}
      onChange={onTabChange}
    />
    {subscriptionStatus === 'incomplete' && userRole === ROLES.CLIENT && activeTab === 0 && (
      <Box mt={2} color="#E20338">Please visit Payments to make payment and complete activating the subscription.</Box>
    )}
    {subscriptionStatus === 'past_due' && userRole === ROLES.CLIENT && activeTab === 0 && (
      <Box mt={2}>The recent payment failed. Please visit Payments to pay the recent invoice.</Box>
    )}
    {subscriptionStatus === 'unpaid' && userRole === ROLES.CLIENT && activeTab === 0 && (
      <Box mt={2}>Please visit Payments to pay the recent invoice and reactivate your subscription.</Box>
    )}
    <Box mt={2}>
      {activeTab === 0 && <Info />}
      {activeTab === 1 && <Table />}
    </Box>
  </Box>
);

export default Content;
