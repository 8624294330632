import { put, takeLatest, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { login, verifyEmailSuccess } from '../../actions/onBoaring';
import { ONBOARDING_TYPES } from '../../constants';
import Api from '../../../api';
import { OnBoardingActionTypes } from '../../actions/onBoaring/types';

export function* worker({ payload }: OnBoardingActionTypes): SagaIterator<void> {
  if (typeof payload !== 'string') {
    return;
  }
  try {
    const { data } = yield call(Api.users.verifyEmailLink, payload);
    if (data.is_active) {
      yield put(login());
    } else {
      yield put(verifyEmailSuccess(data));
    }
  } catch (e) {
    yield put(verifyEmailSuccess(null));
  }
}

export default function* watcher() {
  yield takeLatest(ONBOARDING_TYPES.VERIFY_EMAIL_REQUEST, worker);
}
