import React from 'react';
import { FieldInputProps, FormikProps } from 'formik';

import InputMask from 'react-input-mask';
import TextField from '../TextField';

interface Props {
  field: FieldInputProps<any>,
  form: FormikProps<any>,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void,
  helperText?: string,
  mask: string,
  type?: string,
}

const FormikInput: React.FC<Props> = ({
  field: {
    onChange: fieldOnChange,
    onBlur,
    ...field
  },
  onChange,
  form,
  helperText,
  onFocus,
  mask,
  ...rest
}) => {
  const onChangeHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
    fieldOnChange(event);
  };

  const { name } = field;
  const isError = Boolean(form.touched[name] && form.errors[name]);
  const helper = helperText || (isError ? `${form.errors[name]}` : undefined);

  return (
    <InputMask
      mask={mask}
      value={field.value}
      disabled={false}
      onChange={onChangeHandle}
    >
      {() => (
        <TextField
          {...rest}
          error={isError}
          handleBlur={onBlur}
          onChange={onChangeHandle}
          {...field}
          helperText={helper}
          fullWidth
        />
      )}
    </InputMask>
  );
};

export default FormikInput;
