import { INVOICE_TYPES } from '../constants';

const initState = {
  invoiceInfo: {},
  loading: false,
  refunds: [],
  error: {},
};

export default (state = initState, { type, payload }: any) => {
  switch (type) {
    case INVOICE_TYPES.GET_INVOICE_INFO:
      return {
        ...state,
        loading: true,
      };
    case INVOICE_TYPES.GET_INVOICE_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceInfo: payload.invoice,
        refunds: payload.refunds,
      };
    case INVOICE_TYPES.ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default: {
      return state;
    }
  }
};
