import React from 'react';
import moment from 'moment';

import {
  EmptyTableMessageContainer,
  H2,
  Link,
  CurrencyProvider
} from '../../../../components';

export const subscriptionColumns = [
  {
    Header: 'Status',
    accessor: (row: any) => <span style={{ textTransform: 'uppercase' }}>{row.status.replace(/_/g, ' ')}</span>,
    id: 'status',
  },
  {
    Header: 'Frequency',
    accessor: 'frequency',
  },
  {
    Header: 'Advisor',
    accessor: 'advisor_name',
  },
  {
    Header: 'Amount',
    accessor: (row: any) => <CurrencyProvider value={row.amount / 100} />,
    id: 'amount',
  },
  {
    Header: 'Start Date',
    accessor: (row: any) => (row.date_started ? moment(row.date_started).format('MM/DD/YYYY') : '-'),
    id: 'date_started',
  },
];

export const singlePaymentsColumns = [
  {
    Header: 'Status',
    accessor: (row: any) => <span style={{ textTransform: 'uppercase' }}>{row.status.replace(/_/g, ' ')}</span>,
    id: 'status',
  },
  {
    Header: 'Invoice ID',
    accessor: (row: any) => (
      <Link href={`/billing/single-payments/invoice/${row.id}`}>
        {`#${row.number}`}
      </Link>
    ),
    id: 'number',
  },
  {
    Header: 'Advisor',
    accessor: 'advisor_name',
  },
  {
    Header: 'Amount',
    accessor: (row: any) => <CurrencyProvider value={row.amount / 100} />,
    id: 'amount',
  },
  {
    Header: 'Due Date',
    accessor: (row: any) => moment(row.due_date).format('MM/DD/YYYY'),
    id: 'due_date',
  },
];

export const clientsColumns = [
  {
    Header: 'Client',
    accessor: ({ name, id }: any) => <Link href={`/users/clients/${id}`}>{name}</Link>,
    id: 'name'
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Status',
    accessor: (row: any) => (row.is_active ? 'Registered' : 'Unregistered'),
    id: 'is_active',
  },
];

export const tabs = [
  {
    label: 'Subscriptions'
  },
  {
    label: 'One time payments'
  },
  {
    label: 'Clients'
  },
];

export const EmptyMessage = ({ activeTab }: { activeTab: number }) => (
  <EmptyTableMessageContainer>
    {activeTab === 0 && <H2>No Subscriptions, yet</H2>}
    {activeTab === 1 && <H2>No One Time Payments, yet</H2>}
    {activeTab === 2 && <H2>No Clients, yet</H2>}
  </EmptyTableMessageContainer>
);
