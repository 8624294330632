import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Box from '@material-ui/core/Box';
import moment from 'moment';

import Modal from '../Modal';
import Button from '../Button';
import messages from './messages';
import { getUser as getUserAction } from '../../redux/actions/Auth';
import Api from '../../api';
import { chaliceFee } from '../../constants';

interface Props {
  status: number,
  getUser: () => void,
  userId: string,
}

const MyComponent: React.FC<Props> = ({ status, getUser, userId }) => {
  const [card, setCard] = useState<{
    is_default: boolean, stripe_id: string
  }>({ stripe_id: '', is_default: false });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (status) {
      Api.userProfiles.getBilling()
        .then(({ data }) => {
          setCard(data.filter((item: any) => item.is_default)[0]);
        });
    }
  }, []);

  const handleFinish = () => {
    setLoading(true);
    Api.subscriptions.createSubscription({
      frequency: 'monthly',
      stripe_id: card.stripe_id,
      client: userId,
      advisor: userId,
      type: 'platform',
      amount: chaliceFee * 100,
      date_created: moment().format(),
    }).then(getUser);
  };

  return (
    <Modal
      open={status === 5}
      handleClose={() => {}}
    >
      <Box bgcolor="white" p={3}>
        <Box fontSize={24} fontWeight={500} mb={2}>
          <FormattedMessage {...messages.welcomeMessage} />
        </Box>
        <Box color="#6D6E71">
          <FormattedMessage {...messages.welcomeThanksMessage} />
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={3}>
          <Button
            variant="text"
            onClick={handleFinish}
            loading={loading}
          >
            <FormattedMessage {...messages.finishButton} />
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  status: state.onBoarding.step,
  userId: state.auth.userData.id,
});

const mapDispatchToProps = {
  getUser: getUserAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyComponent);
