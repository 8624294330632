import React from 'react';
import Box from '@material-ui/core/Box';
import { Formik, Form, Field } from 'formik';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage, IntlShape } from 'react-intl';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import * as Yup from 'yup';

import {
  Modal,
  H2,
  FormikInput,
  Button,
  H3Dark,
} from '../../../../components';
import { withParentData } from '../../Context';
import messages from './messages';
import { stripePushKey } from '../../../../constants';

const validationSchema = (intl: IntlShape) => Yup.object().shape({
  accountHolder: Yup.string()
    .required(intl.formatMessage(messages.requiredMassage))
    .max(71, intl.formatMessage(messages.accountHolderMaxError)),
  routingNumber: Yup.string()
    .matches(/^[0-9]{9}$/, intl.formatMessage(messages.routingNumberError))
    .required(intl.formatMessage(messages.requiredMassage)),
  accountNumber: Yup.string()
    .matches(/^[0-9]{6,17}$/, intl.formatMessage(messages.accountNumberError))
    .required(intl.formatMessage(messages.requiredMassage)),
  accountNumberConfirmation: Yup.string()
    .oneOf([Yup.ref('accountNumber'), undefined], intl.formatMessage(messages.accountNumberConfirmationError))
    .required(intl.formatMessage(messages.requiredMassage)),
});

interface Props {
  addTransferModalOpen: boolean,
  transferAccountAdded: boolean,
  closeTransferModal: () => void,
  onTransferAddedSuccess: () => void,
  onAddTransferAccount: (values: {}) => void,
  intl: IntlShape,
  userData: any,
}

const TransferForm: React.FC<Props> = ({
  addTransferModalOpen,
  closeTransferModal,
  onAddTransferAccount,
  onTransferAddedSuccess,
  intl,
  transferAccountAdded,
  userData,
}) => {
  const stripe = useStripe();

  return (
    <Modal
      open={addTransferModalOpen}
      handleClose={closeTransferModal}
    >
      {transferAccountAdded ? (
        <Box p={3} width={550}>
          <H2>Transfer Account added</H2>
          <H3Dark mt={3} mb={3}>
            The bank account information that you just added will be used to receive money
            from client payments on ChalicePay.
          </H3Dark>
          <Box textAlign="right">
            <Button variant="text" onClick={onTransferAddedSuccess}>ok</Button>
          </Box>
        </Box>
      ) : (
        <Box p={3} width={550}>
          <H2 mb={3}>Add transfer account</H2>
          <Formik
            initialValues={{
              accountHolder: `${userData.first_name} ${userData.last_name}`,
              typeOfBusiness: 'Company',
              routingNumber: '',
              accountNumber: '',
              accountNumberConfirmation: '',
            }}
            validationSchema={validationSchema(intl)}
            onSubmit={async (values) => {
              if (stripe) {
                const { token } = await stripe.createToken('bank_account', {
                  country: 'US',
                  currency: 'usd',
                  routing_number: values.routingNumber,
                  account_number: values.accountNumber,
                  account_holder_name: values.accountHolder,
                  account_holder_type: 'individual',
                });
                onAddTransferAccount({ ...values, source: token?.id });
              }
            }}
          >
            {({ isValid, dirty }) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Field
                      component={FormikInput}
                      label={intl.formatMessage(messages.accountHolderField)}
                      name="accountHolder"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={FormikInput}
                      label={intl.formatMessage(messages.typeOfBusinessField)}
                      name="typeOfBusiness"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={FormikInput}
                      label={intl.formatMessage(messages.routingNumberField)}
                      name="routingNumber"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={FormikInput}
                      label={intl.formatMessage(messages.accountNumberField)}
                      name="accountNumber"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={FormikInput}
                      label={intl.formatMessage(messages.accountNumberConfirmationField)}
                      name="accountNumberConfirmation"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Box display="flex" justifyContent="flex-end" mt={3}>
                  <Box mr={3}>
                    <Button onClick={closeTransferModal} variant="text">
                      <FormattedMessage {...messages.cancelButton} />
                    </Button>
                  </Box>
                  <Button type="submit" variant="text" disabled={!dirty || !isValid}>
                    <FormattedMessage {...messages.addButton} />
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      )}
    </Modal>
  );
};

const stripePromise = loadStripe(stripePushKey() || '');

const StripeWrapper: React.FC<Props> = (props) => (
  <Elements stripe={stripePromise}>
    <TransferForm {...props} />
  </Elements>
);

export default withParentData(StripeWrapper);
