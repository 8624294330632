import React from 'react';
import Box from '@material-ui/core/Box';
import DoneIcon from '@material-ui/icons/Done';

import {
  Button,
  Modal,
  Table,
  Link,
  H3Dark,
  H1,
} from '../../components';
import { Form, NoTableItemsMessage } from './components';
import { getAdvisorConvertedRole } from '../../helpers';

interface Props {
  modalOpen: boolean,
  accountSubscribed: boolean,
  accountVerified: boolean,
  advisorAdded: boolean,
  addAdvisor: () => void,
  list: {}[],
  error: {},
  actions?: {
    label?: string,
    onClick?: (row: any) => void
  }[],
  sort: { id: string, firstDirection: boolean },
  pagination: { page: number },
  total: number,
  refetchData: (data: any) => void,
}

const columns = [
  {
    Header: 'Name',
    accessor: ({ name, id }: any) => <Link href={`/users/advisors/${id}`}>{name}</Link>,
    id: 'name'
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Status',
    accessor: (row: any) => (row.is_active ? 'Registered' : 'Unregistered'),
    id: 'is_active',
  },
  {
    Header: 'Role',
    accessor: (row: any) => getAdvisorConvertedRole(row.role[0]),
    id: 'role',
  },
  {
    Header: 'Clients',
    accessor: (row: any) => row.registered_clients_count + row.unregistered_clients_count,
    id: 'registered_clients_count'
  },
];

const Content: React.FC<Props> = ({
  addAdvisor,
  modalOpen,
  list,
  advisorAdded,
  error,
  actions,
  total,
  refetchData,
  sort,
  pagination,
  accountSubscribed,
  accountVerified,
}) => (
  <div>
    <Button
      onClick={addAdvisor}
      variant="contained"
      shouldValidateAccount
      accountVerified={accountSubscribed && accountVerified}
      modalContent={(
        <H3Dark p={3} maxWidth={600}>
          <span>Sorry but you are unable to make this action until your </span>
          {!accountVerified && <span>ChalicePay account is verified. </span>}
          {!accountSubscribed && <span>ChalicePay subscription is unpaid. </span>}
          <span>Please check your </span>
          <Link href="/settings">Company Settings</Link>
        </H3Dark>
      )}
    >
      add advisor
    </Button>
    <H1 mt="52px" mb={2}>Advisors</H1>
    <Table
      pagination={pagination}
      sort={sort}
      countTotal={total}
      refetchData={refetchData}
      columns={columns}
      withPagination
      data={list}
      actions={actions}
      noItemsMessage={<NoTableItemsMessage accountSubscribed={accountSubscribed} addAdvisor={addAdvisor} />}
    />
    <Modal open={modalOpen} handleClose={addAdvisor}><Form /></Modal>
    <Modal open={advisorAdded} handleClose={() => {}}>
      <Box p={5} bgcolor="white" width={450} display="flex" flexDirection="column" alignItems="center">
        {Object.keys(error).length === 0 && (
          <Box color="#3772FF" fontSize={64}><DoneIcon fontSize="inherit" /></Box>
        )}
        <Box mt={3} fontSize={24} color={Object.keys(error).length !== 0 ? '#E20338' : '#011638'}>
          { Object.keys(error).length !== 0
            ? 'The advisor with this email is already added.'
            : 'Advisor added'}
        </Box>
      </Box>
    </Modal>
  </div>
);

export default Content;
