import React from 'react';
import Box from '@material-ui/core/Box';

import {
  H1,
  H2,
  H3,
  Table,
  EmptyTableMessageContainer, Button,
} from '../../components';
import { columns } from './data';

interface Props {
  onRefetchPendingData: (data: any) => void,
  downloadTransfers: () => void,
  onRefetchCompletedData: (data: any) => void,
  pendingTransfers: {}[],
  pendingTotal: number,
  pendingPagination: { page: number },
  pendingSort: { id: string, firstDirection: boolean },
  completedTransfers: {}[],
  completedTotal: number,
  completedPagination: { page: number },
  completedSort: { id: string, firstDirection: boolean },
}

const Content: React.FC<Props> = ({
  pendingTransfers,
  pendingTotal,
  pendingSort,
  pendingPagination,
  completedTransfers,
  completedTotal,
  completedSort,
  completedPagination,
  onRefetchPendingData,
  onRefetchCompletedData,
  downloadTransfers,
}) => (
  <Box>
    <Box mt={3} display="flex" justifyContent="space-between">
      <H1 mb={5}>Transfers</H1>
      <Button onClick={downloadTransfers} variant="contained">download transfers history</Button>
    </Box>
    <H3 mb={2}>We charge 3.5% + $0.30 per transaction each time you accept a credit or debit card payment.</H3>
    <H2 mb={1}>Pending transfers</H2>
    <Table
      withPagination
      pagination={pendingPagination}
      countTotal={pendingTotal}
      columns={columns}
      noItemsMessage={<EmptyTableMessageContainer><H2>No Pending transfers</H2></EmptyTableMessageContainer>}
      data={pendingTransfers}
      refetchData={onRefetchPendingData}
      sort={pendingSort}
      errorRowMessage="This payment has failed. Please contact your client for checking banking details."
      getRowError={({ original }: any) => original.status === 'failed'}
    />
    <H2 mb={1} mt={5}>Completed transfers</H2>
    <Table
      withPagination
      pagination={completedPagination}
      noItemsMessage={<EmptyTableMessageContainer><H2>No Completed transfers</H2></EmptyTableMessageContainer>}
      columns={columns}
      data={completedTransfers}
      sort={completedSort}
      countTotal={completedTotal}
      refetchData={onRefetchCompletedData}
    />
  </Box>
);

export default Content;
