import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

import { Provider } from './Context';
import Content from './Content';
import { tabs } from './components/Tabs';

import { ROLES } from '../../constants';
import { RequestPaymentModal, MakePaymentModal, CancelInvoiceModal } from '../../components';

interface MatchParams {
  id: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  userRole: string,
  getSinglePayments: () => void,
  getCompanyDetails: () => void,
  refetchPayments: (data: {}) => void,
  step: number,
  payments: {}[],
  total: number,
  pagination: { page: number },
  sort: { id: string, firstDirection: boolean },
  accountVerified: boolean,
  accountSubscribed: boolean,
}

interface State {
  activeTab: number,
  searchValue: string,
  requestModalOpen: boolean,
  makePaymentModalOpen: boolean,
  cancelModalOpen: boolean,
  paymentId: string,
  cancelPaymentId: string,
}

class Container extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeTab: 0,
      searchValue: '',
      requestModalOpen: false,
      cancelModalOpen: false,
      makePaymentModalOpen: Boolean(props.match.params.id),
      paymentId: '',
      cancelPaymentId: '',
    };
  }

  componentDidMount() {
    const {
      userRole,
      step,
      history,
      getSinglePayments,
      getCompanyDetails,
    } = this.props;

    if (userRole === ROLES.ACCOUNT_OWNER && step < 5) {
      history.push('/onBoarding');
    }

    getSinglePayments();
    getCompanyDetails();
  }

  get actions() {
    const { history, userRole } = this.props;
    if (userRole === ROLES.CLIENT) {
      return [
        {
          label: 'View invoice',
          onClick: (row: any) => history.push(`/transactions/single-payments/invoice/${row.id}`),
        },
        {
          label: 'View details',
          onClick: (row: any) => history.push(`/transactions/single-payments/invoice-details/${row.id}`),
        },
        {
          label: 'Make payment',
          onClick: (row: any) => this.setState({ makePaymentModalOpen: true, paymentId: row.id }),
          getIsHidden: (row: any) => row.status !== 'unpaid'
            && row.status !== 'past_due'
            && row.status !== 'failed',
        }
      ];
    }
    return [
      {
        label: 'View invoice',
        onClick: (row: any) => history.push(`/billing/single-payments/invoice/${row.id}`),
      },
      {
        label: 'View details',
        onClick: (row: any) => history.push(`/billing/single-payments/invoice-details/${row.id}`),
      },
      {
        label: 'Cancel Invoice',
        onClick: (row: any) => this.setState({ cancelModalOpen: true, cancelPaymentId: row.id }),
        getIsHidden: (row: any) => row.status !== 'unpaid' && row.status !== 'failed' && row.status !== 'past_due',
      }
    ];
  }

  get status() {
    let status = null;
    const { activeTab } = this.state;
    switch (tabs[activeTab].label) {
      case 'Unpaid':
        status = 'unpaid';
        break;
      case 'Past Due':
        status = 'past_due';
        break;
      case 'Paid':
        status = 'paid';
        break;
      case 'Canceled':
        status = 'canceled';
        break;
      case 'Refunded':
        status = 'refunded';
        break;
      case 'Partially refunded':
        status = 'partially_refunded';
        break;
      case 'Failed':
        status = 'failed';
        break;
      default:
        break;
    }
    return status;
  }

  handleTabChange = (value: number) => {
    const { refetchPayments } = this.props;
    this.setState({ activeTab: value }, () => {
      const { activeTab, searchValue } = this.state;
      if (activeTab) {
        return refetchPayments({
          pagination: {
            page: 1
          },
          sort: {
            id: null,
            firstDirection: false,
          },
          search: searchValue,
          status: this.status,
        });
      }
      return refetchPayments({
        pagination: {
          page: 1
        },
        sort: {
          id: null,
          firstDirection: false,
        },
        search: searchValue,
      });
    });
  };

  handleSearchSubmit = (value: string) => {
    const { pagination, sort, refetchPayments } = this.props;
    refetchPayments({
      search: value,
      pagination,
      sort,
      status: this.status,
    });
  };

  handleSearchChange = (value: string) => this.setState({ searchValue: value });

  toggleRequestModal = () => this.setState((prevState) => ({ requestModalOpen: !prevState.requestModalOpen }));

  toggleCancelModal = () => this.setState((prevState) => ({ cancelModalOpen: !prevState.cancelModalOpen }));

  toggleMakePaymentModal = () => this.setState((prevState) => ({
    makePaymentModalOpen: !prevState.makePaymentModalOpen
  }));

  handleCancelInvoice = () => {
    const { pagination, sort, refetchPayments } = this.props;
    const { searchValue } = this.state;
    refetchPayments({
      search: searchValue,
      pagination,
      sort,
      status: this.status,
    });
  };

  handleRefetchData = (data: any) => {
    const { refetchPayments } = this.props;
    const { activeTab, searchValue } = this.state;

    if (!activeTab) {
      return refetchPayments({
        ...data,
        search: searchValue,
      });
    }
    return refetchPayments({
      ...data,
      status: this.status,
      search: searchValue,
    });
  };

  render() {
    const {
      props: {
        userRole,
        total,
        payments,
        pagination,
        accountVerified,
        accountSubscribed,
        sort,
      },
      state: {
        activeTab,
        searchValue,
        requestModalOpen,
        makePaymentModalOpen,
        paymentId,
        cancelPaymentId,
        cancelModalOpen,
      }
    } = this;

    return (
      <Provider
        value={{
          accountVerified,
          accountSubscribed,
          activeTab,
          onTabChange: this.handleTabChange,
          userRole,
        }}
      >
        <Content
          accountSubscribed={accountSubscribed}
          accountVerified={accountVerified}
          pagination={pagination}
          actions={this.actions}
          sort={sort}
          refetchData={this.handleRefetchData}
          payments={payments}
          total={total}
          userRole={userRole}
          searchValue={searchValue}
          onSearchSubmit={this.handleSearchSubmit}
          onSearchChange={this.handleSearchChange}
          onRequestPayment={this.toggleRequestModal}
        />
        {userRole !== ROLES.CLIENT && (
          <RequestPaymentModal
            open={requestModalOpen}
            close={this.toggleRequestModal}
          />
        )}
        {userRole !== ROLES.CLIENT && (
          <CancelInvoiceModal
            open={cancelModalOpen}
            close={this.toggleCancelModal}
            id={cancelPaymentId}
            callback={this.handleCancelInvoice}
          />
        )}
        {userRole === ROLES.CLIENT && (
          <MakePaymentModal
            open={makePaymentModalOpen}
            close={this.toggleMakePaymentModal}
            paymentId={paymentId}
          />
        )}
      </Provider>
    );
  }
}

export default Container;
