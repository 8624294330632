import { ADVISOR_PROFILE_TYPES } from '../../constants';

export const getInfo = (id: string) => ({
  type: ADVISOR_PROFILE_TYPES.GET_INFO,
  payload: id
});

export const getInfoSuccess = (payload: any) => ({
  type: ADVISOR_PROFILE_TYPES.GET_INFO_SUCCESS,
  payload,
});

export const getSinglePayments = (id: string) => ({
  type: ADVISOR_PROFILE_TYPES.GET_SINGLE_PAYMENTS,
  payload: id
});

export const getSinglePaymentsSuccess = (payload: any) => ({
  type: ADVISOR_PROFILE_TYPES.GET_SINGLE_PAYMENTS_SUCCESS,
  payload,
});

export const refetchSinglePayments = (payload: any) => ({
  type: ADVISOR_PROFILE_TYPES.REFETCH_SINGLE_PAYMENTS,
  payload
});

export const refetchSinglePaymentsSuccess = (payload: any) => ({
  type: ADVISOR_PROFILE_TYPES.REFETCH_SINGLE_PAYMENTS_SUCCESS,
  payload
});

export const getSubscription = (id: string) => ({
  type: ADVISOR_PROFILE_TYPES.GET_SUBSCRIPTIONS,
  payload: id
});

export const getSubscriptionSuccess = (payload: any) => ({
  type: ADVISOR_PROFILE_TYPES.GET_SUBSCRIPTIONS_SUCCESS,
  payload,
});

export const refetchSubscription = (payload: any) => ({
  type: ADVISOR_PROFILE_TYPES.REFETCH_SUBSCRIPTIONS,
  payload
});

export const refetchSubscriptionSuccess = (payload: any) => ({
  type: ADVISOR_PROFILE_TYPES.REFETCH_SUBSCRIPTIONS_SUCCESS,
  payload
});

export const getClients = (id: string) => ({
  type: ADVISOR_PROFILE_TYPES.GET_CLIENTS,
  payload: id
});

export const getClientsSuccess = (payload: any) => ({
  type: ADVISOR_PROFILE_TYPES.GET_CLIENTS_SUCCESS,
  payload,
});

export const refetchClients = (payload: any) => ({
  type: ADVISOR_PROFILE_TYPES.REFETCH_CLIENTS,
  payload
});

export const refetchClientsSuccess = (payload: any) => ({
  type: ADVISOR_PROFILE_TYPES.REFETCH_CLIENTS_SUCCESS,
  payload
});

export const sendLink = (callback: any) => ({
  type: ADVISOR_PROFILE_TYPES.SEND_LINK,
  payload: callback
});

export const advisorProfileError = (error: any) => ({
  type: ADVISOR_PROFILE_TYPES.ERROR,
  payload: error
});
