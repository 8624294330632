import { SUBSCRIPTIONS_TYPES } from '../../constants';


export const createSubscription = (payload: any, callback: () => void) => ({
  type: SUBSCRIPTIONS_TYPES.CREATE_SUBSCRIPTION,
  payload,
  callback,
});

export const createSubscriptionSuccess = (payload: any) => ({
  type: SUBSCRIPTIONS_TYPES.CREATE_SUBSCRIPTION_SUCCESS,
  payload,
});


export const getSubscriptions = () => ({
  type: SUBSCRIPTIONS_TYPES.GET_SUBSCRIPTIONS,
});

export const getSubscriptionsSuccess = (payload: any) => ({
  type: SUBSCRIPTIONS_TYPES.GET_SUBSCRIPTIONS_SUCCESS,
  payload,
});

export const refetchSubscriptions = (payload: any) => ({
  type: SUBSCRIPTIONS_TYPES.REFETCH_SUBSCRIPTIONS,
  payload
});

export const refetchSubscriptionsSuccess = (payload: any) => ({
  type: SUBSCRIPTIONS_TYPES.REFETCH_SUBSCRIPTIONS_SUCCESS,
  payload
});

export const subscriptionsError = (error: any) => ({
  type: SUBSCRIPTIONS_TYPES.ERROR,
  payload: error
});
