import { connect } from 'react-redux';

import { getSinglePayments, refetchPayments } from '../../redux/actions/SinglePayments';
import { getCompanyDetails } from '../../redux/actions/Settings';
import Container from './Container';

const mapStateToProps = (state: any) => ({
  userRole: state.auth.userRole,
  step: state.onBoarding.step,
  payments: state.singlePayments.payments,
  total: state.singlePayments.total,
  pagination: state.singlePayments.pagination,
  sort: state.singlePayments.sort,
  accountVerified: state.settings.companyInfo ? state.settings.companyInfo.status === 'verified' : false,
  accountSubscribed: Boolean(state.settings.companyInfo ? state.settings.companyInfo.subscribed : false),
});

const mapDispatchToProps = {
  getSinglePayments,
  refetchPayments,
  getCompanyDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
