import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

import Content from './Content';
import { Provider } from './Context';
import { ROLES } from '../../constants';

interface MatchParams {
  id: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  userRole: string,
  getInfo: (id: string) => void,
  getPayments: (id: string) => void,
  refetchPayments: (data: {}) => void,
  subscriptionInfo: {
    status: string,
  },
  payments: {}[],
  pagination: { page: number },
  sort: {},
  total: number,
}

interface State {
  activeTab: number,
  makePaymentModalOpen: boolean,
  paymentId: string,
}

class Container extends Component<Props, State> {
  state = {
    activeTab: 0,
    makePaymentModalOpen: false,
    paymentId: '',
  };

  componentDidMount() {
    const { getInfo, match, getPayments } = this.props;

    getInfo(match.params.id);
    getPayments(match.params.id);
  }

  get actions() {
    const { history, userRole } = this.props;
    return [
      {
        label: 'View invoice',
        onClick: (row: any) => history.push(`/billing/single-payments/invoice/${row.id}`),
      },
      {
        label: 'View details',
        onClick: (row: any) => history.push(`/billing/single-payments/invoice-details/${row.id}`),
      },
      {
        label: 'Make Payment',
        onClick: (row: any) => this.setState({ paymentId: row.id, makePaymentModalOpen: true }),
        getIsHidden: (row: any) => userRole !== ROLES.CLIENT || row.status !== 'failed',
      },
    ];
  }

  handleTabChange = (value: number) => this.setState({ activeTab: value });

  toggleMakePaymentModal = () => this.setState((prevState) => ({
    makePaymentModalOpen: !prevState.makePaymentModalOpen
  }));

  makePaymentCallback = () => {
    const {
      getInfo,
      match,
      refetchPayments,
      sort,
      pagination,
    } = this.props;

    getInfo(match.params.id);
    refetchPayments({ sort, pagination });
  };

  render() {
    const { activeTab, makePaymentModalOpen, paymentId } = this.state;
    const {
      userRole,
      sort,
      payments,
      pagination,
      subscriptionInfo,
      total,
      refetchPayments
    } = this.props;

    return (
      <Provider
        value={{
          userRole,
          subscriptionInfo,
          sort,
          payments,
          pagination,
          total,
          makePaymentModalOpen,
          paymentId,
          toggleMakePaymentModal: this.toggleMakePaymentModal,
          makePaymentCallback: this.makePaymentCallback,
          actions: this.actions,
          refetchData: refetchPayments
        }}
      >
        <Content
          userRole={userRole}
          subscriptionStatus={subscriptionInfo.status}
          activeTab={activeTab}
          onTabChange={this.handleTabChange}
        />
      </Provider>

    );
  }
}

export default Container;
