import { ADVISORS_TYPES } from '../../constants';

export const createAdvisor = (payload: {},
  callback: (error: boolean) => void) => ({
  type: ADVISORS_TYPES.CREATE_ADVISOR,
  payload,
  callback,
});

export const createAdvisorSuccess = (payload: any) => ({
  type: ADVISORS_TYPES.CREATE_ADVISOR_SUCCESS,
  payload,
});

export const getAdvisors = () => ({
  type: ADVISORS_TYPES.GET_ADVISORS,
});

export const getAdvisorsSuccess = (payload: any) => ({
  type: ADVISORS_TYPES.GET_ADVISORS_SUCCESS,
  payload,
});

export const refetchAdvisors = (payload: any) => ({
  type: ADVISORS_TYPES.REFETCH_ADVISORS_REQUEST,
  payload
});

export const refetchAdvisorsSuccess = (payload: any) => ({
  type: ADVISORS_TYPES.REFETCH_ADVISORS_SUCCESS,
  payload
});

export const advisorsError = (error: any) => ({
  type: ADVISORS_TYPES.ERROR,
  payload: error
});
