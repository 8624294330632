import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import GetAppIcon from '@material-ui/icons/GetApp';
import { connect } from 'react-redux';

import Modal from '../../Modal';
import H3Dark from '../../H3Dark';
import H3 from '../../H3';
import H2 from '../../H2';
import Button from '../../Button';
import Navigation from '../../PaymentNavigation';
import { logout as logoutAction } from '../../../redux/actions/onBoaring';
import Api from '../../../api';
import { apiUrl } from '../../../constants';

interface Props {
  open: boolean,
  close: () => void,
  logout: () => void,
  userData: any,
}

const steps = ['Download transfers history', 'Delete account'];

const DeleteAccountModal: React.FC<Props> = ({
  open,
  close,
  logout,
  userData,
}) => {
  const [activeStep, setActiveStep] = useState(0);

  const handleClose = () => {
    setActiveStep(0);
    close();
  };

  const handleDelete = () => {
    Api.users.deleteAccount({
      id: userData.id,
      email: userData.email,
      is_staff: userData.is_staff,
      is_active: userData.is_active,
      onboarding_status: userData.onboarding_status,
      groups: userData.groups,
    })
      .then(() => setActiveStep(2))
      .catch(() => setActiveStep(3));
  };

  return (
    <Modal
      open={open}
      handleClose={() => {}}
    >
      <Box p={3} width={700}>
        {activeStep < 2 && <Navigation activeStep={activeStep} steps={steps} />}
        {activeStep === 0 && (
          <Box mt={5}>
            <Box textAlign="center">
              <H3Dark mb={3}>
                Your transfers history has all completed transfers from all of your clients.
                Once you’ve deleted your account your clients and invited advisors won’t have access to ChalicePay.
              </H3Dark>
              <GetAppIcon style={{ fontSize: 60, color: '#3772FF' }} />
              <Box mb={1} mt={3}>
                <Button variant="contained" onClick={() => window.open(`${apiUrl}/payments/transfers-csv`)}>
                  Download transfers history
                </Button>
              </Box>
              <H3>.CSV of all completed transfers</H3>
            </Box>
            <Box display="flex" justifyContent="space-between" mt={3}>
              <Button variant="text" onClick={handleClose}>cancel</Button>
              <Button variant="text" onClick={() => setActiveStep(1)}>next</Button>
            </Box>
          </Box>
        )}
        {activeStep === 1 && (
          <Box mt={5}>
            <Box textAlign="center">
              <H2 mb={5}>Delete Account</H2>
              <H3>
                Are you sure you want to delete your account?
                All your data, your clients, and invited advisors will be removed.
              </H3>
            </Box>
            <Box display="flex" justifyContent="space-between" mt={3}>
              <Button variant="text" onClick={handleClose}>cancel</Button>
              <Box display="flex">
                <Box mr={3}>
                  <Button variant="text" onClick={() => setActiveStep(0)}>previous</Button>
                </Box>
                <Button color="secondary" variant="text" onClick={handleDelete}>delete</Button>
              </Box>
            </Box>
          </Box>
        )}
        {activeStep === 2 && (
          <Box mt={3} textAlign="center">
            <H2 mb={5}>Deleted!</H2>
            <H3Dark mb={3}>Your ChalicePay account has been successfully deleted.</H3Dark>
            <Button variant="contained" onClick={logout}>ok</Button>
          </Box>
        )}
        {activeStep === 3 && (
          <Box mt={3} textAlign="center">
            <H3Dark mb={3}>
              You cannot delete you account because there are ongoing subscriptions,
              unpaid invoices, or pending transfers.
            </H3Dark>
            <H3Dark mb={3}>
              Please be sure to cancel all ongoing subscriptions and/or unpaid invoices.
              In case you have any pending or failed transfers, make sure they arrive to your transfer bank account.
            </H3Dark>
            <Button variant="contained" onClick={handleClose}>ok</Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

const mapDispatchToProps = { logout: logoutAction };

const mapStateToProps = (state: any) => ({
  userData: state.auth.userData,
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccountModal);
