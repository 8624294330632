import axios from './axiosInstance';

export const fetchWithFilters = (url: string, { pagination, sort, ...rest }: any) => {
  const params = { ...rest };
  if (pagination) {
    params.page = pagination.page;
  }
  if (sort.id) {
    params.ordering = `${sort.firstDirection ? '' : '-'}${sort.id}`;
  }
  return axios.get(url, { params });
};
