import axios from './axiosInstance';
import { fetchWithFilters } from './helpers';

export default {
  createSubscription: (data: {}) => axios.post('/payments/subscription/', data),
  activateSubscription: (id: string, data: {}) => axios.post(`/payments/subscription/${id}/accept/`, data),
  getSubscriptions: () => axios.get('/payments/subscription/'),
  getSubscriptionById: (id: string) => axios.get(`/payments/subscription/${id}`),
  getSubscriptionPayments: (id: string) => axios.get(`/payments/subscription/${id}/subscription-invoices/`),
  refetchSubscriptionPayments: (id: string, data: {}) => fetchWithFilters(
    `/payments/subscription/${id}/subscription-invoices/`, data
  ),
  getClientSubscriptions: (id: string) => axios.get('/payments/subscription/', { params: { client: id } }),
  getAdvisorSubscriptions: (id: string) => axios.get('/payments/subscription/', { params: { advisor: id } }),
  refetchSubscriptions: (data: any) => fetchWithFilters('/payments/subscription/', data),
  cancelSubscription: (id: string) => axios.delete(`/payments/subscription/${id}/`),
  getAppSubscription: () => axios.get('/payments/subscription/my-billing-subscription/'),
};
