import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'makePayment.title',
    defaultMessage: 'Make Payment',
    description: 'Button label'
  },
  noCardMessage: {
    id: 'makePayment.noWalletTitle',
    defaultMessage: 'No credit cards added, yet!',
    description: 'text',
  },
  noCardDescription: {
    id: 'makePayment.noWalletDescription',
    defaultMessage: 'Please go to Wallet and add a credit card. Then you will be able to make payment.',
    description: 'text',
  },
  successTitle: {
    id: 'makePayment.successTitle',
    defaultMessage: 'Thank you for your payment',
    description: 'text',
  },
  successMessage: {
    id: 'makePayment.successMessage',
    defaultMessage: 'We’ve emailed you a confirmation',
    description: 'text',
  },
  wallet: {
    id: 'wallet',
    defaultMessage: 'Wallet',
    description: 'text',
  },
  creditCard: {
    id: 'requestModal.creditCard',
    defaultMessage: 'Credit card',
    description: 'Card label'
  },
  invoice: {
    id: 'invoice',
    defaultMessage: 'Invoice',
    description: 'Text',
  },
  creditCardDetails: {
    id: 'onBoarding.billingStep.creditCardDetails',
    defaultMessage: 'Credit Card Details',
    description: 'Text'
  },
  cardholder: {
    id: 'fields.CardholderName',
    defaultMessage: 'Card holder name',
    description: 'Field label'
  },
  cardNumber: {
    id: 'fields.cardNumber',
    defaultMessage: 'Card number',
    description: 'Field label'
  },
  address: {
    id: 'onBoarding.review.address',
    defaultMessage: 'Address',
    description: 'Text'
  },
  city: {
    id: 'fields.city',
    defaultMessage: 'City',
    description: 'Field label'
  },
  state: {
    id: 'fields.state',
    defaultMessage: 'State',
    description: 'Field label'
  },
  zipCode: {
    id: 'fields.zipCode',
    defaultMessage: 'Zip Code',
    description: 'Field label'
  },
  previousButton: {
    id: 'buttons.previous',
    defaultMessage: 'previous',
    description: 'Button label'
  },
  confirmPayButton: {
    id: 'buttons.confirmPayButton',
    defaultMessage: 'confirm & pay',
    description: 'Button label'
  },
  okButton: {
    id: 'buttons.ok',
    defaultMessage: 'ok',
    description: 'Button label'
  },
});
