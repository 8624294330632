import { defineMessages } from 'react-intl';

export default defineMessages({
  requiredMassage: {
    id: 'onBoarding.requiredFieldMassage',
    defaultMessage: 'Please fill in all required fields.',
    description: 'Message'
  },
  emailLabel: {
    id: 'fields.email',
    defaultMessage: 'Email',
    description: 'Email TextField Label'
  },
  firstNameField: {
    id: 'fields.firstName',
    defaultMessage: 'First name',
    description: 'Field label'
  },
  firstNameError: {
    id: 'fields.firstName.error',
    defaultMessage: 'First name is too long.',
    description: 'Field label'
  },
  firstNameInvalidError: {
    id: 'fields.firstName.invalidError',
    defaultMessage: 'First name contains invalid symbols.',
    description: 'Field label'
  },
  lastNameField: {
    id: 'fields.lastName',
    defaultMessage: 'Last name',
    description: 'Field label'
  },
  lastNameError: {
    id: 'fields.lastName.error',
    defaultMessage: 'Last name is too long.',
    description: 'Field label'
  },
  lastNameInvalidError: {
    id: 'fields.lastName.invalidError',
    defaultMessage: 'Last name contains invalid symbols.',
    description: 'Field label'
  },
  invalidEmailMessage: {
    id: 'validationErrors.invalidEmail',
    defaultMessage: 'Please enter a valid email address.',
    description: 'Validation error message',
  },
  addButton: {
    id: 'buttons.add',
    defaultMessage: 'Add',
    description: 'Button label'
  },
  cancelButton: {
    id: 'buttons.cancel',
    defaultMessage: 'Cancel',
    description: 'Button label'
  },
  optional: {
    id: 'optional',
    description: 'Label',
    defaultMessage: 'Optional',
  },
});
