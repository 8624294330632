import { CLIENT_TYPES } from '../constants';

interface ClientsState {
  clientsList: {}[],
  advisorsList: {}[],
  loading: boolean,
  error: {},
  clientsTotal: number,
  pagination: {
    page: number
  },
  sort: {
    id: null | string,
    firstDirection: boolean,
  },
}

const initState: ClientsState = {
  clientsList: [],
  advisorsList: [],
  loading: false,
  error: {},
  clientsTotal: 0,
  pagination: {
    page: 1
  },
  sort: {
    id: null,
    firstDirection: false,
  },
};

export default (state = initState, { type, payload }: any): ClientsState => {
  switch (type) {
    case CLIENT_TYPES.CREATE_CLIENT:
      return {
        ...state,
        loading: true,
        error: {},
      };
    case CLIENT_TYPES.CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: {},
      };
    case CLIENT_TYPES.GET_CLIENTS:
      return {
        ...state,
        loading: true
      };
    case CLIENT_TYPES.GET_ALL_ADVISORS_SUCCESS:
      return {
        ...state,
        advisorsList: payload
      };
    case CLIENT_TYPES.GET_CLIENTS_SUCCESS:
      return {
        ...state,
        loading: true,
        clientsTotal: payload.count,
        clientsList: payload.results,
      };
    case CLIENT_TYPES.REFETCH_CLIENTS_SUCCESS:
      return {
        ...state,
        sort: payload.sort,
        pagination: payload.pagination,
        clientsList: payload.data.results,
      };
    case CLIENT_TYPES.ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default: {
      return state;
    }
  }
};
