import React from 'react';
import { Redirect, Route } from 'react-router-dom';

interface Props {
  isAuthorized: boolean,
  component: React.ReactElement,
}

const PublicRoute: React.FC<Props> = ({ component: Component, isAuthorized, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (!isAuthorized) {
        // @ts-ignore
        return <Component {...props} />;
      }

      return (
        <Redirect to={{
          pathname: '/onBoarding',
          state: { from: props.location }
        }}
        />
      );
    }}
  />
);

export default PublicRoute;
