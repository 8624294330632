import axios from './axiosInstance';
import { fetchWithFilters } from './helpers';

export default {
  createClient: (data: {}) => axios.post('/users/clients/', data),
  updateClient: (data: {}, id: string) => axios.post(`/users/clients/${id}`, data),
  getAllClients: () => axios.get('/users/clients/get-all/'),
  getClients: () => axios.get('/users/clients/'),
  resendVerificationEmail: (email: string) => axios.post('/users/clients/re-send-verification-email/', { email }),
  refetchClients: (data: any) => fetchWithFilters('/users/clients/', data),
  getClientById: (id: string) => axios.get(`users/clients/${id}`),
  deleteClient: (id: string) => axios.delete(`users/clients/${id}`),
};
