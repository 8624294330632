import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

import Content from './Content';
import { Provider } from './Context';
import { CancelInvoiceModal, EditAdvisorModal, DeleteAdvisorModal } from '../../components';

interface MatchParams {
  id: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  getInfo: (id: string) => void,
  sendLink: (callback: any) => void,
  advisorInfo: any,
  getSinglePayments: (id: string) => void,
  refetchSinglePayments: (data: {}) => void,
  getClients: (id: string) => void,
  refetchClients: (data: {}) => void,
  getSubscription: (id: string) => void,
  refetchSubscription: (data: {}) => void,
  singlePayments: { id: string }[],
  singlePaymentsTotal: number,
  singlePaymentsPagination: {},
  singlePaymentsSort: {},
  subscriptions: { id: string }[],
  subscriptionsTotal: number,
  subscriptionsPagination: {},
  subscriptionsSort: {},
  clients: {}[],
  clientsTotal: number,
  clientsPagination: {},
  clientsSort: {},
}

interface State {
  activeTab: number,
  modalOpen: boolean,
  cancelInvoiceModalOpen: boolean,
  editAdvisorModalOpen: boolean,
  deleteAdvisorModalOpen: boolean,
  cancelInvoiceId: string,
  cancelInvoiceStatus: string,
  nextBilledDate: string | null,
}

class Container extends Component<Props, State> {
  state = {
    activeTab: 0,
    modalOpen: false,
    cancelInvoiceModalOpen: false,
    editAdvisorModalOpen: false,
    deleteAdvisorModalOpen: false,
    cancelInvoiceId: '',
    nextBilledDate: '',
    cancelInvoiceStatus: '',
  };

  componentDidMount() {
    const {
      getInfo,
      match,
      getSinglePayments,
      getSubscription,
      getClients,
    } = this.props;

    getInfo(match.params.id);
    getSinglePayments(match.params.id);
    getClients(match.params.id);
    getSubscription(match.params.id);
  }

  get singlePaymentActions() {
    const { history } = this.props;

    return [
      {
        label: 'View invoice',
        onClick: (row: any) => history.push(`/billing/single-payments/invoice/${row.id}`),
      },
      {
        label: 'View details',
        onClick: (row: any) => history.push(`/billing/single-payments/invoice-details/${row.id}`),
      },
      {
        label: 'Cancel invoice',
        onClick: (row: any) => this.setState({ cancelInvoiceId: row.id, cancelInvoiceModalOpen: true }),
        getIsHidden: (row: any) => row.status !== 'unpaid' && row.status !== 'past_due',
      }
    ];
  }

  get subscriptionActions() {
    const { history } = this.props;

    return [
      {
        label: 'View Subscription',
        onClick: (row: any) => history.push(`/billing/single-payments/subscription-details/${row.id}`),
      },
      {
        label: 'Cancel Subscription',
        onClick: (row: any) => this.setState({
          cancelInvoiceId: row.id,
          cancelInvoiceModalOpen: true,
          nextBilledDate: row.next_bill_date,
          cancelInvoiceStatus: row.status,
        }),
        getIsHidden: (row: any) => row.status !== 'inactive' && row.status !== 'active' && row.status !== 'failed',
      }
    ];
  }

  get clientActions() {
    const { history } = this.props;
    return [
      {
        label: 'View Profile',
        onClick: (row: { id: string }) => history.push(`/users/clients/${row.id}`),
      },
      {
        label: 'Delete Client',
        onClick: () => {},
      }
    ];
  }

  handleSendLink = () => {
    const { sendLink } = this.props;
    sendLink(this.onLinkSuccessfullySend);
  };

  onLinkSuccessfullySend = () => {
    this.setState({ modalOpen: true }, () => {
      setTimeout(() => this.setState({ modalOpen: false }), 1000);
    });
  };

  handleTabChange = (value: number) => this.setState({ activeTab: value });

  toggleCancelInvoiceModal = () => this.setState((prevState) => ({
    cancelInvoiceModalOpen: !prevState.cancelInvoiceModalOpen
  }));

  toggleEditAdvisorModal = () => this.setState((prevState) => ({
    editAdvisorModalOpen: !prevState.editAdvisorModalOpen
  }));

  toggleDeleteAdvisorModal = () => this.setState((prevState) => ({
    deleteAdvisorModalOpen: !prevState.deleteAdvisorModalOpen
  }));

  handleRefetchSinglePaymentData = (data: any) => {
    const { refetchSinglePayments } = this.props;
    refetchSinglePayments(data);
  };

  handleRefetchSubscriptionData = (data: any) => {
    const { refetchSubscription } = this.props;
    refetchSubscription(data);
  };

  handleRefetchClients = (data: any) => {
    const { refetchClients } = this.props;
    refetchClients(data);
  };

  handleCancelPayment = () => {
    const { refetchSinglePayments, singlePaymentsSort, singlePaymentsPagination } = this.props;
    refetchSinglePayments({
      sort: singlePaymentsSort,
      pagination: singlePaymentsPagination
    });
  };

  render() {
    const {
      props: {
        advisorInfo,
        singlePayments,
        singlePaymentsPagination,
        singlePaymentsSort,
        singlePaymentsTotal,
        subscriptions,
        subscriptionsPagination,
        subscriptionsSort,
        subscriptionsTotal,
        clients,
        clientsPagination,
        clientsSort,
        clientsTotal,
        getInfo,
        history,
      },
      state: {
        activeTab,
        modalOpen,
        cancelInvoiceModalOpen,
        cancelInvoiceId,
        nextBilledDate,
        cancelInvoiceStatus,
        editAdvisorModalOpen,
        deleteAdvisorModalOpen,
      }
    } = this;

    return (
      <Provider
        value={{
          activeTab,
          link: advisorInfo.invite_link,
          singlePayments,
          singlePaymentsPagination,
          singlePaymentsSort,
          singlePaymentsTotal,
          subscriptionsPagination,
          subscriptionsSort,
          subscriptions,
          subscriptionsTotal,
          clients,
          clientsPagination,
          clientsSort,
          clientsTotal,
          onTabChange: this.handleTabChange,
          onLinkClick: this.handleSendLink,
          refetchSinglePaymentData: this.handleRefetchSinglePaymentData,
          refetchClients: this.handleRefetchClients,
          refetchSubscriptionData: this.handleRefetchSubscriptionData,
          singlePaymentActions: this.singlePaymentActions,
          subscriptionActions: this.subscriptionActions,
          clientActions: this.clientActions,
        }}
      >
        <Content
          onDeleteProfile={this.toggleDeleteAdvisorModal}
          onEditProfile={this.toggleEditAdvisorModal}
          modalOpen={modalOpen}
          advisorInfo={advisorInfo}
        />
        <CancelInvoiceModal
          info={
            !activeTab
              ? subscriptions.find((item) => item.id === cancelInvoiceId)
              : singlePayments.find((item) => item.id === cancelInvoiceId)
          }
          status={cancelInvoiceStatus}
          isSubscription={Boolean(!activeTab)}
          userRole="advisor"
          open={cancelInvoiceModalOpen}
          close={this.toggleCancelInvoiceModal}
          id={cancelInvoiceId}
          callback={this.handleCancelPayment}
          nextBilledDate={nextBilledDate}
        />
        <DeleteAdvisorModal
          open={deleteAdvisorModalOpen}
          close={() => {
            this.toggleDeleteAdvisorModal();
            getInfo(advisorInfo.id);
          }}
          id={advisorInfo.id}
          callback={() => history.push('/users/advisors')}
          advisorHasClients={advisorInfo.registered_clients_count + advisorInfo.unregistered_clients_count}
        />
        <EditAdvisorModal
          open={editAdvisorModalOpen}
          close={this.toggleEditAdvisorModal}
          id={advisorInfo.id}
          advisorInfo={advisorInfo}
          callback={() => getInfo(advisorInfo.id)}
        />
      </Provider>
    );
  }
}

export default Container;
