import { connect } from 'react-redux';

import Container from './Container';
import {
  logout,
  setStep,
  submitCompanyStep,
  getOrganization,
  getAccountInfo,
  submitAccountInfoStep,
  submitBankingStep,
  submitBillingStep,
  getBankingAccount,
  getBillingInfo,
  finishOnBoarding,
} from '../../redux/actions/onBoaring';
import { getUser } from '../../redux/actions/Auth';

const mapStateToProps = (state: any) => ({
  step: state.onBoarding.step,
  companyData: state.onBoarding.companyData,
  accountInfo: state.onBoarding.accountInfo,
  billingInfo: state.onBoarding.billingInfo,
  bankingAccountData: state.onBoarding.bankingAccountData,
  loading: state.onBoarding.loading,
  userId: state.auth.userData.id,
  userEmail: state.auth.userData.email,
  userRole: state.auth.userRole,
});
const mapDispatchToProps = {
  logout,
  setStep,
  submitCompanyStep,
  getAccountInfo,
  getOrganization,
  submitAccountInfoStep,
  getUser,
  submitBankingStep,
  submitBillingStep,
  getBankingAccount,
  getBillingInfo,
  finishOnBoarding,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
