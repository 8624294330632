import { all, fork } from 'redux-saga/effects';

import getClientInfo from './getClientInfo';
import sendLink from './sendLink';
import getSinglePayments from './getSinglePayments';
import getSubscription from './getSubscription';
import refetchSubscription from './refetchSubscription';
import refetchSinglePayments from './refetchSinglePayments';
import fetchInitData from './fetchInitData';

export default all([
  fork(getClientInfo),
  fork(sendLink),
  fork(getSinglePayments),
  fork(getSubscription),
  fork(refetchSubscription),
  fork(refetchSinglePayments),
  fork(fetchInitData),
]);
