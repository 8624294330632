import { all, fork } from 'redux-saga/effects';

import createClient from './createClient';
import getClients from './getClients';
import refetchClients from './refetchClients';
import getAllAdvisors from './getAllAdvisors';

export default all([
  fork(createClient),
  fork(getClients),
  fork(refetchClients),
  fork(getAllAdvisors),
]);
