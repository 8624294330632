import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

import { CheckBoxIcon, CheckBoxActiveIcon } from '../../metadata/icons';

// @ts-ignore
function StyledCheckbox(props) {
  return (
    <Checkbox
      style={{ padding: 0 }}
      disableRipple
      color="default"
      checkedIcon={<CheckBoxActiveIcon />}
      icon={<CheckBoxIcon color="#BCBEC0" />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...props}
    />
  );
}
export default StyledCheckbox;
