import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import Box from '@material-ui/core/Box';
import { FormattedMessage, IntlShape } from 'react-intl';
import { Form, Formik, Field } from 'formik';

import messages from './messages';
import { withParentData } from '../../Context';
import {
  FormikInput,
  Button,
  InputMask,
  FormikSelect,
  Modal,
  H1,
} from '../../../../components';
import validationSchema from './validationSchema';
import { STATES } from '../../../../constants';

interface Props {
  intl: IntlShape,
  submitCompany: (values: {}) => void,
  fetchInitCompanyData: () => void,
  companyData: any,
  loading: boolean,
  confirmPopupOpen: boolean,
  togglePopup: () => void,
  onPopupSubmit: () => void,
}

const AccountStep: React.FC<Props> = ({
  intl,
  submitCompany: submit,
  fetchInitCompanyData,
  companyData,
  loading,
  confirmPopupOpen,
  togglePopup,
  onPopupSubmit,
}) => {
  useEffect(() => {
    fetchInitCompanyData();
  }, [fetchInitCompanyData]);


  return (
    <Box width={600}>
      <H1 mb={5}><FormattedMessage {...messages.setUpAccountStep} /></H1>
      <Formik
        initialValues={{
          companyName: companyData.name || '',
          employerIN: companyData.employee_id || '',
          companyStreetAddress: companyData.address ? companyData.address.street || '' : '',
          city: companyData.address ? companyData!.address!.city || '' : '',
          state: companyData.address ? companyData!.address!.state || '' : '',
          zipCode: companyData.address ? companyData!.address!.zip_code || '' : '',
          companySite: companyData.contacts ? companyData!.contacts.web_site || '' : '',
          phoneNumber: companyData.contacts ? companyData!.contacts.phone_number || '' : '',
        }}
        enableReinitialize
        onSubmit={submit}
        validationSchema={validationSchema(intl)}
      >
        {({ isValid, dirty }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item md={6}>
                <Box
                  height={80}
                  border="1px solid #3772FF"
                  borderRadius={4}
                  display="flex"
                  alignItems="center"
                  px={3}
                  color="#3772FF"
                  // @ts-ignore
                  css={{ textTransform: 'uppercase' }}
                >
                  <Radio color="primary" checked size="medium" />
                  <FormattedMessage {...messages.legalEntity} />
                </Box>
              </Grid>
              <Grid item md={6} />
              <Grid item xs={12} md={6}>
                <Field
                  component={FormikInput}
                  label={intl.formatMessage(messages.companyNameField)}
                  name="companyName"
                  disabled={Object.keys(companyData).length !== 0}
                  helperText={Object.keys(companyData).length !== 0 && 'Sorry, this field cannot be edited.'}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  component={InputMask}
                  mask="99-9999999"
                  label={intl.formatMessage(messages.employerINField)}
                  name="employerIN"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  component={FormikInput}
                  label={intl.formatMessage(messages.companyStreetAddressField)}
                  name="companyStreetAddress"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  component={FormikInput}
                  label={intl.formatMessage(messages.cityField)}
                  name="city"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  name="state"
                  component={FormikSelect}
                  label={intl.formatMessage(messages.stateField)}
                  options={STATES}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  component={FormikInput}
                  label={intl.formatMessage(messages.zipCodeField)}
                  name="zipCode"
                  fullWidth
                  type="number"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  component={FormikInput}
                  label={intl.formatMessage(messages.companySiteField)}
                  name="companySite"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  component={InputMask}
                  mask="(999)999-9999"
                  label={intl.formatMessage(messages.phoneNumberField)}
                  name="phoneNumber"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Box mt={5} textAlign="end">
              <Button
                type="submit"
                variant="contained"
                loading={loading}
                disabled={Object.keys(companyData).length === 0 ? (!dirty || !isValid) : !isValid}
              >
                <FormattedMessage {...messages.nextButton} />
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      <Modal
        handleClose={togglePopup}
        open={confirmPopupOpen}
      >
        <Box bgcolor="white" p={3} width={400}>
          <Box>
            Take care, company name will be disabled for editing if you return to this step.
          </Box>
          <Box mt={3} display="flex" justifyContent="flex-end">
            <Box mr={3}>
              <Button onClick={togglePopup}>Cancel</Button>
            </Box>
            <Button onClick={onPopupSubmit} variant="contained">Next</Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default withParentData(AccountStep);
