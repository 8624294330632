import { INVOICE_TYPES } from '../../constants';

export const getInvoice = (id: string) => ({
  type: INVOICE_TYPES.GET_INVOICE_INFO,
  payload: id,
});

export const getInvoiceSuccess = (payload: any) => ({
  type: INVOICE_TYPES.GET_INVOICE_INFO_SUCCESS,
  payload,
});

export const invoiceError = (error: any) => ({
  type: INVOICE_TYPES.ERROR,
  payload: error
});
