import { defineMessages } from 'react-intl';

export default defineMessages({
  billingStep: {
    id: 'onBoarding.billingStep',
    defaultMessage: 'ChalicePay Billing',
    description: 'Navigation'
  },
  useDifferentCard: {
    id: 'onBoarding.billingStep.useDifferentCard',
    defaultMessage: 'Use Different Card',
    description: 'Navigation'
  },
  last4Ending: {
    id: 'onBoarding.billingStep.last4Ending',
    defaultMessage: 'Credit card ending in ',
    description: 'Navigation'
  },
  cityField: {
    id: 'fields.city',
    defaultMessage: 'City',
    description: 'Field label'
  },
  stateField: {
    id: 'fields.state',
    defaultMessage: 'State',
    description: 'Field label'
  },
  zipCodeField: {
    id: 'fields.zipCode',
    defaultMessage: 'Zip Code',
    description: 'Field label'
  },
  streetAddressField: {
    id: 'fields.streetAddress',
    defaultMessage: 'Street address',
    description: 'Field label'
  },
  CardholderNameField: {
    id: 'fields.CardholderName',
    defaultMessage: 'Card holder name',
    description: 'Field label'
  },
  cardHolderNameInvalidError: {
    id: 'fields.cardHolderName.error',
    defaultMessage: 'Cardholder name contains invalid symbols.',
    description: 'Field label'
  },
  cardHolderNameMaxError: {
    id: 'fields.cardHolderName.maxError',
    defaultMessage: 'Cardholder name is too long. Please limit to 71 characters.',
    description: 'Field label'
  },
  cardNumberField: {
    id: 'fields.cardNumber',
    defaultMessage: 'Card number',
    description: 'Field label'
  },
  cardNumberMaxError: {
    id: 'fields.cardNumber.maxError',
    defaultMessage: 'Credit card number should contain 16 digits (15 digits for American Express).',
    description: 'Field label'
  },
  cardNumberInvalidError: {
    id: 'fields.cardNumber.invalidError',
    defaultMessage: 'Credit card number contains invalid symbols.',
    description: 'Field label'
  },
  expiryDateField: {
    id: 'fields.expiryDate',
    defaultMessage: 'Expiry date',
    description: 'Field label'
  },
  expiryDateError: {
    id: 'fields.expiryDate.error',
    defaultMessage: 'Expiry date is invalid.',
    description: 'Field label'
  },
  cvcField: {
    id: 'fields.cvc',
    defaultMessage: 'CVC',
    description: 'Field label'
  },
  cvcFieldError: {
    id: 'fields.cvc.error',
    defaultMessage: 'CVC should contain three digits (four digits for American Express).',
    description: 'Field label'
  },
  creditCardDetails: {
    id: 'onBoarding.billingStep.creditCardDetails',
    defaultMessage: 'Credit Card Details',
    description: 'Text'
  },
  billingAddress: {
    id: 'onBoarding.billingStep.billingAddress',
    defaultMessage: 'Billing Address',
    description: 'Text'
  },
  requiredMassage: {
    id: 'onBoarding.requiredFieldMassage',
    defaultMessage: 'Please fill in all required fields.',
    description: 'Message'
  },
  companyStreetAddressMinError: {
    id: 'fields.companyStreetAddress.minError',
    defaultMessage: 'Street address should be at least 3 letters long.',
    description: 'Field label'
  },
  companyStreetAddressMaxError: {
    id: 'fields.companyStreetAddress.maxError',
    defaultMessage: 'Street address is too long.',
    description: 'Field label'
  },
  zipCodeErrorField: {
    id: 'fields.zipCode.error',
    defaultMessage: 'Zip code should contain from 5 up to 9 digits.',
    description: 'Field label'
  },
  backButton: {
    id: 'buttons.back',
    defaultMessage: 'Back',
    description: 'Button label'
  },
  submitButton: {
    id: 'buttons.submit',
    defaultMessage: 'Submit',
    description: 'Button label'
  },
});
