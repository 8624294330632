import {
  put, takeLatest, call, select
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { advisorsError, refetchAdvisors } from '../../actions/Advisors';
import { ADVISORS_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker({ payload, callback }: any): SagaIterator<void> {
  try {
    yield call(Api.advisors.createAdvisor, payload);
    const store = yield select();
    yield put(refetchAdvisors({
      sort: store.advisors.sort,
      pagination: store.advisors.pagination,
    }));
    yield callback(false);
  } catch (e) {
    yield put(advisorsError(e));
    yield callback(true);
  }
}

export default function* watcher() {
  yield takeLatest(ADVISORS_TYPES.CREATE_ADVISOR, worker);
}
