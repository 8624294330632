import React, { useEffect, useRef } from 'react';
import Box from '@material-ui/core/Box';
import { FormattedMessage, IntlShape } from 'react-intl';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';

import messages from '../../messages';

import {
  Button,
  FormikInput,
  Link,
  Checkbox,
  H1,
} from '../../../../components';
import { withParentData } from '../../Context';
import { REGEXP } from '../../../../constants/validation';

const validationSchema = (intl: IntlShape) => Yup.object().shape({
  password: Yup.string()
    .matches(REGEXP.PASSWORD)
    .required(),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), undefined], intl.formatMessage(messages.confirmPasswordError))
    .required(intl.formatMessage(messages.passwordRequired)),
});

interface Props {
  onSubmit: (values: {}) => void,
  intl: IntlShape,
  openTerms: (isValid: boolean) => void,
  onCheckboxChange: () => void,
  onFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  confirmPassword: string,
  password: string,
  checked: boolean,
  confirmPasswordTouched: boolean,
  passwordTouched: boolean,
  loading: boolean,
  formRef: React.Ref<any>,
  onFieldFocus: (event: React.FocusEvent<HTMLInputElement>) => void,
}

const EnterEmailForm: React.FC<Props> = ({
  onSubmit,
  intl,
  openTerms,
  onFieldChange,
  confirmPassword,
  password,
  checked,
  onCheckboxChange,
  onFieldFocus,
  passwordTouched,
  confirmPasswordTouched,
  loading,
}) => {
  const formRef = useRef(null);
  useEffect(() => {
    if (formRef && formRef.current && (passwordTouched || confirmPasswordTouched)) {
      // @ts-ignore
      formRef.current.validateForm().then(res => {
        // @ts-ignore
        formRef.current.setErrors(res);
        // @ts-ignore
        formRef.current.setTouched({ confirmPassword: confirmPasswordTouched, password: passwordTouched });
      });
    }
  }, [formRef, passwordTouched, confirmPasswordTouched]);

  return (
    <Box width={{
      xs: 560,
      md: 400,
      lg: 600,
    }}
    >
      <H1 mb={3} mt={5}>
        <FormattedMessage {...messages.setPasswordTitle} />
      </H1>
      <Formik
        initialValues={{ password, confirmPassword }}
        // @ts-ignore
        innerRef={formRef}
        onSubmit={onSubmit}
        validationSchema={validationSchema(intl)}
      >
        {({ dirty, isValid }) => (
          <Form>
            <Field
              component={FormikInput}
              label={intl.formatMessage(messages.password)}
              name="password"
              onChange={onFieldChange}
              onFocus={onFieldFocus}
              type="password"
              helperText={intl.formatMessage(messages.passwordHelperText)}
              fullWidth
            />
            <Box mt={4}>
              <Field
                component={FormikInput}
                onChange={onFieldChange}
                onFocus={onFieldFocus}
                type="password"
                label={intl.formatMessage(messages.confirmPassword)}
                name="confirmPassword"
                fullWidth
              />
            </Box>
            <Box color="#6D6E71" mt={3}>
              <Box mr={1} display="inline">
                <Checkbox
                  checked={checked}
                  onChange={onCheckboxChange}
                />
              </Box>
              <FormattedMessage {...messages.agreeStatement} />
              <Box fontSize={16} fontWeight={500} display="inline">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link onClick={() => openTerms(dirty || isValid)}>
                  <FormattedMessage {...messages.termsAndConditions} />
                </Link>
              </Box>
            </Box>
            <Box mt={5} mb={10}>
              <Button
                disabled={!checked || !isValid || (!dirty && (!passwordTouched || !confirmPasswordTouched))}
                type="submit"
                variant="contained"
                loading={loading}
              >
                <FormattedMessage {...messages.registerButton} />
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default withParentData(EnterEmailForm);
