import axios from './axiosInstance';

export default {
  login: () => axios.get('/users/login/'),
  loginCallback: (query: string) => axios.get(`/users/login-callback/${query}`),
  logout: () => axios.get('/users/logout/').then((res) => {
    document.cookie = 'csrftoken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    return res;
  }),
};
