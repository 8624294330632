import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { History } from 'history';

import general from './general';
import onBoarding from './onBoarding';
import auth from './auth';
import clients from './clients';
import clientProfile from './clientProfile';
import settings from './settings';
import wallet from './wallet';
import paymentModal from './paymentModal';
import invoice from './invoice';
import singlePayments from './singlePayments';
import transfers from './transfers';
import subscriptions from './subscriptions';
import subscriptionDetails from './subscriptionDetails';
import advisors from './advisors';
import advisorProfile from './advisorProfile';
import transferDetails from './transferDetails';

export default (history: History<History.PoorMansUnknown>) => combineReducers({
  router: connectRouter(history),
  toastr: toastrReducer,
  auth,
  advisors,
  advisorProfile,
  clients,
  clientProfile,
  general,
  invoice,
  onBoarding,
  paymentModal,
  settings,
  singlePayments,
  subscriptionDetails,
  subscriptions,
  transferDetails,
  transfers,
  wallet,
});
