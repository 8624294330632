import {
  put, takeLatest, call, select
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { advisorProfileError, getInfo } from '../../actions/AdvisorProfile';
import { ADVISOR_PROFILE_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker({ payload }: any): SagaIterator<void> {
  try {
    const { advisorProfile } = yield select();
    const { data } = yield call(Api.clients.resendVerificationEmail, advisorProfile.info.email);
    yield put(getInfo(data.id));
    yield payload();
  } catch (e) {
    yield put(advisorProfileError(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(ADVISOR_PROFILE_TYPES.SEND_LINK, worker);
}
