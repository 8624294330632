import { put, takeLatest, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { onBoardingError, getBankingAccountSuccess } from '../../actions/onBoaring';
import { ONBOARDING_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker(): SagaIterator<void> {
  try {
    const { data } = yield call(Api.userProfiles.getBankingAccounts);
    yield put(getBankingAccountSuccess(data.length === 0 ? {} : data[0]));
  } catch (e) {
    yield put(onBoardingError(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(ONBOARDING_TYPES.GET_BANKING_ACCOUNT, worker);
}
