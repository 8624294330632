import { all, fork } from 'redux-saga/effects';

import fetchInitData from './fetchInitData';
import refetchCompletedTransfers from './refetchCompletedTransfers';
import refetchPendingTransfers from './refetchPendingTransfers';

export default all([
  fork(fetchInitData),
  fork(refetchCompletedTransfers),
  fork(refetchPendingTransfers),
]);
