import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';

import { FormattedMessage } from 'react-intl';
import { withParentData } from '../../Context';
import { SendIcon } from '../../../../metadata/icons';
import messages from '../../messages';

const SendButton = withStyles((theme) => ({
  root: {
    height: 48,
    width: 48,
    minWidth: 48,
    background: '#3772FF',
    '&:hover': {
      background: theme.palette.info.main,
      boxShadow: '0px 8px 8px rgba(55, 114, 255, 0.3)'
    },
    '&:focus': {
      background: theme.palette.info.dark,
      boxShadow: '0px 4px 4px rgba(55, 114, 255, 0.3)',
    },
  }
}))(Button);

interface Props {
  link: string,
  onLinkClick: () => {},
}

const MyComponent: React.FC<Props> = ({ link, onLinkClick }) => (
  <Box mb={3}>
    <Box fontSize={24} mb={1}><FormattedMessage {...messages.invitationLink} /></Box>
    <Box display="flex">
      <Box
        width="100%"
        height={48}
        mr={3}
        boxShadow="4px 10px 20px rgba(55, 114, 255, 0.1)"
        borderRadius={4}
        paddingX={3}
        bgcolor="white"
        lineHeight="48px"
        display="inline-block"
        overflow="hidden"
        textOverflow="ellipsis"
        css={{ whiteSpace: 'nowrap' }}
      >
        {link}
      </Box>
      <SendButton onClick={onLinkClick}>
        <Box
          position="absolute"
          top={9}
          left={14}
          style={{ transform: 'rotate(-45deg)' }}
        >
          <SendIcon color="white" />
        </Box>
      </SendButton>
    </Box>
  </Box>
);

export default withParentData(MyComponent);
