import React from 'react';
import Box from '@material-ui/core/Box';
import DoneIcon from '@material-ui/icons/Done';

interface Props {
  activeStep: number,
  steps: string[],
}

const Navigation: React.FC<Props> = ({
  activeStep,
  steps,
}) => (
  <Box
    display="flex"
    justifyContent="space-between"
    maxWidth={800}
    width="100%"
  >
    {steps.map((step, index) => (
      <Box
        key={step}
        p="12px"
        borderRadius={50}
        fontWeight={500}
        bgcolor={activeStep === index ? '#3772FF' : '#F1F2F2'}
        color={activeStep === index ? 'white' : '#6D6E71'}
        boxShadow={activeStep === index ? '0px 4px 4px rgba(55, 114, 255, 0.3)' : 'none'}
      >
        <span>
          {activeStep > index ? <DoneIcon style={{ fontSize: '24px', marginRight: '8px' }} /> : `${index + 1}. `}
        </span>
        {step}
      </Box>
    ))}
  </Box>
);

export default Navigation;
