import React from 'react';
import Box from '@material-ui/core/Box';

import { OnboardingContainer, Button } from '../../components';
import {
  Navigation,
  AccountInfoStep,
  AccountStep,
  BankingStep,
  BillingStep,
  ReviewStep,
  BankingSuccessStep
} from './components';

interface Props {
  activeStep: number,
  logout: () => void,
}

const RenderSteps = ({ activeStep }: { activeStep: number }) => {
  switch (activeStep) {
    case 0:
      return <AccountStep />;
    case 1:
      return <AccountInfoStep />;
    case 2:
      return <BankingStep />;
    case 2.5:
      return <BankingSuccessStep />;
    case 3:
      return <BillingStep />;
    case 4:
      return <ReviewStep />;
    default: return <AccountStep />;
  }
};

const Content: React.FC<Props> = ({
  activeStep,
  logout,
}) => (
  <OnboardingContainer
    renderLeft={() => <Navigation />}
    renderRight={() => (
      <Box p={3} height={{ xs: 'inherit', md: '100vh' }}>
        <Box height="100%" display="flex" flexDirection="column" style={{ overflowY: 'auto' }}>
          {activeStep !== 4 && (
            <Box display="flex" justifyContent="flex-end">
              <Button variant="text" onClick={logout}>Log out</Button>
            </Box>
          )}
          <Box
            justifyContent="center"
            display="flex"
            alignItems="center"
            flex="1 1 auto"
            height={activeStep ? 'initial' : '100%'}
          >
            <RenderSteps activeStep={activeStep} />
          </Box>
        </Box>
      </Box>
    )}
  />
);

export default Content;
