import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import moment from 'moment';

import {
  H2,
  H3,
  Table,
  MakePaymentModal,
  NewAppSubscriptionModal,
  Button,
  H3Dark,
} from '../../../../components';
import { withParentData } from '../../Context';
import { chaliceFee } from '../../../../constants';

const getSubscriptionStatusText = (status: string, subscription: any) => {
  switch (status) {
    case 'trialing':
      return `We offer a 14-day entirely free trial period. You will be charged our monthly fixed fee of $25 on 
      ${moment(subscription.date_started || undefined).add({ week: 2 }).format('MM/DD/YYYY')}`;
    case 'incomplete':
      return `Your first monthly payment failed due to your billing card error or insufficient funds.
       Please check your billing card and pay the recent failed invoice to activate the subscription.
       Our monthly fixed fee is $25.`;
    case 'expired':
      return `Your subscription expired due to the payment failure.
       Please check your billing card and start a new subscription.`;
    case 'active':
      return `Your subscription is in a good shape! The next bill date is 
        ${moment(subscription.next_bill_date).format('MM/DD/YYYY')}
        Our monthly fixed fee is $25.`;
    case 'past_due':
      return `Your recent payment failed due to the card error or insufficient funds.
       Please check your billing card and pay the recent invoice.
       Our monthly fixed fee is $25.`;
    case 'unpaid':
      return 'Your subscription is deactivated. Please pay the recent invoice to continue using ChalicePay.';
    default:
      return '';
  }
};

const columns = [
  {
    Header: 'Status',
    accessor: (row: any) => <span style={{ textTransform: 'uppercase' }}>{row.status.replace(/_/g, ' ')}</span>,
    id: 'status',
  },
  {
    Header: 'Due date',
    accessor: (row: any) => moment(row.due_date).format('MM/DD/YYYY'),
    id: 'due_date',
  },
  {
    Header: 'Amount',
    accessor: (row: any) => (`$${row.amount / 100}`),
    id: 'amount',
  },
];

interface Props {
  billingInvoices: {}[],
  billingInvoicesTotal: number,
  billingInvoicesPagination: { page: number },
  billingInvoicesSort: { id: string, firstDirection: boolean },
  onRefetchBillingHistory: (data: {}) => void,
  billingActions: any,
  appSubscription: any,
  paymentId: string,
  paymentModalOpen: boolean,
  togglePaymentModal: () => void,
  getAppSubscription: () => void,
  onAccountDelete: () => void,
}

const BillingSection: React.FC<Props> = ({
  billingInvoices,
  billingInvoicesTotal,
  billingInvoicesSort,
  billingInvoicesPagination,
  onRefetchBillingHistory,
  billingActions,
  paymentId,
  paymentModalOpen,
  togglePaymentModal,
  appSubscription: { status, ...subscription },
  getAppSubscription,
  onAccountDelete,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Box mt={5}>
      <Box mb={3} display="flex" justifyContent="space-between">
        <H2>
          <span>ChalicePay Subscription Status - </span>
          <span style={{
            color: status === 'expired' || status === 'unpaid' || status === 'past_due'
              ? '#E20338'
              : 'inherit'
          }}
          >
            {status.toUpperCase()}
          </span>
        </H2>
        <Box display="flex">
          {status === 'expired' && (
            <Box mr={3}>
              <NewAppSubscriptionModal
                open={modalOpen}
                close={() => setModalOpen(false)}
                callback={getAppSubscription}
              />
              <Button onClick={() => setModalOpen(true)}>
                New Subscription
              </Button>
            </Box>
          )}
          <Button
            color="secondary"
            variant="contained"
            onClick={onAccountDelete}
          >
            Delete Account
          </Button>
        </Box>
      </Box>
      <H3Dark mb={3}>{getSubscriptionStatusText(status, subscription)}</H3Dark>
      <H2>Billing History</H2>
      <H3 mt={2} mb={2}>{`Our monthly fixed fee is $${chaliceFee}.`}</H3>
      <Table
        actions={billingActions}
        columns={columns}
        data={billingInvoices}
        withPagination
        pagination={billingInvoicesPagination}
        sort={billingInvoicesSort}
        refetchData={onRefetchBillingHistory}
        countTotal={billingInvoicesTotal}
        getRowError={({ original }: any) => original.status === 'failed'}
        errorRowMessage="Please check your billing card and try making payment."
      />
      <MakePaymentModal
        isAppSubscription
        close={togglePaymentModal}
        paymentId={paymentId}
        open={paymentModalOpen}
      />
    </Box>
  );
};

export default withParentData(BillingSection);
