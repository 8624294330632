import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage, IntlShape } from 'react-intl';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';

import messages from './messages';

import {
  Button,
  FormikInput,
  H2,
  FormikSelect, Help, H3Dark
} from '../../../../components';
import { withParentData } from '../../Context';
import { REGEXP } from '../../../../constants/validation';

const validationSchema = (intl: IntlShape) => Yup.object().shape({
  firstName: Yup.string()
    .max(35, intl.formatMessage(messages.firstNameError))
    .matches(/^[a-zA-Z- ]{1,1000}$/, intl.formatMessage(messages.firstNameInvalidError))
    .required(intl.formatMessage(messages.requiredMassage)),
  lastName: Yup.string()
    .max(35, intl.formatMessage(messages.lastNameError))
    .matches(/^[a-zA-Z- ]{1,1000}$/, intl.formatMessage(messages.lastNameInvalidError))
    .required(intl.formatMessage(messages.requiredMassage)),
  email: Yup.string()
    .matches(REGEXP.EMAIL, intl.formatMessage(messages.invalidEmailMessage))
    .required(intl.formatMessage(messages.requiredMassage)),
  permission: Yup.string()
    .required(intl.formatMessage(messages.requiredMassage)),
});

interface Props {
  intl: IntlShape,
  onAddAdvisor: (values: {}) => void,
  onCloseModal: () => void,
}

const AdvisorForm: React.FC<Props> = ({
  intl,
  onAddAdvisor: submit,
  onCloseModal,
}) => (
  <Box p={3} bgcolor="white" width={650}>
    <H2 mb={2}>Add advisor</H2>
    <Formik
      enableReinitialize
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        permission: '',
      }}
      onSubmit={submit}
      validationSchema={validationSchema(intl)}
    >
      {({ isValid, dirty }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Field
                component={FormikInput}
                name="firstName"
                label={intl.formatMessage(messages.firstNameField)}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={FormikInput}
                name="lastName"
                label={intl.formatMessage(messages.lastNameField)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={FormikInput}
                name="email"
                label={intl.formatMessage(messages.emailLabel)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={11}>
                  <Field
                    component={FormikSelect}
                    name="permission"
                    label="Advisor permission"
                    options={[
                      { label: 'Managing Advisor', value: 'managing_advisor' },
                      { label: 'Standard Advisor', value: 'advisor' }
                    ]}
                  />
                </Grid>
                <Grid item xs={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Help
                    title={(
                      <>
                        <H2>Managing Advisor: </H2>
                        <H3Dark>
                          Managing Advisor can invite other advisors, view other advisors clients and manage
                          billing for their own or other advisors clients.
                        </H3Dark>
                        <H2>Standard Advisor:</H2>
                        <H3Dark>Standard Advisors can only view and bill their own clients.</H3Dark>
                      </>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box mt={3} display="flex" justifyContent="flex-end">
            <Box mr={2}>
              <Button variant="text" onClick={onCloseModal}>
                <FormattedMessage {...messages.cancelButton} />
              </Button>
            </Box>
            <Button variant="text" type="submit" disabled={!dirty || !isValid}>
              <FormattedMessage {...messages.addButton} />
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  </Box>
);

export default withParentData(AdvisorForm);
