import axios from './axiosInstance';
import { fetchWithFilters } from './helpers';

export default {
  createSinglePayment: (data: {}) => axios.post('/payments/invoices/', data),
  getPayments: () => axios.get('/payments/invoices/'),
  getClientPayments: (id: string) => axios.get('/payments/invoices/', { params: { client: id } }),
  getAdvisorPayments: (id: string) => axios.get('/payments/invoices/', { params: { advisor: id } }),
  getPaymentById: (id: string) => axios.get(`/payments/invoices/${id}/`),
  refetchPayments: (data: any) => fetchWithFilters('/payments/invoices/', data),
  createOneTimePayments: (data: {}) => axios.post('/payments/one-time-payments/', data),
  refundInvoice: ({ data, id }: any) => axios.post(`/payments/invoices/${id}/refund/`, data, id),
  cancelInvoice: (id: string) => axios.delete(`/payments/invoices/${id}/`),
  getPendingTransfers: () => axios.get('/payments/transfers/pending/'),
  refetchPendingTransfers: (data: any) => fetchWithFilters('/payments/transfers/pending/', data),
  downloadTransfers: () => axios.get('/payments/transfers-csv'),
  getCompletedTransfersInvoices: (id: string) => axios.get(`/payments/transfers/complete/${id}/payout-invoices/`),
  refetchCompletedTransfersInvoices: ({ id, data }: any) => fetchWithFilters(
    `/payments/transfers/complete/${id}/payout-invoices/`, data
  ),
  getCompletedTransfers: () => axios.get('/payments/transfers/complete/'),
  refetchCompletedTransfers: (data: any) => fetchWithFilters('/payments/transfers/complete/', data),
  fetchBillingHistory: (id: string | number) => axios.get(`/payments/invoices/${id}/my-billing-history/`),
  refetchBillingHistory: ({ data, id }: any) => fetchWithFilters(`/payments/invoices/${id}/my-billing-history/`, data),
  getRefunds: (invoice: string) => axios.get('/payments/refunds/', { params: { invoice } }),
};
