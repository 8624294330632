import { ONBOARDING_TYPES } from '../../constants';
import { Error, VerifiedEmailData, CompanyFormData } from './types';

export const onBoardingError = (error: Error) => ({
  type: ONBOARDING_TYPES.ONBOARDING_ERROR,
  payload: error
});

export const singUpWithEmail = (email: string) => ({
  type: ONBOARDING_TYPES.SING_IN_WITH_EMAIL_REQUEST,
  payload: email
});

export const singUpWithEmailSuccess = () => ({
  type: ONBOARDING_TYPES.SING_IN_WITH_EMAIL_SUCCESS,
});

export const verifyEmail = (hash: string) => ({
  type: ONBOARDING_TYPES.VERIFY_EMAIL_REQUEST,
  payload: hash
});

export const verifyEmailSuccess = (data: VerifiedEmailData | null) => ({
  type: ONBOARDING_TYPES.VERIFY_EMAIL_SUCCESS,
  payload: data
});

export const setUpPassword = (password: string, email: string) => ({
  type: ONBOARDING_TYPES.SET_UP_PASSWORD_REQUEST,
  payload: { password, email }
});

export const setUpPasswordSuccess = () => ({
  type: ONBOARDING_TYPES.SET_UP_PASSWORD_SUCCESS,
});

export const login = () => ({
  type: ONBOARDING_TYPES.GO_TO_LOGIN,
});

export const setStep = (step: number) => ({
  type: ONBOARDING_TYPES.SET_STEP,
  payload: step
});

export const submitCompanyStep = (payload: CompanyFormData) => ({
  type: ONBOARDING_TYPES.SUBMIT_COMPANY_STEP,
  payload,
});

export const submitCompanyStepSuccess = (payload: any) => ({
  type: ONBOARDING_TYPES.SUBMIT_COMPANY_STEP_SUCCESS,
  payload,
});

export const submitAccountInfoStep = (payload: CompanyFormData) => ({
  type: ONBOARDING_TYPES.SUBMIT_ACCOUNT_INFO_STEP,
  payload,
});

export const submitAccountInfoStepSuccess = () => ({
  type: ONBOARDING_TYPES.SUBMIT_ACCOUNT_INFO_STEP_SUCCESS,
});

export const submitBankingStep = (payload: CompanyFormData) => ({
  type: ONBOARDING_TYPES.SUBMIT_BANKING_STEP,
  payload,
});

export const submitBankingStepSuccess = () => ({
  type: ONBOARDING_TYPES.SUBMIT_BANKING_STEP_SUCCESS,
});

export const submitBillingStep = (payload: CompanyFormData) => ({
  type: ONBOARDING_TYPES.SUBMIT_BILLING_STEP,
  payload,
});

export const submitBillingStepSuccess = () => ({
  type: ONBOARDING_TYPES.SUBMIT_BANKING_STEP_SUCCESS,
});

export const getOrganization = () => ({
  type: ONBOARDING_TYPES.GET_COMPANY
});

export const getOrganizationSuccess = (payload: any) => ({
  type: ONBOARDING_TYPES.GET_COMPANY_SUCCESS,
  payload
});

export const getBankingAccount = () => ({
  type: ONBOARDING_TYPES.GET_BANKING_ACCOUNT
});

export const getBankingAccountSuccess = (payload: any) => ({
  type: ONBOARDING_TYPES.GET_BANKING_ACCOUNT_SUCCESS,
  payload
});

export const getAccountInfo = () => ({
  type: ONBOARDING_TYPES.GET_ACCOUNT_INFO
});

export const getAccountInfoSuccess = (payload: any) => ({
  type: ONBOARDING_TYPES.GET_ACCOUNT_INFO_SUCCESS,
  payload
});

export const getBillingInfo = () => ({
  type: ONBOARDING_TYPES.GET_BILLING_INFO
});

export const getBillingInfoSuccess = (payload: any) => ({
  type: ONBOARDING_TYPES.GET_BILLING_INFO_SUCCESS,
  payload
});

export const finishOnBoarding = (payload: 5 | 6) => ({
  type: ONBOARDING_TYPES.FINISH_ONBOARDING,
  payload
});

export const finishOnBoardingSuccess = () => ({
  type: ONBOARDING_TYPES.FINISH_ONBOARDING_SUCCESS,
});


export const logout = () => ({
  type: ONBOARDING_TYPES.LOGOUT,
});
