/* eslint-disable implicit-arrow-linebreak */
import axios from './axiosInstance';

export default {
  getUsers: () => axios.get('/users/'),
  getUsersById: (id: string) => axios.get(`/users/${id}`),
  sendVerificationEmail: (email: string) => axios.post('/users/send-verification-email/', { email }),
  resendVerificationEmail: (email: string) => axios.post('/users/re-send-verification-email/', { email }),
  verifyEmailLink: (hash: string) => axios.post('/users/verify-email-link/', { hash }),
  setUserPassword: ({ email, password }: { email: string, password: string }) =>
    axios.post('/users/set-user-password/', { email, password, password_confirm: password }),
  setOnboardingStatus: ({ step, email }: { step: number, email: string }) => axios.post('/users/set-user-status/', {
    status: step,
    email,
  }),
  changeUserPassword: (data: {}) => axios.post('/users/change-user-password/', data),
  deleteAccount: (data: any) => axios.post('/users/delete-account/', data)
};
