import { SETTINGS_TYPES } from '../../constants';

export const fetchInitData = (payload: string) => ({
  type: SETTINGS_TYPES.FETCH_INIT_DATA,
  payload,
});

export const fetchInitDataSuccess = (payload: any) => ({
  type: SETTINGS_TYPES.FETCH_INIT_DATA_SUCCESS,
  payload,
});

export const getCompanyDetails = () => ({
  type: SETTINGS_TYPES.GET_COMPANY_DETAILS,
});

export const getCompanyDetailsSuccess = (payload: any) => ({
  type: SETTINGS_TYPES.GET_COMPANY_DETAILS_SUCCESS,
  payload,
});

export const getAppSubscription = () => ({
  type: SETTINGS_TYPES.GET_APP_SUBSCRIPTION,
});

export const getAppSubscriptionSuccess = (payload: any) => ({
  type: SETTINGS_TYPES.GET_APP_SUBSCRIPTION_SUCCESS,
  payload,
});

export const getBillingHistory = () => ({
  type: SETTINGS_TYPES.GET_BILLING_HISTORY,
});

export const getBillingHistorySuccess = (payload: any) => ({
  type: SETTINGS_TYPES.GET_BILLING_HISTORY_SUCCESS,
  payload,
});

export const refetchBillingHistory = (payload: any) => ({
  type: SETTINGS_TYPES.REFETCH_BILLING_HISTORY,
  payload,
});

export const refetchBillingHistorySuccess = (payload: any) => ({
  type: SETTINGS_TYPES.REFETCH_BILLING_HISTORY_SUCCESS,
  payload,
});

export const getBankingDetails = () => ({
  type: SETTINGS_TYPES.GET_BANK_DETAILS,
});

export const getBankingDetailsSuccess = (payload: any) => ({
  type: SETTINGS_TYPES.GET_BANK_DETAILS_SUCCESS,
  payload,
});

export const settingsError = (error: any) => ({
  type: SETTINGS_TYPES.ERROR,
  payload: error
});
