/* eslint-disable @typescript-eslint/quotes  */
import { defineMessages } from 'react-intl';

export default defineMessages({
  requestButton: {
    id: 'buttons.requestButton',
    defaultMessage: 'Request payment',
    description: 'Button label'
  },
  totalRevenue: {
    id: 'clientProfile.totalRevenue',
    defaultMessage: 'Total revenue',
    description: 'Button label'
  },
  addedOn: {
    id: 'clientProfile.addedOn',
    defaultMessage: 'Added On',
    description: 'Button label'
  },
  invitationLink: {
    id: 'clientProfile.invitationLink',
    defaultMessage: 'Invitation Link',
    description: 'text'
  },
  invitationLinkSuccess: {
    id: 'clientProfile.invitationLinkSuccess',
    defaultMessage: 'The invitation is on the way! Your client will receive a link for signing up soon.',
    description: 'text'
  },
});
