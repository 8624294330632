import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { settingsError, fetchInitDataSuccess } from '../../actions/Settings';
import { SETTINGS_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker({ payload }: any): SagaIterator<void> {
  try {
    const requestObject = {
      billing: call(Api.settings.getCreditCards),
      banking: call(Api.settings.getBanks),
      organization: call(Api.settings.getOrganizations),
      billingHistory: call(Api.settings.fetchBillingHistory, payload),
      appSubscription: call(Api.settings.getAppSubscription)
    };
    const data = yield all(requestObject);
    yield put(fetchInitDataSuccess(data));
  } catch (e) {
    yield put(settingsError(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(SETTINGS_TYPES.FETCH_INIT_DATA, worker);
}
