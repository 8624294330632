import { put, takeLatest, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { advisorProfileError, getSinglePaymentsSuccess } from '../../actions/AdvisorProfile';
import { ADVISOR_PROFILE_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker({ payload }: { payload: string }): SagaIterator<void> {
  try {
    const { data } = yield call(Api.payments.getAdvisorPayments, payload);
    yield put(getSinglePaymentsSuccess(data));
  } catch (e) {
    yield put(advisorProfileError(e.message));
  }
}

export default function* watcher() {
  // @ts-ignore
  yield takeLatest(ADVISOR_PROFILE_TYPES.GET_SINGLE_PAYMENTS, worker);
}
