import { put, takeLatest, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { invoiceError, getInvoiceSuccess } from '../../actions/Invoice';
import { INVOICE_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker({ payload }: any): SagaIterator<void> {
  try {
    const { data } = yield call(Api.payments.getPaymentById, payload);
    const { data: refundsData } = yield call(Api.payments.getRefunds, payload);

    yield put(getInvoiceSuccess({
      invoice: data,
      refunds: refundsData.results.filter((item: any) => item.invoice === payload),
    }));
  } catch (e) {
    yield put(invoiceError(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(INVOICE_TYPES.GET_INVOICE_INFO, worker);
}
