import { useEffect } from 'react';

import Api from '../../api';

const Login = () => {
  useEffect(() => {
    Api.auth.login().then(({ data }) => {
      window.location.href = data.url;
    });
  }, []);

  return null;
};

export default Login;
