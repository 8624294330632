import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import App from './App';

if (process.env.GTM_ID) {
  const tagManagerArgs = {
    gtmId: process.env.GTM_ID
  };

  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(<App />, document.getElementById('root'));
