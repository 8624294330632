import axios from 'axios';

import { apiUrl } from '../constants';


const axiosInstance = axios.create({
  timeout: 60000,
  baseURL: apiUrl,
});

function getCookie(cname: string) {
  const name = `${cname}=`;
  // eslint-disable-next-line no-undef
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

axiosInstance.interceptors.request.use(config => {
  const updatedConfig = { ...config };
  if (document.cookie) {
    updatedConfig.headers['X-CSRFToken'] = getCookie('csrftoken');
  }

  return updatedConfig;
});

axiosInstance.interceptors.response.use(config => config, error => Promise.reject(error));

export default axiosInstance;
