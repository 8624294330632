import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import DoneIcon from '@material-ui/icons/Done';
import Modal from '../../Modal';
import Api from '../../../api';
import H2 from '../../H2';
import H3 from '../../H3';
import Button from '../../Button';

interface Props {
  open: boolean,
  close: () => void,
  id: string,
  callback: () => void,
}

const DeleteModal: React.FC<Props> = ({
  open,
  close,
  id,
  callback
}) => {
  const [doneStatus, setDoneStatus] = useState(false);

  useEffect(() => {
    if (open) {
      setDoneStatus(false);
    }
  }, [open]);

  const handleDelete = () => {
    Api.userProfiles.deleteCreditCard(id)
      .then(() => {
        setDoneStatus(true);
        setTimeout(() => {
          close();
          callback();
        }, 1000);
      });
  };

  return (
    <Modal
      open={open}
      handleClose={close}
    >
      {!doneStatus ? (
        <Box p={3}>
          <H2>Delete Credit Card?</H2>
          <H3 mt={2}>Are you sure you want to delete this credit card?</H3>
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <Box mr={2}><Button variant="text" onClick={close}>cancel</Button></Box>
            <Button variant="text" onClick={handleDelete}>delete</Button>
          </Box>
        </Box>
      ) : (
        <Box p={5} bgcolor="white" width={450} display="flex" flexDirection="column" alignItems="center">
          <Box color="#3772FF" fontSize={64}><DoneIcon fontSize="inherit" /></Box>
          <H2 mt={3}>
            Credit Card Deleted
          </H2>
        </Box>
      )}
    </Modal>
  );
};

export default DeleteModal;
