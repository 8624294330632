import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import DoneIcon from '@material-ui/icons/Done';
import moment from 'moment';

import Modal from '../Modal';
import H2 from '../H2';
import H3 from '../H3';
import Button from '../Button';
import Api from '../../api';
import { ROLES } from '../../constants';

const InvoiceContent = () => (
  <>
    <H2>Cancel invoice?</H2>
    <H3 mt={2}>Are you sure you want to cancel this invoice?</H3>
  </>
);

const SubscriptionContent: React.FC<{
  role?: string,
  nextBilledDate?: string,
  status?: string,
  info?: any,
}> = ({
  status, role, nextBilledDate, info
}) => (
  <>
    <H2>Cancel subscription?</H2>
    <H3 mt={2}>
      {(status === 'inactive' || status === 'past_due' || status === 'unpaid' || status === 'incomplete')
        && 'Canceled subscription cannot be activated.'}
      {status === 'active' && (
        <>
          <span>
            {role !== ROLES.CLIENT
              ? `Your client paid the full amount for this ${info.frequency === 'quarterly' ? 'quarter' : 'month'}.
                Please make sure your service is provided until ${moment(nextBilledDate).format('MM/DD/YYYY')}
                or check if you can refund the recent invoice.`
              : 'Please contact your advisor to make sure the service is provided until '}
          </span>
          {role === ROLES.CLIENT && <span>{moment(nextBilledDate).format('MM/DD/YYYY')}</span>}
        </>
      )}
    </H3>
  </>
);

interface Props {
  open: boolean,
  close: () => void,
  id: string,
  callback: () => void,
  isSubscription?: boolean,
  userRole?: string,
  nextBilledDate?: string,
  status?: string,
  info?: any,
}

const CancelModal: React.FC<Props> = ({
  open,
  close,
  id,
  callback,
  isSubscription,
  nextBilledDate,
  userRole,
  status,
  info,
}) => {
  const [doneStatus, setDoneStatus] = useState(true);
  const handleCancel = () => {
    if (isSubscription) {
      Api.subscriptions.cancelSubscription(id).then(() => {
        setDoneStatus(true);
        setTimeout(() => {
          close();
          callback();
        }, 1000);
      });
    } else {
      Api.payments.cancelInvoice(id).then(() => {
        setDoneStatus(true);
        setTimeout(() => {
          close();
          callback();
        }, 1000);
      });
    }
  };

  useEffect(() => {
    if (open) {
      setDoneStatus(false);
    }
  }, [open]);

  return (
    <Modal
      handleClose={close}
      open={open}
    >
      {!doneStatus ? (
        <Box p={3} maxWidth={500}>
          {!isSubscription && <InvoiceContent />}
          {isSubscription && (
            <SubscriptionContent
              status={status}
              role={userRole}
              nextBilledDate={nextBilledDate}
              info={info}
            />
          )}
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <Box mr={2}>
              <Button variant="text" onClick={close}>dismiss</Button>
            </Box>
            <Button variant="text" onClick={handleCancel}>
              {isSubscription ? 'Cancel Subscription' : 'CANCEL invoice'}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box p={5} bgcolor="white" width={450} display="flex" flexDirection="column" alignItems="center">
          <Box color="#3772FF" fontSize={64}><DoneIcon fontSize="inherit" /></Box>
          <H2 mt={3}>
            {isSubscription ? 'Subscription Canceled!' : 'Invoice canceled'}
          </H2>
        </Box>
      )}
    </Modal>
  );
};

export default CancelModal;
