/* eslint-disable @typescript-eslint/quotes  */
import { defineMessages } from 'react-intl';

export default defineMessages({
  addClientButton: {
    id: 'clientsPage.addClientButton',
    defaultMessage: 'Add Client',
    description: 'Button label'
  },
  pageTitle: {
    id: 'clientsPage.pageTitle',
    defaultMessage: 'Clients',
    description: 'Button label'
  },
  noClientsMessage: {
    id: 'clientsPage.noClientsMessage',
    defaultMessage: 'No clients, yet',
    description: 'No clients message'
  },
  clientAddedMessage: {
    id: 'clientsPage.clientAddedMessage',
    defaultMessage: 'Client Added',
    description: 'No clients message'
  },
  clientAddedErrorMessage: {
    id: 'clientsPage.clientAddedErrorMessage',
    defaultMessage: 'The client with this email is added by the other advisor. Please use a different email to add this client.',
    description: 'No clients message'
  },

});
