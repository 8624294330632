import Box from '@material-ui/core/Box';
import withStyles from '@material-ui/core/styles/withStyles';

export default withStyles({
  root: {
    color: '#011638',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '32px',
  },
})(Box);
