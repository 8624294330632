import { PAYMENT_MODAL_TYPES } from '../../constants';

export const getClients = () => ({
  type: PAYMENT_MODAL_TYPES.GET_CLIENTS,
});

export const getClientsSuccess = (payload: any) => ({
  type: PAYMENT_MODAL_TYPES.GET_CLIENTS_SUCCESS,
  payload,
});

export const createSinglePayment = (payload: any, callback: () => void) => ({
  type: PAYMENT_MODAL_TYPES.CREATE_SINGLE_PAYMENT,
  payload,
  callback,
});

export const createSinglePaymentSuccess = (payload: any) => ({
  type: PAYMENT_MODAL_TYPES.CREATE_SINGLE_PAYMENT_SUCCESS,
  payload,
});

export const paymentModalError = (error: any) => ({
  type: PAYMENT_MODAL_TYPES.ERROR,
  payload: error,
});
