import React from 'react';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';

import { SearchIcon } from '../../metadata/icons';

interface Props {
  value: string,
  onChange: (value: string) => void,
  onSubmit: (value: string) => void,
}

const Search: React.FC<Props> = ({ value, onChange, onSubmit }) => {
  const [name, setName] = React.useState(value);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    onChange(event.target.value);
  };

  const iconHandler = (event: any) => {
    event.preventDefault();
    onSubmit(name);
  };

  return (
    <TextField
      placeholder="search"
      value={name}
      fullWidth
      onChange={handleChange}
      variant="outlined"
      style={{ height: 40 }}
      InputProps={{
        endAdornment: (
          <IconButton
            style={{ padding: 0 }}
            onClick={iconHandler}
          >
            <SearchIcon color="#3772FF" />
          </IconButton>
        ),
        style: {
          borderRadius: '50px',
        }
      }}
      /* eslint-disable-next-line react/jsx-no-duplicate-props */
      inputProps={{
        style: {
          height: 40,
          padding: '0 14px',
          fontSize: 16,
        },
      }}
    />
  );
};

export default Search;
