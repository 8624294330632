import {
  put, takeLatest, call, select
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { clientProfileError, refetchSinglePaymentsSuccess } from '../../actions/ClientProfile';
import { CLIENT_PROFILE_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker({ payload }: any): SagaIterator<void> {
  try {
    const { router } = yield select();
    const { data } = yield call(Api.payments.refetchPayments,
      {
        client: router.location.pathname.slice(15),
        ...payload
      });
    yield put(refetchSinglePaymentsSuccess({
      ...payload,
      data,
    }));
  } catch (e) {
    yield put(clientProfileError(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(CLIENT_PROFILE_TYPES.REFETCH_SINGLE_PAYMENTS, worker);
}
