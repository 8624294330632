import { TRANSFER_DETAILS_TYPES } from '../constants';

const initState = {
  loading: false,
  error: {},
  data: [],
  total: 0,
  pagination: {
    page: 1
  },
  sort: {
    id: null,
    firstDirection: false,
  },
};

export default (state = initState, { type, payload }: any) => {
  switch (type) {
    case TRANSFER_DETAILS_TYPES.FETCH_INIT_DATA:
      return {
        ...state,
        loading: true,
      };
    case TRANSFER_DETAILS_TYPES.FETCH_INIT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload.results,
        total: payload.count,
      };
    case TRANSFER_DETAILS_TYPES.REFETCH_DATA_SUCCESS:
      return {
        ...state,
        sort: payload.sort,
        pagination: payload.pagination,
        data: payload.data.results,
        total: payload.data.count,
      };
    case TRANSFER_DETAILS_TYPES.ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default: {
      return state;
    }
  }
};
