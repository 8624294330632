import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

import messages from './messages';
import { REGEXP } from '../../../../constants/validation';

export default (intl: IntlShape, errors: string[]) => Yup.object().shape({
  employee_id: Yup.string()
    .required(intl.formatMessage(messages.employerINErrorField))
    .matches(/^[^_]*$/, intl.formatMessage(messages.employerINErrorField)),
  street: Yup.string()
    .required(intl.formatMessage(messages.requiredMassage))
    .matches(/^[a-zA-Z0-9-\\,\\. ]{1,1000}$/, 'Street address contains invalid symbols.')
    .min(3, intl.formatMessage(messages.companyStreetAddressMinError))
    .max(95, intl.formatMessage(messages.companyStreetAddressMaxError)),
  city: Yup.string().required(
    !errors.includes('city')
      ? intl.formatMessage(messages.requiredMassage)
      : 'This field had an error, please fill in again'
  ),
  state: Yup.string().required(
    !errors.includes('state')
      ? intl.formatMessage(messages.requiredMassage)
      : 'This field had an error, please fill in again'
  ),
  zip_code: Yup.string()
    .required(
      !errors.includes('zip_code')
        ? intl.formatMessage(messages.requiredMassage)
        : 'This field had an error, please fill in again'
    )
    .min(5, intl.formatMessage(messages.zipCodeErrorField))
    .max(9, intl.formatMessage(messages.zipCodeErrorField)),
  web_site: Yup.string()
    .required(intl.formatMessage(messages.requiredMassage))
    .matches(REGEXP.WEBSITE_URL, intl.formatMessage(messages.companySiteError)),
  phone_number: Yup.string()
    .required(intl.formatMessage(messages.phoneNumberError))
    .matches(/^[^_]*$/, intl.formatMessage(messages.phoneNumberError)),
  first_name: Yup.string()
    .max(35, intl.formatMessage(messages.firstNameError))
    .matches(/^[a-zA-Z- ]{1,1000}$/, intl.formatMessage(messages.firstNameInvalidError))
    .required(intl.formatMessage(messages.requiredMassage)),
  last_name: Yup.string()
    .max(35, intl.formatMessage(messages.lastNameError))
    .matches(/^[a-zA-Z- ]{1,1000}$/, intl.formatMessage(messages.lastNameInvalidError))
    .required(intl.formatMessage(messages.requiredMassage)),
  social_security_number: Yup.string()
    .required(intl.formatMessage(messages.secNumberError))
    .matches(/[0-9\\*]{9}/, intl.formatMessage(messages.secNumberError))
    .max(9, intl.formatMessage(messages.secNumberError)),
  date_of_birth: Yup.string()
    .required(intl.formatMessage(messages.requiredMassage)),
  user_street: Yup.string()
    .required(intl.formatMessage(messages.requiredMassage))
    .matches(/^[a-zA-Z0-9-\\,\\. ]{1,1000}$/, 'Street address contains invalid symbols.')
    .min(3, intl.formatMessage(messages.companyStreetAddressMinError))
    .max(95, intl.formatMessage(messages.companyStreetAddressMaxError)),
  user_state: Yup.string()
    .required(intl.formatMessage(messages.requiredMassage)),
  user_city: Yup.string()
    .required(intl.formatMessage(messages.requiredMassage)),
  user_zip_code: Yup.string()
    .required(intl.formatMessage(messages.requiredMassage))
    .min(5, intl.formatMessage(messages.zipCodeErrorField))
    .max(9, intl.formatMessage(messages.zipCodeErrorField)),
  user_phone_number: Yup.string()
    .required(intl.formatMessage(messages.phoneNumberError))
    .matches(/^[^_]*$/, intl.formatMessage(messages.phoneNumberError)),
  businessWebsite: Yup.string()
    .matches(REGEXP.WEBSITE_URL, intl.formatMessage(messages.requiredMassage))
    .when(['occupation'], {
      is: (b) => !b,
      then: Yup.string().required(intl.formatMessage(messages.requiredMassage))
    }),
  occupation: Yup.string()
    .when(['businessWebsite'], {
      is: (a) => !a,
      then: Yup.string()
        .required(intl.formatMessage(messages.requiredMassage))
        .notOneOf(['0'], intl.formatMessage(messages.requiredMassage))
    }),
  ...(errors.includes('verification_document') && {
    docs: Yup.array()
      .required(intl.formatMessage(messages.requiredMassage))
      .min(2, 'test mix')
      .max(2, 'test max')
  })
}, [['businessWebsite', 'occupation']]);
