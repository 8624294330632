import {
  put,
  takeLatest,
  call,
  select
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { singlePaymentsError, refetchSingleSuccess } from '../../actions/SinglePayments';
import { SINGLE_PAYMENTS_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker({ payload }: any): SagaIterator<void> {
  try {
    if (payload) {
      const { data } = yield call(Api.payments.refetchPayments, payload);
      yield put(refetchSingleSuccess({
        ...payload,
        data,
      }));
    } else {
      const store = yield select();
      const { data } = yield call(Api.payments.refetchPayments, {
        pagination: store.singlePayments.pagination,
        sort: store.singlePayments.sort,
        status: store.singlePayments.status,
      });
      yield put(refetchSingleSuccess({ data }));
    }
  } catch (e) {
    yield put(singlePaymentsError(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(SINGLE_PAYMENTS_TYPES.REFETCH_PAYMENTS, worker);
}
