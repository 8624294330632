import { ADVISORS_TYPES } from '../constants';

const initState = {
  advisorsList: [],
  loading: false,
  error: {},
  total: 0,
  pagination: {
    page: 1
  },
  sort: {
    id: null,
    firstDirection: false,
  },
};

export default (state = initState, { type, payload }: any) => {
  switch (type) {
    case ADVISORS_TYPES.CREATE_ADVISOR:
      return {
        ...state,
        loading: true,
        error: {},
      };
    case ADVISORS_TYPES.CREATE_ADVISOR_SUCCESS:
      return {
        ...state,
        loading: false,
        error: {},
      };
    case ADVISORS_TYPES.GET_ADVISORS:
      return {
        ...state,
        loading: true
      };
    case ADVISORS_TYPES.GET_ADVISORS_SUCCESS:
      return {
        ...state,
        loading: true,
        total: payload.count,
        advisorsList: payload.results,
      };
    case ADVISORS_TYPES.REFETCH_ADVISORS_SUCCESS:
      return {
        ...state,
        sort: payload.sort,
        pagination: payload.pagination,
        advisorsList: payload.data.results,
      };
    case ADVISORS_TYPES.ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default: {
      return state;
    }
  }
};
