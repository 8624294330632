import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

import messages from './messages';

export default (intl: IntlShape) => Yup.object().shape({
  streetAddress: Yup.string()
    .matches(/^[a-zA-Z0-9-\\,\\. ]{1,1000}$/, 'Street address contains invalid symbols.')
    .required(intl.formatMessage(messages.requiredMassage))
    .min(3, intl.formatMessage(messages.companyStreetAddressMinError))
    .max(95, intl.formatMessage(messages.companyStreetAddressMaxError)),
  city: Yup.string()
    .required(intl.formatMessage(messages.requiredMassage)),
  state: Yup.string()
    .required(intl.formatMessage(messages.requiredMassage)),
  zipCode: Yup.string()
    .required(intl.formatMessage(messages.requiredMassage))
    .min(5, intl.formatMessage(messages.zipCodeErrorField))
    .max(9, intl.formatMessage(messages.zipCodeErrorField)),
  cardHolderName: Yup.string()
    .required(intl.formatMessage(messages.requiredMassage))
    .matches(/^[a-zA-Z- ]{1,1000}$/, intl.formatMessage(messages.cardHolderNameInvalidError))
    .max(71, intl.formatMessage(messages.cardHolderNameMaxError)),
  // cardNumber: Yup.string()
  //   .required(intl.formatMessage(messages.requiredMassage))
  //   .matches(/^[0-9]{1,1000}$/, intl.formatMessage(messages.cardNumberInvalidError))
  //   .min(15, intl.formatMessage(messages.cardNumberMaxError))
  //   .max(16, intl.formatMessage(messages.cardNumberMaxError)),
  // expiryDate: Yup.string()
  //   .required(intl.formatMessage(messages.requiredMassage))
  //   .matches(/(0[1-9]|10|11|12)\/[0-9]{2}$/, intl.formatMessage(messages.expiryDateError)),
  // cvc: Yup.string()
  //   .required(intl.formatMessage(messages.requiredMassage))
  //   .matches(/^[0-9]{3,4}$/, intl.formatMessage(messages.cvcFieldError)),
});
