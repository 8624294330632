import React, { useEffect, useState } from 'react';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';

import Box from '@material-ui/core/Box';
import DoneIcon from '@material-ui/icons/Done';
import Modal from '../../Modal';
import Api from '../../../api';
import H2 from '../../H2';
import Button from '../../Button';
import FormikSelect from '../../Controls/FormikSelect';
import FormikInput from '../../Controls/FormikInput';

const validationSchema = Yup.object().shape({
  permission: Yup.string()
    .required('Please fill in all required fields.'),
});

interface Props {
  open: boolean,
  close: () => void,
  id: string,
  callback: () => void,
  advisorInfo: any
}

const EditModal: React.FC<Props> = ({
  open,
  close,
  id,
  callback,
  advisorInfo,
}) => {
  const [doneStatus, setDoneStatus] = useState(false);

  useEffect(() => {
    if (open) {
      setDoneStatus(false);
    }
  }, [open]);

  const handleSave = (values: any) => {
    Api.advisors.updateAdvisor(id, {
      role: values.permission,
    })
      .then(() => {
        setDoneStatus(true);
        setTimeout(() => {
          close();
          callback();
        }, 1000);
      });
  };

  return (
    <Modal
      open={open}
      handleClose={close}
    >
      {!doneStatus ? (
        <Box p={3} minWidth={450}>
          <H2>{`Edit ${advisorInfo.name} Advisor`}</H2>
          <Formik
            onSubmit={handleSave}
            initialValues={{
              permission: (advisorInfo.role && advisorInfo.role[0]) || '',
            }}
            validationSchema={validationSchema}
          >
            {({ isValid, dirty }) => (
              <Form>
                <Box mt={3}>
                  <Field
                    component={FormikSelect}
                    name="permission"
                    label="Advisor permission"
                    options={[
                      { label: 'Managing Advisor', value: 'managing_advisor' },
                      { label: 'Standard Advisor', value: 'advisor' }
                    ]}
                  />
                </Box>
                <Box display="flex" justifyContent="flex-end" mt={3}>
                  <Box mr={2}><Button variant="text" onClick={close}>cancel</Button></Box>
                  <Button
                    variant="text"
                    type="submit"
                    disabled={!dirty || !isValid}
                  >
                    save
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      ) : (
        <Box p={5} bgcolor="white" width={450} display="flex" flexDirection="column" alignItems="center">
          <Box color="#3772FF" fontSize={64}><DoneIcon fontSize="inherit" /></Box>
          <H2 mt={3}>Advisor edited</H2>
        </Box>
      )}
    </Modal>
  );
};

export default EditModal;
