import { defineMessages } from 'react-intl';

export default defineMessages({
  pageTitle: {
    id: 'onBoarding.bankingDetailsStepTitle',
    defaultMessage: 'Your Banking Details',
    description: 'Text'
  },
  pageDescription: {
    id: 'onBoarding.bankingDetailsStepDescription',
    defaultMessage: 'The bank account information you provide below will be used to receive money from client payments on ChalicePay',
    description: 'Text'
  },
  accountHolderField: {
    id: 'fields.accountHolder',
    defaultMessage: 'Account holder',
    description: 'Field label'
  },
  accountHolderMaxError: {
    id: 'fields.accountHolder.maxError',
    defaultMessage: 'Account holder name is too long. Please limit it to 71 characters.',
    description: 'Field label'
  },
  accountHolderInvalidError: {
    id: 'fields.accountHolder.invalidError',
    defaultMessage: 'Account holder name contains invalid symbols.',
    description: 'Field label'
  },
  companyNameError: {
    id: 'fields.companyName.error',
    defaultMessage: 'Company name is too long.',
    description: 'Field error label'
  },
  typeOfBusinessField: {
    id: 'fields.typeOfBusiness',
    defaultMessage: 'Type of business',
    description: 'Field label'
  },
  routingNumberField: {
    id: 'fields.routingNumber',
    defaultMessage: 'Routing number',
    description: 'Field label'
  },
  routingNumberError: {
    id: 'fields.routingNumber.error',
    defaultMessage: 'Routing number should contain nine digits.',
    description: 'Field label'
  },
  accountNumberField: {
    id: 'fields.accountNumber',
    defaultMessage: 'Account number',
    description: 'Field label'
  },
  accountNumberError: {
    id: 'fields.accountNumber.error',
    defaultMessage: 'Account number should contain from 6 to 17 digits.',
    description: 'Field label'
  },
  accountNumberConfirmationField: {
    id: 'fields.accountNumberConfirmation',
    defaultMessage: 'Confirm account number',
    description: 'Field label'
  },
  accountNumberConfirmationError: {
    id: 'fields.accountNumberConfirmation.error',
    defaultMessage: 'Account numbers do not match.',
    description: 'Field label'
  },
  requiredMassage: {
    id: 'onBoarding.requiredFieldMassage',
    defaultMessage: 'Please fill in all required fields.',
    description: 'Message'
  },
  cancelButton: {
    id: 'buttons.cancel',
    defaultMessage: 'Cancel',
    description: 'Button label'
  },
  addButton: {
    id: 'buttons.add',
    defaultMessage: 'Add',
    description: 'Button label'
  },
});
