import React, { Component } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { RouteComponentProps } from 'react-router';

import { Provider } from './Context';
import Content from './Content';
import { ROLES } from '../../constants';
import { ChangeAdvisorModal, DeleteClientModal } from '../../components';

interface Props extends RouteComponentProps {
  intl: IntlShape,
  getCompanyDetails: () => void,
  getAllAdvisors: () => void,
  refetchClients: (data: any) => void,
  step: number,
  clientsTotal: number,
  userData: { id: string },
  createClient: (payload: any, callback: (error: boolean) => void)
  => void,
  getClients: () => void,
  clientsList: {}[],
  advisorsList: {}[],
  error: {},
  pagination: { page: number },
  sort: { id: string, firstDirection: boolean },
  userRole: string,
  accountSubscribed: boolean,
}

interface State {
  modalOpen: boolean,
  clientAdded: boolean,
  deleteClientModalOpen: boolean,
  deleteClientId: string,
  clientId: string,
  changeAdvisorId: string,
  changeAdvisorModalOpen: boolean,
}

class Container extends Component<Props, State> {
  state = {
    modalOpen: false,
    clientAdded: false,
    deleteClientModalOpen: false,
    deleteClientId: '',
    changeAdvisorId: '',
    clientId: '',
    changeAdvisorModalOpen: false,
  };

  componentDidMount() {
    const {
      getCompanyDetails,
      getClients,
      userRole,
      step,
      history,
      getAllAdvisors,
    } = this.props;

    if (userRole === ROLES.CLIENT) {
      history.push('/transactions/subscriptions');
    }
    if (step < 5 && userRole === ROLES.ACCOUNT_OWNER) {
      history.push('/onBoarding');
    }

    getCompanyDetails();
    getClients();
    getAllAdvisors();
  }

  get actions() {
    const { history, userRole } = this.props;
    return [
      {
        label: 'View Profile',
        onClick: (row: { id: string }) => history.push(`/users/clients/${row.id}`),
      },
      {
        label: 'Delete client',
        onClick: (row: { id: string }) => this.setState((prevState) => ({
          deleteClientId: row.id,
          deleteClientModalOpen: !prevState.deleteClientId,
        })),
      },
      {
        label: 'Change Advisor',
        onClick: (row: any) => this.setState((prevState) => ({
          changeAdvisorId: row.advisor,
          changeAdvisorModalOpen: !prevState.changeAdvisorModalOpen,
          clientId: row.id,
        })),
        getIsHidden: () => userRole === ROLES.ADVISOR
      },
    ];
  }

  toggleClientModal = () => this.setState((prevState) => ({ modalOpen: !prevState.modalOpen }));

  handleClientCreate = (values: any) => {
    const { createClient, userRole, userData } = this.props;

    createClient({
      email: values.email,
      first_name: values.firstName,
      last_name: values.lastName,
      advisor: userRole === ROLES.ADVISOR ? userData.id : values.advisor,
    }, (isError) => {
      this.setState({ clientAdded: true, modalOpen: false }, () => {
        setTimeout(() => this.setState({ clientAdded: false }), isError ? 3000 : 1000);
      });
    });
  };

  handleRefetchClients = (data: any) => {
    const { refetchClients } = this.props;
    refetchClients(data);
  };

  render() {
    const {
      props: {
        intl,
        userData,
        clientsList,
        error,
        sort,
        pagination,
        clientsTotal,
        accountSubscribed,
        getClients,
        advisorsList,
        userRole
      },
      state: {
        modalOpen,
        clientAdded,
        deleteClientModalOpen,
        deleteClientId,
        changeAdvisorModalOpen,
        changeAdvisorId,
        clientId,
      }
    } = this;

    return (
      <Provider
        value={{
          intl,
          userData,
          userRole,
          onCloseModal: this.toggleClientModal,
          onAddClient: this.handleClientCreate,
          accountSubscribed,
          advisorsList,
        }}
      >
        <Content
          userRole={userRole}
          accountSubscribed={accountSubscribed}
          refetchData={this.handleRefetchClients}
          clientsTotal={clientsTotal}
          sort={sort}
          pagination={pagination}
          actions={this.actions}
          error={error}
          list={clientsList}
          addClient={this.toggleClientModal}
          clientAdded={clientAdded}
          modalOpen={modalOpen}
        />
        <DeleteClientModal
          open={deleteClientModalOpen}
          callback={() => {
            getClients();
            this.setState({
              modalOpen: false,
              clientAdded: false,
              deleteClientModalOpen: false,
              deleteClientId: '',
            });
          }}
          id={deleteClientId}
          close={() => this.setState((prevState) => ({ deleteClientModalOpen: !prevState.deleteClientModalOpen }))}
        />
        <ChangeAdvisorModal
          clientId={clientId}
          id={changeAdvisorId}
          open={changeAdvisorModalOpen}
          close={() => this.setState({ changeAdvisorModalOpen: false })}
          callback={getClients}
        />
      </Provider>
    );
  }
}

export default injectIntl(Container);
