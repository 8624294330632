import { defineMessages } from 'react-intl';

export default defineMessages({
  setUpAccountStep: {
    id: 'onBoarding.setUpAccountStep',
    defaultMessage: 'Set Up Your Account',
    description: 'Navigation'
  },
  accountInfoStep: {
    id: 'onBoarding.accountInfoStep',
    defaultMessage: 'Account Information',
    description: 'Navigation'
  },
  bankingDetailsStep: {
    id: 'onBoarding.bankingDetailsStep',
    defaultMessage: 'Banking Details',
    description: 'Navigation'
  },
  billingStep: {
    id: 'onBoarding.billingStep',
    defaultMessage: 'ChalicePay Billing',
    description: 'Navigation'
  },
  reviewStep: {
    id: 'onBoarding.reviewStep',
    defaultMessage: 'Review',
    description: 'Navigation'
  },
});
