import { connect } from 'react-redux';

import Container from './Container';
import { createCard, getCards } from '../../redux/actions/Wallet';

const mapStateToProps = (state: any) => ({
  userId: state.auth.userData.id,
  cards: state.wallet.cardList,
});

const mapDispatchToProps = {
  createCard,
  getCards
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
