import React from 'react';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import { withParentData } from '../../Context';
import H2 from '../../../H2';
import H3Dark from '../../../H3Dark';
import H3 from '../../../H3';
import Button from '../../../Button';
import CurrencyProvider from '../../../CurrencyProvider';
import messages from '../../messages';

const Row = ({ label, info }: { label: string, info: string }) => (
  <Box
    borderBottom="1px solid #F1F2F2"
    height={64}
    display="flex"
    flexDirection="column"
    justifyContent="center"
  >
    <H3Dark fontWeight={500}>{label}</H3Dark>
    <H3>{info}</H3>
  </Box>
);

interface Props {
  goToPrevStep: () => void,
  onFinish: () => void,
  selectedClient: number,
  loading: boolean,
  isSinglePayment: boolean,
  selectedAmount: number,
  selectedPeriod: number,
  selectedDueDate: string,
  description: string,
  clients: {
    value: number,
    label: string,
  }[],
}

const MyComponent: React.FC<Props> = ({
  goToPrevStep,
  selectedDueDate,
  selectedClient,
  clients,
  description,
  selectedAmount,
  onFinish,
  loading,
  isSinglePayment,
  selectedPeriod,
}) => (
  <Box>
    <Box display="flex" justifyContent="space-between" mb="37px">
      <H2>
        {isSinglePayment
          ? <FormattedMessage {...messages.singlePayment} />
          : <FormattedMessage {...messages.subscription} />}
      </H2>
      <H2><CurrencyProvider value={selectedAmount} /></H2>
    </Box>
    <Row label="Client" info={(clients.find(item => item.value === selectedClient) || {}).label || ''} />
    {selectedDueDate && (
      <Row label="Due date" info={moment(selectedDueDate).format('MM/DD/YYYY')} />
    )}
    <Row label="Description" info={description} />
    {!isSinglePayment && <H3 mt={3}>Automatic charges will start as soon as the client makes the first payment.</H3>}
    <Box
      mt={3}
      display="flex"
      justifyContent="flex-end"
    >
      <Box mr={3}>
        <Button onClick={goToPrevStep}>
          <FormattedMessage {...messages.previousButton} />
        </Button>
      </Box>
      <Button loading={loading} onClick={onFinish} variant="contained">
        <FormattedMessage {...messages.finishButton} />
      </Button>
    </Box>
  </Box>
);

export default withParentData(MyComponent);
