import {
  put, takeLatest, call, select
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { paymentModalError } from '../../actions/PaymentModal';
import { refetchSubscription } from '../../actions/ClientProfile';
import { refetchSubscriptions } from '../../actions/Subscriptions';
import { SUBSCRIPTIONS_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker({ payload, callback }: any): SagaIterator<void> {
  try {
    yield call(Api.subscriptions.createSubscription, payload);

    const { router, clientProfile, subscriptions } = yield select();

    if (router.location.pathname === '/billing/subscriptions') {
      yield put(refetchSubscriptions({
        ...(subscriptions.status && { status: subscriptions.status }),
        sort: subscriptions.sort,
        pagination: subscriptions.pagination,
      }));
    } else {
      yield put(refetchSubscription({
        sort: clientProfile.subscriptionsSort,
        pagination: clientProfile.subscriptionsPagination,
      }));
    }

    callback();
  } catch (e) {
    yield put(paymentModalError(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(SUBSCRIPTIONS_TYPES.CREATE_SUBSCRIPTION, worker);
}
