import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import reducer from '../reducers';
import rootSaga from '../sagas';

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const enhancer = compose(
  applyMiddleware(
    sagaMiddleware,
    routeMiddleware,
  )
);

export default function configureStore() {
  const store = createStore(reducer(history), {}, enhancer);
  sagaMiddleware.run(rootSaga);


  return {
    store,
    history
  };
}
