import { connect } from 'react-redux';

import Container from './Container';
import { getCompanyDetails } from '../../redux/actions/Settings';
import { createAdvisor, getAdvisors, refetchAdvisors } from '../../redux/actions/Advisors';

const mapStateToProps = ({ auth, advisors, settings }: any) => ({
  step: auth.userData.onboarding_status,
  advisorsList: advisors.advisorsList,
  error: advisors.error,
  sort: advisors.sort,
  pagination: advisors.pagination,
  total: advisors.total,
  userRole: auth.userRole,
  accountSubscribed: settings.companyInfo.subscribed,
  accountVerified: settings.companyInfo.status === 'verified',
  userId: auth.userData.id,
});

const mapDispatchToProps = {
  getCompanyDetails,
  createAdvisor,
  getAdvisors,
  refetchAdvisors,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
