import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import RootLayout from './RootLayout';
import { ROLES } from '../constants';

interface Props {
  isAuthorized: boolean,
  userRole: string,
  component: any,
  onBoardingStatus: number,
}

const PublicRoute: React.FC<Props> = ({
  component: Component,
  isAuthorized,
  userRole,
  onBoardingStatus,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (!isAuthorized) {
        return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
      }
      if (onBoardingStatus >= 5
        || userRole === ROLES.CLIENT
        || userRole === ROLES.ADVISOR
        || userRole === ROLES.MANAGING_ADVISOR
      ) {
        return (
          <RootLayout {...props}>
            <Component {...props} />
          </RootLayout>
        );
      }
      // @ts-ignore
      return <Component {...props} />;
    }}
  />
);

export default PublicRoute;
