import React, { useState, useRef } from 'react';
import Select from 'react-select';
import Box from '@material-ui/core/Box';

import { ArrowDownIcon, ArrowUpIcon } from '../../metadata/icons';
import './styles.module.scss';

interface Option {
  label: string,
  value: string
}

interface Props {
  disabled?: boolean,
  error?: boolean,
  label?: string,
  helperText?: string,
  options: Option[],
  value: string,
  onChange: (res: Option) => void,
  onBlur?: () => void,
}

const CustomSelect = ({
  disabled,
  label,
  options,
  value,
  onChange,
  onBlur,
  error,
  helperText,
}: Props) => {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [isActive, setActive] = useState(false);
  const getLabelColor = () => {
    switch (true) {
      case error: return '#E20338';
      case disabled: return '#BCBEC0';
      case isActive: return '#3772FF';
      case Boolean(value): return '#011638';
      default: return '#6D6E71';
    }
  };
  const labelHandler = () => {
    // @ts-ignore
    ref!.current!.focus();
  };
  const selectValue = options.find(item => item.value === value) || null;
  return (
    <Box position="relative">
      <Box
        fontFamily="Montserrat"
        position="absolute"
        zIndex={1000}
        fontSize={value || isActive ? 12 : 16}
        left={16}
        top={value || isActive ? 2 : 15}
        color={getLabelColor()}
        onClick={labelHandler}
      >
        {label}
      </Box>
      <Select
        className="select"
        menuPlacement="auto"
        isDisabled={disabled}
        value={selectValue}
        onBlur={() => {
          setActive(false);
          if (onBlur) {
            onBlur();
          }
        }}
        onFocus={() => setActive(true)}
        placeholder={!isActive ? '' : 'Choose item'}
        onMenuOpen={() => setOpen(true)}
        onMenuClose={() => setOpen(false)}
        components={{
          DropdownIndicator: () => (!isOpen
            // eslint-disable-next-line no-nested-ternary
            ? <ArrowDownIcon color={error ? '#E20338' : isActive ? '#3772FF' : '#6D6E71'} />
            // eslint-disable-next-line no-nested-ternary
            : <ArrowUpIcon color={error ? '#E20338' : isActive ? '#3772FF' : '#6D6E71'} />),
        }}
        // @ts-ignore
        styles={{
          indicatorSeparator: () => {},
          control: (base) => ({
            ...base,
            background: '#F2F8FD',
            borderWidth: isActive || error ? '0 0 2px 0' : 0,
            boxSizing: 'border-box',
            paddingRight: 8,
            paddingLeft: 14,
            cursor: disabled ? 'default' : 'text',
            boxShadow: 'none',
            borderRadius: '4px 4px 0 0',
            borderColor: error ? '#E20338' : '#3772FF',
            '&:hover': {
              background: '#E7EDFD'
            },
          }),
          singleValue: (base) => ({
            ...base,
            paddingTop: 14,
          }),
          valueContainer: (base) => ({
            ...base,
            minHeight: 56,
            paddingLeft: 0,
          }),
          container: (base) => ({
            ...base,
          }),
          placeholder: (base) => ({
            ...base,
            paddingTop: 14,
          }),
          input: (base) => ({
            ...base,
            paddingTop: 15,
          }),
          menu: (base) => ({
            ...base,
            zIndex: 1001,
            border: '1px solid #E7EDFD',
            boxShadow: 'none'
          }),
          option: (base) => ({
            ...base,
            background: 'white',
            color: '#6D6E71',
            '&:hover': {
              background: '#E7EDFD'
            },
          }),
        }}
        ref={ref}
        onChange={(option) => {
          // @ts-ignore
          onChange(option);
          // @ts-ignore
          ref!.current!.blur();
        }}
        options={options}
      />
      {helperText && (
        <Box fontSize={12} color={error ? '#E20338' : '#6D6E71'} ml="12px">
          {helperText}
        </Box>
      )}
    </Box>
  );
};

export default CustomSelect;
