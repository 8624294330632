/* eslint-disable @typescript-eslint/quotes  */
import { defineMessages } from 'react-intl';

export default defineMessages({
  backButton: {
    id: 'buttons.back',
    defaultMessage: 'Back',
    description: 'Button label'
  },
  printButton: {
    id: 'buttons.print',
    defaultMessage: 'print',
    description: 'Button label'
  },
  unpaidStatus: {
    id: 'invoiceStatuses.unpaid',
    defaultMessage: 'Unpaid',
    description: 'Invoice status',
  },
  paid: {
    id: 'invoiceDetails.paid',
    defaultMessage: 'Paid',
    description: 'Label'
  },
  billedTo: {
    id: 'invoiceHtml.billedTo',
    defaultMessage: 'BILLED TO:',
    description: 'Text',
  },
  invoiceId: {
    id: 'invoiceHtml.invoiceId',
    defaultMessage: 'Invoice ID: ',
    description: 'Text',
  },
  billed: {
    id: 'invoiceHtml.billed',
    defaultMessage: 'Billed: ',
    description: 'Text',
  },
  dueDate: {
    id: 'invoiceHtml.dueDate',
    defaultMessage: 'Due date: ',
    description: 'Text',
  },
  description: {
    id: 'invoiceHtml.description',
    defaultMessage: 'Description:',
    description: 'Text',
  },
  amount: {
    id: 'invoiceHtml.amount',
    defaultMessage: 'Amount:',
    description: 'Text',
  },
  totalDue: {
    id: 'invoiceHtml.totalDue',
    defaultMessage: 'Total due:',
    description: 'Text',
  },
  invoice: {
    id: 'invoice',
    defaultMessage: 'Invoice',
    description: 'Text',
  },
});
