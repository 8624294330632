import { SETTINGS_TYPES } from '../constants';

const initState = {
  billingList: [],
  bankingList: [],
  billingInvoices: [],
  loading: false,
  companyInfo: {
    errors: [],
  },
  error: {},
  billingInvoicesTotal: 0,
  billingInvoicesPagination: {
    page: 1
  },
  billingInvoicesSort: {
    id: null,
    firstDirection: false,
  },
  appSubscription: {
    status: '',
  },
};

export default (state = initState, { type, payload }: any) => {
  switch (type) {
    case SETTINGS_TYPES.FETCH_INIT_DATA:
      return {
        ...state,
        loading: true,
      };
    case SETTINGS_TYPES.FETCH_INIT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        companyInfo: payload.organization,
        bankingList: payload.banking,
        billingList: payload.billing,
        billingInvoices: payload.billingHistory.results,
        billingInvoicesTotal: payload.billingHistory.count,
        appSubscription: payload.appSubscription,
      };
    case SETTINGS_TYPES.GET_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        companyInfo: payload,
      };
    case SETTINGS_TYPES.GET_BILLING_HISTORY_SUCCESS:
      return {
        ...state,
        billingInvoices: payload.results,
        billingInvoicesTotal: payload.count,
      };
    case SETTINGS_TYPES.REFETCH_BILLING_HISTORY_SUCCESS:
      return {
        ...state,
        billingInvoicesSort: payload.sort,
        billingInvoicesPagination: payload.pagination,
        billingInvoices: payload.data.results,
        billingInvoicesTotal: payload.data.count,
      };
    case SETTINGS_TYPES.GET_BANK_DETAILS_SUCCESS:
      return {
        ...state,
        bankingList: payload.banking,
        billingList: payload.billing,
      };
    case SETTINGS_TYPES.GET_APP_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        appSubscription: payload,
      };
    case SETTINGS_TYPES.ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default: {
      return state;
    }
  }
};
