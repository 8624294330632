import React from 'react';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';

import { FormattedMessage } from 'react-intl';
import H1 from '../H1';
import H3 from '../H3';
import Link from '../Link';
import CloseIcon from '../../metadata/icons/CloseIcon';
import {
  Navigation,
  RequestTypeForm,
  PaymentDetailsForm,
  ReviewForm,
  SuccessScreen,
} from './components';
import messages from './messages';

interface Props {
  close: () => void,
  activeStep: number,
  isVerified: boolean,
}

const Content: React.FC<Props> = ({
  close,
  activeStep,
  isVerified,
}) => (
  <Box
    height="100vh"
    width="100vw"
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    {isVerified ? (
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        pt={10}
        maxWidth="1240px"
        width="inherit"
        marginX={3}
        style={{ overflowY: 'auto' }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <H1><FormattedMessage {...messages.requestButton} /></H1>
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </Box>
        {activeStep !== 3 && <Box mt={12}><Navigation /></Box>}
        <Box width={500} mt={11} mb={3}>
          {activeStep === 0 && <RequestTypeForm />}
          {activeStep === 1 && <PaymentDetailsForm />}
          {activeStep === 2 && <ReviewForm />}
          {activeStep === 3 && <SuccessScreen />}
        </Box>

      </Box>
    ) : (
      <Box textAlign="center">
        <H3 maxWidth={500} mb={5}>
          <span>
            Sorry but you are unable to request payment until your ChalicePay account is verified. Please check your
          </span>
        </H3>
        <Link href="/settings">Company Settings</Link>
      </Box>
    )}
  </Box>
);

export default Content;
