import React from 'react';
import Box from '@material-ui/core/Box';
import { FormattedMessage } from 'react-intl';
import { ReactSVG } from 'react-svg';
import withStyles from '@material-ui/core/styles/withStyles';

import H2 from '../../../H2';
import H3 from '../../../H3';
import messages from '../../messages';
import H3Dark from '../../../H3Dark';
import Button from '../../../Button';
import CurrencyProvider from '../../../CurrencyProvider';
import { getCardBrandLogo } from '../../../../utils/helpers';

const CardLogoContainer = withStyles({
  root: {
    '& div': {
      width: 100,
      height: 50,
    },
    '& svg': {
      width: 100,
      height: 50,
      marginLeft: -10
    }
  }
})(Box);

interface Props {
  invoiceInfo: {
    number: string,
    amount: number,
  },
  selectedCardObject: any,
  submit: () => void,
  goBack: () => void,
  isSubscription: boolean,
  isAppSubscription: boolean,
  loading: boolean,
}

const Details: React.FC<Props> = ({
  invoiceInfo,
  selectedCardObject,
  submit,
  goBack,
  isSubscription,
  isAppSubscription,
  loading,
}) => (
  <Box>
    <Box
      mt={6}
      display="flex"
      justifyContent="space-between"
      pb={(isSubscription || isAppSubscription) ? 0 : 3}
      borderBottom={(isSubscription || isAppSubscription) ? 'none' : '1px solid #C4C4C4'}
    >
      {isAppSubscription && <H2>ChalicePay Subscription</H2>}
      {!isAppSubscription && (isSubscription ? <H2>Subscription</H2> : (
        <H2>
          <FormattedMessage {...messages.invoice} />
          <span> #</span>
          <span>{invoiceInfo.number}</span>
        </H2>
      ))}
      <H2><CurrencyProvider value={invoiceInfo.amount / 100} /></H2>
    </Box>
    {isSubscription && (
      <H3 borderBottom="1px solid #C4C4C4" mt={2} pb={3}>
        Will start charging automatically today.
      </H3>
    )}
    {isAppSubscription && (
      <H3 borderBottom="1px solid #C4C4C4" mt={2} pb={3}>
        Will charge automatically each month.
      </H3>
    )}
    <H2 mt={5} mb={3}>
      <FormattedMessage {...messages.creditCardDetails} />
    </H2>
    <CardLogoContainer mb={3}>
      <ReactSVG src={getCardBrandLogo(selectedCardObject?.card?.brand)} />
    </CardLogoContainer>
    <Box
      p={3}
      border="1px solid #C4C4C4"
      borderRadius={4}
      mb={5}
      display="flex"
    >
      <Box mr={2}>
        <H3Dark mb={2}><FormattedMessage {...messages.cardholder} /></H3Dark>
        <H3Dark mb={2}><FormattedMessage {...messages.cardNumber} /></H3Dark>
        <H3Dark mb={2}><FormattedMessage {...messages.address} /></H3Dark>
        <H3Dark mb={2}><FormattedMessage {...messages.city} /></H3Dark>
        <H3Dark mb={2}><FormattedMessage {...messages.state} /></H3Dark>
        <H3Dark><FormattedMessage {...messages.zipCode} /></H3Dark>
      </Box>
      <Box>
        <H3Dark mb={2}>{selectedCardObject?.billing_details?.name}</H3Dark>
        <H3Dark mb={2}>
          <span>---- ---- ---- </span>
          {selectedCardObject?.card?.last4}
        </H3Dark>
        <H3Dark mb={2}>{selectedCardObject?.billing_details?.address?.line1}</H3Dark>
        <H3Dark mb={2}>{selectedCardObject?.billing_details?.address?.city}</H3Dark>
        <H3Dark mb={2}>{selectedCardObject?.billing_details?.address?.state}</H3Dark>
        <H3Dark>{selectedCardObject?.billing_details?.address?.postal_code}</H3Dark>
      </Box>
    </Box>
    <Box display="flex" justifyContent="flex-end">
      <Box mr={3}>
        <Button onClick={goBack}><FormattedMessage {...messages.previousButton} /></Button>
      </Box>
      <Button onClick={submit} variant="contained" loading={loading}>
        <FormattedMessage {...messages.confirmPayButton} />
      </Button>
    </Box>
  </Box>
);

export default Details;
