import { all, fork } from 'redux-saga/effects';

import getClients from './getClients';
import createSinglePayment from './createSinglePayment';
import createSubscription from './createSubscription';

export default all([
  fork(getClients),
  fork(createSinglePayment),
  fork(createSubscription),
]);
