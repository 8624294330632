import React from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';
import { Field, Formik, Form } from 'formik';
import Box from '@material-ui/core/Box';

import messages from '../../messages';
import H2 from '../../../H2';
import FormikSelect from '../../../Controls/FormikSelect';
import Button from '../../../Button';
import { Card } from '../../index';

interface Props {
  intl: IntlShape,
  options: { label: string, value: string }[],
  cards: Card[],
  onSubmit: (values: { card: string }) => void,
  selectedCard: string,
}

const CardForm: React.FC<Props> = ({
  selectedCard,
  options,
  cards,
  onSubmit,
  intl,
}) => (
  <Box mt={6}>
    <H2 pl={1}>
      <FormattedMessage {...messages.creditCard} />
    </H2>
    <Formik
      initialValues={{
        card: selectedCard || (options.length === 0
          ? ''
          : (cards.find(item => item.is_default) || { stripe_id: '' }).stripe_id)
      }}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <Form>
        <Box mt={3}>
          <Field
            component={FormikSelect}
            name="card"
            options={options}
            label={intl.formatMessage(messages.creditCard)}
          />
        </Box>
        <Box mt={5} display="flex" justifyContent="flex-end">
          <Button variant="contained" type="submit">next</Button>
        </Box>
      </Form>
    </Formik>
  </Box>
);

export default CardForm;
