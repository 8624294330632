import React from 'react';
import { FormattedMessage } from 'react-intl';
import Box from '@material-ui/core/Box';

import OnboardingContainer from '../OnboardingContainer';
import messages from './messages';

const Container: React.FC = ({ children }) => (
  <OnboardingContainer
    renderLeft={() => (
      <Box
        display="flex"
        flex={1}
        justifyContent="center"
        alignItems="center"
        height="100%"
        flexDirection="column"
      >
        <Box
          color="white"
          fontSize={{
            xs: 96,
            md: 80,
            lg: 96,
          }}
          fontWeight={500}
          lineHeight="116px"
        >
          <FormattedMessage {...messages.onBoardingTitle} />
        </Box>
        <Box
          color="white"
          fontSize={16}
          maxWidth={{
            xs: 530,
            md: 440,
            lg: 530,
          }}
          mt={2}
        >
          <FormattedMessage {...messages.onBoardingDescription} />
        </Box>
      </Box>
    )}
    renderRight={() => children}
  />
);

export default Container;
