import { PAYMENT_MODAL_TYPES } from '../constants';

const initState = {
  loading: false,
  error: {},
  clientList: [],
};

export default (state = initState, { type, payload }: any) => {
  switch (type) {
    case PAYMENT_MODAL_TYPES.GET_CLIENTS_SUCCESS:
      return {
        ...state,
        clientList: payload,
      };
    default: {
      return state;
    }
  }
};
