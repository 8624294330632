import React, { Component } from 'react';
import IconButtonMui from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';

import { ArrowDownIcon, ArrowUpIcon } from '../../metadata/icons';
import Menu from '../Menu';
import Button from '../Button';

const IconButton = withStyles({ root: { padding: 0 } })(IconButtonMui);

interface Props {
  rowItem: {},
  options: {
    label: string,
    getLabel: (row: {}) => any,
    onClick: (row: {}) => void,
    getIsDisabled: (row: {}) => boolean,
    getIsHidden: (row: {}) => boolean,
  }[],
}

interface State {
  menuState: boolean,
  anchorEl: null | HTMLDivElement
}

class DataTableCardMenu extends Component<Props, State> {
  state = {
    anchorEl: null,
    menuState: false,
  };

  get notHiddenOptions() {
    const { options, rowItem } = this.props;

    return options.filter((option) => !(option.getIsHidden && option.getIsHidden(rowItem)));
  }

  // @ts-ignore
  onCardMenuOptionSelect = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    // @ts-ignore
    this.setState({ menuState: true, anchorEl: event.currentTarget });
  };

  onClose = (
    // @ts-ignore
    event: MouseEvent<HTMLLIElement, MouseEvent>,
    callback: (row: {}) => void,
    getIsDisabled: (row: {}) => boolean
  ) => {
    const { rowItem } = this.props;

    if (getIsDisabled && getIsDisabled(rowItem)) {
      event.stopPropagation();
      return;
    }

    this.handleCardMenuClose(event);
    callback(rowItem);
  };

  handleCardMenuClose = (event: Event) => {
    event.stopPropagation();
    this.setState({ menuState: false });
  };

  render() {
    const { rowItem } = this.props;
    const {
      menuState,
      anchorEl,
    } = this.state;

    if (!this.notHiddenOptions.length) {
      return null;
    }

    if (this.notHiddenOptions.length === 1) {
      return (
        <Button
          variant="contained"
          onClick={() => this.notHiddenOptions[0].onClick(rowItem)}
        >
          {this.notHiddenOptions[0].label}
        </Button>
      );
    }

    return (
      <div className="d-flex justify-content-end align-items-center">
        <IconButton onClick={this.onCardMenuOptionSelect}>
          {!menuState ? <ArrowDownIcon /> : <ArrowUpIcon /> }
        </IconButton>
        <Menu
          open={menuState}
          anchorEl={anchorEl}
          options={this.notHiddenOptions.map(option => ({
            label: option.label || option.getLabel(rowItem),
            onClick: (event) => this.onClose(event, option.onClick, option.getIsDisabled),
            disabled: option.getIsDisabled && option.getIsDisabled(rowItem)
          }))}
          onClose={this.handleCardMenuClose}
        />
      </div>
    );
  }
}

export default DataTableCardMenu;
