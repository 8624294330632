const getMappedBankStatus = (status: string) => {
  switch (status) {
    case 'new':
      return 'Active';
    case 'validated':
      return 'Active';
    case 'verified':
      return 'Active';
    case 'errored':
      return 'Errored';
    case 'verification_failed':
      return 'Errored';
    default:
      return 'Default';
  }
};

export const transferTableColumn = [
  {
    Header: 'Status',
    accessor: (row: any) => (row.is_default ? 'Default' : getMappedBankStatus(row.status)),
    id: 'status',
  },
  {
    Header: 'Bank',
    accessor: 'bank_name',
  },
  {
    Header: 'Last 4',
    accessor: 'last4',
  },
  {
    Header: 'Routing Number',
    accessor: (row: any) => (`-----${row.routing_number.slice(-4)}`),
    id: 'routing_number',
  },
];

export const cardTableColumn = [
  {
    Header: 'Status',
    accessor: (row: any) => (row.is_default && row.card.status !== 'expired' ? 'Default' : row.card.status),
    id: 'status',
  },
  {
    Header: 'Card Name',
    accessor: 'billing_details.name',
  },
  {
    Header: 'Card Number',
    accessor: (row: any) => (`---- ---- ---- ${row.card.last4}`),
    id: 'last4',
  },
  {
    Header: 'Expires',
    accessor: (row: any) => (`${row.card.exp_month}/${row.card.exp_year}`),
    id: 'expires',
  },
];
