import React from 'react';
import TabsMui from '@material-ui/core/Tabs';
import withStyles from '@material-ui/core/styles/withStyles';

import Tab from '../Tab';

const Tabs = withStyles({
  scrollButtonsDesktop: {
    width: 'initial',
    cursor: 'pointer'
  }
})(TabsMui);

interface Props {
  tabs: {
    label: string,
    disabled?: boolean,
  }[],
  value: number,
  onChange: (val: number) => void,
  variant?: 'scrollable' | 'standard' | 'fullWidth' | undefined,
  scrollButtons?: 'on' | 'off' | 'auto' | 'desktop' | undefined,
}

const CustomTabs: React.FC<Props> = ({
  tabs, onChange, value, ...rest
}) => {
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    onChange(newValue);
  };

  return (
    <Tabs
      value={value}
      indicatorColor="primary"
      textColor="primary"
      onChange={handleChange}
      style={{ borderBottom: '1px solid #F1F2F2' }}
      {...rest}
    >
      {/* eslint-disable-next-line react/no-array-index-key */}
      {tabs.map((item, index) => <Tab {...item} key={index} />)}
    </Tabs>
  );
};

export default CustomTabs;
