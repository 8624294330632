import { put, takeLatest, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';


import { login, onBoardingError, setUpPasswordSuccess } from '../../actions/onBoaring';
import { ONBOARDING_TYPES } from '../../constants';
import Api from '../../../api';
import { OnBoardingActionTypes } from '../../actions/onBoaring/types';

export function* worker({ payload }: OnBoardingActionTypes): SagaIterator<void> {
  try {
    // @ts-ignore
    yield call(Api.users.setUserPassword, payload);
    yield put(setUpPasswordSuccess());
    yield put(login());
  } catch (e) {
    yield put(onBoardingError(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(ONBOARDING_TYPES.SET_UP_PASSWORD_REQUEST, worker);
}
