import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { injectIntl, IntlShape } from 'react-intl';

import { Provider } from './Context';
import Content from './Content';
import { ROLES } from '../../constants';
import Api from '../../api';
import { DeleteBankAccountModal, DeleteCreditCardModal, DeleteAccountModal } from '../../components';

interface Props extends RouteComponentProps<{ id: string }> {
  step: number,
  userRole: string,
  fetchInitData: (id: string) => void,
  getCompanyDetails: () => void,
  getAppSubscription: () => void,
  getBankingDetails: () => void,
  createCard: (values: {}, callback: (state: boolean) => void) => void,
  billingList: {}[],
  bankingList: {}[],
  billingInvoices: {}[],
  billingInvoicesTotal: number,
  billingInvoicesPagination: { page: number },
  billingInvoicesSort: { id: string, firstDirection: boolean },
  refetchBillingHistory: (data: {}) => void,
  appSubscription: any,
  companyInfo: {
    name: string,
    id: string,
    errors: string[],
    address: {
      id: string,
    },
    contacts: {
      id: string,
    },
  },
  userData: {
    id: string,
    social_security_number: string,
    address: {
      id: string,
    },
    contacts: {
      id: string,
    },
  },
  intl: IntlShape,
}

interface State {
  activeTab: number,
  editModalOpen: boolean,
  editLoading: boolean,
  addTransferModalOpen: boolean,
  paymentModalOpen: boolean,
  paymentId: string,
  deleteBankId: string,
  addCardModalOpen: boolean,
  cardAdded: boolean,
  transferAccountAdded: boolean,
  deleteBankModalOpen: boolean,
  deleteAccountModal: boolean,
  deleteModalOpen: boolean,
  deleteId: string,
}

class Container extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeTab: props.match.params.id ? 2 : 0,
      editModalOpen: false,
      editLoading: false,
      addCardModalOpen: false,
      addTransferModalOpen: false,
      cardAdded: false,
      transferAccountAdded: false,
      deleteBankModalOpen: false,
      deleteAccountModal: false,
      paymentId: props.match.params.id || '',
      deleteBankId: '',
      deleteModalOpen: false,
      deleteId: '',
      paymentModalOpen: Boolean(props.match.params.id),
    };
  }

  componentDidMount() {
    const {
      userRole,
      step,
      history,
      fetchInitData,
      userData,
    } = this.props;

    if (userRole === ROLES.CLIENT) {
      history.push('/transactions/subscriptions');
    }
    if (step < 5 && userRole === ROLES.ACCOUNT_OWNER) {
      history.push('/onBoarding');
    }

    fetchInitData(userData.id);
  }

  get creditTableActions() {
    const { getBankingDetails } = this.props;
    return [
      {
        label: 'Make Default',
        onClick: (row: any) => Api.userProfiles.makeCreditCardDefault(row.id).then(getBankingDetails),
        getIsHidden: (row: any) => row.is_default || row.card.status === 'expired',
      },
      {
        label: 'Delete Credit Card',
        onClick: (row: any) => this.setState({ deleteId: row.id, deleteModalOpen: true }),
        getIsHidden: (row: any) => row.is_default,
      },
    ];
  }

  get billingActions() {
    return [
      {
        label: 'Make Payment',
        onClick: (row: any) => this.setState({ paymentModalOpen: true, paymentId: row.id }),
        getIsHidden: (row: any) => row.status !== 'failed',
      },
    ];
  }

  get transferActions() {
    const { getBankingDetails } = this.props;

    return [
      {
        label: 'Make Default',
        onClick: (row: any) => Api.userProfiles.makeTransferAccountDefault(row.id).then(getBankingDetails),
        getIsHidden: (row: any) => row.is_default || row.status === 'errored',
      },
      {
        label: 'Delete Account',
        onClick: (row: any) => this.setState({ deleteBankId: row.id, deleteBankModalOpen: true }),
        getIsHidden: (row: any) => row.is_default,
      },
    ];
  }

  handleTabChange = (val: number) => this.setState({ activeTab: val });

  handleOpenEditForm = () => this.setState((prevState) => ({ editModalOpen: !prevState.editModalOpen }));

  handleSubmitEditForm = (values: any) => {
    const { companyInfo, userData, getCompanyDetails } = this.props;
    this.setState({ editLoading: true }, () => {
      const request = [
        Api.userProfiles.updateCompany({
          id: companyInfo.id,
          name: companyInfo.name,
          contacts: {
            id: companyInfo.contacts.id,
            phone_number: values.phone_number,
            web_site: values.web_site,
          },
          address: {
            id: companyInfo.address.id,
            street: values.street,
            city: values.city,
            zip_code: `${values.zip_code}`,
            state: values.state,
          },
          employee_id: values.employee_id,
          type: 1,
        }),
        Api.userProfiles.updateAccountInfo({
          id: userData.id,
          values: {
            user: userData.id,
            first_name: values.first_name,
            last_name: values.last_name,
            date_of_birth: values.date_of_birth,
            source: values.source,
            occupation: Number(values.occupation),
            address: {
              id: userData.address.id,
              street: values.user_street,
              city: values.user_city,
              zip_code: `${values.user_zip_code}`,
              state: values.user_state,
            },
            contacts: {
              phone_number: values.user_phone_number,
              web_site: values.businessWebsite,
            }
          }
        }),
      ];

      if (values.docs[0]) {
        const formData = new FormData();
        formData.append('identity_document_front', values.docs[0].item);
        formData.append('identity_document_back', values.docs[1].item);
        request.push(Api.userProfiles.updateUserDocument(formData, userData.id));
      }

      Promise.all(request).then(() => {
        setTimeout(() => {
          getCompanyDetails();
          this.setState({ editLoading: false, editModalOpen: false });
        }, 1000);
      });
    });
  };

  handleRefetchBillingHistory = (data: any) => {
    const { refetchBillingHistory } = this.props;
    refetchBillingHistory(data);
  };

  handleSubmitAddTransferForm = (values: any) => {
    const { companyInfo } = this.props;

    Api.userProfiles.createBankingAccount({
      organisation_id: companyInfo.id,
      account_holder_name: values.accountHolder,
      account_holder_type: 0,
      source: values.source
    }).then(() => {
      this.setState({ transferAccountAdded: true });
    });
  };

  toggleCreditCardModal = () => this.setState((prevState) => ({ addCardModalOpen: !prevState.addCardModalOpen }));

  toggleTransferModal = () => this.setState((prevState) => ({ addTransferModalOpen: !prevState.addTransferModalOpen }));

  togglePaymentModal = () => this.setState((prevState) => ({ paymentModalOpen: !prevState.paymentModalOpen }));

  toggleDeleteAccount = () => this.setState((prevState) => ({ deleteAccountModal: !prevState.deleteAccountModal }));

  handleAddCard = (values: any | null) => {
    const { createCard, getBankingDetails, companyInfo } = this.props;
    if (values) {
      createCard({
        content_type_id: companyInfo.id,
        content_type: 'organisation',
        address: {
          street: values.streetAddress,
          city: values.city,
          zip_code: `${values.zipCode}`,
          state: values.state,
        },
        source: values.source,
        cardholder_name: values.cardHolderName,
      }, (state: boolean) => {
        getBankingDetails();
        if (state) {
          return this.setState({ cardAdded: true }, () => {
            setTimeout(() => this.setState({ addCardModalOpen: false, cardAdded: false }), 1000);
          });
        }
        return this.setState({ addCardModalOpen: false });
      });
    }
  };

  handleTransferAddedSuccess = () => {
    const { getBankingDetails } = this.props;

    getBankingDetails();
    this.setState({ transferAccountAdded: false, addTransferModalOpen: false });
  };

  render() {
    const {
      state: {
        activeTab,
        editModalOpen,
        editLoading,
        addCardModalOpen,
        addTransferModalOpen,
        cardAdded,
        transferAccountAdded,
        paymentId,
        paymentModalOpen,
        deleteBankId,
        deleteBankModalOpen,
        deleteId,
        deleteModalOpen,
        deleteAccountModal,
      },
      props: {
        billingList,
        bankingList,
        companyInfo,
        getAppSubscription,
        intl,
        userData,
        billingInvoices,
        billingInvoicesPagination,
        billingInvoicesSort,
        billingInvoicesTotal,
        userRole,
        getBankingDetails,
        appSubscription,
      }
    } = this;

    return (
      <Provider
        value={{
          bankingList,
          billingList,
          companyInfo,
          userData,
          editModalOpen,
          editLoading,
          billingInvoices,
          intl,
          billingInvoicesPagination,
          billingInvoicesSort,
          billingInvoicesTotal,
          addCardModalOpen,
          addTransferModalOpen,
          cardAdded,
          transferAccountAdded,
          paymentId,
          paymentModalOpen,
          appSubscription,
          getAppSubscription,
          togglePaymentModal: this.togglePaymentModal,
          creditTableActions: this.creditTableActions,
          billingActions: this.billingActions,
          openEditForm: this.handleOpenEditForm,
          closeTransferModal: this.toggleTransferModal,
          transferActions: this.transferActions,
          onSubmitEditForm: this.handleSubmitEditForm,
          onRefetchBillingHistory: this.handleRefetchBillingHistory,
          onCreditCardOpen: this.toggleCreditCardModal,
          onAddCard: this.handleAddCard,
          onTransferAccountAdd: this.toggleTransferModal,
          onAddTransferAccount: this.handleSubmitAddTransferForm,
          onTransferAddedSuccess: this.handleTransferAddedSuccess,
          onAccountDelete: this.toggleDeleteAccount,
        }}
      >
        <Content
          userRole={userRole}
          onTabChange={this.handleTabChange}
          activeTab={activeTab}
        />
        <DeleteBankAccountModal
          open={deleteBankModalOpen}
          id={deleteBankId}
          callback={getBankingDetails}
          close={() => this.setState({ deleteBankModalOpen: false, deleteBankId: '' })}
        />
        <DeleteCreditCardModal
          open={deleteModalOpen}
          id={deleteId}
          callback={getBankingDetails}
          close={() => this.setState({ deleteModalOpen: false, deleteId: '' })}
        />
        <DeleteAccountModal
          open={deleteAccountModal}
          close={this.toggleDeleteAccount}
        />
      </Provider>
    );
  }
}

export default injectIntl(Container);
