import React from 'react';
import { IntlShape } from 'react-intl';

import { withParentData } from '../../Context';
import messages from '../../messages';
import PaymentNavigation from '../../../PaymentNavigation';

interface Props {
  activeStep: number,
  intl: IntlShape,
  steps?: string[],
}

const steps = (intl: IntlShape) => [
  intl.formatMessage(messages.selectClientStep),
  intl.formatMessage(messages.paymentDetailsStep),
  intl.formatMessage(messages.reviewStep),
];

const Navigation: React.FC<Props> = ({
  activeStep,
  intl,
}) => (
  <PaymentNavigation
    steps={steps(intl)}
    activeStep={activeStep}
  />
);

export default withParentData(Navigation);
