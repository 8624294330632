import { SUBSCRIPTION_DETAILS_TYPES } from '../constants';

const initState = {
  loading: false,
  error: {},
  subscriptionInfo: {},
  payments: [],
  pagination: {
    page: 1
  },
  sort: {
    id: null,
    firstDirection: false,
  },
  total: 0
};

export default (state = initState, { type, payload }: any) => {
  switch (type) {
    case SUBSCRIPTION_DETAILS_TYPES.GET_INFO:
      return {
        ...state,
        loading: true,
      };
    case SUBSCRIPTION_DETAILS_TYPES.GET_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptionInfo: payload,
      };
    case SUBSCRIPTION_DETAILS_TYPES.GET_PAYMENTS_SUCCESS:
      return {
        ...state,
        total: payload.count,
        payments: payload.results
      };
    case SUBSCRIPTION_DETAILS_TYPES.REFETCH_PAYMENTS_SUCCESS:
      return {
        ...state,
        sort: payload.sort,
        pagination: payload.pagination,
        payments: payload.data.results,
        total: payload.data.count,
      };
    case SUBSCRIPTION_DETAILS_TYPES.ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default: {
      return state;
    }
  }
};
