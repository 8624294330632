import {
  put, takeLatest, call, select
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { clientProfileError, refetchSubscriptionSuccess } from '../../actions/ClientProfile';
import { CLIENT_PROFILE_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker({ payload }: any): SagaIterator<void> {
  try {
    const { router } = yield select();
    const { data } = yield call(Api.subscriptions.refetchSubscriptions,
      {
        client: router.location.pathname.slice(15),
        ...payload
      });
    yield put(refetchSubscriptionSuccess({ ...payload, data }));
  } catch (e) {
    yield put(clientProfileError(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(CLIENT_PROFILE_TYPES.REFETCH_SUBSCRIPTIONS, worker);
}
