import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

import { apiUrl } from '../../constants';
import Content from './Content';

interface Props extends RouteComponentProps<{ id: string }> {
  getInvoice: (id: string) => void,
  invoiceInfo: {
    amount: number,
    refund_amount: number,
    description: string,
    refund_description: string,
    organisation: string,
    due_date: string,
    date_paid: string,
    date_refunded: string,
    advisor_name: string,
    date_created: string,
    date_canceled: string,
    client_name: string,
    invoiceInfo: string,
    status: string,
    advisor_email: string,
    number: string | null,
  },
  userRole: string,
  loading: boolean,
}

class Container extends Component<Props> {
  componentDidMount() {
    const { match, getInvoice } = this.props;

    getInvoice(match.params.id);
  }

  handlePrint = () => {
    const { match } = this.props;

    const pdf = window.open(`${apiUrl}/payments/invoices/${match.params.id}/print-pdf`);
    pdf!.print();
  };

  render() {
    const {
      invoiceInfo,
      loading,
      userRole,
    } = this.props;

    return (
      <Content
        userRole={userRole}
        loading={loading}
        invoiceInfo={invoiceInfo}
        onPrint={this.handlePrint}
      />
    );
  }
}

export default Container;
