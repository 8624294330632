import {
  put, takeLatest, call, select
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { push } from 'connected-react-router';

import { onBoardingError, setStep } from '../../actions/onBoaring';
import { ONBOARDING_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker({ payload }: any): SagaIterator<void> {
  try {
    const store = yield select();
    yield call(Api.users.setOnboardingStatus, { email: store.auth.userData.email, step: payload });
    yield put(setStep(payload));
    yield put(push('/users/clients'));
  } catch (e) {
    yield put(onBoardingError(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(ONBOARDING_TYPES.FINISH_ONBOARDING, worker);
}
