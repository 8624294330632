import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
import { withParentData } from '../../Context';
import { Button, H1, H2 } from '../../../../components';
import { STATES } from '../../../../constants';

interface Props {
  goToBackStep: () => void,
  onConfirmOnboarding: () => void,
  fetchInitReviewData: () => void,
  logout: () => void,
  companyData: any,
  accountInfo: any,
  bankingAccountData: any,
  billingInfo: any,
}

const occupationOptions = [
  { label: 'None', value: 0 },
  { label: 'Independent Registered Investment Advisor', value: 1 },
  { label: 'Sole Proprietorship', value: 2 },
  { label: 'Certified Financial Planner', value: 3 },
];

const AccountStep: React.FC<Props> = ({
  goToBackStep,
  onConfirmOnboarding,
  fetchInitReviewData,
  companyData,
  accountInfo,
  bankingAccountData,
  billingInfo,
  logout,
}) => {
  useEffect(() => {
    fetchInitReviewData();
  }, [fetchInitReviewData]);
  if (!billingInfo.billing_details) {
    return null;
  }

  return (
    <Box maxHeight={{ xs: 'initial', md: '100%' }}>
      <Box display="flex" justifyContent="flex-end">
        <Button variant="text" onClick={logout}>Log out</Button>
      </Box>
      <Box>
        <H1 mb={2}><FormattedMessage {...messages.reviewStep} /></H1>
        <Grid container spacing={3} style={{ maxWidth: '100%' }}>
          <Grid item xs={12} md={6}>
            <H2>
              <FormattedMessage {...messages.setUpAccountStep} />
              <Box p={3} borderRadius={4} bgcolor="#F2F8FD" mt="13px">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box fontSize={16} color="#011638">
                      <FormattedMessage {...messages.typeOfAccount} />
                    </Box>
                  </Grid>
                  <Grid item xs={6}><Box color="#6D6E71" fontSize={16}>Legal Entity</Box></Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box fontSize={16} color="#011638">
                      <FormattedMessage {...messages.companyNameField} />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box color="#6D6E71" fontSize={16}>{companyData.name}</Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box fontSize={16} color="#011638">
                      <FormattedMessage {...messages.employerINField} />
                    </Box>
                  </Grid>
                  <Grid item xs={6}><Box color="#6D6E71" fontSize={16}>{companyData.employee_id}</Box></Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box fontSize={16} color="#011638">
                      <FormattedMessage {...messages.streetAddressField} />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box color="#6D6E71" fontSize={16}>{companyData.address && companyData.address.street}</Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box fontSize={16} color="#011638">
                      <FormattedMessage {...messages.cityField} />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box color="#6D6E71" fontSize={16}>{companyData.address && companyData.address.city}</Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box fontSize={16} color="#011638">
                      <FormattedMessage {...messages.stateField} />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box color="#6D6E71" fontSize={16}>{companyData.address && companyData.address.state}</Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box fontSize={16} color="#011638">
                      <FormattedMessage {...messages.zipCodeField} />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box color="#6D6E71" fontSize={16}>{companyData.address && companyData.address.zip_code}</Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box fontSize={16} color="#011638">
                      <FormattedMessage {...messages.companySiteField} />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box color="#6D6E71" fontSize={16}>{companyData.contacts && companyData.contacts.web_site}</Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box fontSize={16} color="#011638">
                      <FormattedMessage {...messages.phoneNumberField} />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box color="#6D6E71" fontSize={16}>{companyData.contacts && companyData.contacts.phone_number}</Box>
                  </Grid>
                </Grid>
              </Box>
            </H2>
          </Grid>
          <Grid item xs={12} md={6}>
            <H2>
              <FormattedMessage {...messages.accountInfoStep} />
              <Box p={3} borderRadius={4} bgcolor="#F2F8FD" mt="13px">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box fontSize={16} color="#011638">
                      <FormattedMessage {...messages.firstNameField} />
                    </Box>
                  </Grid>
                  <Grid item xs={6}><Box color="#6D6E71" fontSize={16}>{accountInfo.first_name}</Box></Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box fontSize={16} color="#011638">
                      <FormattedMessage {...messages.lastNameField} />
                    </Box>
                  </Grid>
                  <Grid item xs={6}><Box color="#6D6E71" fontSize={16}>{accountInfo.last_name}</Box></Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box fontSize={16} color="#011638">
                      <FormattedMessage {...messages.dateOfBirthField} />
                    </Box>
                  </Grid>
                  <Grid item xs={6}><Box color="#6D6E71" fontSize={16}>{accountInfo.date_of_birth}</Box></Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box fontSize={16} color="#011638">
                      <FormattedMessage {...messages.secNumberField} />
                    </Box>
                  </Grid>
                  <Grid item xs={6}><Box color="#6D6E71" fontSize={16}>*********</Box></Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box fontSize={16} color="#011638">
                      <FormattedMessage {...messages.address} />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box color="#6D6E71" fontSize={16}>{accountInfo.address && accountInfo.address.street}</Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box fontSize={16} color="#011638">
                      <FormattedMessage {...messages.zipCodeField} />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box color="#6D6E71" fontSize={16}>{accountInfo.address && accountInfo.address.zip_code}</Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box fontSize={16} color="#011638">
                      <FormattedMessage {...messages.phoneNumberField} />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box color="#6D6E71" fontSize={16}>{accountInfo.contacts && accountInfo.contacts.phone_number}</Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box fontSize={16} color="#011638">
                      <FormattedMessage {...messages.businessWebsite} />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box color="#6D6E71" fontSize={16}>{accountInfo.contacts && accountInfo.contacts.web_site}</Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box fontSize={16} color="#011638">
                      <FormattedMessage {...messages.professionalOccupationField} />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box color="#6D6E71" fontSize={16}>
                      {(occupationOptions.find(item => item.value === accountInfo.occupation) || {}).label}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </H2>
          </Grid>
          <Grid item xs={12} md={6}>
            <H2>
              <FormattedMessage {...messages.bankingDetailsStep} />
              <Box p={3} borderRadius={4} bgcolor="#F2F8FD" mt="13px">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box fontSize={16} color="#011638">
                      <FormattedMessage {...messages.accountHolderField} />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box color="#6D6E71" fontSize={16}>{bankingAccountData.account_holder_name}</Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box fontSize={16} color="#011638">
                      <FormattedMessage {...messages.typeOfBusinessField} />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box color="#6D6E71" fontSize={16}>Company</Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box fontSize={16} color="#011638">
                      <FormattedMessage {...messages.routingNumberField} />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box color="#6D6E71" fontSize={16}>
                      {`-------${bankingAccountData.routing_number && bankingAccountData.routing_number.slice(-4)}`}
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box fontSize={16} color="#011638">
                      <FormattedMessage {...messages.accountNumberField} />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box color="#6D6E71" fontSize={16}>{`-------${bankingAccountData.last4}`}</Box>
                  </Grid>
                </Grid>
              </Box>
            </H2>
          </Grid>
          <Grid item xs={12} md={6}>
            <H2>
              <FormattedMessage {...messages.billingDetails} />
              <Box p={3} borderRadius={4} bgcolor="#F2F8FD" mt="13px">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box fontSize={16} color="#011638">
                      <FormattedMessage {...messages.addressStreetField} />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box color="#6D6E71" fontSize={16}>{billingInfo.billing_details.address.line1}</Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box fontSize={16} color="#011638">
                      <FormattedMessage {...messages.cityField} />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box color="#6D6E71" fontSize={16}>{billingInfo.billing_details.address.city}</Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box fontSize={16} color="#011638">
                      <FormattedMessage {...messages.stateField} />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box color="#6D6E71" fontSize={16}>
                      {(
                        STATES.find((item) => item.value === billingInfo.billing_details.address.state)
                        || {}).label}
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box fontSize={16} color="#011638">
                      <FormattedMessage {...messages.zipCodeField} />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box color="#6D6E71" fontSize={16}>{billingInfo.billing_details.address.postal_code}</Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box fontSize={16} color="#011638">
                      <FormattedMessage {...messages.accountHolderField} />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box color="#6D6E71" fontSize={16}>{billingInfo.billing_details.name}</Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box fontSize={16} color="#011638">
                      <FormattedMessage {...messages.cardNumberField} />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box color="#6D6E71" fontSize={16}>{`---- ---- ---- ${billingInfo.card.last4}`}</Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box fontSize={16} color="#011638">
                      <FormattedMessage {...messages.expiryDateField} />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box color="#6D6E71" fontSize={16}>{`${billingInfo.card.exp_month}/${billingInfo.card.exp_year}`}</Box>
                  </Grid>
                </Grid>
              </Box>
            </H2>
          </Grid>
          <Box
            pt={3}
            textAlign="end"
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            width="100%"
            pr="12px"
            mb={3}
          >
            <Box mr={3}>
              <Button onClick={goToBackStep}>
                <FormattedMessage {...messages.backButton} />
              </Button>
            </Box>
            <Button onClick={onConfirmOnboarding} variant="contained">
              <FormattedMessage {...messages.submitButton} />
            </Button>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default withParentData(AccountStep);
