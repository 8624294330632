import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

import messages from './messages';
import { REGEXP } from '../../../../constants/validation';

export default (intl: IntlShape) => Yup.object().shape({
  companyName: Yup.string()
    .min(5, 'Company name should be at least 5 letters long.')
    .max(41, intl.formatMessage(messages.companyNameError))
    .required(intl.formatMessage(messages.requiredMassage)),
  employerIN: Yup.string()
    .required(intl.formatMessage(messages.employerINErrorField))
    .matches(/^[^_]*$/, intl.formatMessage(messages.employerINErrorField)),
  companyStreetAddress: Yup.string()
    .required(intl.formatMessage(messages.requiredMassage))
    .matches(/^[a-zA-Z0-9-\\,\\. ]{1,1000}$/, 'Street address contains invalid symbols.')
    .min(3, intl.formatMessage(messages.companyStreetAddressMinError))
    .max(95, intl.formatMessage(messages.companyStreetAddressMaxError)),
  city: Yup.string()
    .required(intl.formatMessage(messages.requiredMassage)),
  state: Yup.string()
    .required(intl.formatMessage(messages.requiredMassage)),
  zipCode: Yup.string()
    .required(intl.formatMessage(messages.requiredMassage))
    .min(5, intl.formatMessage(messages.zipCodeErrorField))
    .max(9, intl.formatMessage(messages.zipCodeErrorField)),
  companySite: Yup.string()
    .required(intl.formatMessage(messages.requiredMassage))
    .matches(REGEXP.WEBSITE_URL, intl.formatMessage(messages.companySiteError)),
  phoneNumber: Yup.string()
    .required(intl.formatMessage(messages.phoneNumberError))
    .matches(/^[^_]*$/, intl.formatMessage(messages.phoneNumberError)),
});
