import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { FormattedMessage, IntlShape } from 'react-intl';
import { Form, Formik, Field } from 'formik';
import moment from 'moment';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe } from '@stripe/react-stripe-js';

import messages from './messages';
import { withParentData } from '../../Context';
import {
  FormikInput,
  Button,
  FormikSelect,
  FormikDatePicker,
  InputMask,
  H1,
  H3,
} from '../../../../components';
import { STATES, occupationOptions, stripePushKey } from '../../../../constants';
import validationSchema from './validationSchema';

interface Props {
  intl: IntlShape,
  submitAccountInfo: (values: {} | null) => void,
  goToBackStep: () => void,
  fetchInitAccountInfoData: () => void,
  accountInfo: any,
  loading: boolean,
  userEmail: string,
}

const AccountStep: React.FC<Props> = ({
  intl,
  submitAccountInfo: submit,
  goToBackStep,
  fetchInitAccountInfoData,
  accountInfo,
  loading,
  userEmail,
}) => {
  const stripe = useStripe();

  useEffect(() => {
    fetchInitAccountInfoData();
  }, [fetchInitAccountInfoData]);

  return (
    <Box m={3} width={600}>
      <H1 mb={3}>
        <FormattedMessage {...messages.accountInfoStep} />
      </H1>
      <H3 mb={3}>
        <FormattedMessage {...messages.accountInfoDescription} />
      </H3>
      <Formik
        initialValues={{
          firstName: accountInfo.first_name || '',
          lastName: accountInfo.last_name || '',
          dateOfBirth: accountInfo.date_of_birth || '',
          secNumber: accountInfo.last_name ? '*********' : '',
          companyStreetAddress: (accountInfo.address && accountInfo.address.street) || '',
          city: (accountInfo.address && accountInfo.address.city) || '',
          state: (accountInfo.address && accountInfo.address.state) || '',
          zipCode: (accountInfo.address && accountInfo.address.zip_code) || '',
          phoneNumber: (accountInfo.contacts && accountInfo.contacts.phone_number) || '',
          businessWebsite: (accountInfo.contacts && accountInfo.contacts.web_site) || '',
          professionalOccupation: accountInfo.occupation || '',
        }}
        validationSchema={validationSchema(intl)}
        enableReinitialize
        onSubmit={async (values) => {
          if (stripe) {
            const { token } = await stripe.createToken('person', {
              first_name: values.firstName,
              last_name: values.lastName,
              ssn_last_4: values.secNumber.slice(-4),
              phone: values.phoneNumber,
              email: userEmail,
              address: {
                city: values.city,
                state: values.state,
                postal_code: `${values.zipCode}`,
                country: 'US',
                line1: values.companyStreetAddress,
              },
              dob: {
                day: moment(values.dateOfBirth).date(),
                month: moment(values.dateOfBirth).month(),
                year: moment(values.dateOfBirth).year(),
              },
              relationship: {
                owner: true,
                representative: true,
                title: 'Advisor',
              },
            });
            submit({ ...values, source: token?.id });
          }
        }}
      >
        {({
          isValid,
          dirty,
          values,
          setFieldValue,
          setFieldTouched
        }) => {
          const handleFieldChange = () => {
            if (Object.keys(accountInfo).length !== 0 && values.secNumber.includes('*')) {
              setFieldTouched('secNumber', true);
              setFieldValue('secNumber', '');
            }
          };

          return (
            <Form style={{ overflowX: 'hidden' }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Field
                    component={FormikInput}
                    label={intl.formatMessage(messages.firstNameField)}
                    name="firstName"
                    onChange={handleFieldChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    component={FormikInput}
                    label={intl.formatMessage(messages.lastNameField)}
                    onChange={handleFieldChange}
                    name="lastName"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    component={FormikDatePicker}
                    label={intl.formatMessage(messages.dateOfBirthField)}
                    onChange={handleFieldChange}
                    name="dateOfBirth"
                    maxDate={moment().set({ year: moment().year() - 18 })}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    component={FormikInput}
                    type="password"
                    label={intl.formatMessage(messages.secNumberField)}
                    onChange={handleFieldChange}
                    name="secNumber"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    component={FormikInput}
                    label={intl.formatMessage(messages.streetAddressField)}
                    onChange={handleFieldChange}
                    name="companyStreetAddress"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    component={FormikInput}
                    label={intl.formatMessage(messages.cityField)}
                    onChange={handleFieldChange}
                    name="city"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="state"
                    component={FormikSelect}
                    label={intl.formatMessage(messages.stateField)}
                    onChange={handleFieldChange}
                    options={STATES}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    component={FormikInput}
                    label={intl.formatMessage(messages.zipCodeField)}
                    onChange={handleFieldChange}
                    name="zipCode"
                    type="number"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    component={InputMask}
                    mask="(999)999-9999"
                    label={intl.formatMessage(messages.phoneNumberField)}
                    onChange={handleFieldChange}
                    name="phoneNumber"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Box mt={3} mb={3} color="#6D6E71">
                <FormattedMessage {...messages.optionText} />
              </Box>
              <Field
                component={FormikInput}
                label={intl.formatMessage(messages.businessWebsiteField)}
                onChange={handleFieldChange}
                name="businessWebsite"
                fullWidth
                disabled={Boolean(values.professionalOccupation && values.professionalOccupation !== '0')}
              />
              <Box mt={3}>
                <Field
                  options={occupationOptions}
                  label={intl.formatMessage(messages.professionalOccupationField)}
                  onChange={handleFieldChange}
                  name="professionalOccupation"
                  component={FormikSelect}
                  disabled={values.businessWebsite}
                />
              </Box>
              <Box
                mt={5}
                textAlign="end"
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                mb={10}
              >
                <Box mr={3}>
                  <Button onClick={goToBackStep}>
                    <FormattedMessage {...messages.backButton} />
                  </Button>
                </Box>
                <Button
                  type={dirty ? 'submit' : 'button'}
                  onClick={() => {
                    if (!dirty) {
                      submit(null);
                    }
                  }}
                  variant="contained"
                  loading={loading}
                  disabled={Object.keys(accountInfo).length === 0 ? (!dirty || !isValid) : !isValid}
                >
                  <FormattedMessage {...messages.submitButton} />
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

const stripePromise = loadStripe(stripePushKey() || '');

const StripeWrapper: React.FC<Props> = (props) => (
  <Elements stripe={stripePromise}>
    <AccountStep {...props} />
  </Elements>
);

export default withParentData(StripeWrapper);
