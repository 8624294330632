import {
  put, takeLatest, call
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { walletError, getCardsSuccess } from '../../actions/Wallet';
import { WALLET_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker(): SagaIterator<void> {
  try {
    const { data } = yield call(Api.userProfiles.getBilling);
    yield put(getCardsSuccess(data));
  } catch (e) {
    yield put(walletError(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(WALLET_TYPES.GET_CARDS, worker);
}
