import React, { Component } from 'react';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import { ConnectedRouter } from 'connected-react-router';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import { LocalizationProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/core/styles';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import AppLocale from './intl';
import RootLayout from './layouts';
import reduxConfig from './redux';
import WelcomePopup from './components/WelcomePopup';
import theme from './metadata/material/theme';

import './metadata/styles/reset.css';

import { getUser } from './redux/actions/Auth';
import { getCookie } from './helpers';

const cache = createIntlCache();

class App extends Component {
  componentDidMount() {
    if (getCookie('csrftoken')) {
      reduxConfig.store.dispatch(getUser());
    } else {
      reduxConfig.store.dispatch({ type: 'finish_init_load' });
    }
  }

  render() {
    const { locale } = reduxConfig.store.getState().general.intl;
    const intl = createIntl(
      AppLocale(locale),
      cache,
    );

    return (
      <Provider store={reduxConfig.store}>
        <ConnectedRouter history={reduxConfig.history}>
          <LocalizationProvider dateAdapter={MomentUtils}>
            <ThemeProvider theme={theme}>
              <RawIntlProvider value={intl}>
                <WelcomePopup />
                <RootLayout />
              </RawIntlProvider>
            </ThemeProvider>
          </LocalizationProvider>
        </ConnectedRouter>
        <ReduxToastr
          timeOut={3000}
          position="top-right"
          transitionIn="bounceInDown"
          transitionOut="bounceOutUp"
          closeOnToastrClick
          preventDuplicates={false}
        />
      </Provider>
    );
  }
}

export default App;
