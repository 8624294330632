import React from 'react';
import Box from '@material-ui/core/Box';
import { FormattedMessage } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router';


import { ArrowLeftIcon } from '../../metadata/icons';
import messages from '../../screens/InvoiceDetails/messages';

const BackButton: React.FC<RouteComponentProps> = ({ history }) => (
  <Box display="flex" style={{ cursor: 'pointer' }} onClick={history.goBack}>
    <ArrowLeftIcon color="#6D6E71" />
    <span style={{ color: '#6D6E71 !important' }}><FormattedMessage {...messages.backButton} /></span>
  </Box>
);

export default withRouter(BackButton);
