import { defineMessages } from 'react-intl';

export default defineMessages({
  setUpAccountStep: {
    id: 'onBoarding.setUpAccountStep',
    defaultMessage: 'Set Up Your Account',
    description: 'Navigation'
  },
  legalEntity: {
    id: 'onBoarding.setUpAccountStep.legalEntity',
    defaultMessage: 'Legal entity',
    description: 'Navigation'
  },
  requiredMassage: {
    id: 'onBoarding.requiredFieldMassage',
    defaultMessage: 'Please fill in all required fields.',
    description: 'Message'
  },
  companyNameField: {
    id: 'fields.companyName',
    defaultMessage: 'Company name',
    description: 'Field label'
  },
  companyNameError: {
    id: 'fields.companyName.error',
    defaultMessage: 'Company name is too long.',
    description: 'Field error label'
  },
  employerINField: {
    id: 'fields.employerIN',
    defaultMessage: 'Employer identification number',
    description: 'Field label'
  },
  employerINErrorField: {
    id: 'fields.employerIN.error',
    defaultMessage: 'Employer identification number should contain nine digits.',
    description: 'Field label'
  },
  companyStreetAddressField: {
    id: 'fields.companyStreetAddress',
    defaultMessage: 'Company street address',
    description: 'Field label'
  },
  companyStreetAddressMinError: {
    id: 'fields.companyStreetAddress.minError',
    defaultMessage: 'Street address should be at least 3 letters long.',
    description: 'Field label'
  },
  companyStreetAddressMaxError: {
    id: 'fields.companyStreetAddress.maxError',
    defaultMessage: 'Street address is too long.',
    description: 'Field label'
  },
  cityField: {
    id: 'fields.city',
    defaultMessage: 'City',
    description: 'Field label'
  },
  stateField: {
    id: 'fields.state',
    defaultMessage: 'State',
    description: 'Field label'
  },
  zipCodeField: {
    id: 'fields.zipCode',
    defaultMessage: 'Zip Code',
    description: 'Field label'
  },
  zipCodeErrorField: {
    id: 'fields.zipCode.error',
    defaultMessage: 'Zip code should contain from 5 up to 9 digits.',
    description: 'Field label'
  },
  companySiteField: {
    id: 'fields.companySite',
    defaultMessage: 'Company website',
    description: 'Field label'
  },
  companySiteError: {
    id: 'fields.companySite.error',
    defaultMessage: 'Please enter a valid company website.',
    description: 'Field label'
  },
  phoneNumberField: {
    id: 'fields.phoneNumber',
    defaultMessage: 'Phone number',
    description: 'Field label'
  },
  phoneNumberError: {
    id: 'fields.phoneNumber.error',
    defaultMessage: 'Phone number should contain only digits.',
    description: 'Field label'
  },
  nextButton: {
    id: 'buttons.next',
    defaultMessage: 'Next',
    description: 'Button label'
  }
});
