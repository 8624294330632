import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { RouteComponentProps } from 'react-router';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import PersonIcon from '@material-ui/icons/Person';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { logout as logoutAction } from '../../redux/actions/onBoaring';
// @ts-ignore
import logo from '../../metadata/img/logo.png';
import { ArrowDownIcon, ArrowUpIcon } from '../../metadata/icons';
import { Menu } from '../../components';
import { ROLES } from '../../constants';

import {
  menuClientOptions,
  menuAdvisorItems,
  menuOptions,
  useStyles,
  ListItem,
  menuClientItems
} from './data';

interface Props extends RouteComponentProps {
  logout: () => void,
  userData: {
    last_name: string,
    first_name: string,
  }
  userRole: string,
}

const Root: React.FC<Props> = ({
  location,
  logout,
  history,
  children,
  userData,
  userRole,
}) => {
  const tabletView = useMediaQuery('(max-width:1024px)');
  const classes = useStyles();
  const [open, setOpen] = useState(tabletView);
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLDivElement>(null);

  useEffect(() => {
    setOpen(!tabletView);
  }, [tabletView]);

  const toggleMenu = () => setMenuOpen(prev => !prev);

  const handleMenuClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    toggleMenu();
  };

  const advisorSidebarItmes = userRole === ROLES.ADVISOR
    ? menuAdvisorItems.filter(item => item.link !== '/users/advisors' && item.link !== '/billing/transfers')
    : menuAdvisorItems;

  const advisorMenuActions = userRole === ROLES.ACCOUNT_OWNER
    ? menuOptions(logout, () => history.push('/settings'), () => history.push('/profile'))
    : menuOptions(logout, () => history.push('/settings'), () => history.push('/profile'))
      .filter(item => item.label !== 'Settings');

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant={tabletView ? 'temporary' : 'persistent'}
        onClose={() => setOpen(false)}
        anchor="left"
        open={open}
        transitionDuration={0}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <img src={logo} alt="logo" className={classes.logo} />
        <List>
          {(userRole !== ROLES.CLIENT ? advisorSidebarItmes : menuClientItems).map(({ icon: Icon, ...item }) => {
            const isActive = location.pathname.includes(item.link) || location.pathname.includes(item.subLink);
            return (
              <ListItem button key={item.link + Boolean(Icon)} onClick={() => history.push(item.link)}>
                {Icon && <Icon color={isActive ? 'white' : '#808B9B'} />}
                <Box color={isActive ? 'white' : '#808B9B'} ml={Icon ? 3 : '54px'}>{item.label}</Box>
              </ListItem>
            );
          })}
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <Box
          display="flex"
          justifyContent={tabletView ? 'space-between' : 'flex-end'}
          mb={tabletView ? 3 : 0}
        >
          <Box
            display={tabletView ? 'flex' : 'none'}
            alignItems="center"
          >
            <IconButton onClick={() => setOpen((prev) => !prev)}>
              <MenuIcon style={{ fontSize: '24px' }} />
            </IconButton>
          </Box>
          <Box
            // @ts-ignore
            css={{ cursor: 'pointer' }}
            height={40}
            paddingX={2}
            paddingY={1}
            display="flex"
            alignItems="center"
            onClick={handleMenuClick}
          >
            <Box color="#3772FF">
              <PersonIcon />
            </Box>
            <Box marginX={2} fontWeight={500}>{`${userData.first_name} ${userData.last_name}`}</Box>
            {menuOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </Box>
        </Box>
        <Menu
          open={menuOpen}
          anchorEl={anchorEl}
          options={userRole !== ROLES.CLIENT
            ? advisorMenuActions
            : menuClientOptions(logout, () => history.push('/profile'))}
          onClose={toggleMenu}
        />
        {children}
      </main>
    </div>
  );
};

const mapDispatchToProps = {
  logout: logoutAction,
};

const mapStateToProps = (state: any) => ({
  userData: state.auth.userData,
  userRole: state.auth.userRole,
});

export default connect(mapStateToProps, mapDispatchToProps)(Root);
