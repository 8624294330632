/* eslint-disable @typescript-eslint/quotes  */
import { defineMessages } from 'react-intl';

export default defineMessages({
  welcomeMessage: {
    id: 'onBoarding.welcomeMessage',
    defaultMessage: 'Welcome To ChalicePay',
    description: 'Message'
  },
  welcomeThanksMessage: {
    id: 'onBoarding.welcomeThanksMessage',
    defaultMessage: 'Thank you for signing up with ChalicePay',
    description: 'Message'
  },
  finishButton: {
    id: 'buttons.finish',
    defaultMessage: 'Finish',
    description: 'Button label'
  },
});
