import React from 'react';
import Box from '@material-ui/core/Box';

import {
  Tabs,
  Table,
} from '../../../../components';
import { withParentData } from '../../Context';
import {
  subscriptionColumns,
  singlePaymentsColumns,
  EmptyMessage,
  tabs,
} from './data';

interface Props {
  activeTab: number,
  onTabChange: (val: number) => void,
  clientInfo: {
    is_active: boolean,
  },
  onPaymentRequest: () => void,
  singlePayments: {}[],
  singlePaymentsTotal: number,
  singlePaymentsPagination: { page: number },
  refetchSinglePaymentData: (data: any) => void,
  refetchSubscriptionData: (data: any) => void,
  singlePaymentsSort: { id: string, firstDirection: boolean },
  singlePaymentActions: any,
  subscriptionActions: any,
  subscriptions: {}[],
  subscriptionsTotal: number,
  subscriptionsPagination: { page: number },
  subscriptionsSort: { id: string, firstDirection: boolean },
  accountVerified: boolean,
  accountSubscribed: boolean,
}

const MyComponent: React.FC<Props> = ({
  activeTab,
  onTabChange,
  clientInfo,
  onPaymentRequest,
  singlePayments,
  singlePaymentsTotal,
  singlePaymentsSort,
  singlePaymentsPagination,
  refetchSinglePaymentData,
  singlePaymentActions,
  subscriptionsTotal,
  subscriptionsSort,
  subscriptionsPagination,
  subscriptions,
  refetchSubscriptionData,
  subscriptionActions,
  accountSubscribed,
  accountVerified,
}) => (
  <Box>
    <Tabs
      tabs={tabs}
      onChange={onTabChange}
      value={activeTab}
    />
    <Box mt={3}>
      {!activeTab ? (
        <Table
          pagination={singlePaymentsPagination}
          sort={singlePaymentsSort}
          countTotal={singlePaymentsTotal}
          refetchData={refetchSinglePaymentData}
          columns={singlePaymentsColumns}
          noItemsMessage={(
            <EmptyMessage
              accountVerified={accountVerified}
              accountSubscribed={accountSubscribed}
              isSubscription={false}
              onClick={onPaymentRequest}
              isActive={clientInfo.is_active}
            />
          )}
          data={singlePayments}
          actions={singlePaymentActions}
          withPagination
        />
      ) : (
        <Table
          columns={subscriptionColumns}
          noItemsMessage={(
            <EmptyMessage
              accountVerified={accountVerified}
              accountSubscribed={accountSubscribed}
              isSubscription
              onClick={onPaymentRequest}
              isActive={clientInfo.is_active}
            />
          )}
          data={subscriptions}
          pagination={subscriptionsPagination}
          sort={subscriptionsSort}
          countTotal={subscriptionsTotal}
          actions={subscriptionActions}
          withPagination
          refetchData={refetchSubscriptionData}
        />
      )}
    </Box>
  </Box>
);

export default withParentData(MyComponent);
