import {
  put, takeLatest, call
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { toastr } from 'react-redux-toastr';

import { onBoardingError, setStep, submitBillingStepSuccess } from '../../actions/onBoaring';
import { SubmitCompanyStepAction } from '../../actions/onBoaring/types';
import { ONBOARDING_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker({ payload }: SubmitCompanyStepAction): SagaIterator<void> {
  try {
    const { data } = yield call(Api.userProfiles.getBilling);
    if (data.length === 0) {
      yield call(Api.userProfiles.createBillingAccount, payload);
    } else {
      yield call(Api.userProfiles.updateBillingAccount, { values: payload, id: data[0].id });
    }

    yield put(submitBillingStepSuccess());
    yield put(setStep(4));
  } catch (e) {
    yield put(onBoardingError(e.message));
    toastr.error('Please check the entered data', e.response.data.detail.slice(28));
  }
}

export default function* watcher() {
  yield takeLatest(ONBOARDING_TYPES.SUBMIT_BILLING_STEP, worker);
}
