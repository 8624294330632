import React from 'react';
import Box from '@material-ui/core/Box';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiListItem from '@material-ui/core/ListItem';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { BillingIcon, ClientsIcon, WalletIcon } from '../../metadata/icons';

const Wallet = ({ color }: { color: string }) => (
  <Box width={32}><WalletIcon color={color} /></Box>
);

export const ListItem = withStyles({
  root: {
    paddingRight: 24,
    paddingLeft: 24,
    boxSizing: 'border-box',
    background: '#011638',
    borderBottom: '2px solid #011638',
    '&:hover': {
      borderBottom: '2px solid #3772FF',
      background: 'none',
      '& path': {
        fill: 'white',
      },
      '& div': {
        color: 'white',
      },
    },
    '&:focus': {
      background: '#0044E8',
      borderBottom: '2px solid #0044E8',
      '& path': {
        fill: 'white',
      },
      '& div': {
        color: 'white',
      },
    },
  }
})(MuiListItem);

export const drawerWidth = 316;

export const menuAdvisorItems = [
  {
    label: 'Users',
    link: '/users/clients',
    icon: ClientsIcon,
    subLink: '/users',
  },
  {
    label: 'Clients',
    link: '/users/clients',
    subLink: ' ',
  },
  {
    label: 'Advisors',
    link: '/users/advisors',
    subLink: ' ',
  },
  {
    label: 'Billing',
    link: '/billing/single-payments',
    icon: BillingIcon,
    subLink: '/billing',
  },
  {
    label: 'One time payments',
    link: '/billing/single-payments',
    subLink: ' ',
  },
  {
    label: 'Subscriptions',
    link: '/billing/subscriptions',
    subLink: ' ',
  },
  {
    label: 'Transfers',
    link: '/billing/transfers',
    subLink: ' ',
  },
];

export const menuClientItems = [
  {
    label: 'Transactions',
    link: '/transactions/single-payments',
    icon: ClientsIcon,
    subLink: '/transactions',
  },
  {
    label: 'Subscriptions',
    link: '/transactions/subscriptions',
    subLink: ' ',
  },
  {
    label: 'One time payments',
    link: '/transactions/single-payments',
    subLink: ' ',
  },
  {
    label: 'Wallet',
    link: '/wallet',
    icon: Wallet,
    subLink: ' ',
  },
];

export const menuOptions = (logOut: () => void, goToSettings: () => void, profile: () => void) => [
  {
    label: 'Your Profile',
    onClick: profile,
  },
  {
    label: 'Settings',
    onClick: goToSettings,
  },
  {
    label: 'Log Out',
    onClick: logOut,
  },
];

export const menuClientOptions = (logOut: () => void, profile: () => void) => [
  {
    label: 'Your Profile',
    onClick: profile,
  },
  {
    label: 'Log Out',
    onClick: logOut,
  },
];

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    minHeight: '100vh'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 999,
  },
  drawerPaper: {
    width: drawerWidth,
    background: '#011638',
    justifyContent: 'center',
  },
  content: {
    background: '#F5FAFE',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '100vh',
    padding: theme.spacing(3),
  },
  contentShift: {
    marginLeft: 0,
  },
  logo: {
    maxWidth: 265,
    position: 'absolute',
    left: 24,
    top: 24,
  }
}));
