import { all, fork } from 'redux-saga/effects';

import getBankDetails from './getBankDetails';
import fetchInitData from './fetchInitData';
import getCompanyDetails from './getCompanyDetails';
import getBillingHistory from './getBillingHistory';
import refetchBillingHistory from './refetchBillingHistory';
import getAppSubscription from './getAppSubscription';

export default all([
  fork(getBillingHistory),
  fork(getBankDetails),
  fork(getCompanyDetails),
  fork(refetchBillingHistory),
  fork(fetchInitData),
  fork(getAppSubscription),
]);
