import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Form, Formik, Field } from 'formik';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import * as Yup from 'yup';

import InfoBox from '../InfoBox';
import { withParentData } from '../../Context';
import { Button, FormikInput } from '../../../../components';
import { ROLES, stripePushKey } from '../../../../constants';

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(35, 'First name is too long.')
    .matches(/^[a-zA-Z- ]{1,1000}$/, 'First name contains invalid symbols.')
    .required('Please fill in all required fields.'),
  lastName: Yup.string()
    .max(35, 'Last name is too long.')
    .matches(/^[a-zA-Z- ]{1,1000}$/, 'Last name contains invalid symbols.')
    .required('Please fill in all required fields.'),
});


interface Props {
  userData: any;
  toggleUserSection: () => void,
  handleInfoChange: (values: {}) => void,
  updateUserActive: boolean,
  userRole: string,
}

const UserInfo: React.FC<Props> = ({
  userData,
  toggleUserSection,
  updateUserActive,
  userRole,
  handleInfoChange,
}) => {
  const stripe = useStripe();
  if (updateUserActive) {
    return (
      <Formik
        initialValues={{
          lastName: userData.last_name,
          firstName: userData.first_name,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          if (userRole === ROLES.ACCOUNT_OWNER && stripe) {
            const { token } = await stripe.createToken('person', {
              first_name: values.firstName,
              last_name: values.lastName,
            });
            return handleInfoChange({ ...values, source: token?.id });
          }
          return handleInfoChange(values);
        }}
      >
        {({ isValid, dirty }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Field
                  component={FormikInput}
                  name="firstName"
                  fullWidth
                  label="First name"
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  component={FormikInput}
                  name="lastName"
                  fullWidth
                  label="Last name"
                />
              </Grid>
              <Grid xs={12} item style={{ display: 'flex' }}>
                <Box mr={3}>
                  <Button onClick={toggleUserSection} variant="text">Cancel</Button>
                </Box>
                <Button type="submit" variant="text" disabled={!dirty || !isValid}>save changes</Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    );
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <InfoBox
          value={userData.first_name}
          label="First name"
        />
      </Grid>
      <Grid item xs={6}>
        <InfoBox
          value={userData.last_name}
          label="Last name"
        />
      </Grid>
      <Grid item xs={12}>
        <InfoBox
          value={userData.email}
          label="Email"
        />
      </Grid>
      <Grid item xs={12}>
        <Button variant="text" onClick={toggleUserSection}>update</Button>
      </Grid>
    </Grid>
  );
};

const stripePromise = loadStripe(stripePushKey() || '');

const StripeWrapper: React.FC<Props> = (props) => (
  <Elements stripe={stripePromise}>
    <UserInfo {...props} />
  </Elements>
);


export default withParentData(StripeWrapper);
