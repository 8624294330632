import React from 'react';
import { FormattedMessage } from 'react-intl';
import Box from '@material-ui/core/Box';
import DoneIcon from '@material-ui/icons/Done';

import {
  Button,
  Modal,
  Table,
  Link,
  H3Dark,
  H1,
} from '../../components';
import { Form, NoTableItemsMessage } from './components';
import messages from './messages';
import { ROLES } from '../../constants';

interface Props {
  modalOpen: boolean,
  userRole: string,
  accountSubscribed: boolean,
  clientAdded: boolean,
  addClient: () => void,
  list: {}[],
  error: {},
  actions?: {
    label?: string,
    onClick?: (row: any) => void
  }[],
  sort: { id: string, firstDirection: boolean },
  pagination: { page: number },
  clientsTotal: number,
  refetchData: (data: any) => void,
}

const columns = (userRole: string) => [
  {
    Header: 'Name',
    accessor: ({ name, id }: any) => <Link href={`/users/clients/${id}`}>{name}</Link>,
    id: 'name'
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Advisor',
    accessor: userRole === ROLES.ADVISOR ? 'advisor_name' : (row: any) => (
      <Link href={`/users/advisors/${row.advisor}`}>
        {row.advisor_name}
      </Link>
    ),
    id: userRole === ROLES.ADVISOR ? 'advisor_name' : 'advisor',
  },
  {
    Header: 'Status',
    accessor: (row: any) => (row.is_active ? 'Registered' : 'Unregistered'),
    id: 'is_active',
  },
];

const MyComponent: React.FC<Props> = ({
  addClient,
  modalOpen,
  list,
  clientAdded,
  error,
  actions,
  clientsTotal,
  refetchData,
  sort,
  pagination,
  accountSubscribed,
  userRole,
}) => (
  <div>
    <Button
      onClick={addClient}
      variant="contained"
      shouldValidateAccount
      accountVerified={accountSubscribed}
      modalContent={(
        <H3Dark p={3} maxWidth={600}>
          <span>
            Sorry but you are unable to make action until your ChalicePay subscription is unpaid. Please check your
          </span>
          {' '}
          <Link href="/settings">Bank Details</Link>
        </H3Dark>
      )}
    >
      <FormattedMessage {...messages.addClientButton} />
    </Button>
    <H1 mt="52px" mb={2}>
      <FormattedMessage {...messages.pageTitle} />
    </H1>
    <Table
      pagination={pagination}
      sort={sort}
      countTotal={clientsTotal}
      refetchData={refetchData}
      columns={columns(userRole)}
      withPagination
      data={list}
      actions={actions}
      noItemsMessage={<NoTableItemsMessage accountSubscribed={accountSubscribed} addClient={addClient} />}
    />
    <Modal open={modalOpen} handleClose={addClient}><Form /></Modal>
    <Modal open={clientAdded} handleClose={() => {}}>
      <Box p={5} bgcolor="white" width={450} display="flex" flexDirection="column" alignItems="center">
        {Object.keys(error).length === 0 && (
          <Box color="#3772FF" fontSize={64}><DoneIcon fontSize="inherit" /></Box>
        )}
        <Box mt={3} fontSize={24} color={Object.keys(error).length !== 0 ? '#E20338' : '#011638'}>
          { Object.keys(error).length !== 0
            ? <FormattedMessage {...messages.clientAddedErrorMessage} />
            : <FormattedMessage {...messages.clientAddedMessage} />}
        </Box>
      </Box>
    </Modal>
  </div>
);

export default MyComponent;
