import { put, takeLatest, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { clientsError, refetchClientsSuccess } from '../../actions/Clients';
import { CLIENT_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker({ payload }: any): SagaIterator<void> {
  try {
    const { data } = yield call(Api.clients.refetchClients, payload);
    yield put(refetchClientsSuccess({
      ...payload,
      data,
    }));
  } catch (e) {
    yield put(clientsError(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(CLIENT_TYPES.REFETCH_CLIENTS_REQUEST, worker);
}
