import { defineMessages } from 'react-intl';

export default defineMessages({
  addTransferAccount: {
    id: 'buttons.addTransferAccount',
    defaultMessage: 'add transfer account',
    description: 'Button label'
  },
  addCreditCard: {
    id: 'buttons.addCreditCard',
    defaultMessage: 'add credit card',
    description: 'Button label'
  },
  pageTitle: {
    id: 'settings.pageTitle',
    defaultMessage: 'Settings',
    description: 'Text'
  },
  billingAddress: {
    id: 'wallet.billingAddress',
    defaultMessage: 'Billing Address',
    description: 'Label'
  },
  transferAccounts: {
    id: 'settings.transferAccounts',
    defaultMessage: 'Transfer Accounts',
    description: 'Text'
  },
  bankInfo: {
    id: 'settings.bankInfo',
    defaultMessage: 'These bank accounts are used to receive client payments',
    description: 'Text'
  },
  cards: {
    id: 'settings.cards',
    defaultMessage: 'Billing Credit Cards',
    description: 'Text'
  },
  cardsInfo: {
    id: 'settings.cardsInfo',
    defaultMessage: 'This credit card is used to charge for the ChalicePay subscription.',
    description: 'Text'
  },
});
