import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { FormattedMessage, IntlShape } from 'react-intl';

import messages from './messages';
import { BillingForm, H1, H3Dark } from '../../../../components';
import { withParentData } from '../../Context';
import { chaliceFee } from '../../../../constants';

interface Props {
  submitBilling: (values: {} | null) => void,
  goToBackStep: () => void,
  fetchInitBillingData: () => void,
  intl: IntlShape,
  loading: boolean,
  billingInfo: any,
  accountInfo: any,
}

const BankingDetailsStep: React.FC<Props> = ({
  submitBilling: submit,
  fetchInitBillingData,
  goToBackStep,
  billingInfo,
  accountInfo,
  loading,
}) => {
  useEffect(() => {
    fetchInitBillingData();
  }, [fetchInitBillingData]);

  return (
    <Box width={600}>
      <H1 mb={3}><FormattedMessage {...messages.billingStep} /></H1>
      <H3Dark mb={3}>
        The credit card information you provide below will be used to charge for subscription with ChalicePay.
        Our monthly fixed fee is $
        <span>{chaliceFee}</span>
      </H3Dark>
      <Box
        color="#011638"
        mb="10px"
      >
        <FormattedMessage {...messages.billingAddress} />
      </Box>
      <BillingForm
        loading={loading}
        submitBilling={submit}
        goToBackStep={goToBackStep}
        billingInfo={billingInfo}
        accountInfo={accountInfo}
      />
    </Box>
  );
};

export default withParentData(BankingDetailsStep);
