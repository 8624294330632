import { connect } from 'react-redux';

import {
  fetchInitData,
  sendLink,
  refetchSinglePayments,
  refetchSubscription,
} from '../../redux/actions/ClientProfile';
import { getCompanyDetails } from '../../redux/actions/Settings';
import Container from './Container';

const mapStateToProps = (state: any) => ({
  clientInfo: state.clientProfile.clientInfo,
  singlePayments: state.clientProfile.singlePayments,
  singlePaymentsTotal: state.clientProfile.singlePaymentsTotal,
  singlePaymentsPagination: state.clientProfile.singlePaymentsPagination,
  singlePaymentsSort: state.clientProfile.singlePaymentsSort,
  subscriptions: state.clientProfile.subscriptions,
  subscriptionsTotal: state.clientProfile.subscriptionsTotal,
  subscriptionsPagination: state.clientProfile.subscriptionsPagination,
  subscriptionsSort: state.clientProfile.subscriptionsSort,
  accountVerified: state.settings.companyInfo !== null ? state.settings.companyInfo.status === 'verified' : false,
  accountSubscribed: Boolean(state.settings.companyInfo ? state.settings.companyInfo.subscribed : false),
  isClientDeleted: Boolean(state.settings.clientProfile ? state.clientProfile.error === 404 : false),
  loading: state.clientProfile.loading,
  userRole: state.auth.userRole,
});

const mapDispatchToProps = {
  fetchInitData,
  sendLink,
  refetchSinglePayments,
  refetchSubscription,
  getCompanyDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
