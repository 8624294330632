import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import { MoonLoader } from 'react-spinners';
import { RouteComponentProps } from 'react-router';

import Api from '../../api';

interface Props extends RouteComponentProps {

}

class Container extends Component<Props> {
  componentDidMount() {
    const { history } = this.props;
    Api.auth.loginCallback(window.location.search).then(() => history.push('/'));
  }

  render() {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh" width="100%">
        <MoonLoader
          size={100}
          color="#011638"
        />
      </Box>
    );
  }
}

export default Container;
