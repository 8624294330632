import {
  GetStarted,
  SetPassword,
  OnBoarding,
  SetToken,
  Clients,
  Login,
  ClientProfile,
  SinglePayments,
  Subscriptions,
  Wallet,
  Settings,
  InvoiceHtml,
  InvoiceDetails,
  Profile,
  Transfers,
  SubscriptionDetails,
  Advisors,
  AdvisorProfile,
  TransferDetails,
} from '../screens';

const routes = [
  {
    path: '/',
    component: GetStarted,
    exact: true
  },
  {
    path: '/login',
    component: Login,
    exact: true
  },
  {
    path: '/set-password/:id',
    component: SetPassword,
    exact: true
  },
  {
    path: '/onBoarding',
    component: OnBoarding,
    exact: true,
    private: true
  },
  {
    path: '/set-token',
    component: SetToken,
    exact: true
  },
  {
    path: '/users/clients',
    component: Clients,
    exact: true,
    private: true,
  },
  {
    path: '/users/clients/:id',
    component: ClientProfile,
    exact: true,
    private: true,
  },
  {
    path: '/users/advisors/:id',
    component: AdvisorProfile,
    exact: true,
    private: true,
  },
  {
    path: '/users/advisors',
    component: Advisors,
    exact: true,
    private: true,
  },
  {
    path: '/billing/single-payments',
    component: SinglePayments,
    exact: true,
    private: true,
  },
  {
    path: '/billing/subscriptions',
    component: Subscriptions,
    exact: true,
    private: true,
  },
  {
    path: '/billing/single-payments/invoice/:id',
    component: InvoiceHtml,
    exact: true,
    private: true,
  },
  {
    path: '/transactions/single-payments/invoice-details/:id',
    component: InvoiceDetails,
    exact: true,
    private: true,
  },
  {
    path: '/transactions/single-payments/subscription-details/:id',
    component: SubscriptionDetails,
    exact: true,
    private: true,
  },
  {
    path: '/billing/single-payments/invoice-details/:id',
    component: InvoiceDetails,
    exact: true,
    private: true,
  },
  {
    path: '/billing/single-payments/subscription-details/:id',
    component: SubscriptionDetails,
    exact: true,
    private: true,
  },
  {
    path: '/transactions/single-payments',
    component: SinglePayments,
    exact: true,
    private: true,
  },
  {
    path: '/transactions/single-payments/:id',
    component: SinglePayments,
    exact: true,
    private: true,
  },
  {
    path: '/transactions/single-payments/invoice/:id',
    component: InvoiceHtml,
    exact: true,
    private: true,
  },
  {
    path: '/transactions/subscriptions',
    component: Subscriptions,
    exact: true,
    private: true,
  },
  {
    path: '/transactions/subscriptions/:id',
    component: Subscriptions,
    exact: true,
    private: true,
  },
  {
    path: '/wallet',
    component: Wallet,
    exact: true,
    private: true,
  },
  {
    path: '/billing/transfers',
    component: Transfers,
    exact: true,
    private: true,
  },
  {
    path: '/billing/transfers/:id',
    component: TransferDetails,
    exact: true,
    private: true,
  },
  {
    path: '/settings',
    component: Settings,
    exact: true,
    private: true,
  },
  {
    path: '/settings/:id',
    component: Settings,
    exact: true,
    private: true,
  },
  {
    path: '/profile',
    component: Profile,
    exact: true,
    private: true,
  },
];

export default routes;
