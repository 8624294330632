import { connect } from 'react-redux';

import { singUpWithEmail, login } from '../../redux/actions/onBoaring';

import Container from './Container';

const mapStateToProps = ({ onBoarding, auth }: any) => ({
  error: onBoarding.error,
  emailSend: onBoarding.emailVerificationMessageSend,
  loading: onBoarding.loading,
  isAuthorized: auth.isAuthorized,
});

const mapDispatchToProps = {
  singUpWithEmail,
  login,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
