/* eslint-disable @typescript-eslint/quotes  */
import { defineMessages } from 'react-intl';

export default defineMessages({
  bankingDetailsStepConfirmation: {
    id: 'onBoarding.bankingDetailsStepConfirmation',
    defaultMessage: 'Your bank Account Was Added',
    description: 'Text'
  },
  bankingConfirmDetails1: {
    id: 'onBoarding.bankingConfirmDetails1',
    // eslint-disable-next-line max-len
    defaultMessage: `The bank account information that you just added will be used to receive money from client payments on ChalicePay.`,
    description: 'CheckEmail description'
  },
  bankingConfirmDetails2: {
    id: 'onBoarding.bankingConfirmDetails2',
    // eslint-disable-next-line max-len
    defaultMessage: 'For payments to go through please validate 2 small deposits from us. The deposits will appear in 2-3 days in your billing settings.',
    description: 'CheckEmail description'
  },
  nextButton: {
    id: 'buttons.next',
    defaultMessage: 'Next',
    description: 'Button label'
  }
});
