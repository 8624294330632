import { put, takeLatest, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
  clientProfileError,
  getClientInfoSuccess,
  getClientSubscriptionSuccess,
  getClientSinglePaymentsSuccess,
  fetchInitDataSuccess,
} from '../../actions/ClientProfile';
import { CLIENT_PROFILE_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker({ payload }: any): SagaIterator<void> {
  try {
    const { data } = yield call(Api.clients.getClientById, payload);
    yield put(getClientInfoSuccess(data));

    const { data: subscriptions } = yield call(Api.subscriptions.getClientSubscriptions, payload);
    yield put(getClientSubscriptionSuccess(subscriptions));

    const { data: singlePayments } = yield call(Api.payments.getClientPayments, payload);

    yield put(getClientSinglePaymentsSuccess(singlePayments));
    yield put(fetchInitDataSuccess());
  } catch (e) {
    yield put(clientProfileError(e.response.status));
  }
}

export default function* watcher() {
  yield takeLatest(CLIENT_PROFILE_TYPES.FETCH_INIT_DATA, worker);
}
