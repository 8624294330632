import { put, takeLatest, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { advisorsError, getAdvisorsSuccess } from '../../actions/Advisors';
import { ADVISORS_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker(): SagaIterator<void> {
  try {
    const { data } = yield call(Api.advisors.getAdvisors);
    yield put(getAdvisorsSuccess(data));
  } catch (e) {
    yield put(advisorsError(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(ADVISORS_TYPES.GET_ADVISORS, worker);
}
