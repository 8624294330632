import React from 'react';
import moment from 'moment';
import {CurrencyProvider, Link} from '../../components';

export const columns = [
  {
    Header: 'Arrival date',
    accessor: (row: any) => moment(row.arrive_on).format('MM/DD/YYYY'),
    id: 'arrive_on',
  },
  {
    Header: 'Amount',
    accessor: (row: any) => <CurrencyProvider value={row.amount / 100} />,
    id: 'amount',
  },
  {
    Header: 'Status',
    accessor: (row: any) => <span style={{ textTransform: 'uppercase' }}>{row.status.replace(/_/g, ' ')}</span>,
    id: 'status',
  },
  {
    Header: 'Payments',
    accessor: (row: any) => <Link href={`/billing/transfers/${row.id}`}>{row.invoice_count}</Link>,
    id: 'invoice_count',
  },
];
