import {
  put,
  takeLatest,
  call,
  all,
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { fetchInitDataSuccess, transfersError } from '../../actions/TransferDetails';
import { TRANSFER_DETAILS_TYPES } from '../../constants';
import Api from '../../../api';

export function* worker({ payload }: any): SagaIterator<void> {
  try {
    const { data } = yield call(Api.payments.getCompletedTransfersInvoices, payload);

    yield put(fetchInitDataSuccess(data));
  } catch (e) {
    yield put(transfersError(e.message));
  }
}

export default function* watcher() {
  yield takeLatest(TRANSFER_DETAILS_TYPES.FETCH_INIT_DATA, worker);
}
